export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  CustomString: any;
  DeadlockDocumentContentInputBase64Json: any;
  DeadlockStructDocumentInputBase64Json: any;
  DeadlockUserGeneratedDocumentInputBase64Json: any;
  DestinyDateTime: any;
  DestinyHexColor: any;
  DestinyLocale: any;
  DestinyUrl: any;
  Diablo4DateTime: any;
  Diablo4HexColor: any;
  Diablo4Locale: any;
  Diablo4Url: any;
  ExampleGameDocumentContentInputBase64Json: any;
  ExampleGameStructDocumentInputBase64Json: any;
  ExampleGameUserGeneratedDocumentInputBase64Json: any;
  Int64: any;
  LostArkDateTime: any;
  LostArkHexColor: any;
  LostArkLocale: any;
  LostArkUrl: any;
  Map: any;
  NgfDateTime: any;
  /** NgfDocumentStrJsonLd is a string representation of a JSON-LD structure. */
  NgfDocumentStrJsonLd: any;
  /**
   * NgfDocumentRichTextContent is an arbitrary JSON output of a rich text editor, which is used to store rich text content.
   * - Golang representation - json.RawMessage
   * - Typescript representation - any
   */
  NgfDocumentWidgetRichTextContentJson: any;
  Poe2DocumentContentInputBase64Json: any;
  Poe2StructDocumentInputBase64Json: any;
  Poe2UserGeneratedDocumentInputBase64Json: any;
  /**
   * Puuid is unique identifier for a player regionwise.
   * Fixed length 78 characters.
   * https://riot-api-libraries.readthedocs.io/en/latest/ids.htmlr
   */
  Puuid: any;
  /** Coordinate type for unit position in composition. */
  TftCoordinates: any;
  /**
   * DateTime in RFC3339
   * i.e "2006-01-02T15:04:05Z07:00"
   */
  TftDateTime: any;
  /**
   * Set is an alias for a season of TFT.
   * Must be positive integer.
   */
  TftSet: any;
  TheBazaarDocumentContentInputBase64Json: any;
  TheBazaarStructDocumentInputBase64Json: any;
  TheBazaarUserGeneratedDocumentInputBase64Json: any;
  Timestamp: any;
  TreasuryFlowTime: any;
  TreasuryFlowTimeDuration: any;
  /**
   * Treasury Payments API duration type
   * Implements ISO8601 duration format
   */
  TreasuryPaymentsDuration: any;
  /**
   * Treasury Payments API time type
   * Implements RFC3999 time format
   */
  TreasuryPaymentsTime: any;
};

/** !!! DEPRECATED !!! */
export enum AugmentTier {
  A = 'A',
  B = 'B',
  S = 'S',
}

export type AutogeneratedMainType = {
  __typename?: 'AutogeneratedMainType';
  aspectSlots?: Maybe<Array<Maybe<Diablo4AspectSlot>>>;
  aspectTypes?: Maybe<Array<Maybe<Diablo4AspectType>>>;
  aspects?: Maybe<Array<Maybe<Diablo4Aspect>>>;
  buildSlots?: Maybe<Array<Maybe<Diablo4BuildSlot>>>;
  classes?: Maybe<Array<Maybe<Diablo4Class>>>;
  dungeons?: Maybe<Array<Maybe<Diablo4Dungeon>>>;
  gemTypes?: Maybe<Array<Maybe<Diablo4GemType>>>;
  gems?: Maybe<Array<Maybe<Diablo4Gem>>>;
  governingStones?: Maybe<Array<Maybe<Diablo4GoverningStone>>>;
  hearts?: Maybe<Array<Maybe<Diablo4Heart>>>;
  locations?: Maybe<Array<Maybe<Diablo4Location>>>;
  mapItemTypes?: Maybe<Array<Maybe<Diablo4MapItemType>>>;
  mapItems?: Maybe<Array<Maybe<Diablo4MapItem>>>;
  mapZones?: Maybe<Array<Maybe<Diablo4MapZone>>>;
  mercenaries?: Maybe<Array<Maybe<Diablo4Mercenary>>>;
  mercenarySkills?: Maybe<Array<Maybe<Diablo4MercenarySkill>>>;
  opportunities?: Maybe<Array<Maybe<Diablo4Opportunity>>>;
  paragonBoards?: Maybe<Array<Maybe<Diablo4ParagonBoard>>>;
  paragonGlyphRarities?: Maybe<Array<Maybe<Diablo4ParagonGlyphRarity>>>;
  paragonGlyphs?: Maybe<Array<Maybe<Diablo4ParagonGlyph>>>;
  paragonNodeTypes?: Maybe<Array<Maybe<Diablo4ParagonNodeType>>>;
  paragonNodes?: Maybe<Array<Maybe<Diablo4ParagonNode>>>;
  runes?: Maybe<Array<Maybe<Diablo4Rune>>>;
  skillResources?: Maybe<Array<Maybe<Diablo4SkillResource>>>;
  skillSections?: Maybe<Array<Maybe<Diablo4SkillSection>>>;
  skillTags?: Maybe<Array<Maybe<Diablo4SkillTag>>>;
  skillTypes?: Maybe<Array<Maybe<Diablo4SkillType>>>;
  skills?: Maybe<Array<Maybe<Diablo4Skill>>>;
  statImplicit?: Maybe<Array<Maybe<Diablo4StatImplicit>>>;
  statTempering?: Maybe<Array<Maybe<Diablo4StatTempering>>>;
  statTypes?: Maybe<Array<Maybe<Diablo4StatType>>>;
  stats?: Maybe<Array<Maybe<Diablo4Stat>>>;
  stoneRarities?: Maybe<Array<Maybe<Diablo4StoneRarity>>>;
  tiers?: Maybe<Array<Maybe<Diablo4Tier>>>;
  tuningStones?: Maybe<Array<Maybe<Diablo4TuningStone>>>;
  uniqueItemTypes?: Maybe<Array<Maybe<Diablo4UniqueItemType>>>;
  uniqueItems?: Maybe<Array<Maybe<Diablo4UniqueItem>>>;
  vampirePowers?: Maybe<Array<Maybe<Diablo4VampirePower>>>;
  witchcraftPowers?: Maybe<Array<Maybe<Diablo4WitchcraftPower>>>;
};

export type AvailableWidget = {
  __typename?: 'AvailableWidget';
  meta: WidgetMeta;
  prohibitedForRegularUser: Scalars['Boolean'];
};

export enum BadgeVar {
  ARCHETYPE_ID = 'ARCHETYPE_ID',
  CHAMPION_SLUG = 'CHAMPION_SLUG',
  LOSE_NUMBER = 'LOSE_NUMBER',
  LP_NUMBER = 'LP_NUMBER',
  PLACE_NUMBER = 'PLACE_NUMBER',
  WINS_NUMBER = 'WINS_NUMBER',
}

export type CharacterStrengthAndWeaknessOrError = CharacterStrengthsAndWeaknesses | LostArkGenericError;

export type CharacterStrengthsAndWeaknesses = {
  __typename?: 'CharacterStrengthsAndWeaknesses';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type CursorPage = {
  count: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
};

export type CursorPaginationInfo = {
  __typename?: 'CursorPaginationInfo';
  cursor: Scalars['String'];
  hasMoreItems: Scalars['Boolean'];
};

export type DeadlockAbilityStat = {
  __typename?: 'DeadlockAbilityStat';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type DeadlockAbilityStatsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type DeadlockAbilityStatsResult = {
  __typename?: 'DeadlockAbilityStatsResult';
  data?: Maybe<Array<DeadlockAbilityStat>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type DeadlockActive = {
  __typename?: 'DeadlockActive';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Array<DeadlockValue>>;
};

export type DeadlockAutogeneratedMainType = {
  __typename?: 'DeadlockAutogeneratedMainType';
  abilityStats?: Maybe<Array<Maybe<DeadlockAbilityStat>>>;
  heroAbilities?: Maybe<Array<Maybe<DeadlockHeroAbility>>>;
  heroStats?: Maybe<Array<Maybe<DeadlockHeroStat>>>;
  heroes?: Maybe<Array<Maybe<DeadlockHero>>>;
  itemStats?: Maybe<Array<Maybe<DeadlockItemStat>>>;
  itemTypes?: Maybe<Array<Maybe<DeadlockItemType>>>;
  items?: Maybe<Array<Maybe<DeadlockItem>>>;
};

export type DeadlockBoxstat = {
  __typename?: 'DeadlockBoxstat';
  description?: Maybe<Scalars['String']>;
  spiritAffected?: Maybe<Scalars['Boolean']>;
};

export type DeadlockDocumentCmWidgetBuildsStatsV1 = NgfDocumentContent & {
  __typename?: 'DeadlockDocumentCmWidgetBuildsStatsV1';
  data: DeadlockDocumentCmWidgetBuildsStatsV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type DeadlockDocumentCmWidgetBuildsStatsV1Data = {
  __typename?: 'DeadlockDocumentCmWidgetBuildsStatsV1Data';
  hero: DeadlockHero;
};

export type DeadlockDocumentCmWidgetHeroHeaderV1 = NgfDocumentContent & {
  __typename?: 'DeadlockDocumentCmWidgetHeroHeaderV1';
  data: DeadlockDocumentCmWidgetHeroHeaderV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type DeadlockDocumentCmWidgetHeroHeaderV1Data = {
  __typename?: 'DeadlockDocumentCmWidgetHeroHeaderV1Data';
  hero: DeadlockHero;
};

export type DeadlockDocumentCmWidgetV1 = NgfDocumentContent & {
  __typename?: 'DeadlockDocumentCmWidgetV1';
  data: DeadlockDocumentCmWidgetV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type DeadlockDocumentCmWidgetV1Data = {
  __typename?: 'DeadlockDocumentCmWidgetV1Data';
  border: Scalars['String'];
  content: Scalars['NgfDocumentWidgetRichTextContentJson'];
  title: Scalars['String'];
};

export type DeadlockDocumentContent =
  | DeadlockDocumentCmWidgetBuildsStatsV1
  | DeadlockDocumentCmWidgetHeroHeaderV1
  | DeadlockDocumentCmWidgetV1
  | NgfDocumentCmSectionAuto1V1
  | NgfDocumentCmSectionAuto3V1
  | NgfDocumentCmSectionAuto400V1
  | NgfDocumentCmSectionVideoAdV1
  | NgfDocumentCmWidgetCardGridV1
  | NgfDocumentCmWidgetCardGridV2
  | NgfDocumentCmWidgetContentVariantsV1
  | NgfDocumentCmWidgetCrossPageTabBarV1
  | NgfDocumentCmWidgetDiscoveryPreviewCompactV1
  | NgfDocumentCmWidgetDiscoveryPreviewCompactV2
  | NgfDocumentCmWidgetDiscoveryPreviewV1
  | NgfDocumentCmWidgetDiscoveryPreviewV2
  | NgfDocumentCmWidgetDocumentsFeedV1
  | NgfDocumentCmWidgetDocumentsFeedV2
  | NgfDocumentCmWidgetErrorV1
  | NgfDocumentCmWidgetGameDataCardGridV1
  | NgfDocumentCmWidgetGameDataCardGridV2
  | NgfDocumentCmWidgetPromoBannerHorizontalV1
  | NgfDocumentCmWidgetPromoBannerNetboardV1
  | NgfDocumentCmWidgetRichTextSimplifiedV1
  | NgfDocumentCmWidgetRichTextSimplifiedV2
  | NgfDocumentCmWidgetRichTextV1
  | NgfDocumentCmWidgetRichTextV2
  | NgfDocumentCmWidgetTableOfContentV1
  | NgfDocumentCmWidgetUGTierListV1
  | NgfDocumentCmWidgetUGTierListV2
  | NgfDocumentCmWidgetVideoV1
  | NgfDocumentCmWidgetVideoV2
  | NgfDocumentStWidgetDocumentsDiscoveryV1
  | NgfDocumentStWidgetHeaderV1
  | NgfDocumentStWidgetHeaderV2
  | NgfDocumentStWidgetHomePageHeaderV1
  | NgfDocumentStWidgetHomePageHeaderV2
  | NgfDocumentStWidgetLinksGridManualV1
  | NgfDocumentStWidgetLinksGridManualV2
  | NgfDocumentUgWidgetHeaderV1;

export type DeadlockDocumentSeason = NgfDocumentSeason & {
  __typename?: 'DeadlockDocumentSeason';
  slug: Scalars['String'];
};

export type DeadlockDocumentTags = NgfDocumentTags & {
  __typename?: 'DeadlockDocumentTags';
  data?: Maybe<Array<NgfDocumentTag>>;
};

export type DeadlockDocumentsMutation = {
  __typename?: 'DeadlockDocumentsMutation';
  /** Create operation creates a new document with the provided data and content. */
  structDocumentCreate: DeadlockStructDocumentCreatePayload;
  /** SEO operation affects only document.seo field. */
  structDocumentSetSeo: DeadlockStructDocumentSetSeoPayload;
  /** Status operation affects only document.status field. */
  structDocumentSetStatus: DeadlockStructDocumentSetStatusPayload;
  /** Update operation affects only document.data and document.content fields. */
  structDocumentUpdate: DeadlockStructDocumentUpdatePayload;
  /** Create operation creates a new document with the provided data and content. */
  userGeneratedDocumentCreate: DeadlockUserGeneratedDocumentCreatePayload;
  /** Status operation affects only document.favorites field. */
  userGeneratedDocumentSetFavorite: DeadlockUserGeneratedDocumentSetFavoritePayload;
  /** Status operation affects only document.featured field. */
  userGeneratedDocumentSetFeatured: DeadlockUserGeneratedDocumentSetFeaturedPayload;
  /** SEO operation affects only document.seo field. */
  userGeneratedDocumentSetSeo: DeadlockUserGeneratedDocumentSetSeoPayload;
  /** Status operation affects only document.status field. */
  userGeneratedDocumentSetStatus: DeadlockUserGeneratedDocumentSetStatusPayload;
  /** Update operation affects only document.data and document.content fields. */
  userGeneratedDocumentUpdate: DeadlockUserGeneratedDocumentUpdatePayload;
};

export type DeadlockDocumentsMutationstructDocumentCreateArgs = {
  input: DeadlockStructDocumentCreateInput;
};

export type DeadlockDocumentsMutationstructDocumentSetSeoArgs = {
  input: NgfDocumentSetSeoInput;
};

export type DeadlockDocumentsMutationstructDocumentSetStatusArgs = {
  input: DeadlockStructDocumentSetStatusInput;
};

export type DeadlockDocumentsMutationstructDocumentUpdateArgs = {
  input: DeadlockStructDocumentUpdateInput;
};

export type DeadlockDocumentsMutationuserGeneratedDocumentCreateArgs = {
  input: DeadlockUserGeneratedDocumentCreateInput;
};

export type DeadlockDocumentsMutationuserGeneratedDocumentSetFavoriteArgs = {
  input: DeadlockUserGeneratedDocumentSetFavoriteInput;
};

export type DeadlockDocumentsMutationuserGeneratedDocumentSetFeaturedArgs = {
  input: DeadlockUserGeneratedDocumentSetFeaturedInput;
};

export type DeadlockDocumentsMutationuserGeneratedDocumentSetSeoArgs = {
  input: NgfDocumentSetSeoInput;
};

export type DeadlockDocumentsMutationuserGeneratedDocumentSetStatusArgs = {
  input: DeadlockUserGeneratedDocumentSetStatusInput;
};

export type DeadlockDocumentsMutationuserGeneratedDocumentUpdateArgs = {
  input: DeadlockUserGeneratedDocumentUpdateInput;
};

export type DeadlockDocumentsQuery = {
  __typename?: 'DeadlockDocumentsQuery';
  previewDocumentContent: DeadlockPreviewDocumentContentPayload;
  structDocumentBySlug: DeadlockStructDocumentPayload;
  structDocumentWidgets: NgfStructDocumentWidgetsPayload;
  structDocuments: NgfStructDocumentsListPayload;
  userGeneratedDocumentById: DeadlockUserGeneratedDocumentPayload;
  userGeneratedDocumentBySlug: DeadlockUserGeneratedDocumentPayload;
  userGeneratedDocumentTagGroups: NgfUserGeneratedDocumentTagGroupsPayload;
  userGeneratedDocumentTemplate: DeadlockTemplateDocumentPayload;
  userGeneratedDocumentTypes: NgfUserGeneratedDocumentTypesPayload;
  userGeneratedDocuments: NgfUserGeneratedDocumentsListPayload;
};

export type DeadlockDocumentsQuerypreviewDocumentContentArgs = {
  input: DeadlockPreviewDocumentContentInput;
};

export type DeadlockDocumentsQuerystructDocumentBySlugArgs = {
  input: DeadlockStructDocumentInputBySlug;
};

export type DeadlockDocumentsQuerystructDocumentsArgs = {
  input: DeadlockStructDocumentsInput;
};

export type DeadlockDocumentsQueryuserGeneratedDocumentByIdArgs = {
  input: DeadlockUserGeneratedDocumentInputById;
};

export type DeadlockDocumentsQueryuserGeneratedDocumentBySlugArgs = {
  input: DeadlockUserGeneratedDocumentInputBySlug;
};

export type DeadlockDocumentsQueryuserGeneratedDocumentTagGroupsArgs = {
  input?: InputMaybe<NgfUserGeneratedDocumentTagGroupsInput>;
};

export type DeadlockDocumentsQueryuserGeneratedDocumentTemplateArgs = {
  input: DeadlockTemplateDocumentInput;
};

export type DeadlockDocumentsQueryuserGeneratedDocumentsArgs = {
  input: DeadlockUserGeneratedDocumentsListInput;
  page: DeadlockUserGeneratedDocumentsListPage;
};

export type DeadlockDynamicBuild = {
  __typename?: 'DeadlockDynamicBuild';
  abilityOrder?: Maybe<Array<DeadlockHeroAbility>>;
  abilityPickRates?: Maybe<Array<DeadlockDynamicBuildAbilityPickRates>>;
  buildID: Scalars['String'];
  earlyGameItems?: Maybe<Array<DeadlockDynamicBuildItemPickRate>>;
  firstMaxedAbility: DeadlockHeroAbility;
  lateGameItems?: Maybe<Array<DeadlockDynamicBuildItemPickRate>>;
  matches: Scalars['Int64'];
  midGameItems?: Maybe<Array<DeadlockDynamicBuildItemPickRate>>;
  wins: Scalars['Int64'];
};

export type DeadlockDynamicBuildAbilityPickRates = {
  __typename?: 'DeadlockDynamicBuildAbilityPickRates';
  ability: DeadlockHeroAbility;
  rates?: Maybe<Array<Scalars['Float']>>;
};

export type DeadlockDynamicBuildItemPickRate = {
  __typename?: 'DeadlockDynamicBuildItemPickRate';
  item: DeadlockItem;
  rate: Scalars['Float'];
};

export type DeadlockDynamicBuildsFilter = {
  buildId?: InputMaybe<Scalars['String']>;
  heroId: Scalars['String'];
};

export type DeadlockDynamicBuildsPayload = {
  __typename?: 'DeadlockDynamicBuildsPayload';
  builds?: Maybe<Array<DeadlockDynamicBuild>>;
  hero?: Maybe<DeadlockHero>;
};

export type DeadlockGameDataQuery = {
  __typename?: 'DeadlockGameDataQuery';
  deadlockAbilityStats: DeadlockAbilityStatsResult;
  deadlockHeroAbilities: DeadlockHeroAbilitiesResult;
  deadlockHeroStats: DeadlockHeroStatsResult;
  deadlockHeroes: DeadlockHeroesResult;
  deadlockItemStats: DeadlockItemStatsResult;
  deadlockItemTypes: DeadlockItemTypesResult;
  deadlockItems: DeadlockItemsResult;
};

export type DeadlockGameDataQuerydeadlockAbilityStatsArgs = {
  filter?: InputMaybe<DeadlockAbilityStatsFilter>;
};

export type DeadlockGameDataQuerydeadlockHeroAbilitiesArgs = {
  filter?: InputMaybe<DeadlockHeroAbilitiesFilter>;
};

export type DeadlockGameDataQuerydeadlockHeroStatsArgs = {
  filter?: InputMaybe<DeadlockHeroStatsFilter>;
};

export type DeadlockGameDataQuerydeadlockHeroesArgs = {
  filter?: InputMaybe<DeadlockHeroesFilter>;
};

export type DeadlockGameDataQuerydeadlockItemStatsArgs = {
  filter?: InputMaybe<DeadlockItemStatsFilter>;
};

export type DeadlockGameDataQuerydeadlockItemTypesArgs = {
  filter?: InputMaybe<DeadlockItemTypesFilter>;
};

export type DeadlockGameDataQuerydeadlockItemsArgs = {
  filter?: InputMaybe<DeadlockItemsFilter>;
};

export type DeadlockGeneralStat = {
  __typename?: 'DeadlockGeneralStat';
  slug: Scalars['String'];
  spiritAffected?: Maybe<Scalars['Boolean']>;
  stat?: Maybe<DeadlockAbilityStat>;
  value?: Maybe<Scalars['String']>;
};

export type DeadlockHero = {
  __typename?: 'DeadlockHero';
  abilities?: Maybe<Array<Scalars['String']>>;
  fullDescription?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  heroBuildsStats?: Maybe<Array<DeadlockDynamicBuild>>;
  heroStats?: Maybe<DeadlockHeroStatsData>;
  heroStatsValues?: Maybe<Array<DeadlockHeroStatsValue>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  quickDescription?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
};

export type DeadlockHeroheroStatsArgs = {
  filter?: InputMaybe<DeadlockHeroHeroStatsFilter>;
};

export type DeadlockHeroAbilitiesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type DeadlockHeroAbilitiesResult = {
  __typename?: 'DeadlockHeroAbilitiesResult';
  data?: Maybe<Array<DeadlockHeroAbility>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type DeadlockHeroAbility = {
  __typename?: 'DeadlockHeroAbility';
  boxstats?: Maybe<Array<DeadlockBoxstat>>;
  description?: Maybe<Scalars['String']>;
  generalStats?: Maybe<Array<DeadlockGeneralStat>>;
  hero?: Maybe<Scalars['String']>;
  hotkey?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  powerUp?: Maybe<Array<DeadlockPowerUp>>;
  slug: Scalars['String'];
};

export type DeadlockHeroHeroStatsFilter = {
  region?: InputMaybe<Scalars['String']>;
};

export type DeadlockHeroStat = {
  __typename?: 'DeadlockHeroStat';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type DeadlockHeroStatsData = {
  __typename?: 'DeadlockHeroStatsData';
  avgDenies: Scalars['Float'];
  avgKda: Scalars['Float'];
  avgLastHitPerMinute: Scalars['Float'];
  avgNetWorthPerMinute: Scalars['Float'];
  gamesAmount: Scalars['Int64'];
  hero?: Maybe<DeadlockHero>;
  pickRate: Scalars['Float'];
  winRate: Scalars['Float'];
};

export type DeadlockHeroStatsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type DeadlockHeroStatsResult = {
  __typename?: 'DeadlockHeroStatsResult';
  data?: Maybe<Array<DeadlockHeroStat>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type DeadlockHeroStatsValue = {
  __typename?: 'DeadlockHeroStatsValue';
  powerUp?: Maybe<Scalars['Float']>;
  slug: Scalars['String'];
  spiritAffected?: Maybe<Scalars['Boolean']>;
  stat?: Maybe<DeadlockHeroStat>;
  value?: Maybe<Scalars['String']>;
};

export type DeadlockHeroTierListFilter = {
  heroID?: InputMaybe<Scalars['Int']>;
};

export type DeadlockHeroesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type DeadlockHeroesResult = {
  __typename?: 'DeadlockHeroesResult';
  data?: Maybe<Array<DeadlockHero>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type DeadlockItem = {
  __typename?: 'DeadlockItem';
  active?: Maybe<Array<DeadlockActive>>;
  componentOf?: Maybe<Array<DeadlockItem>>;
  componentOfSlugs?: Maybe<Array<Scalars['String']>>;
  components?: Maybe<Array<DeadlockItem>>;
  componentsSlugs?: Maybe<Array<Scalars['String']>>;
  cost?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  itemStatsValues?: Maybe<Array<DeadlockItemStatsValue>>;
  itemType?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  passive?: Maybe<Array<DeadlockPassive>>;
  slug: Scalars['String'];
};

export type DeadlockItemStat = {
  __typename?: 'DeadlockItemStat';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type DeadlockItemStatsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type DeadlockItemStatsResult = {
  __typename?: 'DeadlockItemStatsResult';
  data?: Maybe<Array<DeadlockItemStat>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type DeadlockItemStatsValue = {
  __typename?: 'DeadlockItemStatsValue';
  slug: Scalars['String'];
  stat?: Maybe<DeadlockItemStat>;
  value?: Maybe<Scalars['String']>;
};

export type DeadlockItemType = {
  __typename?: 'DeadlockItemType';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type DeadlockItemTypesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type DeadlockItemTypesResult = {
  __typename?: 'DeadlockItemTypesResult';
  data?: Maybe<Array<DeadlockItemType>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type DeadlockItemsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type DeadlockItemsResult = {
  __typename?: 'DeadlockItemsResult';
  data?: Maybe<Array<DeadlockItem>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type DeadlockMutation = {
  __typename?: 'DeadlockMutation';
  documents?: Maybe<DeadlockDocumentsMutation>;
  id: Scalars['ID'];
};

export type DeadlockPassive = {
  __typename?: 'DeadlockPassive';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Array<DeadlockValue>>;
};

export type DeadlockPowerUp = {
  __typename?: 'DeadlockPowerUp';
  description?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['String']>;
};

export enum DeadlockPreviewDocumentContentError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type DeadlockPreviewDocumentContentInput = {
  content: Scalars['DeadlockDocumentContentInputBase64Json'];
};

export type DeadlockPreviewDocumentContentPayload = {
  __typename?: 'DeadlockPreviewDocumentContentPayload';
  data?: Maybe<DeadlockDocumentContent>;
  error?: Maybe<DeadlockPreviewDocumentContentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type DeadlockProfileGeneralStats = {
  __typename?: 'DeadlockProfileGeneralStats';
  accuracy: Scalars['Int'];
  assists: Scalars['Float'];
  creepsPerMin: Scalars['Float'];
  deaths: Scalars['Float'];
  denies: Scalars['Float'];
  gamesAmount: Scalars['Int'];
  headshots: Scalars['Float'];
  heroDamagePerMin: Scalars['Float'];
  heroesStats: Array<DeadlockProfileHeroStats>;
  kda: Scalars['Float'];
  kills: Scalars['Float'];
  killsPerMin: Scalars['Float'];
  netWorthPerMin: Scalars['Float'];
  objDamage: Scalars['Int'];
  objDamagePerMin: Scalars['Float'];
  percentiles?: Maybe<DeadlockProfileGeneralStatsPercentiles>;
  steamAccount: DeadlockProfileSteamAccountInfo;
  winRate: Scalars['Float'];
};

export type DeadlockProfileGeneralStatsFilter = {
  steamId: Scalars['String'];
};

export type DeadlockProfileGeneralStatsPayload = {
  __typename?: 'DeadlockProfileGeneralStatsPayload';
  data?: Maybe<DeadlockProfileGeneralStats>;
  error?: Maybe<DeadlockStatsError>;
};

export type DeadlockProfileGeneralStatsPercentiles = {
  __typename?: 'DeadlockProfileGeneralStatsPercentiles';
  kda: Scalars['Float'];
  winRate: Scalars['Float'];
};

export type DeadlockProfileHeroStats = {
  __typename?: 'DeadlockProfileHeroStats';
  creepsPerMin: Scalars['Float'];
  gamesAmount: Scalars['Int'];
  headshots: Scalars['Float'];
  hero?: Maybe<DeadlockHero>;
  heroDamagePerMin: Scalars['Float'];
  kda: Scalars['Float'];
  netWorthPerMin: Scalars['Float'];
  winRate: Scalars['Float'];
};

export type DeadlockProfileMatchStatsPercentiles = {
  __typename?: 'DeadlockProfileMatchStatsPercentiles';
  kda: Scalars['Float'];
};

export type DeadlockProfileQuery = {
  __typename?: 'DeadlockProfileQuery';
  generalStats: DeadlockProfileGeneralStatsPayload;
  recentMatches: DeadlockProfileRecentMatchesPayload;
};

export type DeadlockProfileQuerygeneralStatsArgs = {
  filter: DeadlockProfileGeneralStatsFilter;
};

export type DeadlockProfileQueryrecentMatchesArgs = {
  filter: DeadlockProfileRecentMatchesFilter;
  page: DeadlockStatsCursorPage;
};

export type DeadlockProfileRecentMatch = {
  __typename?: 'DeadlockProfileRecentMatch';
  assists: Scalars['Int'];
  deaths: Scalars['Int'];
  denies: Scalars['Int'];
  duration: Scalars['Int'];
  hero?: Maybe<DeadlockHero>;
  heroDamage: Scalars['Int'];
  kda: Scalars['Float'];
  kills: Scalars['Int'];
  lastHits: Scalars['Int'];
  losingTeam: Array<DeadlockProfileTeamPlayerInfo>;
  matchId: Scalars['ID'];
  netWorth: Scalars['Int'];
  percentiles?: Maybe<DeadlockProfileMatchStatsPercentiles>;
  slug: Scalars['String'];
  timestamp: Scalars['Int'];
  win: Scalars['Boolean'];
  winningTeam: Array<DeadlockProfileTeamPlayerInfo>;
};

export type DeadlockProfileRecentMatchesFilter = {
  steamId: Scalars['String'];
};

export type DeadlockProfileRecentMatchesPayload = {
  __typename?: 'DeadlockProfileRecentMatchesPayload';
  data?: Maybe<Array<DeadlockProfileRecentMatch>>;
  error?: Maybe<DeadlockStatsError>;
  paginationInfo?: Maybe<DeadlockStatsCursorPaginationInfo>;
};

export type DeadlockProfileSteamAccountInfo = {
  __typename?: 'DeadlockProfileSteamAccountInfo';
  avatarURL?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  steamId: Scalars['ID'];
};

export type DeadlockProfileTeamPlayerInfo = {
  __typename?: 'DeadlockProfileTeamPlayerInfo';
  hero?: Maybe<DeadlockHero>;
  steamAccount: DeadlockProfileSteamAccountInfo;
};

export type DeadlockQuery = {
  __typename?: 'DeadlockQuery';
  documents?: Maybe<DeadlockDocumentsQuery>;
  gameData?: Maybe<DeadlockGameDataQuery>;
  id: Scalars['ID'];
  profile?: Maybe<DeadlockProfileQuery>;
  search?: Maybe<DeadlockSearchResultPayload>;
  stats?: Maybe<DeadlockStatsQuery>;
};

export type DeadlockQuerysearchArgs = {
  query: Scalars['String'];
  top?: InputMaybe<Scalars['Int']>;
};

export type DeadlockSearchResultPayload = {
  __typename?: 'DeadlockSearchResultPayload';
  players?: Maybe<Array<DeadlockProfileSteamAccountInfo>>;
};

export type DeadlockStatsBadRequestError = DeadlockStatsError & {
  __typename?: 'DeadlockStatsBadRequestError';
  message?: Maybe<Scalars['String']>;
};

export type DeadlockStatsCursorPage = {
  count: Scalars['Int'];
  cursor?: InputMaybe<Scalars['String']>;
};

export type DeadlockStatsCursorPaginationInfo = {
  __typename?: 'DeadlockStatsCursorPaginationInfo';
  cursor: Scalars['String'];
  hasMoreItems: Scalars['Boolean'];
};

export type DeadlockStatsError = {
  message?: Maybe<Scalars['String']>;
};

export type DeadlockStatsHeroCounter = {
  __typename?: 'DeadlockStatsHeroCounter';
  hero?: Maybe<DeadlockHero>;
  winRate: Scalars['Float'];
  /** @deprecated Use `winRate` instead, already in percent */
  winRateFactor: Scalars['Float'];
};

export type DeadlockStatsHeroCountersData = {
  __typename?: 'DeadlockStatsHeroCountersData';
  counters: Array<DeadlockStatsHeroCounter>;
  hero?: Maybe<DeadlockHero>;
  winRate: Scalars['Float'];
};

export type DeadlockStatsInternalError = DeadlockStatsError & {
  __typename?: 'DeadlockStatsInternalError';
  message?: Maybe<Scalars['String']>;
};

export type DeadlockStatsNotFoundError = DeadlockStatsError & {
  __typename?: 'DeadlockStatsNotFoundError';
  message?: Maybe<Scalars['String']>;
};

export type DeadlockStatsQuery = {
  __typename?: 'DeadlockStatsQuery';
  builds: DeadlockDynamicBuildsPayload;
  heroCounters: Array<DeadlockStatsHeroCountersData>;
  heroTierList: Array<DeadlockHeroStatsData>;
  synergies: DeadlockStatsSynergiesPayload;
};

export type DeadlockStatsQuerybuildsArgs = {
  filter: DeadlockDynamicBuildsFilter;
};

export type DeadlockStatsQueryheroTierListArgs = {
  filter?: InputMaybe<DeadlockHeroTierListFilter>;
};

export type DeadlockStatsQuerysynergiesArgs = {
  filter: DeadlockStatsSynergiesFilter;
  page: DeadlockStatsCursorPage;
};

export type DeadlockStatsSynergiesFilter = {
  heroID?: InputMaybe<Scalars['Int']>;
};

export type DeadlockStatsSynergiesPayload = {
  __typename?: 'DeadlockStatsSynergiesPayload';
  data: Array<DeadlockStatsSynergyData>;
  paginationInfo: DeadlockStatsCursorPaginationInfo;
};

export type DeadlockStatsSynergyData = {
  __typename?: 'DeadlockStatsSynergyData';
  avgDenies: Scalars['Float'];
  avgKda: Scalars['Float'];
  avgLastHitPerMinute: Scalars['Float'];
  avgNetWorthPerMinute: Scalars['Float'];
  hero1?: Maybe<DeadlockHero>;
  hero2?: Maybe<DeadlockHero>;
  pickRate: Scalars['Float'];
  slug: Scalars['String'];
  winRate: Scalars['Float'];
  winRateFactor: Scalars['Float'];
};

export type DeadlockStructDocument = NgfStructDocument & {
  __typename?: 'DeadlockStructDocument';
  content: Array<DeadlockDocumentContent>;
  createdAt: Scalars['NgfDateTime'];
  data: DeadlockStructDocumentData;
  id: Scalars['ID'];
  permissions?: Maybe<NgfDocumentPermissions>;
  seo?: Maybe<NgfDocumentSeo>;
  slug: Scalars['String'];
  status: NgfStructDocumentStatus;
  updatedAt: Scalars['NgfDateTime'];
  version: Scalars['Int'];
};

export enum DeadlockStructDocumentCreateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
}

export type DeadlockStructDocumentCreateInput = {
  document: Scalars['DeadlockStructDocumentInputBase64Json'];
};

export type DeadlockStructDocumentCreatePayload = {
  __typename?: 'DeadlockStructDocumentCreatePayload';
  data?: Maybe<DeadlockStructDocument>;
  error?: Maybe<DeadlockStructDocumentCreateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type DeadlockStructDocumentData = NgfStructDocumentData & {
  __typename?: 'DeadlockStructDocumentData';
  childrenIds: Array<Scalars['String']>;
  name: Scalars['String'];
  season: DeadlockDocumentSeason;
  tags?: Maybe<DeadlockDocumentTags>;
};

export enum DeadlockStructDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type DeadlockStructDocumentInputBySlug = {
  /** Slug is unique human readable identifier of a struct document. */
  slug: Scalars['String'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type DeadlockStructDocumentPayload = {
  __typename?: 'DeadlockStructDocumentPayload';
  data?: Maybe<DeadlockStructDocument>;
  error?: Maybe<DeadlockStructDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum DeadlockStructDocumentSetSeoError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type DeadlockStructDocumentSetSeoPayload = {
  __typename?: 'DeadlockStructDocumentSetSeoPayload';
  data?: Maybe<DeadlockStructDocument>;
  error?: Maybe<DeadlockStructDocumentSetSeoError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum DeadlockStructDocumentSetStatusError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type DeadlockStructDocumentSetStatusInput = {
  id: Scalars['ID'];
  status: NgfStructDocumentStatus;
};

export type DeadlockStructDocumentSetStatusPayload = {
  __typename?: 'DeadlockStructDocumentSetStatusPayload';
  data?: Maybe<DeadlockStructDocument>;
  error?: Maybe<DeadlockStructDocumentSetStatusError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum DeadlockStructDocumentUpdateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type DeadlockStructDocumentUpdateInput = {
  document: Scalars['DeadlockStructDocumentInputBase64Json'];
  id: Scalars['ID'];
};

export type DeadlockStructDocumentUpdatePayload = {
  __typename?: 'DeadlockStructDocumentUpdatePayload';
  data?: Maybe<DeadlockStructDocument>;
  error?: Maybe<DeadlockStructDocumentUpdateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type DeadlockStructDocumentsInput = {
  statuses: Array<NgfStructDocumentStatus>;
};

export type DeadlockTemplateDocument = NgfTemplateDocument & {
  __typename?: 'DeadlockTemplateDocument';
  content: Array<DeadlockDocumentContent>;
  data: DeadlockTemplateDocumentData;
  type: Scalars['String'];
  typeData: NgfDocumentType;
};

export type DeadlockTemplateDocumentData = NgfTemplateDocumentData & {
  __typename?: 'DeadlockTemplateDocumentData';
  childrenIds: Array<Scalars['String']>;
  name: Scalars['String'];
  season: DeadlockDocumentSeason;
};

export enum DeadlockTemplateDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
}

export type DeadlockTemplateDocumentInput = {
  type: Scalars['String'];
};

export type DeadlockTemplateDocumentPayload = {
  __typename?: 'DeadlockTemplateDocumentPayload';
  data?: Maybe<DeadlockTemplateDocument>;
  error?: Maybe<DeadlockTemplateDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type DeadlockUserGeneratedDocument = NgfUserGeneratedDocument & {
  __typename?: 'DeadlockUserGeneratedDocument';
  author: NgfDocumentAuthor;
  content: Array<DeadlockDocumentContent>;
  createdAt: Scalars['NgfDateTime'];
  data: DeadlockUserGeneratedDocumentData;
  favorites?: Maybe<NgfDocumentFavorites>;
  featured?: Maybe<NgfDocumentFeatured>;
  firstPublishedAt?: Maybe<Scalars['NgfDateTime']>;
  id: Scalars['ID'];
  permissions?: Maybe<NgfDocumentPermissions>;
  seo?: Maybe<NgfDocumentSeo>;
  status: NgfUserGeneratedDocumentStatus;
  tags?: Maybe<DeadlockDocumentTags>;
  type: Scalars['String'];
  typeData: NgfDocumentType;
  updatedAt: Scalars['NgfDateTime'];
  version: Scalars['Int'];
};

export enum DeadlockUserGeneratedDocumentCreateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type DeadlockUserGeneratedDocumentCreateInput = {
  document: Scalars['DeadlockUserGeneratedDocumentInputBase64Json'];
};

export type DeadlockUserGeneratedDocumentCreatePayload = {
  __typename?: 'DeadlockUserGeneratedDocumentCreatePayload';
  data?: Maybe<DeadlockUserGeneratedDocument>;
  error?: Maybe<DeadlockUserGeneratedDocumentCreateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type DeadlockUserGeneratedDocumentData = NgfUserGeneratedDocumentData & {
  __typename?: 'DeadlockUserGeneratedDocumentData';
  backgroundImage?: Maybe<Scalars['String']>;
  childrenIds: Array<Scalars['String']>;
  gameSpecificProp1?: Maybe<Scalars['String']>;
  gameSpecificProp2?: Maybe<Scalars['Int']>;
  gameSpecificProp3?: Maybe<Scalars['Boolean']>;
  gameSpecificProp4?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  season: DeadlockDocumentSeason;
};

export enum DeadlockUserGeneratedDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type DeadlockUserGeneratedDocumentInputById = {
  /** Unique identifier of a document. Either id or slug is required to get content document. */
  id: Scalars['ID'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type DeadlockUserGeneratedDocumentInputBySlug = {
  /** Unique human readable identifier of a document. Either id or slug is required to get content document. */
  slug: Scalars['String'];
  /** Document type. */
  type: Scalars['String'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type DeadlockUserGeneratedDocumentPayload = {
  __typename?: 'DeadlockUserGeneratedDocumentPayload';
  data?: Maybe<DeadlockUserGeneratedDocument>;
  error?: Maybe<DeadlockUserGeneratedDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum DeadlockUserGeneratedDocumentSetFavoriteError {
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type DeadlockUserGeneratedDocumentSetFavoriteInput = {
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
};

export type DeadlockUserGeneratedDocumentSetFavoritePayload = {
  __typename?: 'DeadlockUserGeneratedDocumentSetFavoritePayload';
  data?: Maybe<DeadlockUserGeneratedDocument>;
  error?: Maybe<DeadlockUserGeneratedDocumentSetFavoriteError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum DeadlockUserGeneratedDocumentSetFeaturedError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
}

export type DeadlockUserGeneratedDocumentSetFeaturedInput = {
  documentSlug: Scalars['String'];
  id: Scalars['ID'];
  status: NgfDocumentFeaturedStatus;
};

export type DeadlockUserGeneratedDocumentSetFeaturedPayload = {
  __typename?: 'DeadlockUserGeneratedDocumentSetFeaturedPayload';
  data?: Maybe<DeadlockUserGeneratedDocument>;
  error?: Maybe<DeadlockUserGeneratedDocumentSetFeaturedError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum DeadlockUserGeneratedDocumentSetSeoError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type DeadlockUserGeneratedDocumentSetSeoPayload = {
  __typename?: 'DeadlockUserGeneratedDocumentSetSeoPayload';
  data?: Maybe<DeadlockUserGeneratedDocument>;
  error?: Maybe<DeadlockUserGeneratedDocumentSetSeoError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum DeadlockUserGeneratedDocumentSetStatusError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type DeadlockUserGeneratedDocumentSetStatusInput = {
  id: Scalars['ID'];
  status: NgfUserGeneratedDocumentStatus;
};

export type DeadlockUserGeneratedDocumentSetStatusPayload = {
  __typename?: 'DeadlockUserGeneratedDocumentSetStatusPayload';
  data?: Maybe<DeadlockUserGeneratedDocument>;
  error?: Maybe<DeadlockUserGeneratedDocumentSetStatusError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum DeadlockUserGeneratedDocumentUpdateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type DeadlockUserGeneratedDocumentUpdateInput = {
  document: Scalars['DeadlockUserGeneratedDocumentInputBase64Json'];
  id: Scalars['ID'];
};

export type DeadlockUserGeneratedDocumentUpdatePayload = {
  __typename?: 'DeadlockUserGeneratedDocumentUpdatePayload';
  data?: Maybe<DeadlockUserGeneratedDocument>;
  error?: Maybe<DeadlockUserGeneratedDocumentUpdateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type DeadlockUserGeneratedDocumentsListInput = {
  /** If specified, filters by author id. */
  authorId?: InputMaybe<Scalars['ID']>;
  /** If specified, filters by document status. Specified as list because we have a case when we need to filter by both DRAFT and PUBLISHED statuses. */
  documentStatus?: InputMaybe<Array<NgfUserGeneratedDocumentStatus>>;
  /** If specified, filters by document featured status. */
  featuredStatuses?: InputMaybe<Array<NgfDocumentFeaturedStatus>>;
  /** If specified, filters by document ids. */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** If specified, filters documents that were added to favorites by the current user. */
  isFavorite?: InputMaybe<Scalars['Boolean']>;
  /** If specified, filters by name of the document. */
  name?: InputMaybe<Scalars['String']>;
  /** If specified, filters by document the timeframe when document been published. */
  publishedTimeframe?: InputMaybe<NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe>;
  /** If specified, filters by document season or "current-season" keyword. If "current-season" is passed, the current season will be used. */
  seasonSlug?: InputMaybe<Scalars['String']>;
  /**
   * If specified, filters by document slugs - human readable version of document id.
   * Slug can be defined only for featured documents, like document ids, they must be unique on game level.
   */
  slugs?: InputMaybe<Array<Scalars['String']>>;
  /** Defines the way output list is sorted. */
  sortBy?: InputMaybe<NgfUserGeneratedDocumentsDiscoverySortingOption>;
  /** If specified, filters by document tags. */
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
  /**
   * If specified, filters by document type.
   * 'profile-default-tab' is a special const, allows to request docs without specifying particular type.
   */
  type?: InputMaybe<Scalars['String']>;
};

export type DeadlockUserGeneratedDocumentsListPage = {
  /**
   * If specified, filters out document before the specified cursor (forward cursor direction).
   * Backward cursor pagination is not supported as we currently don't have use cases for it.
   */
  cursor?: InputMaybe<Scalars['String']>;
  /** Limits the number of items in response. */
  limit: Scalars['Int'];
};

export type DeadlockValue = {
  __typename?: 'DeadlockValue';
  slug: Scalars['String'];
  stat?: Maybe<DeadlockItemStat>;
  value?: Maybe<Scalars['String']>;
};

export type DestinyAbilityInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type DestinyAmmoType = {
  __typename?: 'DestinyAmmoType';
  color?: Maybe<Scalars['DestinyHexColor']>;
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DestinyAmmoTypeOrError = DestinyAmmoType | DestinyGenericError;

export type DestinyAppSettings = {
  __typename?: 'DestinyAppSettings';
  currentPromoPupupIds?: Maybe<Array<Scalars['ID']>>;
  currentReleaseNoteIds?: Maybe<Array<Scalars['ID']>>;
  currentTopBannerIds?: Maybe<Array<Scalars['ID']>>;
  promoPopups?: Maybe<Array<DestinyPromoPopupOrError>>;
  releaseNotes?: Maybe<Array<DestinyReleaseNoteOrError>>;
  season?: Maybe<Scalars['String']>;
  topBanners?: Maybe<Array<DestinyTopBannerOrError>>;
};

export type DestinyAppSettingsOrError = DestinyAppSettings | DestinyGenericError;

export type DestinyArmorInput = {
  id: Scalars['ID'];
};

export type DestinyArtifactPerksInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type DestinyAspectInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type DestinyAssignedItem = {
  __typename?: 'DestinyAssignedItem';
  item: DestinyItem;
  position: Scalars['Int'];
};

export type DestinyAssignedPerk = {
  __typename?: 'DestinyAssignedPerk';
  perk: DestinyItem;
  position: Scalars['Int'];
};

export type DestinyAssignedStatsPriority = {
  __typename?: 'DestinyAssignedStatsPriority';
  position: Scalars['Int'];
  stat: DestinyStat;
};

export type DestinyAssignedWeapon = {
  __typename?: 'DestinyAssignedWeapon';
  perks?: Maybe<Array<Maybe<DestinyAssignedPerk>>>;
  slotType: DestinyBuilderSlotType;
  weapon: DestinyItem;
};

export type DestinyAuthor = {
  __typename?: 'DestinyAuthor';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  socialLinks?: Maybe<Array<DestinyAuthorSocialLinkOrError>>;
};

export type DestinyAuthorLink = {
  __typename?: 'DestinyAuthorLink';
  network?: Maybe<DestinySocialLinkType>;
  url?: Maybe<Scalars['String']>;
};

export type DestinyAuthorOrError = DestinyAuthor | DestinyGenericError | DestinyNotFoundError;

export type DestinyAuthorSocialLink = {
  __typename?: 'DestinyAuthorSocialLink';
  link?: Maybe<Scalars['DestinyUrl']>;
  type?: Maybe<DestinySocialLinkType>;
};

export type DestinyAuthorSocialLinkOrError = DestinyAuthorSocialLink | DestinyGenericError;

export type DestinyAuthorV2 = {
  __typename?: 'DestinyAuthorV2';
  id: Scalars['ID'];
  links?: Maybe<Array<DestinyAuthorLink>>;
  name?: Maybe<Scalars['String']>;
  twitch?: Maybe<Twitch>;
};

export type DestinyAuthorsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type DestinyBucketType = {
  __typename?: 'DestinyBucketType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DestinyBucketTypeOrError = DestinyBucketType | DestinyGenericError;

export type DestinyBuild = {
  __typename?: 'DestinyBuild';
  abilityItems?: Maybe<Array<DestinyItemOrError>>;
  armMods?: Maybe<Array<DestinyItemOrError>>;
  armor?: Maybe<DestinyItemOrError>;
  armorDescription?: Maybe<Scalars['String']>;
  artifactItems?: Maybe<Array<DestinyItemOrError>>;
  aspectItems?: Maybe<Array<DestinyItemOrError>>;
  author?: Maybe<DestinyAuthorOrError>;
  buildType?: Maybe<DestinyBuildTypeOrError>;
  chestMods?: Maybe<Array<DestinyItemOrError>>;
  class?: Maybe<DestinyClassOrError>;
  classItems?: Maybe<Array<DestinyItemOrError>>;
  damageType?: Maybe<DestinyDamageTypeOrError>;
  fragmentItems?: Maybe<Array<DestinyItemOrError>>;
  gameplayLoopDescription?: Maybe<Scalars['String']>;
  headMods?: Maybe<Array<DestinyItemOrError>>;
  howItWorksDescription?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isArchived?: Maybe<Scalars['Boolean']>;
  isFeatured?: Maybe<Scalars['Boolean']>;
  legsMods?: Maybe<Array<DestinyItemOrError>>;
  name?: Maybe<Scalars['String']>;
  screenshot?: Maybe<Scalars['DestinyUrl']>;
  statsPriority?: Maybe<Array<DestinyPrioritizedStatOrError>>;
  superItems?: Maybe<Array<DestinyItemOrError>>;
  video?: Maybe<Scalars['DestinyUrl']>;
  weapons?: Maybe<Array<DestinyDescribedItemOrError>>;
};

export type DestinyBuildFilter = {
  buildTypes?: InputMaybe<Array<Scalars['ID']>>;
  classes?: InputMaybe<Array<Scalars['ID']>>;
  damageTypes?: InputMaybe<Array<Scalars['ID']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isArchived?: InputMaybe<Scalars['Boolean']>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
};

/** Filter for getting single build */
export type DestinyBuildFilterV2 = {
  id?: InputMaybe<Scalars['ID']>;
  slug?: InputMaybe<Scalars['String']>;
};

export type DestinyBuildListPage = {
  __typename?: 'DestinyBuildListPage';
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<DestinySeoMetaData>;
};

export type DestinyBuildListPageOrError = DestinyBuildListPage | DestinyGenericError;

export type DestinyBuildPage = {
  __typename?: 'DestinyBuildPage';
  communityWidgetLinkText?: Maybe<Scalars['String']>;
  communityWidgetLinkUrl?: Maybe<Scalars['DestinyUrl']>;
  communityWidgetText?: Maybe<Scalars['String']>;
  communityWidgetTitle?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<DestinySeoMetaData>;
};

export type DestinyBuildPageOrError = DestinyBuildPage | DestinyGenericError;

export type DestinyBuildPagination = {
  __typename?: 'DestinyBuildPagination';
  builds?: Maybe<Array<DestinyBuild>>;
  pageInfo?: Maybe<DestinyPaginationInfo>;
};

export type DestinyBuildPaginationOrError = DestinyBuildPagination | DestinyGenericError;

export type DestinyBuildPayload = {
  __typename?: 'DestinyBuildPayload';
  build?: Maybe<DestinyBuildV2>;
  error?: Maybe<DestinyCustomBuildPayloadError>;
};

export type DestinyBuildTag = {
  __typename?: 'DestinyBuildTag';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DestinyBuildType = {
  __typename?: 'DestinyBuildType';
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DestinyBuildTypeOrError = DestinyBuildType | DestinyGenericError;

export type DestinyBuildV2 = {
  __typename?: 'DestinyBuildV2';
  abilities?: Maybe<Array<Maybe<DestinyAssignedItem>>>;
  armor?: Maybe<DestinyItem>;
  artifactPerks?: Maybe<Array<Maybe<DestinyAssignedItem>>>;
  aspects?: Maybe<Array<Maybe<DestinyAssignedItem>>>;
  author: DestinyAuthorV2;
  buildType?: Maybe<DestinyBuildType>;
  chestMods?: Maybe<Array<Maybe<DestinyAssignedItem>>>;
  class: DestinyClass;
  classItemMods?: Maybe<Array<Maybe<DestinyAssignedItem>>>;
  createdAt: Scalars['DestinyDateTime'];
  dimLink?: Maybe<Scalars['DestinyUrl']>;
  favoriteCounter: Scalars['Int'];
  fragments?: Maybe<Array<Maybe<DestinyAssignedItem>>>;
  gameplayLoop?: Maybe<Scalars['String']>;
  handMods?: Maybe<Array<Maybe<DestinyAssignedItem>>>;
  headMods?: Maybe<Array<Maybe<DestinyAssignedItem>>>;
  howItWorks?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  inDepthExplanation?: Maybe<Array<DestinyInDepthExplanationBlock>>;
  isFavorite: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  legsMods?: Maybe<Array<Maybe<DestinyAssignedItem>>>;
  metaInfo?: Maybe<MetaInfo>;
  name: Scalars['String'];
  statsPriority?: Maybe<Array<Maybe<DestinyAssignedStatsPriority>>>;
  strengthsWeaknesses?: Maybe<DestinyStrengthsWeaknesses>;
  subclass: DestinyDamageType;
  superAbility?: Maybe<DestinyItem>;
  tags?: Maybe<Array<Maybe<DestinyBuildTag>>>;
  updatedAt: Scalars['DestinyDateTime'];
  videoGuide?: Maybe<Scalars['DestinyUrl']>;
  weapons?: Maybe<Array<Maybe<DestinyAssignedWeapon>>>;
};

export type DestinyBuilderSettings = {
  __typename?: 'DestinyBuilderSettings';
  artifactPerksAmount: Scalars['Int'];
  subclassFragmentsAmount: Scalars['Int'];
};

export type DestinyBuilderSlotCategories = {
  __typename?: 'DestinyBuilderSlotCategories';
  categoryIDs?: Maybe<Array<Scalars['ID']>>;
  slotType: DestinyBuilderSlotType;
};

export enum DestinyBuilderSlotType {
  ARMOR = 'ARMOR',
  ARTIFACT_PERKS = 'ARTIFACT_PERKS',
  CHEST = 'CHEST',
  CLASS_ITEM = 'CLASS_ITEM',
  HANDS = 'HANDS',
  HEAD = 'HEAD',
  LEGS = 'LEGS',
  SUBCLASS = 'SUBCLASS',
  WEAPON_ENERGY = 'WEAPON_ENERGY',
  WEAPON_KINETIC = 'WEAPON_KINETIC',
  WEAPON_POWER = 'WEAPON_POWER',
}

/** Filter for getting list of builds */
export type DestinyBuildsListFilter = {
  armorId?: InputMaybe<Scalars['ID']>;
  /**
   * Creator of the build (User) IDs.
   * 1. empty - means any author.
   * 2. 'me' means current logged in user
   * 3. UUID means specific user
   */
  author?: InputMaybe<Scalars['String']>;
  buildType?: InputMaybe<Scalars['ID']>;
  class?: InputMaybe<Scalars['ID']>;
  /** Featured builds you can see only if meta builds are provided */
  featuredBuilds?: InputMaybe<Scalars['Boolean']>;
  /**
   * 1. empty means do not consider favorite status.
   * 2. false == empty
   * 3. true - also include deleted (validate - author MUST BE 'me')
   */
  isFavourite?: InputMaybe<Scalars['Boolean']>;
  /**
   * 1. empty means all (published and unpublished)
   * 2. if false - validate, author must be 'me'
   */
  isPublished?: InputMaybe<Scalars['Boolean']>;
  /** Show only meta builds */
  metaBuilds?: InputMaybe<Scalars['Boolean']>;
  /** Filter by the date build was first time published. Empty value returns all records */
  publishedDuring?: InputMaybe<DestinyTimeframe>;
  subClass?: InputMaybe<Scalars['ID']>;
  /** Filter by tags. Multiple tags are treated as AND. Build will be returned if it matches all tags */
  tags?: InputMaybe<Array<Scalars['ID']>>;
  weaponId?: InputMaybe<Scalars['ID']>;
};

export type DestinyBuildsListPayload = {
  __typename?: 'DestinyBuildsListPayload';
  author?: Maybe<DestinyAuthorV2>;
  builds?: Maybe<Array<DestinyBuildV2>>;
  error?: Maybe<DestinyCustomBuildsListPayloadError>;
  pageInfo?: Maybe<DestinyCursorPaginationInfo>;
};

export enum DestinyBuildsListSortingOption {
  DEFAULT = 'DEFAULT',
  IS_FEATURED = 'IS_FEATURED',
  /** Sort by first published date */
  NEW = 'NEW',
  /** Sort by favorites count */
  TOP = 'TOP',
  /** Sort by trending index */
  TRENDING = 'TRENDING',
}

export type DestinyBuildsListSortingOptions = {
  option?: DestinyBuildsListSortingOption;
};

export type DestinyCatalystPerk = {
  __typename?: 'DestinyCatalystPerk';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  isDisplayable?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
};

export type DestinyCatalystPerkOrError = DestinyCatalystPerk | DestinyGenericError;

export type DestinyCategory = {
  __typename?: 'DestinyCategory';
  ammoType?: Maybe<Array<DestinyAmmoTypeOrError>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  pluralTitle?: Maybe<Scalars['String']>;
  shortTitle?: Maybe<Scalars['String']>;
};

export type DestinyCategoryOrError = DestinyCategory | DestinyGenericError;

export type DestinyClass = {
  __typename?: 'DestinyClass';
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DestinyClassOrError = DestinyClass | DestinyGenericError;

export type DestinyCommonBuildData = {
  abilities?: InputMaybe<Array<InputMaybe<DestinyAbilityInput>>>;
  armor?: InputMaybe<DestinyArmorInput>;
  artifactPerks?: InputMaybe<Array<InputMaybe<DestinyArtifactPerksInput>>>;
  aspects?: InputMaybe<Array<InputMaybe<DestinyAspectInput>>>;
  buildType?: InputMaybe<Scalars['ID']>;
  chestMods?: InputMaybe<Array<InputMaybe<DestinyGearModInput>>>;
  classID: Scalars['ID'];
  classItemMods?: InputMaybe<Array<InputMaybe<DestinyGearModInput>>>;
  dimLink?: InputMaybe<Scalars['DestinyUrl']>;
  fragments?: InputMaybe<Array<InputMaybe<DestinyFragmentInput>>>;
  gameplayLoop?: InputMaybe<Scalars['String']>;
  handMods?: InputMaybe<Array<InputMaybe<DestinyGearModInput>>>;
  headMods?: InputMaybe<Array<InputMaybe<DestinyGearModInput>>>;
  howItWorks?: InputMaybe<Scalars['String']>;
  inDepthExplanation?: InputMaybe<Array<DestinyInDepthExplanationBlockInput>>;
  legsMods?: InputMaybe<Array<InputMaybe<DestinyGearModInput>>>;
  statsPriority?: InputMaybe<Array<InputMaybe<DestinyStatsPriorityInput>>>;
  strengthsWeaknesses?: InputMaybe<DestinyStrengthsWeaknessesInput>;
  subClassID: Scalars['ID'];
  superAbility?: InputMaybe<DestinyAbilityInput>;
  videoGuide?: InputMaybe<Scalars['DestinyUrl']>;
  weapons?: InputMaybe<Array<InputMaybe<DestinyWeaponInput>>>;
};

export type DestinyCreateBuildInput = {
  common: DestinyCommonBuildData;
  name: Scalars['String'];
  publish?: InputMaybe<Scalars['Boolean']>;
  tags?: InputMaybe<Array<Scalars['ID']>>;
};

export type DestinyCreateBuildPayload = {
  __typename?: 'DestinyCreateBuildPayload';
  build?: Maybe<DestinyBuildV2>;
  error?: Maybe<DestinyCustomBuildPayloadError>;
};

export type DestinyCreateBuildPayloadError = DestinyGenericError;

export type DestinyCursorPage = {
  count?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
};

export type DestinyCursorPaginationInfo = {
  __typename?: 'DestinyCursorPaginationInfo';
  cursor?: Maybe<Scalars['String']>;
  hasMoreItems?: Maybe<Scalars['Boolean']>;
};

export type DestinyCustomBuildPayloadError = DestinyGenericError | DestinyNotFoundError;

export type DestinyCustomBuildsListPayloadError = DestinyGenericError;

export type DestinyDamageType = {
  __typename?: 'DestinyDamageType';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  transperentIconUrl?: Maybe<Scalars['DestinyUrl']>;
};

export type DestinyDamageTypeOrError = DestinyDamageType | DestinyGenericError;

export type DestinyDefinitions = {
  __typename?: 'DestinyDefinitions';
  buildTypes?: Maybe<Array<DestinyBuildTypeOrError>>;
  builderSettings: DestinyBuilderSettings;
  /** Mapping between builder slots on UI to Item Categories that fit into this slot */
  builderSlotCategories?: Maybe<Array<DestinyBuilderSlotCategories>>;
  classes?: Maybe<Array<DestinyClassOrError>>;
  damageTypes?: Maybe<Array<DestinyDamageTypeOrError>>;
  statsPriority?: Maybe<Array<DestinyStat>>;
  tags?: Maybe<Array<DestinyBuildTag>>;
  weaponCategories?: Maybe<Array<DestinyCategoryOrError>>;
  weaponSlots?: Maybe<Array<DestinyCategoryOrError>>;
};

export type DestinyDeleteBuildInput = {
  /** ID of the build */
  id: Scalars['ID'];
};

export type DestinyDeleteBuildPayload = {
  __typename?: 'DestinyDeleteBuildPayload';
  error?: Maybe<DestinyCustomBuildPayloadError>;
  success: Scalars['Boolean'];
};

export type DestinyDeleteBuildPayloadError = DestinyGenericError;

export type DestinyDescribedItem = {
  __typename?: 'DestinyDescribedItem';
  description?: Maybe<Scalars['String']>;
  item?: Maybe<DestinyItemOrError>;
  itemSlug?: Maybe<Scalars['String']>;
};

export type DestinyDescribedItemOrError = DestinyDescribedItem | DestinyGenericError;

export type DestinyDimItemsError = DestinyGenericError;

export type DestinyDimItemsFilter = {
  categories?: InputMaybe<Array<Scalars['ID']>>;
  dimLink: Scalars['DestinyUrl'];
};

export type DestinyDimItemsPayload = {
  __typename?: 'DestinyDimItemsPayload';
  error?: Maybe<DestinyDimItemsError>;
  items?: Maybe<Array<DestinyItem>>;
};

export type DestinyDuplicateBuildError = DestinyGenericError | DestinyNotFoundError;

export type DestinyDuplicateBuildInput = {
  /** ID of the build to duplicate */
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type DestinyDuplicateBuildPayload = {
  __typename?: 'DestinyDuplicateBuildPayload';
  build?: Maybe<DestinyBuildV2>;
  error?: Maybe<DestinyDuplicateBuildError>;
};

export type DestinyEquipmentSlot = {
  __typename?: 'DestinyEquipmentSlot';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DestinyEquipmentSlotOrError = DestinyEquipmentSlot | DestinyGenericError;

export type DestinyError = {
  message?: Maybe<Scalars['String']>;
};

export type DestinyExoticWeaponPage = {
  __typename?: 'DestinyExoticWeaponPage';
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<DestinySeoMetaData>;
};

export type DestinyExoticWeaponPageOrError = DestinyExoticWeaponPage | DestinyGenericError;

export type DestinyFragmentInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type DestinyGame = {
  __typename?: 'DestinyGame';
  /** Returns single build. V2 is due to pre existing builds in strapi */
  build?: Maybe<DestinyBuildPayload>;
  builds?: Maybe<DestinyBuildPaginationOrError>;
  /** Returns filtered & paginated list of builds. V2 is due to pre existing builds in strapi */
  buildsV2?: Maybe<DestinyBuildsListPayload>;
  definitions?: Maybe<DestinyDefinitions>;
  dimItems?: Maybe<DestinyDimItemsPayload>;
  id: Scalars['ID'];
  items?: Maybe<DestinyItemPaginationOrError>;
  vendors?: Maybe<Array<DestinyVendorOrError>>;
};

export type DestinyGamebuildArgs = {
  filter?: InputMaybe<DestinyBuildFilterV2>;
};

export type DestinyGamebuildsArgs = {
  filter?: InputMaybe<DestinyBuildFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type DestinyGamebuildsV2Args = {
  filter?: InputMaybe<DestinyBuildsListFilter>;
  page?: InputMaybe<DestinyCursorPage>;
  sort?: InputMaybe<DestinyBuildsListSortingOption>;
};

export type DestinyGamedimItemsArgs = {
  filter?: InputMaybe<DestinyDimItemsFilter>;
};

export type DestinyGameitemsArgs = {
  filter?: InputMaybe<DestinyItemFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type DestinyGamevendorsArgs = {
  filter?: InputMaybe<DestinyVendorFilter>;
};

export type DestinyGearModInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type DestinyGenericError = DestinyError & {
  __typename?: 'DestinyGenericError';
  message?: Maybe<Scalars['String']>;
};

export type DestinyHomePage = {
  __typename?: 'DestinyHomePage';
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<DestinySeoMetaData>;
};

export type DestinyHomePageOrError = DestinyGenericError | DestinyHomePage;

export type DestinyInDepthExplanationBlock = {
  __typename?: 'DestinyInDepthExplanationBlock';
  content?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DestinyInDepthExplanationBlockInput = {
  content?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type DestinyItem = {
  __typename?: 'DestinyItem';
  alternatives?: Maybe<Array<DestinyItemOrError>>;
  ammoType?: Maybe<DestinyAmmoTypeOrError>;
  ammoTypeV2?: Maybe<DestinyAmmoType>;
  bestInClass?: Maybe<Scalars['Boolean']>;
  bucketType?: Maybe<DestinyBucketTypeOrError>;
  bucketTypeV2?: Maybe<DestinyBucketType>;
  catalystPerks?: Maybe<Array<DestinyCatalystPerkOrError>>;
  categories?: Maybe<Array<DestinyCategoryOrError>>;
  class?: Maybe<DestinyClassOrError>;
  classV2?: Maybe<DestinyClass>;
  damageTypes?: Maybe<Array<DestinyDamageTypeOrError>>;
  /** if it is an Engram (box with drop), then this attribute will contain the items this Engram contains */
  derivedItems?: Maybe<Array<DestinyItemOrError>>;
  description?: Maybe<Scalars['String']>;
  equipmentSlot?: Maybe<DestinyEquipmentSlotOrError>;
  equipmentSlotV2?: Maybe<DestinyEquipmentSlot>;
  flavourText?: Maybe<Scalars['String']>;
  howToGetDescription?: Maybe<Scalars['String']>;
  howToObtainCatalyst?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  iconWatermarkShelvedUrl?: Maybe<Scalars['DestinyUrl']>;
  iconWatermarkUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  /** Stats given by the item (if if is a Weapon Mod/Perk etc.) */
  investmentStats?: Maybe<Array<DestinyValuedInvestmentStatOrError>>;
  itemTypeAndTierDisplayName?: Maybe<Scalars['String']>;
  itemTypeDisplayName?: Maybe<Scalars['String']>;
  lore?: Maybe<DestinyLoreOrError>;
  loreV2?: Maybe<DestinyLore>;
  name?: Maybe<Scalars['String']>;
  /**
   * Objective represents how much of some action you have to do to achive the item.
   * Like defeat 400 targets to unlock the weapon
   */
  objectives?: Maybe<Array<DestinyItemObjectiveOrError>>;
  perkCombinations?: Maybe<Array<DestinyPerkCombinationOrError>>;
  pveTier?: Maybe<DestinyTierOrError>;
  pvpTier?: Maybe<DestinyTierOrError>;
  rarity?: Maybe<DestinyRarityOrError>;
  rarityV2?: Maybe<DestinyRarity>;
  screenshotUrl?: Maybe<Scalars['DestinyUrl']>;
  sockets?: Maybe<Array<DestinySocketOrError>>;
  source?: Maybe<Scalars['String']>;
  /** item stats (only for weapons and maybe gear) */
  stats?: Maybe<Array<DestinyValuedStatOrError>>;
  weaponDescription?: Maybe<Scalars['String']>;
};

export type DestinyItemFilter = {
  ammoTypes?: InputMaybe<Array<Scalars['ID']>>;
  bestInClass?: InputMaybe<Scalars['Boolean']>;
  categories?: InputMaybe<Array<Scalars['ID']>>;
  classes?: InputMaybe<Array<Scalars['ID']>>;
  damageTypes?: InputMaybe<Array<Scalars['ID']>>;
  equipmentSlots?: InputMaybe<Array<Scalars['ID']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  name?: InputMaybe<Scalars['String']>;
  pveTiers?: InputMaybe<Array<Scalars['ID']>>;
  pvpTiers?: InputMaybe<Array<Scalars['ID']>>;
  rarities?: InputMaybe<Array<Scalars['ID']>>;
};

export type DestinyItemObjective = {
  __typename?: 'DestinyItemObjective';
  completionValue?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  progressDescription?: Maybe<Scalars['String']>;
};

export type DestinyItemObjectiveOrError = DestinyGenericError | DestinyItemObjective;

export type DestinyItemOrError = DestinyGenericError | DestinyItem;

export type DestinyItemPagination = {
  __typename?: 'DestinyItemPagination';
  items?: Maybe<Array<DestinyItem>>;
  pageInfo?: Maybe<DestinyPaginationInfo>;
};

export type DestinyItemPaginationOrError = DestinyGenericError | DestinyItemPagination;

export type DestinyLegendaryWeaponPage = {
  __typename?: 'DestinyLegendaryWeaponPage';
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<DestinySeoMetaData>;
};

export type DestinyLegendaryWeaponPageOrError = DestinyGenericError | DestinyLegendaryWeaponPage;

export type DestinyLink = {
  __typename?: 'DestinyLink';
  linkText?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['DestinyUrl']>;
};

export type DestinyLore = {
  __typename?: 'DestinyLore';
  id: Scalars['ID'];
  lore?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['String']>;
};

export type DestinyLoreOrError = DestinyGenericError | DestinyLore;

export type DestinyMarketing = {
  __typename?: 'DestinyMarketing';
  appSettings?: Maybe<DestinyAppSettingsOrError>;
  promoPopups?: Maybe<Array<DestinyPromoPopupOrError>>;
  releaseNotes?: Maybe<Array<DestinyReleaseNoteOrError>>;
  topBanners?: Maybe<Array<DestinyTopBannerOrError>>;
};

export type DestinyMarketingpromoPopupsArgs = {
  filter?: InputMaybe<DestinyPromoPopupFilter>;
};

export type DestinyMarketingreleaseNotesArgs = {
  filter?: InputMaybe<DestinyReleaseNoteFilter>;
};

export type DestinyMarketingtopBannersArgs = {
  filter?: InputMaybe<DestinyTopBannerFilter>;
};

export type DestinyMutation = {
  __typename?: 'DestinyMutation';
  /** Create custom build with variants for registered user */
  createBuild: DestinyCreateBuildPayload;
  /** Delete custom build of registered user */
  deleteBuild: DestinyDeleteBuildPayload;
  /** Duplicate custom build of registered user */
  duplicateBuild: DestinyDuplicateBuildPayload;
  /** Add/remove custom build to favourites */
  setFavourite: DestinySetFavouritePayload;
  /** Publish/unpublish custom build */
  setPublished: DestinySetPublishedPayload;
  /** Update custom build of registered user */
  updateBuild: DestinyUpdateBuildPayload;
};

export type DestinyMutationcreateBuildArgs = {
  input: DestinyCreateBuildInput;
};

export type DestinyMutationdeleteBuildArgs = {
  input: DestinyDeleteBuildInput;
};

export type DestinyMutationduplicateBuildArgs = {
  input?: InputMaybe<DestinyDuplicateBuildInput>;
};

export type DestinyMutationsetFavouriteArgs = {
  input: DestinySetFavouriteInput;
};

export type DestinyMutationsetPublishedArgs = {
  input: DestinySetPublishedInput;
};

export type DestinyMutationupdateBuildArgs = {
  input: DestinyUpdateBuildInput;
};

export type DestinyNotFoundError = DestinyError & {
  __typename?: 'DestinyNotFoundError';
  message?: Maybe<Scalars['String']>;
};

export type DestinyPages = {
  __typename?: 'DestinyPages';
  buildListPage?: Maybe<DestinyBuildListPageOrError>;
  buildPage?: Maybe<DestinyBuildPageOrError>;
  exoticWeaponPage?: Maybe<DestinyExoticWeaponPageOrError>;
  homePage?: Maybe<DestinyHomePageOrError>;
  legendaryWeaponPage?: Maybe<DestinyLegendaryWeaponPageOrError>;
  tierListPage?: Maybe<DestinyTierListPageOrError>;
  weaponListPage?: Maybe<DestinyWeaponListPageOrError>;
};

export type DestinyPaginationInfo = {
  __typename?: 'DestinyPaginationInfo';
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type DestinyPaginationInfoOrError = DestinyGenericError | DestinyPaginationInfo;

export type DestinyPerkCombination = {
  __typename?: 'DestinyPerkCombination';
  alternativePerks?: Maybe<Array<DestinyItemOrError>>;
  componentId?: Maybe<Scalars['Int']>;
  group?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  name?: Maybe<Scalars['String']>;
  optionalPerks?: Maybe<Array<DestinyItemOrError>>;
  requiredPerks?: Maybe<Array<DestinyItemOrError>>;
};

export type DestinyPerkCombinationOrError = DestinyGenericError | DestinyPerkCombination;

/** Represent possible platforms for the game. Commonly used to separate banners by platform. */
export enum DestinyPlatform {
  DESKTOP = 'DESKTOP',
  WEB = 'WEB',
}

export type DestinyPrioritizedStat = {
  __typename?: 'DestinyPrioritizedStat';
  priority?: Maybe<Scalars['Int']>;
  stat?: Maybe<DestinyStatOrError>;
  statSlug?: Maybe<Scalars['String']>;
};

export type DestinyPrioritizedStatOrError = DestinyGenericError | DestinyPrioritizedStat;

export type DestinyPromoPopup = {
  __typename?: 'DestinyPromoPopup';
  button?: Maybe<DestinyPromoPopupButton>;
  disallowedUserAttributes?: Maybe<Array<DestinyUserAttribute>>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  labelBackground?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['DestinyLocale']>;
  mixpanelName?: Maybe<Scalars['String']>;
  modalBackground?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<DestinyPlatform>>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userLevels?: Maybe<Array<DestinyUserLevel>>;
  value?: Maybe<Scalars['String']>;
};

export type DestinyPromoPopupButton = {
  __typename?: 'DestinyPromoPopupButton';
  link?: Maybe<Scalars['DestinyUrl']>;
  title?: Maybe<Scalars['String']>;
};

export type DestinyPromoPopupFilter = {
  disallowedUserAttribute?: InputMaybe<DestinyUserAttribute>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locale?: InputMaybe<Scalars['DestinyLocale']>;
  platform?: InputMaybe<DestinyPlatform>;
  userLevel?: InputMaybe<DestinyUserLevel>;
};

export type DestinyPromoPopupOrError = DestinyGenericError | DestinyPromoPopup;

export type DestinyPromoPopupOrNotFoundError = DestinyGenericError | DestinyNotFoundError | DestinyPromoPopup;

export type DestinyQuery = {
  __typename?: 'DestinyQuery';
  game?: Maybe<DestinyGame>;
  marketing?: Maybe<DestinyMarketing>;
  page?: Maybe<DestinyPages>;
};

export type DestinyRarity = {
  __typename?: 'DestinyRarity';
  color?: Maybe<Scalars['DestinyHexColor']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DestinyRarityOrError = DestinyGenericError | DestinyRarity;

/** Represent a list of changes in the game. Usually comes out with a new patch. */
export type DestinyReleaseNote = {
  __typename?: 'DestinyReleaseNote';
  button?: Maybe<DestinyReleaseNoteButton>;
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['DestinyLocale']>;
  platforms?: Maybe<Array<DestinyPlatform>>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userLevels?: Maybe<Array<DestinyUserLevel>>;
};

export type DestinyReleaseNoteButton = {
  __typename?: 'DestinyReleaseNoteButton';
  link?: Maybe<Scalars['DestinyUrl']>;
  title?: Maybe<Scalars['String']>;
};

export type DestinyReleaseNoteFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locale?: InputMaybe<Scalars['DestinyLocale']>;
  platform?: InputMaybe<DestinyPlatform>;
  userLevel?: InputMaybe<DestinyUserLevel>;
};

export type DestinyReleaseNoteOrError = DestinyGenericError | DestinyReleaseNote;

export type DestinyReleaseNoteOrNotFoundError = DestinyGenericError | DestinyNotFoundError | DestinyReleaseNote;

/** Metadata for correct page indexing by search engines */
export type DestinySeoMetaData = {
  __typename?: 'DestinySeoMetaData';
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  ogImage?: Maybe<Scalars['DestinyUrl']>;
  title?: Maybe<Scalars['String']>;
};

export type DestinySetFavouriteInput = {
  /** Flag that indicates if build has to be added to favourites */
  favourite: Scalars['Boolean'];
  /** ID of the build */
  id: Scalars['ID'];
};

export type DestinySetFavouritePayload = {
  __typename?: 'DestinySetFavouritePayload';
  error?: Maybe<DestinySetFavouritePayloadError>;
  success: Scalars['Boolean'];
};

export type DestinySetFavouritePayloadError = DestinyGenericError;

export type DestinySetPublishedInput = {
  /** ID of the build */
  id: Scalars['ID'];
  /** Flag that indicates if build has to be published */
  published: Scalars['Boolean'];
};

export type DestinySetPublishedPayload = {
  __typename?: 'DestinySetPublishedPayload';
  error?: Maybe<DestinySetPublishedPayloadError>;
  success: Scalars['Boolean'];
};

export type DestinySetPublishedPayloadError = DestinyGenericError | DestinyNotFoundError;

export type DestinySocialLinkType = {
  __typename?: 'DestinySocialLinkType';
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DestinySocket = {
  __typename?: 'DestinySocket';
  /**
   * The technical field required for 'reusableItems' resolving.
   * ComponentID representing internal Strapi ID for the 'Socket' component.
   */
  componentId?: Maybe<Scalars['Int']>;
  plugSetItems?: Maybe<Array<DestinyItemOrError>>;
  predefinedItem?: Maybe<DestinyItemOrError>;
  /** The technical field required for 'predefinedItem' resolving */
  predefinedItemSlug?: Maybe<Scalars['String']>;
  reusableItems?: Maybe<Array<DestinyItemOrError>>;
  socketType?: Maybe<DestinySocketTypeOrError>;
  /** The technical field required for 'predefinedItem' resolving */
  socketTypeSlug?: Maybe<Scalars['String']>;
};

export type DestinySocketOrError = DestinyGenericError | DestinySocket;

export type DestinySocketType = {
  __typename?: 'DestinySocketType';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DestinySocketTypeOrError = DestinyGenericError | DestinySocketType;

export type DestinyStat = {
  __typename?: 'DestinyStat';
  description?: Maybe<Scalars['String']>;
  displayAsNumeric?: Maybe<Scalars['Boolean']>;
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type DestinyStatInterpolation = {
  __typename?: 'DestinyStatInterpolation';
  value?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
};

export type DestinyStatOrError = DestinyGenericError | DestinyStat;

export type DestinyStatsPriorityInput = {
  position: Scalars['Int'];
  statID: Scalars['ID'];
};

export type DestinyStrengthsWeaknesses = {
  __typename?: 'DestinyStrengthsWeaknesses';
  strengths?: Maybe<Array<Scalars['String']>>;
  weaknesses?: Maybe<Array<Scalars['String']>>;
};

export type DestinyStrengthsWeaknessesInput = {
  strengths?: InputMaybe<Array<Scalars['String']>>;
  weaknesses?: InputMaybe<Array<Scalars['String']>>;
};

export type DestinyTier = {
  __typename?: 'DestinyTier';
  color?: Maybe<Scalars['DestinyHexColor']>;
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type DestinyTierListPage = {
  __typename?: 'DestinyTierListPage';
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<DestinySeoMetaData>;
};

export type DestinyTierListPageOrError = DestinyGenericError | DestinyTierListPage;

export type DestinyTierOrError = DestinyGenericError | DestinyTier;

export enum DestinyTimeframe {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

/** Banner which is displayed on the top of the web-page throughout the site. */
export type DestinyTopBanner = {
  __typename?: 'DestinyTopBanner';
  background?: Maybe<Scalars['String']>;
  disallowedUserAttributes?: Maybe<Array<DestinyUserAttribute>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  link?: Maybe<DestinyTopBannerLink>;
  locale?: Maybe<Scalars['DestinyLocale']>;
  mixpanelName?: Maybe<Scalars['String']>;
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  platforms?: Maybe<Array<DestinyPlatform>>;
  text?: Maybe<Scalars['String']>;
  userLevels?: Maybe<Array<DestinyUserLevel>>;
  withCloseButton?: Maybe<Scalars['Boolean']>;
};

export type DestinyTopBannerFilter = {
  disallowedUserAttribute?: InputMaybe<DestinyUserAttribute>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locale?: InputMaybe<Scalars['DestinyLocale']>;
  platform?: InputMaybe<DestinyPlatform>;
  userLevel?: InputMaybe<DestinyUserLevel>;
};

export type DestinyTopBannerLink = {
  __typename?: 'DestinyTopBannerLink';
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['DestinyUrl']>;
};

export type DestinyTopBannerOrError = DestinyGenericError | DestinyTopBanner;

export type DestinyTopBannerOrNotFoundError = DestinyGenericError | DestinyNotFoundError | DestinyTopBanner;

export type DestinyUpdateBuildInput = {
  /** UUID of the build */
  id: Scalars['ID'];
  /** New build full data */
  newBuild: DestinyCreateBuildInput;
};

export type DestinyUpdateBuildPayload = {
  __typename?: 'DestinyUpdateBuildPayload';
  build?: Maybe<DestinyBuildV2>;
  error?: Maybe<DestinyCustomBuildPayloadError>;
};

export type DestinyUpdateBuildPayloadError = DestinyGenericError;

/** Represent user attributes. Typically used to display banners to specific user groups. */
export enum DestinyUserAttribute {
  LESSON_PLAN = 'LESSON_PLAN',
}

/** Represent user attributes. Typically used to display banners to specific user levels. */
export enum DestinyUserLevel {
  CARRY = 'CARRY',
  CHURN = 'CHURN',
  COLLECTOR_EDITION = 'COLLECTOR_EDITION',
  GUEST = 'GUEST',
  REGULAR = 'REGULAR',
  SUPPORTER = 'SUPPORTER',
  TRIAL = 'TRIAL',
}

export type DestinyValuedInvestmentStat = {
  __typename?: 'DestinyValuedInvestmentStat';
  stat?: Maybe<DestinyStatOrError>;
  statSlug?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type DestinyValuedInvestmentStatOrError = DestinyGenericError | DestinyValuedInvestmentStat;

export type DestinyValuedStat = {
  __typename?: 'DestinyValuedStat';
  displayMaximum?: Maybe<Scalars['Int']>;
  interpolation?: Maybe<Array<DestinyStatInterpolation>>;
  maximum?: Maybe<Scalars['Int']>;
  minimum?: Maybe<Scalars['Int']>;
  stat?: Maybe<DestinyStatOrError>;
  statSlug?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type DestinyValuedStatOrError = DestinyGenericError | DestinyValuedStat;

export type DestinyVendor = {
  __typename?: 'DestinyVendor';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['DestinyUrl']>;
  id: Scalars['ID'];
  /** items list that vendor contains */
  items?: Maybe<Array<DestinyVendorItemOrError>>;
  name?: Maybe<Scalars['String']>;
};

export type DestinyVendorFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type DestinyVendorItem = {
  __typename?: 'DestinyVendorItem';
  category?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  item?: Maybe<DestinyItemOrError>;
  quantity?: Maybe<Scalars['Int']>;
};

export type DestinyVendorItemOrError = DestinyGenericError | DestinyVendorItem;

export type DestinyVendorOrError = DestinyGenericError | DestinyVendor;

export type DestinyVideo = {
  __typename?: 'DestinyVideo';
  placeholderUrl?: Maybe<Scalars['DestinyUrl']>;
  videoUrl?: Maybe<Scalars['DestinyUrl']>;
};

export type DestinyWeaponInput = {
  id: Scalars['ID'];
  perks?: InputMaybe<Array<DestinyWeaponPerkInput>>;
  /** KINETIC / ENERGY / POWER */
  slotTypeID: Scalars['ID'];
};

export type DestinyWeaponListPage = {
  __typename?: 'DestinyWeaponListPage';
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<DestinySeoMetaData>;
};

export type DestinyWeaponListPageOrError = DestinyGenericError | DestinyWeaponListPage;

export type DestinyWeaponPerkInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type Diablo4AffixStatInput = {
  slotId: Scalars['ID'];
  statIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type Diablo4AppSettings = {
  __typename?: 'Diablo4AppSettings';
  appLandingLinkURL?: Maybe<Scalars['Diablo4Url']>;
  currentPromoPopupIds?: Maybe<Array<Scalars['ID']>>;
  currentReleaseNoteIds?: Maybe<Array<Scalars['ID']>>;
  currentTopBannerIds?: Maybe<Array<Scalars['ID']>>;
  promoPopups?: Maybe<Array<Diablo4PromoPopupOrError>>;
  releaseNotes?: Maybe<Array<Diablo4ReleaseNoteOrError>>;
  topBanners?: Maybe<Array<Diablo4TopBannerOrError>>;
};

export type Diablo4AppSettingsOrError = Diablo4AppSettings | Diablo4GenericError;

export type Diablo4Aspect = {
  __typename?: 'Diablo4Aspect';
  campaign?: Maybe<Scalars['Boolean']>;
  class?: Maybe<Diablo4Class>;
  classSlug?: Maybe<Scalars['String']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  dungeon?: Maybe<Diablo4Dungeon>;
  dungeonSlug?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<Diablo4Location>;
  locationSlug?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Diablo4AspectType>;
  typeSlug?: Maybe<Scalars['String']>;
};

export type Diablo4AspectSlot = {
  __typename?: 'Diablo4AspectSlot';
  bonusPercent?: Maybe<Scalars['Int']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4AspectSlotsFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4AspectSlotsResult = {
  __typename?: 'Diablo4AspectSlotsResult';
  data?: Maybe<Array<Diablo4AspectSlot>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4AspectType = {
  __typename?: 'Diablo4AspectType';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4AspectTypesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4AspectTypesResult = {
  __typename?: 'Diablo4AspectTypesResult';
  data?: Maybe<Array<Diablo4AspectType>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4AspectsFilter = {
  classSlug?: InputMaybe<OneOfOptionalString>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4AspectsResult = {
  __typename?: 'Diablo4AspectsResult';
  data?: Maybe<Array<Diablo4Aspect>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4AssignedOpportunity = {
  __typename?: 'Diablo4AssignedOpportunity';
  opportunity?: Maybe<Diablo4Opportunity>;
  skill?: Maybe<Diablo4Skill>;
};

export type Diablo4AssignedRune = {
  __typename?: 'Diablo4AssignedRune';
  position: Scalars['Int'];
  rune?: Maybe<Diablo4Rune>;
  section?: Maybe<Diablo4RuneSection>;
};

export type Diablo4AssignedRuneInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
  section?: InputMaybe<Diablo4RuneSection>;
};

export type Diablo4AssignedSkill = {
  __typename?: 'Diablo4AssignedSkill';
  position: Scalars['Int'];
  skill: Diablo4Skill;
};

export type Diablo4AssignedSkillInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type Diablo4AssignedSpiritGuardians = {
  __typename?: 'Diablo4AssignedSpiritGuardians';
  primaryId?: Maybe<Scalars['ID']>;
  secondaryId?: Maybe<Scalars['ID']>;
};

export type Diablo4AssignedVampirePower = {
  __typename?: 'Diablo4AssignedVampirePower';
  position: Scalars['Int'];
  vampirePower: Diablo4VampirePower;
};

export type Diablo4AssignedVampirePowerInput = {
  id: Scalars['ID'];
  position: Scalars['Int'];
};

export type Diablo4AssignedWitchcraftPowers = {
  __typename?: 'Diablo4AssignedWitchcraftPowers';
  normal?: Maybe<Array<Diablo4WitchcraftPower>>;
  unique?: Maybe<Diablo4WitchcraftPower>;
};

export type Diablo4Author = {
  __typename?: 'Diablo4Author';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['Diablo4Url']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  socialLinks?: Maybe<Array<Diablo4AuthorSocialLinkOrError>>;
};

export type Diablo4AuthorLink = {
  __typename?: 'Diablo4AuthorLink';
  network?: Maybe<Diablo4SocialLinkType>;
  url?: Maybe<Scalars['String']>;
};

export type Diablo4AuthorOrError = Diablo4Author | Diablo4GenericError | Diablo4NotFoundError;

export type Diablo4AuthorSocialLink = {
  __typename?: 'Diablo4AuthorSocialLink';
  link?: Maybe<Scalars['Diablo4Url']>;
  type?: Maybe<Diablo4SocialLinkType>;
};

export type Diablo4AuthorSocialLinkOrError = Diablo4AuthorSocialLink | Diablo4GenericError;

export type Diablo4Bonus = {
  __typename?: 'Diablo4Bonus';
  description?: Maybe<Scalars['String']>;
  slot?: Maybe<Diablo4GemType>;
  slotSlug?: Maybe<Scalars['String']>;
};

export type Diablo4BuildGoverningStone = {
  __typename?: 'Diablo4BuildGoverningStone';
  governingStone?: Maybe<Diablo4GoverningStone>;
  governingStoneLevel?: Maybe<Scalars['Int']>;
};

export type Diablo4BuildLink = {
  __typename?: 'Diablo4BuildLink';
  linkText: Scalars['String'];
  linkType?: Maybe<Scalars['String']>;
  linkUrl: Scalars['String'];
};

export type Diablo4BuildListPage = {
  __typename?: 'Diablo4BuildListPage';
  backgroundImageUrl?: Maybe<Scalars['Diablo4Url']>;
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<Diablo4SeoMetaData>;
};

export type Diablo4BuildListPageOrError = Diablo4BuildListPage | Diablo4GenericError;

export type Diablo4BuildPage = {
  __typename?: 'Diablo4BuildPage';
  communityBuildsLink?: Maybe<Diablo4Link>;
  communityBuildsText?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  headerBannerBackgroundUrl?: Maybe<Scalars['Diablo4Url']>;
  headerBannerButtonLink?: Maybe<Diablo4Link>;
  headerBannerText?: Maybe<Scalars['String']>;
  metadata?: Maybe<Diablo4SeoMetaData>;
};

export type Diablo4BuildPageOrError = Diablo4BuildPage | Diablo4GenericError;

export type Diablo4BuildParagonBoard = {
  __typename?: 'Diablo4BuildParagonBoard';
  board: Diablo4ParagonBoard;
  glyph?: Maybe<Diablo4ParagonGlyph>;
  glyphLevel?: Maybe<Scalars['Int']>;
  rotation?: Maybe<Scalars['Int']>;
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type Diablo4BuildSeneschalCompanion = {
  __typename?: 'Diablo4BuildSeneschalCompanion';
  governingStone?: Maybe<Diablo4BuildGoverningStone>;
  position?: Maybe<Scalars['Int']>;
  tuningStones?: Maybe<Array<Diablo4BuildTuningStone>>;
};

export type Diablo4BuildSlot = {
  __typename?: 'Diablo4BuildSlot';
  aspectBonuses?: Maybe<Array<Scalars['String']>>;
  aspectTypes?: Maybe<Array<Diablo4AspectType>>;
  classes?: Maybe<Array<Diablo4Class>>;
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  itemTypes?: Maybe<Array<Diablo4UniqueItemType>>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4BuildSlotDictionaryFilter = {
  classes?: InputMaybe<Array<Scalars['ID']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Diablo4BuildSlotsFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4BuildSlotsResult = {
  __typename?: 'Diablo4BuildSlotsResult';
  data?: Maybe<Array<Diablo4BuildSlot>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4BuildSlottedGem = {
  __typename?: 'Diablo4BuildSlottedGem';
  gem: Diablo4Gem;
  slot: Diablo4GemType;
};

export type Diablo4BuildSlottedHeart = {
  __typename?: 'Diablo4BuildSlottedHeart';
  heart: Diablo4Heart;
  slot: Diablo4BuildSlot;
};

export type Diablo4BuildTuningStone = {
  __typename?: 'Diablo4BuildTuningStone';
  position?: Maybe<Scalars['Int']>;
  tuningStone?: Maybe<Diablo4TuningStone>;
  tuningStoneLevel?: Maybe<Scalars['Int']>;
};

export type Diablo4BuildType = {
  __typename?: 'Diablo4BuildType';
  iconUrl?: Maybe<Scalars['Diablo4Url']>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Diablo4BuildTypeOrError = Diablo4BuildType | Diablo4GenericError;

export type Diablo4BuildTypesDictionaryFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type Diablo4Class = {
  __typename?: 'Diablo4Class';
  backgroundImageUrl?: Maybe<Scalars['String']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  dex?: Maybe<Scalars['Int']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  int?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  str?: Maybe<Scalars['Int']>;
  uniqueSystemName?: Maybe<Scalars['String']>;
  will?: Maybe<Scalars['Int']>;
};

export type Diablo4ClassesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4ClassesResult = {
  __typename?: 'Diablo4ClassesResult';
  data?: Maybe<Array<Diablo4Class>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4CommonVariantBuildInput = {
  /** Build overview */
  buildOverview?: InputMaybe<Scalars['String']>;
  /** Build summary */
  buildSummary?: InputMaybe<Scalars['String']>;
  /** Detailed gameplay loop i.e how to use skills */
  gameplayLoop?: InputMaybe<Scalars['String']>;
  /** In depth explanation block */
  inDepthExplanation?: InputMaybe<Array<Diablo4InDepthExplanationBlockInput>>;
  /** Build strengths and weaknesses */
  strengthsWeaknesses?: InputMaybe<Diablo4StrengthsWeaknessesInput>;
  /** Different variants of the build */
  variants?: InputMaybe<Array<Diablo4VariantInput>>;
};

export type Diablo4CommunityBuildPage = {
  __typename?: 'Diablo4CommunityBuildPage';
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<Diablo4SeoMetaData>;
};

export type Diablo4CommunityBuildPageOrError = Diablo4CommunityBuildPage | Diablo4GenericError;

/** Create custom build (registered users only) result */
export type Diablo4CreateBuildPayload = {
  __typename?: 'Diablo4CreateBuildPayload';
  /** The build itself */
  build?: Maybe<Diablo4CustomBuildV3>;
  /** Any error occurred during the creation */
  error?: Maybe<Diablo4Error>;
};

export type Diablo4CreateBuildV2Payload = {
  __typename?: 'Diablo4CreateBuildV2Payload';
  /** The build itself */
  build?: Maybe<Diablo4CustomBuildV3>;
  /** Any error occurred during the creation */
  error?: Maybe<Diablo4CreateBuildV2PayloadError>;
};

/**
 * Create custom build v2 (registered users only) result
 * The difference between v1 and v2 is that v2 has build variants
 */
export type Diablo4CreateBuildV2PayloadError = Diablo4GenericError;

export type Diablo4CreateVariantBuildInput = {
  /** Diablo4 class */
  classID: Scalars['ID'];
  /** Build common input */
  common: Diablo4CommonVariantBuildInput;
  /** Links related to build */
  links?: InputMaybe<Array<Diablo4LinkInput>>;
  /** Name of the build */
  name: Scalars['String'];
  /** Flag that indicates if builds has to be published */
  publish?: InputMaybe<Scalars['Boolean']>;
  /** Season of the build */
  season?: InputMaybe<Scalars['ID']>;
  /** Custom tags assigned to this build */
  tags?: InputMaybe<Array<Scalars['String']>>;
  /** Tier of this build */
  tierID?: InputMaybe<Scalars['ID']>;
};

export type Diablo4CursorPage = {
  count?: InputMaybe<Scalars['Int']>;
  cursor?: InputMaybe<Scalars['String']>;
};

export type Diablo4CursorPaginationInfo = {
  __typename?: 'Diablo4CursorPaginationInfo';
  cursor?: Maybe<Scalars['String']>;
  hasMoreItems?: Maybe<Scalars['Boolean']>;
};

export type Diablo4CustomAuthor = {
  __typename?: 'Diablo4CustomAuthor';
  /** User's Diablo 4 id */
  id: Scalars['ID'];
  /** User's Diablo 4 links */
  links?: Maybe<Array<Diablo4AuthorLink>>;
  /** User's name */
  name?: Maybe<Scalars['String']>;
  twitch?: Maybe<Twitch>;
};

/** Filter to get single build */
export type Diablo4CustomBuildFilter = {
  /** UUID of the build */
  id: Scalars['ID'];
};

export type Diablo4CustomBuildV3 = {
  __typename?: 'Diablo4CustomBuildV3';
  /** Author of the build */
  author?: Maybe<Diablo4CustomAuthor>;
  /** Build overview */
  buildOverview?: Maybe<Scalars['String']>;
  /** Build summary */
  buildSummary?: Maybe<Scalars['String']>;
  /** Diablo4 class */
  class: Diablo4Class;
  /** When the build was created */
  createdAt: Scalars['Diablo4DateTime'];
  /** User who created the build */
  creator?: Maybe<Diablo4User>;
  /** How many people added this build to their favourites */
  favouriteCounter: Scalars['Int'];
  /** The date when the build was first published */
  firstPublishedAt?: Maybe<Scalars['Diablo4DateTime']>;
  /** Detailed gameplay loop i.e how to use skills */
  gameplayLoop?: Maybe<Scalars['String']>;
  /** UUID of the build */
  id: Scalars['ID'];
  /** In depth explanation */
  inDepthExplanation?: Maybe<Array<Diablo4InDepthExplanationBlock>>;
  /** If author of the build deleted this build */
  isDeleted: Scalars['Boolean'];
  /** If this builds was added to favourites by current user */
  isFavourite: Scalars['Boolean'];
  /** If the build is published at current moment */
  isPublished: Scalars['Boolean'];
  /** Links related to this build */
  links?: Maybe<Array<Diablo4BuildLink>>;
  /** Name of the build, can be empty for anonymous (shared) builds */
  name?: Maybe<Scalars['String']>;
  /** Build season */
  season?: Maybe<Diablo4Season>;
  /** Strengths and weaknesses */
  strengthsWeaknesses?: Maybe<Diablo4StrengthsWeaknesses>;
  /** Custom tags assigned to this build */
  tags?: Maybe<Array<Scalars['String']>>;
  /** Tier of this build */
  tier?: Maybe<Diablo4Tier>;
  /** When the build was last updated */
  updatedAt: Scalars['Diablo4DateTime'];
  /**
   * Variant represents configuration of the build for specific use case
   * For example, variant can represent leveling, farming, boss killing, etc.
   */
  variants?: Maybe<Array<Diablo4Variant>>;
};

export type Diablo4CustomBuildV3Payload = {
  __typename?: 'Diablo4CustomBuildV3Payload';
  build?: Maybe<Diablo4CustomBuildV3>;
  error?: Maybe<Diablo4CustomBuildV3PayloadError>;
};

export type Diablo4CustomBuildV3PayloadError = Diablo4GenericError | Diablo4NotFoundError;

export enum Diablo4CustomBuildsFavouritesFilter {
  ONLY = 'ONLY',
}

/** Filter to get build's list */
export type Diablo4CustomBuildsListFilter = {
  /** Diablo 4 classes */
  class?: InputMaybe<Array<Scalars['ID']>>;
  /** Filtering based on favourite flag */
  favourites?: InputMaybe<Diablo4CustomBuildsFavouritesFilter>;
  /** Include builds that were deleted by the author */
  includeDeleted: Scalars['Boolean'];
  /** Filtering result set by published flag. Empty value means = include published and unpublished */
  published?: InputMaybe<Diablo4CustomBuildsPublishedFilter>;
  /** Filter by the date build was first time published. Empty value returns all records */
  publishedDuring?: InputMaybe<Diablo4Timeframe>;
  /** Filter by season */
  season?: InputMaybe<Scalars['String']>;
  /** Filter by tags. Multiple tags are treated as AND. Build will be returned if it matches all tags */
  tags?: InputMaybe<Array<Scalars['ID']>>;
  /** Which tiers were assigned to the build */
  tier?: InputMaybe<Array<Scalars['ID']>>;
  /** Creator of the build (User) IDs */
  userID?: InputMaybe<Scalars['ID']>;
};

export enum Diablo4CustomBuildsPublishedFilter {
  EXCLUDE = 'EXCLUDE',
  ONLY = 'ONLY',
}

/** Delete custom build (only non-anonymous builds) request */
export type Diablo4DeleteBuildInput = {
  /** ID of the build */
  id: Scalars['ID'];
};

/** Delete build result */
export type Diablo4DeleteBuildPayload = {
  __typename?: 'Diablo4DeleteBuildPayload';
  /** Any error occurred during the deletion */
  error?: Maybe<Diablo4Error>;
  /** Was the deletion successful */
  success: Scalars['Boolean'];
};

export type Diablo4DruidBoon = {
  __typename?: 'Diablo4DruidBoon';
  type: Diablo4DruidBoonType;
  value?: Maybe<Array<Diablo4Skill>>;
};

export type Diablo4DruidBoonInput = {
  type: Diablo4DruidBoonType;
  value?: InputMaybe<Array<Diablo4SkillInput>>;
};

export enum Diablo4DruidBoonType {
  DEER = 'DEER',
  EAGLE = 'EAGLE',
  SNAKE = 'SNAKE',
  WOLF = 'WOLF',
}

export type Diablo4Dungeon = {
  __typename?: 'Diablo4Dungeon';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4DungeonsFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4DungeonsResult = {
  __typename?: 'Diablo4DungeonsResult';
  data?: Maybe<Array<Diablo4Dungeon>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4Error = {
  message?: Maybe<Scalars['String']>;
};

export type Diablo4Game = {
  __typename?: 'Diablo4Game';
  author?: Maybe<Diablo4CustomAuthor>;
  buildTypes?: Maybe<Array<Diablo4BuildTypeOrError>>;
  id: Scalars['ID'];
  mapHelltideEvents: Diablo4MapHelltideEventsPayload;
  metaBuildVariant?: Maybe<Diablo4MetaBuildVariantPayload>;
  metaBuildVariants?: Maybe<Diablo4MetaBuildVariantsPayload>;
  plannerLayouts?: Maybe<Array<Diablo4PlannerLayoutOrError>>;
  seasons?: Maybe<Array<Diablo4SeasonOrError>>;
  settings?: Maybe<Diablo4GameSettingsOrError>;
  staticData?: Maybe<Diablo4StaticDataQuery>;
  /** Diablo 4 event timers */
  timers: Diablo4Timers;
  /** Returns single custom build with variants that satisfies filter criteria */
  variantBuild: Diablo4CustomBuildV3Payload;
  /** Returns filtered & paginated list of variant builds (cursor pagination and build variants) */
  variantBuildsCursor: Diablo4VariantBuildsCursorPayload;
  /** Returns filtered & paginated list of variant builds (offset pagination) */
  variantBuildsOffset: Diablo4VariantBuildsOffsetPayload;
};

export type Diablo4GameauthorArgs = {
  userID: Scalars['ID'];
};

export type Diablo4GamebuildTypesArgs = {
  filter?: InputMaybe<Diablo4BuildTypesDictionaryFilter>;
};

export type Diablo4GamemapHelltideEventsArgs = {
  filter?: InputMaybe<Diablo4MapHelltideEventsFilter>;
};

export type Diablo4GamemetaBuildVariantArgs = {
  filter: Diablo4MetaBuildFilter;
};

export type Diablo4GamemetaBuildVariantsArgs = {
  filter?: InputMaybe<Diablo4MetaBuildsFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Diablo4MetaBuildsSortingOption>;
};

export type Diablo4GameplannerLayoutsArgs = {
  filter?: InputMaybe<Diablo4PlannerLayoutFilter>;
};

export type Diablo4GamevariantBuildArgs = {
  filter?: InputMaybe<Diablo4CustomBuildFilter>;
};

export type Diablo4GamevariantBuildsCursorArgs = {
  filter?: InputMaybe<Diablo4CustomBuildsListFilter>;
  page?: InputMaybe<Diablo4CursorPage>;
  sort?: InputMaybe<Diablo4ListSortingOption>;
};

export type Diablo4GamevariantBuildsOffsetArgs = {
  filter?: InputMaybe<Diablo4CustomBuildsListFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Diablo4ListSortingOption>;
};

export type Diablo4GameSettings = {
  __typename?: 'Diablo4GameSettings';
  currentSeason?: Maybe<Diablo4SeasonOrError>;
  paragonNodesLimit?: Maybe<Scalars['Int']>;
  renownValue?: Maybe<Scalars['Int']>;
  skillLimit?: Maybe<Scalars['Int']>;
};

export type Diablo4GameSettingsOrError = Diablo4GameSettings | Diablo4GenericError;

export type Diablo4Gear = {
  __typename?: 'Diablo4Gear';
  aspect?: Maybe<Diablo4Aspect>;
  implicitStats?: Maybe<Array<Maybe<Diablo4StatImplicit>>>;
  item?: Maybe<Diablo4UniqueItem>;
  slot: Diablo4BuildSlot;
  stats?: Maybe<Array<Maybe<Diablo4Stat>>>;
  temperingStats?: Maybe<Array<Maybe<Diablo4StatTempering>>>;
};

/** Describes an item in a specific slot. Must be either aspect or item */
export type Diablo4GearInput = {
  aspectId?: InputMaybe<Scalars['ID']>;
  implicitStatIds?: InputMaybe<Array<Scalars['ID']>>;
  itemId?: InputMaybe<Scalars['ID']>;
  slotId: Scalars['ID'];
  /** statIds is deprecated. Use stats instead */
  statIds?: InputMaybe<Array<Scalars['ID']>>;
  stats?: InputMaybe<Array<InputMaybe<Diablo4StatInput>>>;
  /** temperingStatIds is deprecated. Use temperingStats instead */
  temperingStatIds?: InputMaybe<Array<Scalars['ID']>>;
  temperingStats?: InputMaybe<Array<InputMaybe<Diablo4TemperingStatInput>>>;
};

export type Diablo4Gem = {
  __typename?: 'Diablo4Gem';
  bonuses?: Maybe<Array<Diablo4Bonus>>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  occult?: Maybe<Scalars['Boolean']>;
  rarity?: Maybe<Scalars['String']>;
  season?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type Diablo4GemInput = {
  gemId: Scalars['ID'];
  slotId: Scalars['ID'];
};

export type Diablo4GemType = {
  __typename?: 'Diablo4GemType';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4GemTypesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4GemTypesResult = {
  __typename?: 'Diablo4GemTypesResult';
  data?: Maybe<Array<Diablo4GemType>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4GemsFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  season?: InputMaybe<OneOfOptionalString>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4GemsResult = {
  __typename?: 'Diablo4GemsResult';
  data?: Maybe<Array<Diablo4Gem>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4GenericError = Diablo4Error & {
  __typename?: 'Diablo4GenericError';
  message?: Maybe<Scalars['String']>;
};

export type Diablo4GoverningStone = {
  __typename?: 'Diablo4GoverningStone';
  damageTypes?: Maybe<Array<Scalars['String']>>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  maxLevel?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  rarity?: Maybe<Diablo4StoneRarity>;
  raritySlug?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  stoneStats?: Maybe<Array<Diablo4StoneStat>>;
  tags?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Scalars['String']>;
};

export type Diablo4GoverningStoneInput = {
  governingStoneId: Scalars['ID'];
  governingStoneLevel?: InputMaybe<Scalars['Int']>;
};

export type Diablo4GoverningStonesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4GoverningStonesResult = {
  __typename?: 'Diablo4GoverningStonesResult';
  data?: Maybe<Array<Diablo4GoverningStone>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4Heart = {
  __typename?: 'Diablo4Heart';
  class?: Maybe<Diablo4Class>;
  classSlug?: Maybe<Scalars['String']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4HeartInput = {
  heartId: Scalars['ID'];
  slotId: Scalars['ID'];
};

export type Diablo4HeartsFilter = {
  classSlug?: InputMaybe<OneOfOptionalString>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4HeartsResult = {
  __typename?: 'Diablo4HeartsResult';
  data?: Maybe<Array<Diablo4Heart>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4HelltideTimer = {
  __typename?: 'Diablo4HelltideTimer';
  /** Upcoming (or current) helltide event end time */
  endTime?: Maybe<Scalars['Diablo4DateTime']>;
  /** Next helltide end time */
  nextEndTime?: Maybe<Scalars['Diablo4DateTime']>;
  /** Next helltide start time */
  nextStartTime?: Maybe<Scalars['Diablo4DateTime']>;
  /** Chests that have been spawned in helltide zone. Rotate in the beginning of each hour. */
  spawnedChests?: Maybe<Array<Diablo4MapItem>>;
  /** Upcoming (or current) helltide event start time */
  startTime?: Maybe<Scalars['Diablo4DateTime']>;
  /** DEPRECATED */
  zone?: Maybe<Scalars['String']>;
  /** Map zone of current helltide event */
  zoneV2?: Maybe<Diablo4MapZone>;
};

export type Diablo4HomePage = {
  __typename?: 'Diablo4HomePage';
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<Diablo4SeoMetaData>;
};

export type Diablo4HomePageOrError = Diablo4GenericError | Diablo4HomePage;

export type Diablo4InDepthExplanationBlock = {
  __typename?: 'Diablo4InDepthExplanationBlock';
  /** Content of the block */
  content?: Maybe<Scalars['String']>;
  /** Title of the block */
  title: Scalars['String'];
};

export type Diablo4InDepthExplanationBlockInput = {
  content?: InputMaybe<Scalars['String']>;
  title: Scalars['String'];
};

export type Diablo4LegionTimer = {
  __typename?: 'Diablo4LegionTimer';
  /** Next legion event start time */
  nextStartTime?: Maybe<Scalars['Diablo4DateTime']>;
  /** Upcoming legion start time */
  startTime?: Maybe<Scalars['Diablo4DateTime']>;
};

export type Diablo4LeveledMercenarySkill = {
  __typename?: 'Diablo4LeveledMercenarySkill';
  actionType: Diablo4SkillActionType;
  skill: Diablo4MercenarySkill;
};

export type Diablo4LeveledSkill = {
  __typename?: 'Diablo4LeveledSkill';
  actionType: Diablo4SkillActionType;
  skill: Diablo4Skill;
};

export type Diablo4LeveledSkillInput = {
  action: Diablo4SkillActionType;
  id: Scalars['ID'];
};

export type Diablo4Link = {
  __typename?: 'Diablo4Link';
  linkText?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['Diablo4Url']>;
};

/** Data for link creation */
export type Diablo4LinkInput = {
  linkText: Scalars['String'];
  linkType?: InputMaybe<Scalars['String']>;
  linkUrl: Scalars['String'];
};

export enum Diablo4ListSortingOption {
  NEW = 'NEW',
  TOP = 'TOP',
  TRENDING = 'TRENDING',
}

export type Diablo4Location = {
  __typename?: 'Diablo4Location';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4LocationsFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4LocationsResult = {
  __typename?: 'Diablo4LocationsResult';
  data?: Maybe<Array<Diablo4Location>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4MapHelltideEvent = {
  __typename?: 'Diablo4MapHelltideEvent';
  /** URL for the icon of this type of event. */
  iconURL: Scalars['Diablo4Url'];
  /** Name of the helltide event. */
  name: Scalars['String'];
  /** X coordinate of the location/item. */
  x: Scalars['Float'];
  /** Y coordinate of the location/item. */
  y: Scalars['Float'];
};

export type Diablo4MapHelltideEventsFilter = {
  /** Filter by the zone of helltide event. */
  zone?: InputMaybe<Scalars['ID']>;
};

export type Diablo4MapHelltideEventsPayload = {
  __typename?: 'Diablo4MapHelltideEventsPayload';
  data?: Maybe<Array<Diablo4MapHelltideEvent>>;
  errors?: Maybe<Diablo4MapHelltideEventsPayloadError>;
};

export type Diablo4MapHelltideEventsPayloadError = Diablo4GenericError;

export type Diablo4MapItem = {
  __typename?: 'Diablo4MapItem';
  armoryID?: Maybe<Scalars['Int']>;
  chestIndex?: Maybe<Scalars['Int']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  mapItemType?: Maybe<Diablo4MapItemType>;
  mapItemTypeSlug?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

export type Diablo4MapItemType = {
  __typename?: 'Diablo4MapItemType';
  deprecated?: Maybe<Scalars['Boolean']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4MapItemTypesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4MapItemTypesResult = {
  __typename?: 'Diablo4MapItemTypesResult';
  data?: Maybe<Array<Diablo4MapItemType>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4MapItemsFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  mapItemTypeSlug?: InputMaybe<OneOfOptionalString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4MapItemsResult = {
  __typename?: 'Diablo4MapItemsResult';
  data?: Maybe<Array<Diablo4MapItem>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4MapZone = {
  __typename?: 'Diablo4MapZone';
  centerX?: Maybe<Scalars['Int']>;
  centerY?: Maybe<Scalars['Int']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  /** Helltide events that are happening in this zone. */
  helltideEvents?: Maybe<Array<Diablo4MapHelltideEvent>>;
  id: Scalars['String'];
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4MapZonesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4MapZonesResult = {
  __typename?: 'Diablo4MapZonesResult';
  data?: Maybe<Array<Diablo4MapZone>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4Marketing = {
  __typename?: 'Diablo4Marketing';
  appSettings?: Maybe<Diablo4AppSettingsOrError>;
  promoPopups?: Maybe<Array<Diablo4PromoPopupOrError>>;
  releaseNotes?: Maybe<Array<Diablo4ReleaseNoteOrError>>;
  topBanners?: Maybe<Array<Diablo4TopBannerOrError>>;
};

export type Diablo4MarketingpromoPopupsArgs = {
  filter?: InputMaybe<Diablo4PromoPopupFilter>;
};

export type Diablo4MarketingreleaseNotesArgs = {
  filter?: InputMaybe<Diablo4ReleaseNoteFilter>;
};

export type Diablo4MarketingtopBannersArgs = {
  filter?: InputMaybe<Diablo4TopBannerFilter>;
};

export type Diablo4MercenariesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  season?: InputMaybe<OneOfOptionalString>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4MercenariesResult = {
  __typename?: 'Diablo4MercenariesResult';
  data?: Maybe<Array<Diablo4Mercenary>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4Mercenary = {
  __typename?: 'Diablo4Mercenary';
  deprecated?: Maybe<Scalars['Boolean']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  season?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

export type Diablo4MercenaryBuildData = {
  __typename?: 'Diablo4MercenaryBuildData';
  opportunity?: Maybe<Diablo4AssignedOpportunity>;
  primaryMercenary?: Maybe<Diablo4Mercenary>;
  reinforcementMercenary?: Maybe<Diablo4Mercenary>;
  skill?: Maybe<Diablo4MercenarySkill>;
  skillTree?: Maybe<Array<Diablo4LeveledMercenarySkill>>;
};

export type Diablo4MercenaryInput = {
  opportunity?: InputMaybe<Diablo4MercenaryOpportunityInput>;
  primaryMercenary?: InputMaybe<Scalars['ID']>;
  reinforcementMercenary?: InputMaybe<Scalars['ID']>;
  skill?: InputMaybe<Scalars['ID']>;
  skillTree?: InputMaybe<Array<Diablo4LeveledSkillInput>>;
};

export type Diablo4MercenaryOpportunityInput = {
  opportunityId?: InputMaybe<Scalars['ID']>;
  skillId?: InputMaybe<Scalars['ID']>;
};

export type Diablo4MercenarySkill = {
  __typename?: 'Diablo4MercenarySkill';
  attackDescription?: Maybe<Scalars['String']>;
  depends?: Maybe<Array<Scalars['String']>>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  disables?: Maybe<Array<Scalars['String']>>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mercenary?: Maybe<Diablo4Mercenary>;
  mercenarySlug?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  nodeTypeSlug?: Maybe<Scalars['String']>;
  outs?: Maybe<Array<Scalars['String']>>;
  resources?: Maybe<Array<Diablo4Resource>>;
  season?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type Diablo4MercenarySkillsFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  mercenarySlug?: InputMaybe<OneOfOptionalString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  season?: InputMaybe<OneOfOptionalString>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4MercenarySkillsResult = {
  __typename?: 'Diablo4MercenarySkillsResult';
  data?: Maybe<Array<Diablo4MercenarySkill>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4MetaBuildFilter = {
  id: Scalars['ID'];
};

export type Diablo4MetaBuildVariant = {
  __typename?: 'Diablo4MetaBuildVariant';
  archived?: Maybe<Scalars['Boolean']>;
  build?: Maybe<Diablo4CustomBuildV3>;
  class?: Maybe<Diablo4Class>;
  id: Scalars['ID'];
  isFeatured?: Maybe<Scalars['Boolean']>;
  season?: Maybe<Diablo4SeasonOrError>;
  tier?: Maybe<Diablo4Tier>;
  types?: Maybe<Array<Diablo4BuildTypeOrError>>;
};

export type Diablo4MetaBuildVariantPayload = {
  __typename?: 'Diablo4MetaBuildVariantPayload';
  data?: Maybe<Diablo4MetaBuildVariant>;
  error?: Maybe<Diablo4MetaBuildVariantsPayloadError>;
};

export type Diablo4MetaBuildVariantPayloadError = Diablo4GenericError;

export type Diablo4MetaBuildVariantsPayload = {
  __typename?: 'Diablo4MetaBuildVariantsPayload';
  data?: Maybe<Array<Diablo4MetaBuildVariant>>;
  error?: Maybe<Diablo4MetaBuildVariantPayloadError>;
  pageInfo?: Maybe<Diablo4PaginationInfo>;
};

export type Diablo4MetaBuildVariantsPayloadError = Diablo4GenericError | Diablo4NotFoundError;

export type Diablo4MetaBuildsFilter = {
  archived?: InputMaybe<Scalars['Boolean']>;
  buildUuids?: InputMaybe<Array<Scalars['ID']>>;
  classes?: InputMaybe<Array<Scalars['ID']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  isFeatured?: InputMaybe<Scalars['Boolean']>;
  /**
   * Filters meta builds by season
   * if putted id = "current-season" it will be replaced by current season id automatically
   */
  seasons?: InputMaybe<Array<Scalars['ID']>>;
  tiers?: InputMaybe<Array<Scalars['ID']>>;
  types?: InputMaybe<Array<Scalars['ID']>>;
};

export enum Diablo4MetaBuildsSortingOption {
  IS_FEATURED = 'IS_FEATURED',
}

export type Diablo4Mutation = {
  __typename?: 'Diablo4Mutation';
  /** Create custom build with variants for registered user */
  createVariantBuild: Diablo4CreateBuildPayload;
  /** Delete custom build of registered user */
  deleteBuild: Diablo4DeleteBuildPayload;
  /** Add/remove custom build to favourites */
  setFavourite: Diablo4SetFavouritePayload;
  /** Update custom build of registered user */
  updateVariantBuild: Diablo4UpdateBuildPayload;
};

export type Diablo4MutationcreateVariantBuildArgs = {
  input: Diablo4CreateVariantBuildInput;
};

export type Diablo4MutationdeleteBuildArgs = {
  input: Diablo4DeleteBuildInput;
};

export type Diablo4MutationsetFavouriteArgs = {
  input: Diablo4SetFavouriteInput;
};

export type Diablo4MutationupdateVariantBuildArgs = {
  input: Diablo4UpdateVariantBuildInput;
};

export type Diablo4NecromancerSummon = {
  __typename?: 'Diablo4NecromancerSummon';
  spec: Diablo4Skill;
  type: Diablo4NecromancerSummonType;
  upgrade: Scalars['Int'];
};

export type Diablo4NecromancerSummonInput = {
  spec: Diablo4SkillInput;
  type: Diablo4NecromancerSummonType;
  upgrade: Scalars['Int'];
};

export enum Diablo4NecromancerSummonType {
  GOLEMS = 'GOLEMS',
  SKELETAL_MAGES = 'SKELETAL_MAGES',
  SKELETAL_WARRIORS = 'SKELETAL_WARRIORS',
}

export type Diablo4NodeStat = {
  __typename?: 'Diablo4NodeStat';
  type?: Maybe<Diablo4StatType>;
  typeSlug?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Diablo4NotFoundError = Diablo4Error & {
  __typename?: 'Diablo4NotFoundError';
  message?: Maybe<Scalars['String']>;
};

export type Diablo4OpportunitiesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  season?: InputMaybe<OneOfOptionalString>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4OpportunitiesResult = {
  __typename?: 'Diablo4OpportunitiesResult';
  data?: Maybe<Array<Diablo4Opportunity>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4Opportunity = {
  __typename?: 'Diablo4Opportunity';
  deprecated?: Maybe<Scalars['Boolean']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  season?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type Diablo4Pact = {
  __typename?: 'Diablo4Pact';
  divinity?: Maybe<Scalars['Int']>;
  eternity?: Maybe<Scalars['Int']>;
  ferocity?: Maybe<Scalars['Int']>;
};

export type Diablo4Pages = {
  __typename?: 'Diablo4Pages';
  buildListPage?: Maybe<Diablo4BuildListPageOrError>;
  buildPage?: Maybe<Diablo4BuildPageOrError>;
  communityBuildPage?: Maybe<Diablo4CommunityBuildPageOrError>;
  homePage?: Maybe<Diablo4HomePageOrError>;
  tierListPage?: Maybe<Diablo4TierListPageOrError>;
};

export type Diablo4PaginationInfo = {
  __typename?: 'Diablo4PaginationInfo';
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type Diablo4PaginationInfoOrError = Diablo4GenericError | Diablo4PaginationInfo;

export type Diablo4ParagonBoard = {
  __typename?: 'Diablo4ParagonBoard';
  classes?: Maybe<Array<Diablo4Class>>;
  classesSlugs?: Maybe<Array<Scalars['String']>>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  nodes?: Maybe<Array<Diablo4ParagonNode>>;
  nodesSlugs?: Maybe<Array<Scalars['String']>>;
  season?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  stats?: Maybe<Array<Diablo4Stat>>;
  title?: Maybe<Scalars['String']>;
};

export type Diablo4ParagonBoardInput = {
  glyphId?: InputMaybe<Scalars['ID']>;
  glyphLevel?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  rotation?: InputMaybe<Scalars['Int']>;
  x: Scalars['Int'];
  y: Scalars['Int'];
};

export type Diablo4ParagonBoardsFilter = {
  classesSlugs?: InputMaybe<StringsIntersection>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  season?: InputMaybe<OneOfOptionalString>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4ParagonBoardsResult = {
  __typename?: 'Diablo4ParagonBoardsResult';
  data?: Maybe<Array<Diablo4ParagonBoard>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4ParagonGlyph = {
  __typename?: 'Diablo4ParagonGlyph';
  additionalBonus?: Maybe<Array<Scalars['String']>>;
  affectedType?: Maybe<Diablo4ParagonNodeType>;
  affectedTypeSlug?: Maybe<Scalars['String']>;
  bonus?: Maybe<Array<Scalars['String']>>;
  class?: Maybe<Diablo4Class>;
  classSlug?: Maybe<Scalars['String']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  legendaryBonus?: Maybe<Array<Scalars['String']>>;
  legendaryValue?: Maybe<Scalars['String']>;
  maxLevel?: Maybe<Scalars['Int']>;
  maxRadius?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  radiusDistribution?: Maybe<Array<Diablo4RadiusDistribution>>;
  rarity?: Maybe<Diablo4ParagonGlyphRarity>;
  raritySlug?: Maybe<Scalars['String']>;
  requirementsStats?: Maybe<Array<Diablo4RequirementsStat>>;
  scaling?: Maybe<Scalars['Float']>;
  season?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  statName?: Maybe<Scalars['String']>;
  statScaling?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type Diablo4ParagonGlyphRaritiesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4ParagonGlyphRaritiesResult = {
  __typename?: 'Diablo4ParagonGlyphRaritiesResult';
  data?: Maybe<Array<Diablo4ParagonGlyphRarity>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4ParagonGlyphRarity = {
  __typename?: 'Diablo4ParagonGlyphRarity';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4ParagonGlyphsFilter = {
  classSlug?: InputMaybe<OneOfOptionalString>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  season?: InputMaybe<OneOfOptionalString>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4ParagonGlyphsResult = {
  __typename?: 'Diablo4ParagonGlyphsResult';
  data?: Maybe<Array<Diablo4ParagonGlyph>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4ParagonNode = {
  __typename?: 'Diablo4ParagonNode';
  bonusAmount?: Maybe<Array<Scalars['String']>>;
  class?: Maybe<Diablo4Class>;
  classSlug?: Maybe<Scalars['String']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  effects?: Maybe<Array<Scalars['String']>>;
  iconUrlActive?: Maybe<Scalars['String']>;
  iconUrlNormal?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  nodeStats?: Maybe<Array<Diablo4NodeStat>>;
  requiredStats?: Maybe<Array<Diablo4RequiredStat>>;
  requires?: Maybe<Array<Scalars['String']>>;
  season?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  type?: Maybe<Diablo4ParagonNodeType>;
  typeSlug?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type Diablo4ParagonNodeInput = {
  id: Scalars['ID'];
};

export type Diablo4ParagonNodeType = {
  __typename?: 'Diablo4ParagonNodeType';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4ParagonNodeTypesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4ParagonNodeTypesResult = {
  __typename?: 'Diablo4ParagonNodeTypesResult';
  data?: Maybe<Array<Diablo4ParagonNodeType>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4ParagonNodesFilter = {
  classSlug?: InputMaybe<OneOfOptionalString>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  season?: InputMaybe<OneOfOptionalString>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4ParagonNodesResult = {
  __typename?: 'Diablo4ParagonNodesResult';
  data?: Maybe<Array<Diablo4ParagonNode>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4PlannerAspectsAndUniques = {
  __typename?: 'Diablo4PlannerAspectsAndUniques';
  buildSlot?: Maybe<Scalars['String']>;
  col?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['String']>;
};

export type Diablo4PlannerGems = {
  __typename?: 'Diablo4PlannerGems';
  allowed?: Maybe<Array<Scalars['String']>>;
  buildSlot?: Maybe<Scalars['String']>;
};

export type Diablo4PlannerLayout = {
  __typename?: 'Diablo4PlannerLayout';
  aspectsAndUniques?: Maybe<Array<Diablo4PlannerAspectsAndUniques>>;
  class?: Maybe<Diablo4Class>;
  gems?: Maybe<Array<Diablo4PlannerGems>>;
  id: Scalars['ID'];
  season?: Maybe<Diablo4SeasonOrError>;
};

export type Diablo4PlannerLayoutFilter = {
  classes?: InputMaybe<Array<Scalars['ID']>>;
  seasons?: InputMaybe<Array<Scalars['ID']>>;
};

export type Diablo4PlannerLayoutOrError = Diablo4GenericError | Diablo4PlannerLayout;

/** Represent possible platforms for the game. Commonly used to separate banners by platform. */
export enum Diablo4Platform {
  DESKTOP = 'DESKTOP',
  OVERWOLF = 'OVERWOLF',
  WEB = 'WEB',
}

export type Diablo4PromoPopup = {
  __typename?: 'Diablo4PromoPopup';
  button?: Maybe<Diablo4PromoPopupButton>;
  disallowedUserAttributes?: Maybe<Array<Diablo4UserAttribute>>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  labelBackground?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['Diablo4Locale']>;
  mixpanelName?: Maybe<Scalars['String']>;
  modalBackground?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<Diablo4Platform>>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userLevels?: Maybe<Array<Diablo4UserLevel>>;
  value?: Maybe<Scalars['String']>;
};

export type Diablo4PromoPopupButton = {
  __typename?: 'Diablo4PromoPopupButton';
  link?: Maybe<Scalars['Diablo4Url']>;
  title?: Maybe<Scalars['String']>;
};

export type Diablo4PromoPopupFilter = {
  disallowedUserAttribute?: InputMaybe<Diablo4UserAttribute>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locale?: InputMaybe<Scalars['Diablo4Locale']>;
  platform?: InputMaybe<Diablo4Platform>;
  userLevel?: InputMaybe<Diablo4UserLevel>;
};

export type Diablo4PromoPopupOrError = Diablo4GenericError | Diablo4PromoPopup;

export type Diablo4PromoPopupOrNotFoundError = Diablo4GenericError | Diablo4NotFoundError | Diablo4PromoPopup;

export type Diablo4Query = {
  __typename?: 'Diablo4Query';
  game?: Maybe<Diablo4Game>;
  marketing?: Maybe<Diablo4Marketing>;
  pages?: Maybe<Diablo4Pages>;
};

export type Diablo4RadiusDistribution = {
  __typename?: 'Diablo4RadiusDistribution';
  level?: Maybe<Scalars['Int']>;
  radius?: Maybe<Scalars['Int']>;
};

/** Represent a list of changes in the game. Usually comes out with a new patch. */
export type Diablo4ReleaseNote = {
  __typename?: 'Diablo4ReleaseNote';
  button?: Maybe<Diablo4ReleaseNoteButton>;
  content?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['Diablo4Locale']>;
  platforms?: Maybe<Array<Diablo4Platform>>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  userLevels?: Maybe<Array<Diablo4UserLevel>>;
};

export type Diablo4ReleaseNoteButton = {
  __typename?: 'Diablo4ReleaseNoteButton';
  link?: Maybe<Scalars['Diablo4Url']>;
  title?: Maybe<Scalars['String']>;
};

export type Diablo4ReleaseNoteFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locale?: InputMaybe<Scalars['Diablo4Locale']>;
  platform?: InputMaybe<Diablo4Platform>;
  userLevel?: InputMaybe<Diablo4UserLevel>;
};

export type Diablo4ReleaseNoteOrError = Diablo4GenericError | Diablo4ReleaseNote;

export type Diablo4ReleaseNoteOrNotFoundError = Diablo4GenericError | Diablo4NotFoundError | Diablo4ReleaseNote;

export type Diablo4RequiredStat = {
  __typename?: 'Diablo4RequiredStat';
  step?: Maybe<Scalars['String']>;
  type?: Maybe<Diablo4StatType>;
  typeSlug?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Diablo4RequirementsStat = {
  __typename?: 'Diablo4RequirementsStat';
  type?: Maybe<Diablo4StatType>;
  typeSlug?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type Diablo4Resource = {
  __typename?: 'Diablo4Resource';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Diablo4Rune = {
  __typename?: 'Diablo4Rune';
  bonus?: Maybe<Scalars['String']>;
  cooldown?: Maybe<Scalars['Int']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  gain?: Maybe<Scalars['Int']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  overflow?: Maybe<Scalars['String']>;
  rarity?: Maybe<Scalars['String']>;
  requires?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export enum Diablo4RuneSection {
  ARMOR = 'ARMOR',
  WEAPON = 'WEAPON',
}

export type Diablo4RunesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4RunesResult = {
  __typename?: 'Diablo4RunesResult';
  data?: Maybe<Array<Diablo4Rune>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4Season = {
  __typename?: 'Diablo4Season';
  id: Scalars['ID'];
  name: Scalars['String'];
  /** Amount of paragon points available for paragon tree in this season */
  paragonPoints?: Maybe<Scalars['Int']>;
  /** Amount of skill points awarded from renown reward in this season */
  renownSkillPoints?: Maybe<Scalars['Int']>;
  seasonMechanics?: Maybe<Array<Diablo4SeasonMechanic>>;
  /** Amount of skill points available for skill tree in this season */
  skillPoints?: Maybe<Scalars['Int']>;
  /** Character level threshold related to skill tree in this season */
  skillTreeLevelThreshold?: Maybe<Scalars['Int']>;
  startingAt?: Maybe<Scalars['Diablo4DateTime']>;
};

export enum Diablo4SeasonMechanic {
  HEARTS = 'HEARTS',
  RUNEWORDS = 'RUNEWORDS',
  SENESCHAL = 'SENESCHAL',
  VAMPIRE_POWERS = 'VAMPIRE_POWERS',
  WITCHCRAFT = 'WITCHCRAFT',
}

export type Diablo4SeasonOrError = Diablo4GenericError | Diablo4Season;

export type Diablo4SeneschalCompanionInput = {
  governingStone?: InputMaybe<Diablo4GoverningStoneInput>;
  position?: InputMaybe<Scalars['Int']>;
  tuningStones?: InputMaybe<Array<Diablo4TuningStonesInput>>;
};

/** Metadata for correct page indexing by search engines */
export type Diablo4SeoMetaData = {
  __typename?: 'Diablo4SeoMetaData';
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  ogImage?: Maybe<Scalars['Diablo4Url']>;
  title?: Maybe<Scalars['String']>;
};

export type Diablo4SetFavouriteInput = {
  buildId: Scalars['String'];
  value: Scalars['Boolean'];
};

export type Diablo4SetFavouritePayload = {
  __typename?: 'Diablo4SetFavouritePayload';
  error?: Maybe<Diablo4GenericError>;
};

export type Diablo4Skill = {
  __typename?: 'Diablo4Skill';
  class?: Maybe<Diablo4Class>;
  classSlug?: Maybe<Scalars['String']>;
  dependsOn?: Maybe<Array<Diablo4Skill>>;
  dependsOnSlugs?: Maybe<Array<Scalars['String']>>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  disables?: Maybe<Array<Diablo4Skill>>;
  disablesSlugs?: Maybe<Array<Scalars['String']>>;
  enchantment?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  maxRank?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  requiredRank?: Maybe<Scalars['Int']>;
  resources?: Maybe<Array<Diablo4Resource>>;
  season?: Maybe<Scalars['String']>;
  section?: Maybe<Diablo4SkillSection>;
  sectionSlug?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  substitutions?: Maybe<Array<Diablo4Substitution>>;
  tags?: Maybe<Array<Diablo4SkillTag>>;
  tagsSlugs?: Maybe<Array<Scalars['String']>>;
  type?: Maybe<Diablo4SkillType>;
  typeSlug?: Maybe<Scalars['String']>;
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export enum Diablo4SkillActionType {
  ACTIVATE = 'ACTIVATE',
  REFUND = 'REFUND',
}

export type Diablo4SkillInput = {
  id: Scalars['ID'];
};

export type Diablo4SkillResource = {
  __typename?: 'Diablo4SkillResource';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Diablo4SkillResourcesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4SkillResourcesResult = {
  __typename?: 'Diablo4SkillResourcesResult';
  data?: Maybe<Array<Diablo4SkillResource>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4SkillSection = {
  __typename?: 'Diablo4SkillSection';
  class?: Maybe<Diablo4Class>;
  classSlug?: Maybe<Scalars['String']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  requiredRank?: Maybe<Scalars['Int']>;
  season?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<Diablo4Skill>>;
  skillsSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
  x?: Maybe<Scalars['Int']>;
  y?: Maybe<Scalars['Int']>;
};

export type Diablo4SkillSectionsFilter = {
  classSlug?: InputMaybe<OneOfOptionalString>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  season?: InputMaybe<OneOfOptionalString>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4SkillSectionsResult = {
  __typename?: 'Diablo4SkillSectionsResult';
  data?: Maybe<Array<Diablo4SkillSection>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4SkillTag = {
  __typename?: 'Diablo4SkillTag';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  season?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type Diablo4SkillTagsFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  season?: InputMaybe<OneOfOptionalString>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4SkillTagsResult = {
  __typename?: 'Diablo4SkillTagsResult';
  data?: Maybe<Array<Diablo4SkillTag>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4SkillType = {
  __typename?: 'Diablo4SkillType';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4SkillTypesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4SkillTypesResult = {
  __typename?: 'Diablo4SkillTypesResult';
  data?: Maybe<Array<Diablo4SkillType>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4SkillsFilter = {
  classSlug?: InputMaybe<OneOfOptionalString>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  season?: InputMaybe<OneOfOptionalString>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4SkillsResult = {
  __typename?: 'Diablo4SkillsResult';
  data?: Maybe<Array<Diablo4Skill>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4SocialLinkType = {
  __typename?: 'Diablo4SocialLinkType';
  iconUrl?: Maybe<Scalars['Diablo4Url']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type Diablo4SpiritGuardiansInput = {
  primary?: InputMaybe<Scalars['ID']>;
  secondary?: InputMaybe<Scalars['ID']>;
};

export type Diablo4Stat = {
  __typename?: 'Diablo4Stat';
  buildSlots?: Maybe<Array<Diablo4BuildSlot>>;
  buildSlotsSlugs?: Maybe<Array<Scalars['String']>>;
  classes?: Maybe<Array<Diablo4Class>>;
  classesSlugs?: Maybe<Array<Scalars['String']>>;
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isGreater?: Maybe<Scalars['Boolean']>;
  isUniqueItemStat?: Maybe<Scalars['Boolean']>;
  masterworkLevel?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4StatImplicit = {
  __typename?: 'Diablo4StatImplicit';
  buildSlots?: Maybe<Array<Diablo4BuildSlot>>;
  buildSlotsSlugs?: Maybe<Array<Scalars['String']>>;
  classes?: Maybe<Array<Diablo4Class>>;
  classesSlugs?: Maybe<Array<Scalars['String']>>;
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  isUniqueItemStat?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4StatImplicitFilter = {
  buildSlotsSlugs?: InputMaybe<StringsIntersection>;
  classesSlugs?: InputMaybe<StringsIntersection>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4StatImplicitResult = {
  __typename?: 'Diablo4StatImplicitResult';
  data?: Maybe<Array<Diablo4StatImplicit>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4StatInput = {
  id: Scalars['ID'];
  isGreater?: InputMaybe<Scalars['Boolean']>;
  masterworkLevel?: InputMaybe<Scalars['Int']>;
};

export type Diablo4StatTempering = {
  __typename?: 'Diablo4StatTempering';
  buildSlots?: Maybe<Array<Diablo4BuildSlot>>;
  buildSlotsSlugs?: Maybe<Array<Scalars['String']>>;
  classes?: Maybe<Array<Diablo4Class>>;
  classesSlugs?: Maybe<Array<Scalars['String']>>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isUniqueItemStat?: Maybe<Scalars['Boolean']>;
  masterworkLevel?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4StatTemperingFilter = {
  buildSlotsSlugs?: InputMaybe<StringsIntersection>;
  classesSlugs?: InputMaybe<StringsIntersection>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4StatTemperingResult = {
  __typename?: 'Diablo4StatTemperingResult';
  data?: Maybe<Array<Diablo4StatTempering>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4StatType = {
  __typename?: 'Diablo4StatType';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4StatTypesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4StatTypesResult = {
  __typename?: 'Diablo4StatTypesResult';
  data?: Maybe<Array<Diablo4StatType>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4StaticDataQuery = {
  __typename?: 'Diablo4StaticDataQuery';
  diablo4AspectSlots: Diablo4AspectSlotsResult;
  diablo4AspectTypes: Diablo4AspectTypesResult;
  diablo4Aspects: Diablo4AspectsResult;
  diablo4BuildSlots: Diablo4BuildSlotsResult;
  diablo4Classes: Diablo4ClassesResult;
  diablo4Dungeons: Diablo4DungeonsResult;
  diablo4GemTypes: Diablo4GemTypesResult;
  diablo4Gems: Diablo4GemsResult;
  diablo4GoverningStones: Diablo4GoverningStonesResult;
  diablo4Hearts: Diablo4HeartsResult;
  diablo4Locations: Diablo4LocationsResult;
  diablo4MapItemTypes: Diablo4MapItemTypesResult;
  diablo4MapItems: Diablo4MapItemsResult;
  diablo4MapZones: Diablo4MapZonesResult;
  diablo4Mercenaries: Diablo4MercenariesResult;
  diablo4MercenarySkills: Diablo4MercenarySkillsResult;
  diablo4Opportunities: Diablo4OpportunitiesResult;
  diablo4ParagonBoards: Diablo4ParagonBoardsResult;
  diablo4ParagonGlyphRarities: Diablo4ParagonGlyphRaritiesResult;
  diablo4ParagonGlyphs: Diablo4ParagonGlyphsResult;
  diablo4ParagonNodeTypes: Diablo4ParagonNodeTypesResult;
  diablo4ParagonNodes: Diablo4ParagonNodesResult;
  diablo4Runes: Diablo4RunesResult;
  diablo4SkillResources: Diablo4SkillResourcesResult;
  diablo4SkillSections: Diablo4SkillSectionsResult;
  diablo4SkillTags: Diablo4SkillTagsResult;
  diablo4SkillTypes: Diablo4SkillTypesResult;
  diablo4Skills: Diablo4SkillsResult;
  diablo4StatImplicit: Diablo4StatImplicitResult;
  diablo4StatTempering: Diablo4StatTemperingResult;
  diablo4StatTypes: Diablo4StatTypesResult;
  diablo4Stats: Diablo4StatsResult;
  diablo4StoneRarities: Diablo4StoneRaritiesResult;
  diablo4Tiers: Diablo4TiersResult;
  diablo4TuningStones: Diablo4TuningStonesResult;
  diablo4UniqueItemTypes: Diablo4UniqueItemTypesResult;
  diablo4UniqueItems: Diablo4UniqueItemsResult;
  diablo4VampirePowers: Diablo4VampirePowersResult;
  diablo4WitchcraftPowers: Diablo4WitchcraftPowersResult;
};

export type Diablo4StaticDataQuerydiablo4AspectSlotsArgs = {
  filter?: InputMaybe<Diablo4AspectSlotsFilter>;
};

export type Diablo4StaticDataQuerydiablo4AspectTypesArgs = {
  filter?: InputMaybe<Diablo4AspectTypesFilter>;
};

export type Diablo4StaticDataQuerydiablo4AspectsArgs = {
  filter?: InputMaybe<Diablo4AspectsFilter>;
};

export type Diablo4StaticDataQuerydiablo4BuildSlotsArgs = {
  filter?: InputMaybe<Diablo4BuildSlotsFilter>;
};

export type Diablo4StaticDataQuerydiablo4ClassesArgs = {
  filter?: InputMaybe<Diablo4ClassesFilter>;
};

export type Diablo4StaticDataQuerydiablo4DungeonsArgs = {
  filter?: InputMaybe<Diablo4DungeonsFilter>;
};

export type Diablo4StaticDataQuerydiablo4GemTypesArgs = {
  filter?: InputMaybe<Diablo4GemTypesFilter>;
};

export type Diablo4StaticDataQuerydiablo4GemsArgs = {
  filter?: InputMaybe<Diablo4GemsFilter>;
};

export type Diablo4StaticDataQuerydiablo4GoverningStonesArgs = {
  filter?: InputMaybe<Diablo4GoverningStonesFilter>;
};

export type Diablo4StaticDataQuerydiablo4HeartsArgs = {
  filter?: InputMaybe<Diablo4HeartsFilter>;
};

export type Diablo4StaticDataQuerydiablo4LocationsArgs = {
  filter?: InputMaybe<Diablo4LocationsFilter>;
};

export type Diablo4StaticDataQuerydiablo4MapItemTypesArgs = {
  filter?: InputMaybe<Diablo4MapItemTypesFilter>;
};

export type Diablo4StaticDataQuerydiablo4MapItemsArgs = {
  filter?: InputMaybe<Diablo4MapItemsFilter>;
};

export type Diablo4StaticDataQuerydiablo4MapZonesArgs = {
  filter?: InputMaybe<Diablo4MapZonesFilter>;
};

export type Diablo4StaticDataQuerydiablo4MercenariesArgs = {
  filter?: InputMaybe<Diablo4MercenariesFilter>;
};

export type Diablo4StaticDataQuerydiablo4MercenarySkillsArgs = {
  filter?: InputMaybe<Diablo4MercenarySkillsFilter>;
};

export type Diablo4StaticDataQuerydiablo4OpportunitiesArgs = {
  filter?: InputMaybe<Diablo4OpportunitiesFilter>;
};

export type Diablo4StaticDataQuerydiablo4ParagonBoardsArgs = {
  filter?: InputMaybe<Diablo4ParagonBoardsFilter>;
};

export type Diablo4StaticDataQuerydiablo4ParagonGlyphRaritiesArgs = {
  filter?: InputMaybe<Diablo4ParagonGlyphRaritiesFilter>;
};

export type Diablo4StaticDataQuerydiablo4ParagonGlyphsArgs = {
  filter?: InputMaybe<Diablo4ParagonGlyphsFilter>;
};

export type Diablo4StaticDataQuerydiablo4ParagonNodeTypesArgs = {
  filter?: InputMaybe<Diablo4ParagonNodeTypesFilter>;
};

export type Diablo4StaticDataQuerydiablo4ParagonNodesArgs = {
  filter?: InputMaybe<Diablo4ParagonNodesFilter>;
};

export type Diablo4StaticDataQuerydiablo4RunesArgs = {
  filter?: InputMaybe<Diablo4RunesFilter>;
};

export type Diablo4StaticDataQuerydiablo4SkillResourcesArgs = {
  filter?: InputMaybe<Diablo4SkillResourcesFilter>;
};

export type Diablo4StaticDataQuerydiablo4SkillSectionsArgs = {
  filter?: InputMaybe<Diablo4SkillSectionsFilter>;
};

export type Diablo4StaticDataQuerydiablo4SkillTagsArgs = {
  filter?: InputMaybe<Diablo4SkillTagsFilter>;
};

export type Diablo4StaticDataQuerydiablo4SkillTypesArgs = {
  filter?: InputMaybe<Diablo4SkillTypesFilter>;
};

export type Diablo4StaticDataQuerydiablo4SkillsArgs = {
  filter?: InputMaybe<Diablo4SkillsFilter>;
};

export type Diablo4StaticDataQuerydiablo4StatImplicitArgs = {
  filter?: InputMaybe<Diablo4StatImplicitFilter>;
};

export type Diablo4StaticDataQuerydiablo4StatTemperingArgs = {
  filter?: InputMaybe<Diablo4StatTemperingFilter>;
};

export type Diablo4StaticDataQuerydiablo4StatTypesArgs = {
  filter?: InputMaybe<Diablo4StatTypesFilter>;
};

export type Diablo4StaticDataQuerydiablo4StatsArgs = {
  filter?: InputMaybe<Diablo4StatsFilter>;
};

export type Diablo4StaticDataQuerydiablo4StoneRaritiesArgs = {
  filter?: InputMaybe<Diablo4StoneRaritiesFilter>;
};

export type Diablo4StaticDataQuerydiablo4TiersArgs = {
  filter?: InputMaybe<Diablo4TiersFilter>;
};

export type Diablo4StaticDataQuerydiablo4TuningStonesArgs = {
  filter?: InputMaybe<Diablo4TuningStonesFilter>;
};

export type Diablo4StaticDataQuerydiablo4UniqueItemTypesArgs = {
  filter?: InputMaybe<Diablo4UniqueItemTypesFilter>;
};

export type Diablo4StaticDataQuerydiablo4UniqueItemsArgs = {
  filter?: InputMaybe<Diablo4UniqueItemsFilter>;
};

export type Diablo4StaticDataQuerydiablo4VampirePowersArgs = {
  filter?: InputMaybe<Diablo4VampirePowersFilter>;
};

export type Diablo4StaticDataQuerydiablo4WitchcraftPowersArgs = {
  filter?: InputMaybe<Diablo4WitchcraftPowersFilter>;
};

export type Diablo4StatsFilter = {
  classesSlugs?: InputMaybe<StringsIntersection>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  isUniqueItemStat?: InputMaybe<OptionalFlag>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4StatsResult = {
  __typename?: 'Diablo4StatsResult';
  data?: Maybe<Array<Diablo4Stat>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4StoneRaritiesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4StoneRaritiesResult = {
  __typename?: 'Diablo4StoneRaritiesResult';
  data?: Maybe<Array<Diablo4StoneRarity>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4StoneRarity = {
  __typename?: 'Diablo4StoneRarity';
  color?: Maybe<Scalars['String']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4StoneStat = {
  __typename?: 'Diablo4StoneStat';
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Diablo4StrengthsWeaknesses = {
  __typename?: 'Diablo4StrengthsWeaknesses';
  strengths?: Maybe<Array<Scalars['String']>>;
  weaknesses?: Maybe<Array<Scalars['String']>>;
};

export type Diablo4StrengthsWeaknessesInput = {
  strengths?: InputMaybe<Array<Scalars['String']>>;
  weaknesses?: InputMaybe<Array<Scalars['String']>>;
};

export type Diablo4Substitution = {
  __typename?: 'Diablo4Substitution';
  key?: Maybe<Scalars['String']>;
  values?: Maybe<Array<Scalars['String']>>;
};

export type Diablo4TemperingStatInput = {
  id: Scalars['ID'];
  masterworkLevel?: InputMaybe<Scalars['Int']>;
};

export type Diablo4Tier = {
  __typename?: 'Diablo4Tier';
  color?: Maybe<Scalars['String']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4TierListPage = {
  __typename?: 'Diablo4TierListPage';
  description?: Maybe<Scalars['String']>;
  header?: Maybe<Scalars['String']>;
  metadata?: Maybe<Diablo4SeoMetaData>;
};

export type Diablo4TierListPageOrError = Diablo4GenericError | Diablo4TierListPage;

export type Diablo4TiersFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4TiersResult = {
  __typename?: 'Diablo4TiersResult';
  data?: Maybe<Array<Diablo4Tier>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export enum Diablo4Timeframe {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export type Diablo4Timers = {
  __typename?: 'Diablo4Timers';
  /** Helltide event timers */
  helltide?: Maybe<Diablo4HelltideTimer>;
  /** Legion event timers */
  legion?: Maybe<Diablo4LegionTimer>;
  /** World boss spawn timers */
  worldBoss?: Maybe<Diablo4WorldBossTimer>;
};

/** Banner which is displayed on the top of the web-page throughout the site. */
export type Diablo4TopBanner = {
  __typename?: 'Diablo4TopBanner';
  background?: Maybe<Scalars['String']>;
  disallowedUserAttributes?: Maybe<Array<Diablo4UserAttribute>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  link?: Maybe<Diablo4TopBannerLink>;
  locale?: Maybe<Scalars['Diablo4Locale']>;
  mixpanelName?: Maybe<Scalars['String']>;
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  platforms?: Maybe<Array<Diablo4Platform>>;
  text?: Maybe<Scalars['String']>;
  userLevels?: Maybe<Array<Diablo4UserLevel>>;
  withCloseButton?: Maybe<Scalars['Boolean']>;
};

export type Diablo4TopBannerFilter = {
  disallowedUserAttribute?: InputMaybe<Diablo4UserAttribute>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locale?: InputMaybe<Scalars['Diablo4Locale']>;
  platform?: InputMaybe<Diablo4Platform>;
  userLevel?: InputMaybe<Diablo4UserLevel>;
};

export type Diablo4TopBannerLink = {
  __typename?: 'Diablo4TopBannerLink';
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['Diablo4Url']>;
};

export type Diablo4TopBannerOrError = Diablo4GenericError | Diablo4TopBanner;

export type Diablo4TopBannerOrNotFoundError = Diablo4GenericError | Diablo4NotFoundError | Diablo4TopBanner;

export type Diablo4TuningStone = {
  __typename?: 'Diablo4TuningStone';
  compatibleTypes?: Maybe<Array<Scalars['String']>>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  location?: Maybe<Scalars['String']>;
  maxLevel?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  rarity?: Maybe<Diablo4StoneRarity>;
  raritySlug?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type Diablo4TuningStonesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4TuningStonesInput = {
  position?: InputMaybe<Scalars['Int']>;
  tuningStoneId: Scalars['ID'];
  tuningStoneLevel?: InputMaybe<Scalars['Int']>;
};

export type Diablo4TuningStonesResult = {
  __typename?: 'Diablo4TuningStonesResult';
  data?: Maybe<Array<Diablo4TuningStone>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4UniqueItem = {
  __typename?: 'Diablo4UniqueItem';
  class?: Maybe<Diablo4Class>;
  classSlug?: Maybe<Scalars['String']>;
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  effect?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  mainStats?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  secondaryStats?: Maybe<Array<Scalars['String']>>;
  secondaryStatsSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
  type?: Maybe<Diablo4UniqueItemType>;
  typeSlug?: Maybe<Scalars['String']>;
};

export type Diablo4UniqueItemType = {
  __typename?: 'Diablo4UniqueItemType';
  deprecated?: Maybe<Scalars['Boolean']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Diablo4UniqueItemTypesFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4UniqueItemTypesResult = {
  __typename?: 'Diablo4UniqueItemTypesResult';
  data?: Maybe<Array<Diablo4UniqueItemType>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4UniqueItemsFilter = {
  classSlug?: InputMaybe<OneOfOptionalString>;
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4UniqueItemsResult = {
  __typename?: 'Diablo4UniqueItemsResult';
  data?: Maybe<Array<Diablo4UniqueItem>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

/** Update custom build (registered users only) result */
export type Diablo4UpdateBuildPayload = {
  __typename?: 'Diablo4UpdateBuildPayload';
  /** The build itself */
  build?: Maybe<Diablo4CustomBuildV3>;
  /** Any error occurred during the update */
  error?: Maybe<Diablo4Error>;
};

export type Diablo4UpdateBuildV2Payload = {
  __typename?: 'Diablo4UpdateBuildV2Payload';
  /** The build itself */
  build?: Maybe<Diablo4CustomBuildV3>;
  /** Any error occurred during the update */
  error?: Maybe<Diablo4UpdateBuildV2PayloadError>;
};

/** Update custom build v2 (registered users only) result */
export type Diablo4UpdateBuildV2PayloadError = Diablo4GenericError;

/** Update custom build (registered users only) request */
export type Diablo4UpdateVariantBuildInput = {
  /** UUID of the build */
  id: Scalars['ID'];
  /** New build full data */
  newBuild: Diablo4CreateVariantBuildInput;
};

/** User's Diablo 4 information */
export type Diablo4User = {
  __typename?: 'Diablo4User';
  /** User's Diablo 4 id */
  id: Scalars['ID'];
};

export enum Diablo4UserAttribute {
  LESSON_PLAN = 'LESSON_PLAN',
}

/** Represent user attributes. Typically used to display banners to specific user levels. */
export enum Diablo4UserLevel {
  CARRY = 'CARRY',
  CHURN = 'CHURN',
  COLLECTOR_EDITION = 'COLLECTOR_EDITION',
  GUEST = 'GUEST',
  REGULAR = 'REGULAR',
  SUPPORTER = 'SUPPORTER',
  TRIAL = 'TRIAL',
}

export type Diablo4VampirePower = {
  __typename?: 'Diablo4VampirePower';
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  pacts?: Maybe<Diablo4Pact>;
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
};

export type Diablo4VampirePowersFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4VampirePowersResult = {
  __typename?: 'Diablo4VampirePowersResult';
  data?: Maybe<Array<Diablo4VampirePower>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4Variant = {
  __typename?: 'Diablo4Variant';
  /** Description of the Aspects&Uniques section */
  aspectsAndUniquesDescription?: Maybe<Scalars['String']>;
  /** Skills picked up for skill panel */
  assignedSkills?: Maybe<Array<Diablo4AssignedSkill>>;
  /** Description of the assigned skills */
  assignedSkillsDescription?: Maybe<Scalars['String']>;
  /** Vampire powers picked up for vampire power panel */
  assignedVampirePowers?: Maybe<Array<Diablo4AssignedVampirePower>>;
  /** Druid boons */
  boons?: Maybe<Array<Diablo4DruidBoon>>;
  /** Variant description */
  description?: Maybe<Scalars['String']>;
  /** Sorceress enchantments */
  enchantments?: Maybe<Array<Diablo4Skill>>;
  /** Barbarian expertise */
  expertise?: Maybe<Array<Diablo4Skill>>;
  /** Gear details */
  gear?: Maybe<Array<Diablo4Gear>>;
  /** Description of the gear stats section */
  gearStatsDescription?: Maybe<Scalars['String']>;
  /** List of picked up games for the build */
  gems?: Maybe<Array<Diablo4BuildSlottedGem>>;
  /** List of picked up malignant hearts for the build */
  hearts?: Maybe<Array<Diablo4BuildSlottedHeart>>;
  /** Mercenary data */
  mercenary?: Maybe<Diablo4MercenaryBuildData>;
  /** Variant name */
  name: Scalars['String'];
  /** Description of the paragon board section */
  paragonBoardDescription?: Maybe<Scalars['String']>;
  /** Paragon board details */
  paragonBoards?: Maybe<Array<Diablo4BuildParagonBoard>>;
  /**
   *  List of all paragon nodes (from all boards)
   * that were picked up by the user for the builds
   */
  paragonNodes?: Maybe<Array<Diablo4ParagonNode>>;
  /** Season  6 runewords mechanics */
  runes?: Maybe<Array<Diablo4AssignedRune>>;
  /** Description of the seneschal construct section */
  senechalConstructDescription?: Maybe<Scalars['String']>;
  /** Senescial companion assigned to this build */
  seneschalCompanion?: Maybe<Array<Diablo4BuildSeneschalCompanion>>;
  /** Description of the skill tree section */
  skillTreeDescription?: Maybe<Scalars['String']>;
  /** Skills assigned to this build */
  skills?: Maybe<Array<Diablo4LeveledSkill>>;
  /** Rogue specialization */
  specialization?: Maybe<Array<Diablo4Skill>>;
  /** Spiritborn mechanics */
  spiritGuardians?: Maybe<Diablo4AssignedSpiritGuardians>;
  /** Necromancer summons */
  summons?: Maybe<Array<Diablo4NecromancerSummon>>;
  /** Witchcraft powers for season 7 */
  witchcraft?: Maybe<Diablo4AssignedWitchcraftPowers>;
};

export type Diablo4VariantBuildCursorPayloadError = Diablo4GenericError;

export type Diablo4VariantBuildOffsetPayloadError = Diablo4GenericError;

export type Diablo4VariantBuildsCursorPayload = {
  __typename?: 'Diablo4VariantBuildsCursorPayload';
  builds?: Maybe<Array<Diablo4CustomBuildV3>>;
  error?: Maybe<Diablo4VariantBuildCursorPayloadError>;
  pageInfo?: Maybe<Diablo4CursorPaginationInfo>;
};

export type Diablo4VariantBuildsOffsetPayload = {
  __typename?: 'Diablo4VariantBuildsOffsetPayload';
  builds?: Maybe<Array<Diablo4CustomBuildV3>>;
  error?: Maybe<Diablo4VariantBuildOffsetPayloadError>;
  pageInfo?: Maybe<Diablo4PaginationInfo>;
};

/**
 * Variant represents configuration of the build for specific use case
 * For example, variant can represent leveling, farming, boss killing, etc.
 */
export type Diablo4VariantInput = {
  /** Affixes that were picked up for the build */
  affixStats?: InputMaybe<Array<Diablo4AffixStatInput>>;
  /** Description of the Aspects&Uniques section */
  aspectsAndUniquesDescription?: InputMaybe<Scalars['String']>;
  /** Skills that were assigned to skill panel */
  assignedSkills?: InputMaybe<Array<Diablo4AssignedSkillInput>>;
  /** Description of the assigned skills */
  assignedSkillsDescription?: InputMaybe<Scalars['String']>;
  /** Vampire powers that were assigned to vampire power panel */
  assignedVampirePowers?: InputMaybe<Array<Diablo4AssignedVampirePowerInput>>;
  /** Druid boons */
  boons?: InputMaybe<Array<Diablo4DruidBoonInput>>;
  /** General description of the variant */
  description?: InputMaybe<Scalars['String']>;
  /** Sorceress enchantments */
  enchantments?: InputMaybe<Array<Diablo4SkillInput>>;
  /** Barbarian expertise */
  expertise?: InputMaybe<Array<Diablo4SkillInput>>;
  /** Gear that was picked up for the build */
  gear?: InputMaybe<Array<Diablo4GearInput>>;
  /** Description of the gear stats section */
  gearStatsDescription?: InputMaybe<Scalars['String']>;
  /** Gems that were picked up for the build */
  gems?: InputMaybe<Array<Diablo4GemInput>>;
  /** MalignantHearts that were picked up for the build */
  hearts?: InputMaybe<Array<Diablo4HeartInput>>;
  /** Skills selected for mercenary skill tree */
  mercenary?: InputMaybe<Diablo4MercenaryInput>;
  /** Name of the variant */
  name: Scalars['String'];
  /** Description of the paragon board section */
  paragonBoardDescription?: InputMaybe<Scalars['String']>;
  /** Paragon board details */
  paragonBoards?: InputMaybe<Array<Diablo4ParagonBoardInput>>;
  /** Paragon nodes details */
  paragonNodes?: InputMaybe<Array<Diablo4ParagonNodeInput>>;
  /** Season 6 runewords mechanics */
  runes?: InputMaybe<Array<Diablo4AssignedRuneInput>>;
  /** Description of the seneschal construct section */
  senechalConstructDescription?: InputMaybe<Scalars['String']>;
  /** Seneschal companion that were picked up for the build */
  seneschalCompanion?: InputMaybe<Array<Diablo4SeneschalCompanionInput>>;
  /** Description of the skill tree section */
  skillTreeDescription?: InputMaybe<Scalars['String']>;
  /** Skills that were picked up for the build */
  skills?: InputMaybe<Array<Diablo4LeveledSkillInput>>;
  /** Rogue specialization */
  specialization?: InputMaybe<Array<Diablo4SkillInput>>;
  /** Spiritborn mechanics */
  spiritGuardians?: InputMaybe<Diablo4SpiritGuardiansInput>;
  /** Necromancer summons */
  summons?: InputMaybe<Array<Diablo4NecromancerSummonInput>>;
  /** Assigned witchcraft powers for season 7 */
  witchcraft?: InputMaybe<Diablo4WitchcraftPowersInput>;
};

export type Diablo4Video = {
  __typename?: 'Diablo4Video';
  placeholderUrl?: Maybe<Scalars['Diablo4Url']>;
  videoUrl?: Maybe<Scalars['Diablo4Url']>;
};

export type Diablo4WitchcraftPower = {
  __typename?: 'Diablo4WitchcraftPower';
  deprecated?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  unique?: Maybe<Scalars['Boolean']>;
};

export type Diablo4WitchcraftPowersFilter = {
  deprecated?: InputMaybe<OptionalFlag>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Diablo4WitchcraftPowersInput = {
  normal?: InputMaybe<Array<Scalars['ID']>>;
  unique?: InputMaybe<Scalars['ID']>;
};

export type Diablo4WitchcraftPowersResult = {
  __typename?: 'Diablo4WitchcraftPowersResult';
  data?: Maybe<Array<Diablo4WitchcraftPower>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Diablo4WorldBossTimer = {
  __typename?: 'Diablo4WorldBossTimer';
  /** Upcoming world boss name */
  bossName?: Maybe<Scalars['String']>;
  /** Name of the next world boss */
  nextBossName?: Maybe<Scalars['String']>;
  /** Time of the next world boss spawn */
  nextStartTime?: Maybe<Scalars['Diablo4DateTime']>;
  /** Upcoming world boss spawn time */
  startTime?: Maybe<Scalars['Diablo4DateTime']>;
};

export type DisplayMetadata = {
  __typename?: 'DisplayMetadata';
  coverImageUrlPattern?: Maybe<Scalars['String']>;
  iconTagGroupSlug: Scalars['String'];
  subTitleTagGroupSlug: Scalars['String'];
};

export type ExampleGameDocumentCmWidgetV1 = NgfDocumentContent & {
  __typename?: 'ExampleGameDocumentCmWidgetV1';
  data: ExampleGameDocumentCmWidgetV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type ExampleGameDocumentCmWidgetV1Data = {
  __typename?: 'ExampleGameDocumentCmWidgetV1Data';
  border: Scalars['String'];
  content: Scalars['NgfDocumentWidgetRichTextContentJson'];
  title: Scalars['String'];
};

export type ExampleGameDocumentContent =
  | ExampleGameDocumentCmWidgetV1
  | NgfDocumentCmSectionAuto1V1
  | NgfDocumentCmSectionAuto3V1
  | NgfDocumentCmSectionAuto400V1
  | NgfDocumentCmSectionVideoAdV1
  | NgfDocumentCmWidgetCardGridV1
  | NgfDocumentCmWidgetCardGridV2
  | NgfDocumentCmWidgetContentVariantsV1
  | NgfDocumentCmWidgetCrossPageTabBarV1
  | NgfDocumentCmWidgetDiscoveryPreviewCompactV1
  | NgfDocumentCmWidgetDiscoveryPreviewCompactV2
  | NgfDocumentCmWidgetDiscoveryPreviewV1
  | NgfDocumentCmWidgetDiscoveryPreviewV2
  | NgfDocumentCmWidgetDocumentsFeedV1
  | NgfDocumentCmWidgetDocumentsFeedV2
  | NgfDocumentCmWidgetErrorV1
  | NgfDocumentCmWidgetGameDataCardGridV1
  | NgfDocumentCmWidgetGameDataCardGridV2
  | NgfDocumentCmWidgetPromoBannerHorizontalV1
  | NgfDocumentCmWidgetPromoBannerNetboardV1
  | NgfDocumentCmWidgetRichTextSimplifiedV1
  | NgfDocumentCmWidgetRichTextSimplifiedV2
  | NgfDocumentCmWidgetRichTextV1
  | NgfDocumentCmWidgetRichTextV2
  | NgfDocumentCmWidgetTableOfContentV1
  | NgfDocumentCmWidgetUGTierListV1
  | NgfDocumentCmWidgetUGTierListV2
  | NgfDocumentCmWidgetVideoV1
  | NgfDocumentCmWidgetVideoV2
  | NgfDocumentStWidgetDocumentsDiscoveryV1
  | NgfDocumentStWidgetHeaderV1
  | NgfDocumentStWidgetHeaderV2
  | NgfDocumentStWidgetHomePageHeaderV1
  | NgfDocumentStWidgetHomePageHeaderV2
  | NgfDocumentStWidgetLinksGridManualV1
  | NgfDocumentStWidgetLinksGridManualV2
  | NgfDocumentUgWidgetHeaderV1;

export type ExampleGameDocumentSeason = NgfDocumentSeason & {
  __typename?: 'ExampleGameDocumentSeason';
  slug: Scalars['String'];
};

export type ExampleGameDocumentTags = NgfDocumentTags & {
  __typename?: 'ExampleGameDocumentTags';
  data?: Maybe<Array<NgfDocumentTag>>;
};

export type ExampleGameDocumentsMutation = {
  __typename?: 'ExampleGameDocumentsMutation';
  /** Create operation creates a new document with the provided data and content. */
  structDocumentCreate: ExampleGameStructDocumentCreatePayload;
  /** SEO operation affects only document.seo field. */
  structDocumentSetSeo: ExampleGameStructDocumentSetSeoPayload;
  /** Status operation affects only document.status field. */
  structDocumentSetStatus: ExampleGameStructDocumentSetStatusPayload;
  /** Update operation affects only document.data and document.content fields. */
  structDocumentUpdate: ExampleGameStructDocumentUpdatePayload;
  /** Create operation creates a new document with the provided data and content. */
  userGeneratedDocumentCreate: ExampleGameUserGeneratedDocumentCreatePayload;
  /** Status operation affects only document.favorites field. */
  userGeneratedDocumentSetFavorite: ExampleGameUserGeneratedDocumentSetFavoritePayload;
  /** Status operation affects only document.featured field. */
  userGeneratedDocumentSetFeatured: ExampleGameUserGeneratedDocumentSetFeaturedPayload;
  /** SEO operation affects only document.seo field. */
  userGeneratedDocumentSetSeo: ExampleGameUserGeneratedDocumentSetSeoPayload;
  /** Status operation affects only document.status field. */
  userGeneratedDocumentSetStatus: ExampleGameUserGeneratedDocumentSetStatusPayload;
  /** Update operation affects only document.data and document.content fields. */
  userGeneratedDocumentUpdate: ExampleGameUserGeneratedDocumentUpdatePayload;
};

export type ExampleGameDocumentsMutationstructDocumentCreateArgs = {
  input: ExampleGameStructDocumentCreateInput;
};

export type ExampleGameDocumentsMutationstructDocumentSetSeoArgs = {
  input: NgfDocumentSetSeoInput;
};

export type ExampleGameDocumentsMutationstructDocumentSetStatusArgs = {
  input: ExampleGameStructDocumentSetStatusInput;
};

export type ExampleGameDocumentsMutationstructDocumentUpdateArgs = {
  input: ExampleGameStructDocumentUpdateInput;
};

export type ExampleGameDocumentsMutationuserGeneratedDocumentCreateArgs = {
  input: ExampleGameUserGeneratedDocumentCreateInput;
};

export type ExampleGameDocumentsMutationuserGeneratedDocumentSetFavoriteArgs = {
  input: ExampleGameUserGeneratedDocumentSetFavoriteInput;
};

export type ExampleGameDocumentsMutationuserGeneratedDocumentSetFeaturedArgs = {
  input: ExampleGameUserGeneratedDocumentSetFeaturedInput;
};

export type ExampleGameDocumentsMutationuserGeneratedDocumentSetSeoArgs = {
  input: NgfDocumentSetSeoInput;
};

export type ExampleGameDocumentsMutationuserGeneratedDocumentSetStatusArgs = {
  input: ExampleGameUserGeneratedDocumentSetStatusInput;
};

export type ExampleGameDocumentsMutationuserGeneratedDocumentUpdateArgs = {
  input: ExampleGameUserGeneratedDocumentUpdateInput;
};

export type ExampleGameDocumentsQuery = {
  __typename?: 'ExampleGameDocumentsQuery';
  previewDocumentContent: ExampleGamePreviewDocumentContentPayload;
  structDocumentBySlug: ExampleGameStructDocumentPayload;
  structDocumentWidgets: NgfStructDocumentWidgetsPayload;
  structDocuments: NgfStructDocumentsListPayload;
  userGeneratedDocumentById: ExampleGameUserGeneratedDocumentPayload;
  userGeneratedDocumentBySlug: ExampleGameUserGeneratedDocumentPayload;
  userGeneratedDocumentTagGroups: NgfUserGeneratedDocumentTagGroupsPayload;
  userGeneratedDocumentTemplate: ExampleGameTemplateDocumentPayload;
  userGeneratedDocumentTypes: NgfUserGeneratedDocumentTypesPayload;
  userGeneratedDocuments: NgfUserGeneratedDocumentsListPayload;
};

export type ExampleGameDocumentsQuerypreviewDocumentContentArgs = {
  input: ExampleGamePreviewDocumentContentInput;
};

export type ExampleGameDocumentsQuerystructDocumentBySlugArgs = {
  input: ExampleGameStructDocumentInputBySlug;
};

export type ExampleGameDocumentsQuerystructDocumentsArgs = {
  input: ExampleGameStructDocumentsInput;
};

export type ExampleGameDocumentsQueryuserGeneratedDocumentByIdArgs = {
  input: ExampleGameUserGeneratedDocumentInputById;
};

export type ExampleGameDocumentsQueryuserGeneratedDocumentBySlugArgs = {
  input: ExampleGameUserGeneratedDocumentInputBySlug;
};

export type ExampleGameDocumentsQueryuserGeneratedDocumentTagGroupsArgs = {
  input?: InputMaybe<NgfUserGeneratedDocumentTagGroupsInput>;
};

export type ExampleGameDocumentsQueryuserGeneratedDocumentTemplateArgs = {
  input: ExampleGameTemplateDocumentInput;
};

export type ExampleGameDocumentsQueryuserGeneratedDocumentsArgs = {
  input: ExampleGameUserGeneratedDocumentsListInput;
  page: ExampleGameUserGeneratedDocumentsListPage;
};

export type ExampleGameMutation = {
  __typename?: 'ExampleGameMutation';
  documents?: Maybe<ExampleGameDocumentsMutation>;
  id: Scalars['ID'];
};

export enum ExampleGamePreviewDocumentContentError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type ExampleGamePreviewDocumentContentInput = {
  content: Scalars['ExampleGameDocumentContentInputBase64Json'];
};

export type ExampleGamePreviewDocumentContentPayload = {
  __typename?: 'ExampleGamePreviewDocumentContentPayload';
  data?: Maybe<ExampleGameDocumentContent>;
  error?: Maybe<ExampleGamePreviewDocumentContentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type ExampleGameQuery = {
  __typename?: 'ExampleGameQuery';
  documents?: Maybe<ExampleGameDocumentsQuery>;
  id: Scalars['ID'];
};

export type ExampleGameStructDocument = NgfStructDocument & {
  __typename?: 'ExampleGameStructDocument';
  content: Array<ExampleGameDocumentContent>;
  createdAt: Scalars['NgfDateTime'];
  data: ExampleGameStructDocumentData;
  id: Scalars['ID'];
  permissions?: Maybe<NgfDocumentPermissions>;
  seo?: Maybe<NgfDocumentSeo>;
  slug: Scalars['String'];
  status: NgfStructDocumentStatus;
  updatedAt: Scalars['NgfDateTime'];
  version: Scalars['Int'];
};

export enum ExampleGameStructDocumentCreateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
}

export type ExampleGameStructDocumentCreateInput = {
  document: Scalars['ExampleGameStructDocumentInputBase64Json'];
};

export type ExampleGameStructDocumentCreatePayload = {
  __typename?: 'ExampleGameStructDocumentCreatePayload';
  data?: Maybe<ExampleGameStructDocument>;
  error?: Maybe<ExampleGameStructDocumentCreateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type ExampleGameStructDocumentData = NgfStructDocumentData & {
  __typename?: 'ExampleGameStructDocumentData';
  childrenIds: Array<Scalars['String']>;
  name: Scalars['String'];
  season: ExampleGameDocumentSeason;
  tags?: Maybe<ExampleGameDocumentTags>;
};

export enum ExampleGameStructDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type ExampleGameStructDocumentInputBySlug = {
  /** Slug is unique human readable identifier of a struct document. */
  slug: Scalars['String'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type ExampleGameStructDocumentPayload = {
  __typename?: 'ExampleGameStructDocumentPayload';
  data?: Maybe<ExampleGameStructDocument>;
  error?: Maybe<ExampleGameStructDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum ExampleGameStructDocumentSetSeoError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type ExampleGameStructDocumentSetSeoPayload = {
  __typename?: 'ExampleGameStructDocumentSetSeoPayload';
  data?: Maybe<ExampleGameStructDocument>;
  error?: Maybe<ExampleGameStructDocumentSetSeoError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum ExampleGameStructDocumentSetStatusError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type ExampleGameStructDocumentSetStatusInput = {
  id: Scalars['ID'];
  status: NgfStructDocumentStatus;
};

export type ExampleGameStructDocumentSetStatusPayload = {
  __typename?: 'ExampleGameStructDocumentSetStatusPayload';
  data?: Maybe<ExampleGameStructDocument>;
  error?: Maybe<ExampleGameStructDocumentSetStatusError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum ExampleGameStructDocumentUpdateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type ExampleGameStructDocumentUpdateInput = {
  document: Scalars['ExampleGameStructDocumentInputBase64Json'];
  id: Scalars['ID'];
};

export type ExampleGameStructDocumentUpdatePayload = {
  __typename?: 'ExampleGameStructDocumentUpdatePayload';
  data?: Maybe<ExampleGameStructDocument>;
  error?: Maybe<ExampleGameStructDocumentUpdateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type ExampleGameStructDocumentsInput = {
  statuses: Array<NgfStructDocumentStatus>;
};

export type ExampleGameTemplateDocument = NgfTemplateDocument & {
  __typename?: 'ExampleGameTemplateDocument';
  content: Array<ExampleGameDocumentContent>;
  data: ExampleGameTemplateDocumentData;
  type: Scalars['String'];
  typeData: NgfDocumentType;
};

export type ExampleGameTemplateDocumentData = NgfTemplateDocumentData & {
  __typename?: 'ExampleGameTemplateDocumentData';
  childrenIds: Array<Scalars['String']>;
  name: Scalars['String'];
  season: ExampleGameDocumentSeason;
};

export enum ExampleGameTemplateDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
}

export type ExampleGameTemplateDocumentInput = {
  type: Scalars['String'];
};

export type ExampleGameTemplateDocumentPayload = {
  __typename?: 'ExampleGameTemplateDocumentPayload';
  data?: Maybe<ExampleGameTemplateDocument>;
  error?: Maybe<ExampleGameTemplateDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type ExampleGameUserGeneratedDocument = NgfUserGeneratedDocument & {
  __typename?: 'ExampleGameUserGeneratedDocument';
  author: NgfDocumentAuthor;
  content: Array<ExampleGameDocumentContent>;
  createdAt: Scalars['NgfDateTime'];
  data: ExampleGameUserGeneratedDocumentData;
  favorites?: Maybe<NgfDocumentFavorites>;
  featured?: Maybe<NgfDocumentFeatured>;
  firstPublishedAt?: Maybe<Scalars['NgfDateTime']>;
  id: Scalars['ID'];
  permissions?: Maybe<NgfDocumentPermissions>;
  seo?: Maybe<NgfDocumentSeo>;
  status: NgfUserGeneratedDocumentStatus;
  tags?: Maybe<ExampleGameDocumentTags>;
  type: Scalars['String'];
  typeData: NgfDocumentType;
  updatedAt: Scalars['NgfDateTime'];
  version: Scalars['Int'];
};

export enum ExampleGameUserGeneratedDocumentCreateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type ExampleGameUserGeneratedDocumentCreateInput = {
  document: Scalars['ExampleGameUserGeneratedDocumentInputBase64Json'];
};

export type ExampleGameUserGeneratedDocumentCreatePayload = {
  __typename?: 'ExampleGameUserGeneratedDocumentCreatePayload';
  data?: Maybe<ExampleGameUserGeneratedDocument>;
  error?: Maybe<ExampleGameUserGeneratedDocumentCreateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type ExampleGameUserGeneratedDocumentData = NgfUserGeneratedDocumentData & {
  __typename?: 'ExampleGameUserGeneratedDocumentData';
  backgroundImage?: Maybe<Scalars['String']>;
  childrenIds: Array<Scalars['String']>;
  gameSpecificProp1?: Maybe<Scalars['String']>;
  gameSpecificProp2?: Maybe<Scalars['Int']>;
  gameSpecificProp3?: Maybe<Scalars['Boolean']>;
  gameSpecificProp4?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  season: ExampleGameDocumentSeason;
};

export enum ExampleGameUserGeneratedDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type ExampleGameUserGeneratedDocumentInputById = {
  /** Unique identifier of a document. Either id or slug is required to get content document. */
  id: Scalars['ID'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type ExampleGameUserGeneratedDocumentInputBySlug = {
  /** Unique human readable identifier of a document. Either id or slug is required to get content document. */
  slug: Scalars['String'];
  /** Document type. */
  type: Scalars['String'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type ExampleGameUserGeneratedDocumentPayload = {
  __typename?: 'ExampleGameUserGeneratedDocumentPayload';
  data?: Maybe<ExampleGameUserGeneratedDocument>;
  error?: Maybe<ExampleGameUserGeneratedDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum ExampleGameUserGeneratedDocumentSetFavoriteError {
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type ExampleGameUserGeneratedDocumentSetFavoriteInput = {
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
};

export type ExampleGameUserGeneratedDocumentSetFavoritePayload = {
  __typename?: 'ExampleGameUserGeneratedDocumentSetFavoritePayload';
  data?: Maybe<ExampleGameUserGeneratedDocument>;
  error?: Maybe<ExampleGameUserGeneratedDocumentSetFavoriteError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum ExampleGameUserGeneratedDocumentSetFeaturedError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
}

export type ExampleGameUserGeneratedDocumentSetFeaturedInput = {
  documentSlug: Scalars['String'];
  id: Scalars['ID'];
  status: NgfDocumentFeaturedStatus;
};

export type ExampleGameUserGeneratedDocumentSetFeaturedPayload = {
  __typename?: 'ExampleGameUserGeneratedDocumentSetFeaturedPayload';
  data?: Maybe<ExampleGameUserGeneratedDocument>;
  error?: Maybe<ExampleGameUserGeneratedDocumentSetFeaturedError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum ExampleGameUserGeneratedDocumentSetSeoError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type ExampleGameUserGeneratedDocumentSetSeoPayload = {
  __typename?: 'ExampleGameUserGeneratedDocumentSetSeoPayload';
  data?: Maybe<ExampleGameUserGeneratedDocument>;
  error?: Maybe<ExampleGameUserGeneratedDocumentSetSeoError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum ExampleGameUserGeneratedDocumentSetStatusError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type ExampleGameUserGeneratedDocumentSetStatusInput = {
  id: Scalars['ID'];
  status: NgfUserGeneratedDocumentStatus;
};

export type ExampleGameUserGeneratedDocumentSetStatusPayload = {
  __typename?: 'ExampleGameUserGeneratedDocumentSetStatusPayload';
  data?: Maybe<ExampleGameUserGeneratedDocument>;
  error?: Maybe<ExampleGameUserGeneratedDocumentSetStatusError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum ExampleGameUserGeneratedDocumentUpdateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type ExampleGameUserGeneratedDocumentUpdateInput = {
  document: Scalars['ExampleGameUserGeneratedDocumentInputBase64Json'];
  id: Scalars['ID'];
};

export type ExampleGameUserGeneratedDocumentUpdatePayload = {
  __typename?: 'ExampleGameUserGeneratedDocumentUpdatePayload';
  data?: Maybe<ExampleGameUserGeneratedDocument>;
  error?: Maybe<ExampleGameUserGeneratedDocumentUpdateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type ExampleGameUserGeneratedDocumentsListInput = {
  /** If specified, filters by author id. */
  authorId?: InputMaybe<Scalars['ID']>;
  /** If specified, filters by document status. Specified as list because we have a case when we need to filter by both DRAFT and PUBLISHED statuses. */
  documentStatus?: InputMaybe<Array<NgfUserGeneratedDocumentStatus>>;
  /** If specified, filters by document featured status. */
  featuredStatuses?: InputMaybe<Array<NgfDocumentFeaturedStatus>>;
  /** If specified, filters by document ids. */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** If specified, filters documents that were added to favorites by the current user. */
  isFavorite?: InputMaybe<Scalars['Boolean']>;
  /** If specified, filters by name of the document. */
  name?: InputMaybe<Scalars['String']>;
  /** If specified, filters by document the timeframe when document been published. */
  publishedTimeframe?: InputMaybe<NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe>;
  /** If specified, filters by document season or "current-season" keyword. If "current-season" is passed, the current season will be used. */
  seasonSlug?: InputMaybe<Scalars['String']>;
  /**
   * If specified, filters by document slugs - human readable version of document id.
   * Slug can be defined only for featured documents, like document ids, they must be unique on game level.
   */
  slugs?: InputMaybe<Array<Scalars['String']>>;
  /** Defines the way output list is sorted. */
  sortBy?: InputMaybe<NgfUserGeneratedDocumentsDiscoverySortingOption>;
  /** If specified, filters by document tags. */
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
  /**
   * If specified, filters by document type.
   * 'profile-default-tab' is a special const, allows to request docs without specifying particular type.
   */
  type?: InputMaybe<Scalars['String']>;
};

export type ExampleGameUserGeneratedDocumentsListPage = {
  /**
   * If specified, filters out document before the specified cursor (forward cursor direction).
   * Backward cursor pagination is not supported as we currently don't have use cases for it.
   */
  cursor?: InputMaybe<Scalars['String']>;
  /** Limits the number of items in response. */
  limit: Scalars['Int'];
};

export type LostArkAccessoryItem = {
  __typename?: 'LostArkAccessoryItem';
  basicEffects?: Maybe<Scalars['String']>;
  combatStats?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  itemLevel?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  possibleEffects?: Maybe<Scalars['String']>;
  rarity?: Maybe<LostArkItemRarity>;
  tier?: Maybe<Scalars['Int']>;
  type?: Maybe<LostArkItemType>;
};

export type LostArkAccessoryItemFilter = {
  id: Scalars['ID'];
};

export type LostArkAccessoryItemOrError = LostArkAccessoryItem | LostArkGenericError | LostArkNotFoundError;

export type LostArkAccessoryItemsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkAppSettings = {
  __typename?: 'LostArkAppSettings';
  currentPromoPupupID: Scalars['ID'];
  currentReleaseNoteID: Scalars['ID'];
  currentTopBannerID: Scalars['ID'];
  promoPopup?: Maybe<LostArkPromoPopupOrNotFoundError>;
  releaseNote?: Maybe<LostArkReleaseNoteOrNotFoundError>;
  topBanner?: Maybe<LostArkTopBannerOrNotFoundError>;
};

export type LostArkAppSettingsOrError = LostArkAppSettings | LostArkGenericError;

export type LostArkAppSettingsV2 = {
  __typename?: 'LostArkAppSettingsV2';
  currentPromoPupupIds?: Maybe<Array<Scalars['ID']>>;
  currentReleaseNoteIds?: Maybe<Array<Scalars['ID']>>;
  currentTopBannerIds?: Maybe<Array<Scalars['ID']>>;
  promoPopups?: Maybe<Array<LostArkPromoPopupOrError>>;
  releaseNotes?: Maybe<Array<LostArkReleaseNoteOrError>>;
  topBanners?: Maybe<Array<LostArkTopBannerOrError>>;
};

export type LostArkAppSettingsV2OrError = LostArkAppSettingsV2 | LostArkGenericError;

export type LostArkAuthor = {
  __typename?: 'LostArkAuthor';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  socialLinks?: Maybe<Array<LostArkAuthorSocialLinkOrError>>;
  type?: Maybe<LostArkAuthorType>;
};

export type LostArkAuthorFilter = {
  id: Scalars['ID'];
};

export type LostArkAuthorOrError = LostArkAuthor | LostArkGenericError | LostArkNotFoundError;

export type LostArkAuthorSocialLink = {
  __typename?: 'LostArkAuthorSocialLink';
  link?: Maybe<Scalars['LostArkUrl']>;
  type?: Maybe<LostArkSocialLinkType>;
};

export type LostArkAuthorSocialLinkOrError = LostArkAuthorSocialLink | LostArkGenericError;

export enum LostArkAuthorType {
  EXTERNAL_EXPERT = 'EXTERNAL_EXPERT',
  REGULAR = 'REGULAR',
}

export type LostArkAuthorsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkBossDifficulty = {
  __typename?: 'LostArkBossDifficulty';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sortingIndex?: Maybe<Scalars['Int']>;
};

export type LostArkBossGuide = {
  __typename?: 'LostArkBossGuide';
  authors?: Maybe<Array<LostArkAuthorOrError>>;
  /** @deprecated battleItems is deprecated. Use battleItems v2 instead. */
  battleItems?: Maybe<Array<LostArkBossGuideBattleItemOrError>>;
  battleItemsV2?: Maybe<Array<LostArkItemOrError>>;
  bossFullVideo?: Maybe<LostArkVideo>;
  bossIconUrl?: Maybe<Scalars['LostArkUrl']>;
  bossName?: Maybe<Scalars['String']>;
  bossOverview?: Maybe<Scalars['String']>;
  bossPhase?: Maybe<Scalars['String']>;
  cheatSheetUrl?: Maybe<Scalars['LostArkUrl']>;
  gearScore?: Maybe<Array<LostArkBossGuideGearScoreOrError>>;
  id: Scalars['ID'];
  raidTier?: Maybe<LostArkRaidTier>;
  raidType?: Maybe<LostArkRaidType>;
  /** @deprecated rewards is deprecated. Use rewards v2 instead. */
  rewards?: Maybe<Array<LostArkBossGuideRewardOrError>>;
  rewardsV2?: Maybe<Array<LostArkBossGuideRewardV2OrError>>;
  sections?: Maybe<Array<Maybe<LostArkBossGuideSectionOrError>>>;
  sortingIndex?: Maybe<Scalars['Int']>;
  thumbnailImageUrl?: Maybe<Scalars['LostArkUrl']>;
  topBackgroundImgUrl?: Maybe<Scalars['LostArkUrl']>;
};

export type LostArkBossGuideBattleItem = {
  __typename?: 'LostArkBossGuideBattleItem';
  name?: Maybe<Scalars['String']>;
};

export type LostArkBossGuideBattleItemOrError = LostArkBossGuideBattleItem | LostArkGenericError;

export type LostArkBossGuideFilter = {
  id: Scalars['ID'];
};

export type LostArkBossGuideGearScore = {
  __typename?: 'LostArkBossGuideGearScore';
  difficulty?: Maybe<LostArkBossDifficulty>;
  gearScore?: Maybe<Scalars['Int']>;
};

export type LostArkBossGuideGearScoreOrError = LostArkBossGuideGearScore | LostArkGenericError;

export type LostArkBossGuideOrError = LostArkBossGuide | LostArkGenericError;

export type LostArkBossGuideOrNotFoundError = LostArkBossGuide | LostArkGenericError | LostArkNotFoundError;

export type LostArkBossGuidePage = {
  __typename?: 'LostArkBossGuidePage';
  metadata?: Maybe<LostArkSeoMetaData>;
};

export type LostArkBossGuidePageOrError = LostArkBossGuidePage | LostArkGenericError;

export type LostArkBossGuideReward = {
  __typename?: 'LostArkBossGuideReward';
  bossGuide?: Maybe<LostArkBossGuide>;
  componentId?: Maybe<Scalars['Int']>;
  difficulty?: Maybe<LostArkBossDifficulty>;
  /** @deprecated items is deprecated. Use items v2 instead. */
  items?: Maybe<Array<LostArkRewardItemOrError>>;
};

export type LostArkBossGuideRewardOrError = LostArkBossGuideReward | LostArkGenericError;

export type LostArkBossGuideRewardV2 = {
  __typename?: 'LostArkBossGuideRewardV2';
  bossGuide?: Maybe<LostArkBossGuide>;
  componentId?: Maybe<Scalars['Int']>;
  difficulty?: Maybe<LostArkBossDifficulty>;
  items?: Maybe<Array<LostArkItemOrError>>;
};

export type LostArkBossGuideRewardV2OrError = LostArkBossGuideRewardV2 | LostArkGenericError;

export type LostArkBossGuideSection = {
  __typename?: 'LostArkBossGuideSection';
  blocks?: Maybe<Array<LostArkBossGuideSectionBlockOrError>>;
  componentId?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
};

export type LostArkBossGuideSectionBlock = {
  __typename?: 'LostArkBossGuideSectionBlock';
  description?: Maybe<Scalars['String']>;
  keyInsight?: Maybe<Scalars['String']>;
  phaseBossHealth?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
};

export type LostArkBossGuideSectionBlockOrError = LostArkBossGuideSectionBlock | LostArkGenericError;

export type LostArkBossGuideSectionOrError = LostArkBossGuideSection | LostArkGenericError;

export type LostArkBossGuidesFilter = {
  gates?: InputMaybe<Array<Scalars['Int']>>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkBossesListPage = {
  __typename?: 'LostArkBossesListPage';
  metadata?: Maybe<LostArkSeoMetaData>;
};

export type LostArkBossesListPageOrError = LostArkBossesListPage | LostArkGenericError;

export type LostArkBuildCardDeck = {
  __typename?: 'LostArkBuildCardDeck';
  _componentID?: Maybe<Scalars['Int']>;
  build?: Maybe<LostArkCharacterBuild>;
  cardSets?: Maybe<Array<LostArkCardSetOrError>>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type LostArkBuildCardDeckOrError = LostArkBuildCardDeck | LostArkGenericError;

export type LostArkCard = {
  __typename?: 'LostArkCard';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rarity?: Maybe<LostArkCardRarity>;
};

export type LostArkCardFilter = {
  id: Scalars['ID'];
};

export type LostArkCardOrError = LostArkCard | LostArkGenericError | LostArkNotFoundError;

export type LostArkCardRarity = {
  __typename?: 'LostArkCardRarity';
  borderImageUrl?: Maybe<Scalars['LostArkUrl']>;
  color?: Maybe<Scalars['LostArkHexColor']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkCardSetBonus = {
  __typename?: 'LostArkCardSetBonus';
  text?: Maybe<Scalars['String']>;
};

export type LostArkCardSetBonusOrError = LostArkCardSetBonus | LostArkGenericError;

export type LostArkCardSetOrError = LostArkCardsSet | LostArkGenericError;

export type LostArkCardsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkCardsSet = {
  __typename?: 'LostArkCardsSet';
  bookBonusDescriptions?: Maybe<Array<LostArkCardSetBonusOrError>>;
  cards?: Maybe<Array<LostArkCardOrError>>;
  equipBonusDescriptions?: Maybe<Array<LostArkCardSetBonusOrError>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkCharacterBasicClass = {
  __typename?: 'LostArkCharacterBasicClass';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkCharacterBuild = {
  __typename?: 'LostArkCharacterBuild';
  alternativeEngravings?: Maybe<Array<LostArkCharacterBuildEngravingOrError>>;
  alternativeEngravingsText?: Maybe<Scalars['String']>;
  authors?: Maybe<Array<LostArkAuthorOrError>>;
  buildOverviewText?: Maybe<Scalars['String']>;
  cardSets?: Maybe<Array<LostArkBuildCardDeckOrError>>;
  combatStats?: Maybe<Array<LostArkCharacterBuildCombatStatOrError>>;
  combatStatsText?: Maybe<Scalars['String']>;
  /** Items are sorted by sortingIndex of the LostArkCharacterBuildEngraving item. */
  engravings?: Maybe<Array<LostArkCharacterBuildEngravingOrError>>;
  engravingsText?: Maybe<Scalars['String']>;
  gearTripods?: Maybe<Array<LostArkCharacterBuildGearTripodOrError>>;
  gearTripodsText?: Maybe<Scalars['String']>;
  gems?: Maybe<Array<LostArkCharacterBuildGemOrError>>;
  gemsV2?: Maybe<Array<LostArkCharacterBuildGemV2OrError>>;
  howtoPlayText?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  requiredSkillPoints?: Maybe<Scalars['Int']>;
  skillPriorityText?: Maybe<Scalars['String']>;
  skills?: Maybe<Array<LostArkCharacterBuildSkillOrError>>;
  type?: Maybe<LostArkCharacterBuildType>;
  updatedAt?: Maybe<Scalars['LostArkDateTime']>;
};

export type LostArkCharacterBuildCombatStat = {
  __typename?: 'LostArkCharacterBuildCombatStat';
  stat?: Maybe<LostArkCharacterCombatStat>;
  value?: Maybe<Scalars['Int']>;
};

export type LostArkCharacterBuildCombatStatOrError = LostArkCharacterBuildCombatStat | LostArkGenericError;

export type LostArkCharacterBuildEngraving = {
  __typename?: 'LostArkCharacterBuildEngraving';
  _componentId?: Maybe<Scalars['Int']>;
  build?: Maybe<LostArkCharacterBuild>;
  engraving?: Maybe<LostArkEngravingOrError>;
  level?: Maybe<Scalars['Int']>;
  sortingIndex?: Maybe<Scalars['Int']>;
};

export type LostArkCharacterBuildEngravingOrError = LostArkCharacterBuildEngraving | LostArkGenericError;

export type LostArkCharacterBuildFilter = {
  buildId?: InputMaybe<Scalars['ID']>;
  classId?: InputMaybe<Scalars['ID']>;
};

export type LostArkCharacterBuildGearTripod = {
  __typename?: 'LostArkCharacterBuildGearTripod';
  _componentId?: Maybe<Scalars['Int']>;
  build?: Maybe<LostArkCharacterBuild>;
  skill?: Maybe<LostArkCharacterSkillOrError>;
  tripods?: Maybe<Array<LostArkCharacterSkillTripodOrError>>;
};

export type LostArkCharacterBuildGearTripodOrError = LostArkCharacterBuildGearTripod | LostArkGenericError;

export type LostArkCharacterBuildGem = {
  __typename?: 'LostArkCharacterBuildGem';
  build?: Maybe<LostArkCharacterBuild>;
  description?: Maybe<Scalars['String']>;
  /** Items are sorted by sortingIndex of the LostArkCharacterSkill item. */
  skills?: Maybe<Array<LostArkCharacterSkillOrError>>;
  type?: Maybe<LostArkCharacterBuildGemType>;
};

export type LostArkCharacterBuildGemOrError = LostArkCharacterBuildGem | LostArkGenericError;

export enum LostArkCharacterBuildGemType {
  ATTACK = 'ATTACK',
  COOLDOWN = 'COOLDOWN',
}

export type LostArkCharacterBuildGemV2 = {
  __typename?: 'LostArkCharacterBuildGemV2';
  build?: Maybe<LostArkCharacterBuild>;
  description?: Maybe<Scalars['String']>;
  items?: Maybe<Array<LostArkCharacterGemItem>>;
  type?: Maybe<LostArkCharacterBuildGemType>;
};

export type LostArkCharacterBuildGemV2OrError = LostArkCharacterBuildGemV2 | LostArkGenericError;

export type LostArkCharacterBuildOrError = LostArkCharacterBuild | LostArkGenericError;

export type LostArkCharacterBuildPage = {
  __typename?: 'LostArkCharacterBuildPage';
  metadata?: Maybe<LostArkSeoMetaData>;
};

export type LostArkCharacterBuildPageOrError = LostArkCharacterBuildPage | LostArkGenericError;

export type LostArkCharacterBuildSkill = {
  __typename?: 'LostArkCharacterBuildSkill';
  _componentId?: Maybe<Scalars['Int']>;
  build?: Maybe<LostArkCharacterBuild>;
  priority?: Maybe<Scalars['Int']>;
  rune?: Maybe<LostArkRuneOrError>;
  skill?: Maybe<LostArkCharacterSkillOrError>;
  skillLevel?: Maybe<Scalars['Int']>;
  skillTripods?: Maybe<Array<LostArkCharacterSkillTripodOrError>>;
  whyText?: Maybe<Scalars['String']>;
};

export type LostArkCharacterBuildSkillOrError = LostArkCharacterBuildSkill | LostArkGenericError;

export type LostArkCharacterBuildType = {
  __typename?: 'LostArkCharacterBuildType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  tabSortingIndex?: Maybe<Scalars['Int']>;
};

export type LostArkCharacterClass = {
  __typename?: 'LostArkCharacterClass';
  basicClass?: Maybe<LostArkCharacterBasicClass>;
  builds?: Maybe<Array<LostArkCharacterBuildOrError>>;
  classMechanicsText?: Maybe<Scalars['String']>;
  classOverviewVideo?: Maybe<LostArkVideo>;
  classPlaystyleText?: Maybe<Scalars['String']>;
  /** Items are sorted by sortingIndex of the LostArkCharacterSkill item. */
  coreSkills?: Maybe<Array<LostArkCharacterSkillOrError>>;
  description?: Maybe<Scalars['String']>;
  difficulty?: Maybe<LostArkCharacterDifficulty>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  overviewText?: Maybe<Scalars['String']>;
  pveText?: Maybe<Scalars['String']>;
  pveTier?: Maybe<LostArkCharacterTier>;
  pvpText?: Maybe<Scalars['String']>;
  pvpTier?: Maybe<LostArkCharacterTier>;
  strengths?: Maybe<Array<CharacterStrengthAndWeaknessOrError>>;
  thumbnailImageUrl?: Maybe<Scalars['LostArkUrl']>;
  topBackgroundImgUrl?: Maybe<Scalars['LostArkUrl']>;
  weaknesses?: Maybe<Array<CharacterStrengthAndWeaknessOrError>>;
};

export type LostArkCharacterClassFilter = {
  id: Scalars['ID'];
};

export type LostArkCharacterClassOrGenericError = LostArkCharacterClass | LostArkGenericError;

export type LostArkCharacterClassOrNotFoundError = LostArkCharacterClass | LostArkGenericError | LostArkNotFoundError;

export type LostArkCharacterClassSpecificGem = {
  __typename?: 'LostArkCharacterClassSpecificGem';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkCharacterClassesFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkCharacterCombatStat = {
  __typename?: 'LostArkCharacterCombatStat';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkCharacterDifficulty = {
  __typename?: 'LostArkCharacterDifficulty';
  color?: Maybe<Scalars['LostArkHexColor']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkCharacterGemItem = LostArkCharacterClassSpecificGem | LostArkCharacterSkill | LostArkGenericError;

export type LostArkCharacterListPage = {
  __typename?: 'LostArkCharacterListPage';
  metadata?: Maybe<LostArkSeoMetaData>;
};

export type LostArkCharacterListPageOrError = LostArkCharacterListPage | LostArkGenericError;

export type LostArkCharacterPage = {
  __typename?: 'LostArkCharacterPage';
  metadata?: Maybe<LostArkSeoMetaData>;
};

export type LostArkCharacterPageOrError = LostArkCharacterPage | LostArkGenericError;

export type LostArkCharacterSkill = {
  __typename?: 'LostArkCharacterSkill';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sortingIndex?: Maybe<Scalars['Int']>;
  tripods?: Maybe<Array<LostArkCharacterSkillTripodOrError>>;
  type?: Maybe<LostArkCharacterSkillType>;
  video?: Maybe<LostArkVideo>;
};

export type LostArkCharacterSkillFilter = {
  id: Scalars['ID'];
};

export type LostArkCharacterSkillOrError = LostArkCharacterSkill | LostArkGenericError | LostArkNotFoundError;

export type LostArkCharacterSkillTripod = {
  __typename?: 'LostArkCharacterSkillTripod';
  descriptions?: Maybe<Array<LostArkCharacterSkillTripodDescriptionOrError>>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sortingIndex?: Maybe<Scalars['Int']>;
};

export type LostArkCharacterSkillTripodDescription = {
  __typename?: 'LostArkCharacterSkillTripodDescription';
  level?: Maybe<Scalars['Int']>;
  text?: Maybe<Scalars['String']>;
};

export type LostArkCharacterSkillTripodDescriptionOrError =
  | LostArkCharacterSkillTripodDescription
  | LostArkGenericError;

export type LostArkCharacterSkillTripodOrError =
  | LostArkCharacterSkillTripod
  | LostArkGenericError
  | LostArkNotFoundError;

export type LostArkCharacterSkillType = {
  __typename?: 'LostArkCharacterSkillType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkCharacterSkillsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkCharacterTier = {
  __typename?: 'LostArkCharacterTier';
  color?: Maybe<Scalars['LostArkHexColor']>;
  goldenIconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  whiteIconUrl?: Maybe<Scalars['LostArkUrl']>;
};

export type LostArkCombatSupplyItem = {
  __typename?: 'LostArkCombatSupplyItem';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rarity?: Maybe<LostArkItemRarity>;
  type?: Maybe<LostArkItemType>;
};

export type LostArkCombatSupplyItemFilter = {
  id: Scalars['ID'];
};

export type LostArkCombatSupplyItemOrError = LostArkCombatSupplyItem | LostArkGenericError | LostArkNotFoundError;

export type LostArkCombatSupplyItemsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkEngraving = {
  __typename?: 'LostArkEngraving';
  descriptions?: Maybe<Array<LostArkEngravingDescriptionOrError>>;
  exclusiveToClass?: Maybe<LostArkCharacterClass>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rarity?: Maybe<LostArkEngravingRarity>;
};

export type LostArkEngravingDescription = {
  __typename?: 'LostArkEngravingDescription';
  description?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
};

export type LostArkEngravingDescriptionOrError = LostArkEngravingDescription | LostArkGenericError;

export type LostArkEngravingFilter = {
  id: Scalars['ID'];
};

export type LostArkEngravingItem = {
  __typename?: 'LostArkEngravingItem';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rarity?: Maybe<LostArkItemRarity>;
};

export type LostArkEngravingItemFilter = {
  id: Scalars['ID'];
};

export type LostArkEngravingItemOrError = LostArkEngravingItem | LostArkGenericError | LostArkNotFoundError;

export type LostArkEngravingItemsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkEngravingOrError = LostArkEngraving | LostArkGenericError | LostArkNotFoundError;

export type LostArkEngravingRarity = {
  __typename?: 'LostArkEngravingRarity';
  color?: Maybe<Scalars['LostArkHexColor']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkEngravingsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkEnhancementItem = {
  __typename?: 'LostArkEnhancementItem';
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rarity?: Maybe<LostArkItemRarity>;
};

export type LostArkEnhancementItemFilter = {
  id: Scalars['ID'];
};

export type LostArkEnhancementItemOrError = LostArkEnhancementItem | LostArkGenericError | LostArkNotFoundError;

export type LostArkEnhancementItemsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkError = {
  message?: Maybe<Scalars['String']>;
};

export type LostArkGame = {
  __typename?: 'LostArkGame';
  author?: Maybe<LostArkAuthorOrError>;
  authors?: Maybe<Array<LostArkAuthorOrError>>;
  bossGuide?: Maybe<LostArkBossGuideOrNotFoundError>;
  bossGuides?: Maybe<Array<LostArkBossGuideOrError>>;
  builds?: Maybe<Array<LostArkCharacterBuildOrError>>;
  card?: Maybe<LostArkCardOrError>;
  cards?: Maybe<Array<LostArkCardOrError>>;
  class?: Maybe<LostArkCharacterClassOrNotFoundError>;
  classes?: Maybe<Array<LostArkCharacterClassOrGenericError>>;
  engraving?: Maybe<LostArkEngravingOrError>;
  engravings?: Maybe<Array<LostArkEngravingOrError>>;
  items?: Maybe<LostArkItems>;
  /** @deprecated Use patchV2 instead */
  patch?: Maybe<LostArkPatchOrError>;
  patchV2?: Maybe<LostArkPatchOrError>;
  raidTiers?: Maybe<Array<LostArkRaidTierOrError>>;
  raidTypes?: Maybe<Array<LostArkRaidTypeOrError>>;
  rune?: Maybe<LostArkRuneOrError>;
  runes?: Maybe<Array<LostArkRuneOrError>>;
  skill?: Maybe<LostArkCharacterSkillOrError>;
  skills?: Maybe<Array<LostArkCharacterSkillOrError>>;
  tripod?: Maybe<LostArkCharacterSkillTripodOrError>;
  tripods?: Maybe<Array<LostArkCharacterSkillTripodOrError>>;
};

export type LostArkGameauthorArgs = {
  filter: LostArkAuthorFilter;
};

export type LostArkGameauthorsArgs = {
  filter?: InputMaybe<LostArkAuthorsFilter>;
};

export type LostArkGamebossGuideArgs = {
  filter: LostArkBossGuideFilter;
};

export type LostArkGamebossGuidesArgs = {
  filter?: InputMaybe<LostArkBossGuidesFilter>;
};

export type LostArkGamebuildsArgs = {
  filter?: InputMaybe<LostArkCharacterBuildFilter>;
};

export type LostArkGamecardArgs = {
  filter: LostArkCardFilter;
};

export type LostArkGamecardsArgs = {
  filter?: InputMaybe<LostArkCardsFilter>;
};

export type LostArkGameclassArgs = {
  filter: LostArkCharacterClassFilter;
};

export type LostArkGameclassesArgs = {
  filter?: InputMaybe<LostArkCharacterClassesFilter>;
};

export type LostArkGameengravingArgs = {
  filter: LostArkEngravingFilter;
};

export type LostArkGameengravingsArgs = {
  filter?: InputMaybe<LostArkEngravingsFilter>;
};

export type LostArkGameraidTiersArgs = {
  filter?: InputMaybe<LostArkRaidTiersFilter>;
};

export type LostArkGameraidTypesArgs = {
  filter?: InputMaybe<LostArkRaidTypesFilter>;
};

export type LostArkGameruneArgs = {
  filter: LostArkRuneFilter;
};

export type LostArkGamerunesArgs = {
  filter?: InputMaybe<LostArkRunesFilter>;
};

export type LostArkGameskillArgs = {
  filter: LostArkCharacterSkillFilter;
};

export type LostArkGameskillsArgs = {
  filter?: InputMaybe<LostArkCharacterSkillsFilter>;
};

export type LostArkGametripodArgs = {
  filter: LostArkTripodFilter;
};

export type LostArkGametripodsArgs = {
  filter?: InputMaybe<LostArkTripodsFilter>;
};

export type LostArkGenericError = LostArkError & {
  __typename?: 'LostArkGenericError';
  message?: Maybe<Scalars['String']>;
};

export type LostArkHomePage = {
  __typename?: 'LostArkHomePage';
  metadata?: Maybe<LostArkSeoMetaData>;
};

export type LostArkHomePageOrError = LostArkGenericError | LostArkHomePage;

export type LostArkItemOrError =
  | LostArkAccessoryItem
  | LostArkCombatSupplyItem
  | LostArkEngravingItem
  | LostArkEnhancementItem
  | LostArkGenericError
  | LostArkRune;

export type LostArkItemRarity = {
  __typename?: 'LostArkItemRarity';
  color?: Maybe<Scalars['LostArkHexColor']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkItemSet = {
  __typename?: 'LostArkItemSet';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rarity?: Maybe<LostArkItemRarity>;
};

export type LostArkItemSetFilter = {
  id: Scalars['ID'];
};

export type LostArkItemSetOrError = LostArkGenericError | LostArkItemSet | LostArkNotFoundError;

export type LostArkItemSetsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkItemType = {
  __typename?: 'LostArkItemType';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkItems = {
  __typename?: 'LostArkItems';
  accessories?: Maybe<Array<LostArkAccessoryItemOrError>>;
  accessory?: Maybe<LostArkAccessoryItemOrError>;
  engraving?: Maybe<LostArkEngravingItemOrError>;
  engravings?: Maybe<Array<LostArkEngravingItemOrError>>;
  enhancement?: Maybe<LostArkEnhancementItemOrError>;
  enhancements?: Maybe<Array<LostArkEnhancementItemOrError>>;
  set?: Maybe<LostArkItemSetOrError>;
  sets?: Maybe<Array<LostArkItemSetOrError>>;
  supplies?: Maybe<Array<LostArkCombatSupplyItemOrError>>;
  supply?: Maybe<LostArkCombatSupplyItemOrError>;
};

export type LostArkItemsaccessoriesArgs = {
  filter?: InputMaybe<LostArkAccessoryItemsFilter>;
};

export type LostArkItemsaccessoryArgs = {
  filter?: InputMaybe<LostArkAccessoryItemFilter>;
};

export type LostArkItemsengravingArgs = {
  filter?: InputMaybe<LostArkEngravingItemFilter>;
};

export type LostArkItemsengravingsArgs = {
  filter?: InputMaybe<LostArkEngravingItemsFilter>;
};

export type LostArkItemsenhancementArgs = {
  filter?: InputMaybe<LostArkEnhancementItemFilter>;
};

export type LostArkItemsenhancementsArgs = {
  filter?: InputMaybe<LostArkEnhancementItemsFilter>;
};

export type LostArkItemssetArgs = {
  filter?: InputMaybe<LostArkItemSetFilter>;
};

export type LostArkItemssetsArgs = {
  filter?: InputMaybe<LostArkItemSetsFilter>;
};

export type LostArkItemssuppliesArgs = {
  filter?: InputMaybe<LostArkCombatSupplyItemsFilter>;
};

export type LostArkItemssupplyArgs = {
  filter?: InputMaybe<LostArkCombatSupplyItemFilter>;
};

export type LostArkLink = {
  __typename?: 'LostArkLink';
  linkText?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['LostArkUrl']>;
};

export type LostArkMarketing = {
  __typename?: 'LostArkMarketing';
  /** @deprecated Doesn't support multiple entities. Use appSettingsV2 instead */
  appSettings?: Maybe<LostArkAppSettingsOrError>;
  appSettingsV2?: Maybe<LostArkAppSettingsV2OrError>;
  promoPopups?: Maybe<Array<LostArkPromoPopupOrError>>;
  releaseNotes?: Maybe<Array<LostArkReleaseNoteOrError>>;
  topBanners?: Maybe<Array<LostArkTopBannerOrError>>;
};

export type LostArkMarketingpromoPopupsArgs = {
  filter?: InputMaybe<LostArkPromoPopupFilter>;
};

export type LostArkMarketingreleaseNotesArgs = {
  filter?: InputMaybe<LostArkReleaseNoteFilter>;
};

export type LostArkMarketingtopBannersArgs = {
  filter?: InputMaybe<LostArkTopBannerFilter>;
};

export type LostArkNotFoundError = LostArkError & {
  __typename?: 'LostArkNotFoundError';
  message?: Maybe<Scalars['String']>;
};

export type LostArkPages = {
  __typename?: 'LostArkPages';
  bossGuidePage?: Maybe<LostArkBossGuidePageOrError>;
  bossListPage?: Maybe<LostArkBossesListPageOrError>;
  characterBuildPage?: Maybe<LostArkCharacterBuildPageOrError>;
  characterListPage?: Maybe<LostArkCharacterListPageOrError>;
  characterPage?: Maybe<LostArkCharacterPageOrError>;
  homePage?: Maybe<LostArkHomePageOrError>;
  tierListPage?: Maybe<LostArkTierListPageOrError>;
};

export type LostArkPatch = {
  __typename?: 'LostArkPatch';
  name?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type LostArkPatchOrError = LostArkGenericError | LostArkNotFoundError | LostArkPatch;

/** Represent possible platforms for the game. Commonly used to separate banners by platform. */
export enum LostArkPlatform {
  DESKTOP = 'DESKTOP',
  OVERWOLF = 'OVERWOLF',
  WEB = 'WEB',
}

export type LostArkPromoPopup = {
  __typename?: 'LostArkPromoPopup';
  button?: Maybe<LostArkPromoPopupButton>;
  createdAt?: Maybe<Scalars['LostArkDateTime']>;
  disallowedUserAttributes?: Maybe<Array<LostArkUserAttribute>>;
  id: Scalars['ID'];
  label?: Maybe<Scalars['String']>;
  labelBackground?: Maybe<Scalars['String']>;
  locale?: Maybe<Scalars['LostArkLocale']>;
  mixpanelName?: Maybe<Scalars['String']>;
  modalBackground?: Maybe<Scalars['String']>;
  platforms?: Maybe<Array<LostArkPlatform>>;
  publishedAt?: Maybe<Scalars['LostArkDateTime']>;
  subtitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['LostArkDateTime']>;
  userLevels?: Maybe<Array<LostArkUserLevel>>;
  value?: Maybe<Scalars['String']>;
};

export type LostArkPromoPopupButton = {
  __typename?: 'LostArkPromoPopupButton';
  link?: Maybe<Scalars['LostArkUrl']>;
  title?: Maybe<Scalars['String']>;
};

export type LostArkPromoPopupFilter = {
  disallowedUserAttribute?: InputMaybe<LostArkUserAttribute>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locale?: InputMaybe<Scalars['LostArkLocale']>;
  platform?: InputMaybe<LostArkPlatform>;
  userLevel?: InputMaybe<LostArkUserLevel>;
};

export type LostArkPromoPopupOrError = LostArkGenericError | LostArkPromoPopup;

export type LostArkPromoPopupOrNotFoundError = LostArkGenericError | LostArkNotFoundError | LostArkPromoPopup;

export type LostArkQuery = {
  __typename?: 'LostArkQuery';
  game?: Maybe<LostArkGame>;
  marketing?: Maybe<LostArkMarketing>;
  page?: Maybe<LostArkPages>;
};

export type LostArkRaidTier = {
  __typename?: 'LostArkRaidTier';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sortingIndex?: Maybe<Scalars['Int']>;
};

export type LostArkRaidTierOrError = LostArkGenericError | LostArkNotFoundError | LostArkRaidTier;

export type LostArkRaidTiersFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

export type LostArkRaidType = {
  __typename?: 'LostArkRaidType';
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sortingIndex?: Maybe<Scalars['Int']>;
};

export type LostArkRaidTypeOrError = LostArkGenericError | LostArkNotFoundError | LostArkRaidType;

export type LostArkRaidTypesFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

/** Represent a list of changes in the game. Usually comes out with a new patch. */
export type LostArkReleaseNote = {
  __typename?: 'LostArkReleaseNote';
  button?: Maybe<LostArkReleaseNoteButton>;
  content?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['LostArkDateTime']>;
  id: Scalars['ID'];
  locale?: Maybe<Scalars['LostArkLocale']>;
  platforms?: Maybe<Array<LostArkPlatform>>;
  publishedAt?: Maybe<Scalars['LostArkDateTime']>;
  subTitle?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['LostArkDateTime']>;
  userLevels?: Maybe<Array<LostArkUserLevel>>;
};

export type LostArkReleaseNoteButton = {
  __typename?: 'LostArkReleaseNoteButton';
  link?: Maybe<Scalars['LostArkUrl']>;
  title?: Maybe<Scalars['String']>;
};

export type LostArkReleaseNoteFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locale?: InputMaybe<Scalars['LostArkLocale']>;
  platform?: InputMaybe<LostArkPlatform>;
  userLevel?: InputMaybe<LostArkUserLevel>;
};

export type LostArkReleaseNoteOrError = LostArkGenericError | LostArkReleaseNote;

export type LostArkReleaseNoteOrNotFoundError = LostArkGenericError | LostArkNotFoundError | LostArkReleaseNote;

export type LostArkRewardItem = {
  __typename?: 'LostArkRewardItem';
  name?: Maybe<Scalars['String']>;
};

export type LostArkRewardItemOrError = LostArkGenericError | LostArkRewardItem;

export type LostArkRune = {
  __typename?: 'LostArkRune';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  rarity?: Maybe<LostArkRuneRarity>;
};

export type LostArkRuneFilter = {
  id: Scalars['ID'];
};

export type LostArkRuneOrError = LostArkGenericError | LostArkNotFoundError | LostArkRune;

export type LostArkRuneRarity = {
  __typename?: 'LostArkRuneRarity';
  color?: Maybe<Scalars['LostArkHexColor']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkRunesFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

/** Metadata for correct page indexing by search engines */
export type LostArkSeoMetaData = {
  __typename?: 'LostArkSeoMetaData';
  description?: Maybe<Scalars['String']>;
  keywords?: Maybe<Scalars['String']>;
  ogImage?: Maybe<Scalars['LostArkUrl']>;
  title?: Maybe<Scalars['String']>;
};

export type LostArkSocialLinkType = {
  __typename?: 'LostArkSocialLinkType';
  iconUrl?: Maybe<Scalars['LostArkUrl']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type LostArkTierListPage = {
  __typename?: 'LostArkTierListPage';
  marketingLinks?: Maybe<Array<LostArkTierListPageMarketingLinkOrError>>;
  metadata?: Maybe<LostArkSeoMetaData>;
  methodology?: Maybe<LostArkTierListPageMethodologyOrError>;
};

export type LostArkTierListPageMarketingLink = {
  __typename?: 'LostArkTierListPageMarketingLink';
  id: Scalars['ID'];
  link?: Maybe<LostArkLink>;
  text?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  video?: Maybe<LostArkVideo>;
};

export type LostArkTierListPageMarketingLinkOrError = LostArkGenericError | LostArkTierListPageMarketingLink;

export type LostArkTierListPageMethodology = {
  __typename?: 'LostArkTierListPageMethodology';
  authors?: Maybe<Array<LostArkAuthorOrError>>;
  link?: Maybe<LostArkLink>;
  text?: Maybe<Scalars['String']>;
  video?: Maybe<LostArkVideo>;
};

export type LostArkTierListPageMethodologyOrError = LostArkGenericError | LostArkTierListPageMethodology;

export type LostArkTierListPageOrError = LostArkGenericError | LostArkTierListPage;

/** Banner which is displayed on the top of the web-page throughout the site. */
export type LostArkTopBanner = {
  __typename?: 'LostArkTopBanner';
  background?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['LostArkDateTime']>;
  disallowedUserAttributes?: Maybe<Array<LostArkUserAttribute>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  link?: Maybe<LostArkTopBannerLink>;
  locale?: Maybe<Scalars['LostArkLocale']>;
  mixpanelName?: Maybe<Scalars['String']>;
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  platforms?: Maybe<Array<LostArkPlatform>>;
  publishedAt?: Maybe<Scalars['LostArkDateTime']>;
  text?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['LostArkDateTime']>;
  userLevels?: Maybe<Array<LostArkUserLevel>>;
  withCloseButton?: Maybe<Scalars['Boolean']>;
};

export type LostArkTopBannerFilter = {
  disallowedUserAttribute?: InputMaybe<LostArkUserAttribute>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  locale?: InputMaybe<Scalars['LostArkLocale']>;
  platform?: InputMaybe<LostArkPlatform>;
  userLevel?: InputMaybe<LostArkUserLevel>;
};

export type LostArkTopBannerLink = {
  __typename?: 'LostArkTopBannerLink';
  text?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['LostArkUrl']>;
};

export type LostArkTopBannerOrError = LostArkGenericError | LostArkTopBanner;

export type LostArkTopBannerOrNotFoundError = LostArkGenericError | LostArkNotFoundError | LostArkTopBanner;

export type LostArkTripodFilter = {
  id: Scalars['ID'];
};

export type LostArkTripodsFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};

/** Represent user attributes. Typically used to display banners to specific user groups. */
export enum LostArkUserAttribute {
  LESSON_PLAN = 'LESSON_PLAN',
}

/** Represent user attributes. Typically used to display banners to specific user levels. */
export enum LostArkUserLevel {
  CARRY = 'CARRY',
  CHURN = 'CHURN',
  COLLECTOR_EDITION = 'COLLECTOR_EDITION',
  GUEST = 'GUEST',
  REGULAR = 'REGULAR',
  SUPPORTER = 'SUPPORTER',
  TRIAL = 'TRIAL',
}

export type LostArkVideo = {
  __typename?: 'LostArkVideo';
  placeholderUrl?: Maybe<Scalars['LostArkUrl']>;
  videoUrl?: Maybe<Scalars['LostArkUrl']>;
};

export type MetaInfo = {
  __typename?: 'MetaInfo';
  isFeatured: Scalars['Boolean'];
  slug: Scalars['String'];
  status: MetaStatus;
};

export enum MetaStatus {
  ARCHIVED = 'ARCHIVED',
  NORMAL = 'NORMAL',
}

export enum MetricTier {
  A = 'A',
  B = 'B',
  S = 'S',
}

export type Mutation = {
  __typename?: 'Mutation';
  deadlock: DeadlockMutation;
  destiny?: Maybe<DestinyMutation>;
  diablo4?: Maybe<Diablo4Mutation>;
  exampleGame: ExampleGameMutation;
  poe2: Poe2Mutation;
  tft?: Maybe<TftMutation>;
  theBazaar: TheBazaarMutation;
  treasury?: Maybe<TreasuryMutation>;
};

export type NgfCommonWidgetsOverride = {
  NgfDocumentCmWidgetUGTierListV1?: InputMaybe<NgfDocumentCmWidgetUGTierListOverrideV1>;
  NgfDocumentCmWidgetUGTierListV2?: InputMaybe<NgfDocumentCmWidgetUGTierListOverrideV2>;
  NgfDocumentStWidgetDocumentsDiscoveryV1?: InputMaybe<NgfDocumentWidgetDocumentsDiscoveryOverrideV1>;
  widgetID: Scalars['String'];
};

export type NgfDocumentAuthor = {
  __typename?: 'NgfDocumentAuthor';
  id: Scalars['ID'];
  links?: Maybe<Array<NgfDocumentAuthorLink>>;
  name: Scalars['String'];
};

export type NgfDocumentAuthorLink = {
  __typename?: 'NgfDocumentAuthorLink';
  network: NgfDocumentAuthorNetwork;
  url: Scalars['String'];
};

export type NgfDocumentAuthorNetwork = {
  __typename?: 'NgfDocumentAuthorNetwork';
  iconUrl?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export enum NgfDocumentCmIconStyle {
  CIRCLE = 'CIRCLE',
  RHOMBUS = 'RHOMBUS',
  SQUARE = 'SQUARE',
  SQUARE_ROUNDED = 'SQUARE_ROUNDED',
}

export type NgfDocumentCmSectionAuto1V1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmSectionAuto1V1';
  data: NgfDocumentCmSectionAuto1V1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmSectionAuto1V1Data = {
  __typename?: 'NgfDocumentCmSectionAuto1V1Data';
  childrenIds: Array<Scalars['String']>;
};

export type NgfDocumentCmSectionAuto3V1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmSectionAuto3V1';
  data: NgfDocumentCmSectionAuto3V1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmSectionAuto3V1Data = {
  __typename?: 'NgfDocumentCmSectionAuto3V1Data';
  childrenIds: Array<Scalars['String']>;
};

export type NgfDocumentCmSectionAuto400V1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmSectionAuto400V1';
  data: NgfDocumentCmSectionAuto400V1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmSectionAuto400V1Data = {
  __typename?: 'NgfDocumentCmSectionAuto400V1Data';
  childrenIds: Array<Scalars['String']>;
};

export type NgfDocumentCmSectionVideoAdV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmSectionVideoAdV1';
  data: NgfDocumentCmSectionVideoAdV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmSectionVideoAdV1Data = {
  __typename?: 'NgfDocumentCmSectionVideoAdV1Data';
  childrenIds: Array<Scalars['String']>;
};

export type NgfDocumentCmWidgetCardGridV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetCardGridV1';
  data: NgfDocumentCmWidgetCardGridV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetCardGridV1Data = {
  __typename?: 'NgfDocumentCmWidgetCardGridV1Data';
  cardLayout: NgfDocumentCmWidgetCardGridV1Layout;
  description?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  items: Array<NgfDocumentCmWidgetCardGridV1DataItem>;
  subTitle?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetCardGridV1DataItem = {
  __typename?: 'NgfDocumentCmWidgetCardGridV1DataItem';
  imageUrl?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum NgfDocumentCmWidgetCardGridV1Layout {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export type NgfDocumentCmWidgetCardGridV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetCardGridV2';
  data: NgfDocumentCmWidgetCardGridV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetCardGridV2Data = {
  __typename?: 'NgfDocumentCmWidgetCardGridV2Data';
  cardLayout: NgfDocumentCmWidgetCardGridV2Layout;
  description?: Maybe<NgfDocumentRichTextContent>;
  items: Array<NgfDocumentCmWidgetCardGridV2DataItem>;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetCardGridV2DataItem = {
  __typename?: 'NgfDocumentCmWidgetCardGridV2DataItem';
  imageUrl?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  subtitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum NgfDocumentCmWidgetCardGridV2Layout {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export type NgfDocumentCmWidgetContentVariantsV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetContentVariantsV1';
  data: NgfDocumentCmWidgetContentVariantsV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetContentVariantsV1Data = {
  __typename?: 'NgfDocumentCmWidgetContentVariantsV1Data';
  childrenVariants: Array<NgfDocumentCmWidgetContentVariantsV1DataChildVariant>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetContentVariantsV1DataChildVariant = {
  __typename?: 'NgfDocumentCmWidgetContentVariantsV1DataChildVariant';
  childrenIds: Array<Scalars['String']>;
  description?: Maybe<NgfDocumentRichTextContent>;
  id: Scalars['ID'];
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetCrossPageTabBarV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetCrossPageTabBarV1';
  data: NgfDocumentCmWidgetCrossPageTabBarV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetCrossPageTabBarV1Data = {
  __typename?: 'NgfDocumentCmWidgetCrossPageTabBarV1Data';
  items: Array<NgfDocumentCmWidgetCrossPageTabBarV1Item>;
};

export type NgfDocumentCmWidgetCrossPageTabBarV1Item = {
  __typename?: 'NgfDocumentCmWidgetCrossPageTabBarV1Item';
  path: Scalars['String'];
  tag?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetDiscoveryPreviewCompactOverrideV1 = {
  cursor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishedTimeframe?: InputMaybe<NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe>;
  sortBy?: InputMaybe<NgfUserGeneratedDocumentsDiscoverySortingOption>;
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
};

export type NgfDocumentCmWidgetDiscoveryPreviewCompactOverrideV2 = {
  cursor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishedTimeframe?: InputMaybe<NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe>;
  sortBy?: InputMaybe<NgfUserGeneratedDocumentsDiscoverySortingOption>;
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
};

/** NgfDocumentCmWidgetDiscoveryPreviewCompactV1 is useful to promote specific types of documents e.g. Popular Guides in UG documents (Builds, Guides). */
export type NgfDocumentCmWidgetDiscoveryPreviewCompactV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetDiscoveryPreviewCompactV1';
  data: NgfDocumentCmWidgetDiscoveryPreviewCompactV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetDiscoveryPreviewCompactV1Data = {
  __typename?: 'NgfDocumentCmWidgetDiscoveryPreviewCompactV1Data';
  buttonLinkUrl?: Maybe<Scalars['String']>;
  buttonTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  discovery: NgfUserGeneratedDocumentsDiscovery;
  openButtonLinkInNewTab?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  uiCapabilities?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1UICapabilities>;
  visual: NgfDocumentCmWidgetDiscoveryPreviewCompactV1Visual;
};

export enum NgfDocumentCmWidgetDiscoveryPreviewCompactV1Visual {
  COUNTER = 'COUNTER',
  IMAGE = 'IMAGE',
}

/** NgfDocumentCmWidgetDiscoveryPreviewCompactV2 is useful to promote specific types of documents e.g. Popular Guides in UG documents (Builds, Guides). */
export type NgfDocumentCmWidgetDiscoveryPreviewCompactV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetDiscoveryPreviewCompactV2';
  data: NgfDocumentCmWidgetDiscoveryPreviewCompactV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetDiscoveryPreviewCompactV2Data = {
  __typename?: 'NgfDocumentCmWidgetDiscoveryPreviewCompactV2Data';
  buttonLinkUrl?: Maybe<Scalars['String']>;
  buttonTitle?: Maybe<Scalars['String']>;
  discovery: NgfUserGeneratedDocumentsDiscovery;
  openButtonLinkInNewTab?: Maybe<Scalars['Boolean']>;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
  uiCapabilities?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1UICapabilities>;
  visual: NgfDocumentCmWidgetDiscoveryPreviewCompactV2Visual;
};

export enum NgfDocumentCmWidgetDiscoveryPreviewCompactV2Visual {
  COUNTER = 'COUNTER',
  IMAGE = 'IMAGE',
}

export type NgfDocumentCmWidgetDiscoveryPreviewOverrideV1 = {
  cursor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishedTimeframe?: InputMaybe<NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe>;
  sortBy?: InputMaybe<NgfUserGeneratedDocumentsDiscoverySortingOption>;
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
};

export type NgfDocumentCmWidgetDiscoveryPreviewOverrideV2 = {
  cursor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishedTimeframe?: InputMaybe<NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe>;
  sortBy?: InputMaybe<NgfUserGeneratedDocumentsDiscoverySortingOption>;
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
};

/** NgfDocumentCmWidgetDiscoveryPreviewV1 is useful to promote specific types of documents e.g. Featured or Community Builds on pages like Home Page or Wiki Pages.. */
export type NgfDocumentCmWidgetDiscoveryPreviewV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetDiscoveryPreviewV1';
  data: NgfDocumentCmWidgetDiscoveryPreviewV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetDiscoveryPreviewV1Data = {
  __typename?: 'NgfDocumentCmWidgetDiscoveryPreviewV1Data';
  buttonLinkUrl?: Maybe<Scalars['String']>;
  buttonTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  discovery: NgfUserGeneratedDocumentsDiscovery;
  openButtonLinkInNewTab?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
  uiCapabilities?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1UICapabilities>;
};

/** NgfDocumentCmWidgetDiscoveryPreviewV2 is useful to promote specific types of documents e.g. Featured or Community Builds on pages like Home Page or Wiki Pages.. */
export type NgfDocumentCmWidgetDiscoveryPreviewV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetDiscoveryPreviewV2';
  data: NgfDocumentCmWidgetDiscoveryPreviewV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetDiscoveryPreviewV2Data = {
  __typename?: 'NgfDocumentCmWidgetDiscoveryPreviewV2Data';
  buttonLinkUrl?: Maybe<Scalars['String']>;
  buttonTitle?: Maybe<Scalars['String']>;
  discovery: NgfUserGeneratedDocumentsDiscovery;
  openButtonLinkInNewTab?: Maybe<Scalars['Boolean']>;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
  uiCapabilities?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1UICapabilities>;
};

export type NgfDocumentCmWidgetDocumentsFeedV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetDocumentsFeedV1';
  data: NgfDocumentCmWidgetDocumentsFeedV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetDocumentsFeedV1Data = {
  __typename?: 'NgfDocumentCmWidgetDocumentsFeedV1Data';
  buttonLinkUrl?: Maybe<Scalars['String']>;
  buttonTitle?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  discovery: NgfUserGeneratedDocumentsDiscovery;
  openButtonLinkInNewTab?: Maybe<Scalars['Boolean']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetDocumentsFeedV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetDocumentsFeedV2';
  data: NgfDocumentCmWidgetDocumentsFeedV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetDocumentsFeedV2Data = {
  __typename?: 'NgfDocumentCmWidgetDocumentsFeedV2Data';
  buttonLinkUrl?: Maybe<Scalars['String']>;
  buttonTitle?: Maybe<Scalars['String']>;
  discovery: NgfUserGeneratedDocumentsDiscovery;
  openButtonLinkInNewTab?: Maybe<Scalars['Boolean']>;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetErrorV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetErrorV1';
  data: NgfDocumentCmWidgetErrorV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetErrorV1Data = {
  __typename?: 'NgfDocumentCmWidgetErrorV1Data';
  error: NgfDocumentCmWidgetErrorV1DataError;
};

export enum NgfDocumentCmWidgetErrorV1DataError {
  INTERNAL = 'INTERNAL',
}

export type NgfDocumentCmWidgetGameDataCardGridV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetGameDataCardGridV1';
  data: NgfDocumentCmWidgetGameDataCardGridV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetGameDataCardGridV1Data = {
  __typename?: 'NgfDocumentCmWidgetGameDataCardGridV1Data';
  description?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  iconStyle?: Maybe<NgfDocumentCmIconStyle>;
  items: Array<NgfDocumentCmWidgetGameDataCardGridV1DataItem>;
  layout: NgfDocumentCmWidgetGameDataCardGridV1Layout;
  subTitle?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetGameDataCardGridV1DataItem = {
  __typename?: 'NgfDocumentCmWidgetGameDataCardGridV1DataItem';
  groupSlug: Scalars['String'];
  iconStyle?: Maybe<NgfDocumentCmIconStyle>;
  imageUrl?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum NgfDocumentCmWidgetGameDataCardGridV1Layout {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export type NgfDocumentCmWidgetGameDataCardGridV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetGameDataCardGridV2';
  data: NgfDocumentCmWidgetGameDataCardGridV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetGameDataCardGridV2Data = {
  __typename?: 'NgfDocumentCmWidgetGameDataCardGridV2Data';
  description?: Maybe<NgfDocumentRichTextContent>;
  iconStyle?: Maybe<NgfDocumentCmIconStyle>;
  items: Array<NgfDocumentCmWidgetGameDataCardGridV2DataItem>;
  layout: NgfDocumentCmWidgetGameDataCardGridV2Layout;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetGameDataCardGridV2DataItem = {
  __typename?: 'NgfDocumentCmWidgetGameDataCardGridV2DataItem';
  groupSlug: Scalars['String'];
  iconStyle?: Maybe<NgfDocumentCmIconStyle>;
  imageUrl?: Maybe<Scalars['String']>;
  linkUrl?: Maybe<Scalars['String']>;
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  slug: Scalars['String'];
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export enum NgfDocumentCmWidgetGameDataCardGridV2Layout {
  HORIZONTAL = 'HORIZONTAL',
  VERTICAL = 'VERTICAL',
}

export type NgfDocumentCmWidgetPromoBannerHorizontalV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetPromoBannerHorizontalV1';
  data: NgfDocumentCmWidgetPromoBannerHorizontalV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetPromoBannerHorizontalV1Data = {
  __typename?: 'NgfDocumentCmWidgetPromoBannerHorizontalV1Data';
  backgroundCss?: Maybe<Scalars['String']>;
  backgroundImageMask?: Maybe<Scalars['String']>;
  buttonColor?: Maybe<Scalars['String']>;
  buttonTextColor?: Maybe<Scalars['String']>;
  buttonTitle: Scalars['String'];
  linkUrl: Scalars['String'];
  mixpanelSlug: Scalars['String'];
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  platforms?: Maybe<Array<NgfPlatform>>;
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userLevels?: Maybe<Array<NgfUserLevel>>;
};

export type NgfDocumentCmWidgetPromoBannerNetboardV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetPromoBannerNetboardV1';
  data: NgfDocumentCmWidgetPromoBannerNetboardV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetPromoBannerNetboardV1Data = {
  __typename?: 'NgfDocumentCmWidgetPromoBannerNetboardV1Data';
  backgroundCss?: Maybe<Scalars['String']>;
  backgroundImageMask?: Maybe<Scalars['String']>;
  buttonColor?: Maybe<Scalars['String']>;
  buttonTextColor?: Maybe<Scalars['String']>;
  buttonTitle: Scalars['String'];
  linkUrl: Scalars['String'];
  mixpanelSlug: Scalars['String'];
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  platforms?: Maybe<Array<NgfPlatform>>;
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userLevels?: Maybe<Array<NgfUserLevel>>;
};

export type NgfDocumentCmWidgetRichTextSimplifiedV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetRichTextSimplifiedV1';
  data: NgfDocumentCmWidgetRichTextSimplifiedV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetRichTextSimplifiedV1Data = {
  __typename?: 'NgfDocumentCmWidgetRichTextSimplifiedV1Data';
  content?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetRichTextSimplifiedV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetRichTextSimplifiedV2';
  data: NgfDocumentCmWidgetRichTextSimplifiedV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetRichTextSimplifiedV2Data = {
  __typename?: 'NgfDocumentCmWidgetRichTextSimplifiedV2Data';
  content?: Maybe<NgfDocumentRichTextContent>;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetRichTextV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetRichTextV1';
  data: NgfDocumentCmWidgetRichTextV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetRichTextV1Data = {
  __typename?: 'NgfDocumentCmWidgetRichTextV1Data';
  content?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetRichTextV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetRichTextV2';
  data: NgfDocumentCmWidgetRichTextV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetRichTextV2Data = {
  __typename?: 'NgfDocumentCmWidgetRichTextV2Data';
  content?: Maybe<Scalars['Map']>;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetTableOfContentV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetTableOfContentV1';
  data: NgfDocumentCmWidgetTableOfContentV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetTableOfContentV1Data = {
  __typename?: 'NgfDocumentCmWidgetTableOfContentV1Data';
  stub?: Maybe<Scalars['String']>;
};

export type NgfDocumentCmWidgetUGTierListOverrideV1 = {
  cursor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
};

export type NgfDocumentCmWidgetUGTierListOverrideV2 = {
  cursor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
};

export type NgfDocumentCmWidgetUGTierListV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetUGTierListV1';
  data: NgfDocumentCmWidgetUGTierListV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetUGTierListV1Data = {
  __typename?: 'NgfDocumentCmWidgetUGTierListV1Data';
  childrenIds: Array<Scalars['String']>;
  description?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  discovery: NgfUserGeneratedDocumentsDiscovery;
  iconStyle?: Maybe<NgfDocumentCmIconStyle>;
  searchByAuthor?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1DataSearchByAuthor>;
  searchByName?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1DataSearchByName>;
  tagFilters?: Maybe<Array<NgfDocumentStWidgetDocumentsDiscoveryV1DataTagFilter>>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetUGTierListV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetUGTierListV2';
  data: NgfDocumentCmWidgetUGTierListV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetUGTierListV2Data = {
  __typename?: 'NgfDocumentCmWidgetUGTierListV2Data';
  childrenIds: Array<Scalars['String']>;
  discovery: NgfUserGeneratedDocumentsDiscovery;
  iconStyle?: Maybe<NgfDocumentCmIconStyle>;
  searchByAuthor?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1DataSearchByAuthor>;
  searchByName?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1DataSearchByName>;
  subTitle?: Maybe<Scalars['Map']>;
  tagFilters?: Maybe<Array<NgfDocumentStWidgetDocumentsDiscoveryV1DataTagFilter>>;
  title: Scalars['String'];
};

export type NgfDocumentCmWidgetVideoV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetVideoV1';
  data: NgfDocumentCmWidgetVideoV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetVideoV1Data = {
  __typename?: 'NgfDocumentCmWidgetVideoV1Data';
  autoplay?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  title: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
};

export type NgfDocumentCmWidgetVideoV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentCmWidgetVideoV2';
  data: NgfDocumentCmWidgetVideoV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentCmWidgetVideoV2Data = {
  __typename?: 'NgfDocumentCmWidgetVideoV2Data';
  autoplay?: Maybe<Scalars['Boolean']>;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
  videoUrl?: Maybe<Scalars['String']>;
};

export type NgfDocumentContent = {
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentContentMeta = {
  __typename?: 'NgfDocumentContentMeta';
  config?: Maybe<NgfDocumentContentMetaConfig>;
};

export type NgfDocumentContentMetaConfig = {
  __typename?: 'NgfDocumentContentMetaConfig';
  editable?: Maybe<Scalars['Boolean']>;
  movable?: Maybe<Scalars['Boolean']>;
  removable?: Maybe<Scalars['Boolean']>;
};

export type NgfDocumentFavorites = {
  __typename?: 'NgfDocumentFavorites';
  favoritesCount: Scalars['Int'];
  isFavorite: Scalars['Boolean'];
};

export type NgfDocumentFeatured = {
  __typename?: 'NgfDocumentFeatured';
  slug: Scalars['String'];
  status: NgfDocumentFeaturedStatus;
};

export enum NgfDocumentFeaturedStatus {
  ARCHIVED = 'ARCHIVED',
  DEFAULT = 'DEFAULT',
  FEATURED = 'FEATURED',
}

export enum NgfDocumentPermission {
  /** Defines whenever a user can delete the document. Usually, this is only allowed for document owners and admin users. */
  DELETE = 'DELETE',
  /** Defines whenever a user can feature/unfeature the document. Usually, this is only allowed for admin users. */
  FEATURE = 'FEATURE',
  /** Defines whenever a user can publish/unpublish the document. Usually, this is only allowed for document owners and admin users. */
  PUBLISH = 'PUBLISH',
  /** Defines whenever a user can update document content e.g. widgets. Usually, this is only allowed for document owners. */
  UPDATE_CONTENT = 'UPDATE_CONTENT',
  /** Defines whenever a user can update document SEO e.g. title, description, etc. */
  UPDATE_SEO = 'UPDATE_SEO',
}

export type NgfDocumentPermissions = {
  __typename?: 'NgfDocumentPermissions';
  data?: Maybe<Array<NgfDocumentPermission>>;
};

export type NgfDocumentRichTextContent = {
  __typename?: 'NgfDocumentRichTextContent';
  charLimit?: Maybe<Scalars['Int']>;
  placeholder?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Map']>;
};

export type NgfDocumentSeason = {
  slug: Scalars['String'];
};

export enum NgfDocumentSectionLayout {
  /** AUTO - full width section layout (width: 100%). */
  AUTO = 'AUTO',
  /** AUTO_AUTO_AUTO - three columns layout, where each column has equal width. */
  AUTO_AUTO_AUTO = 'AUTO_AUTO_AUTO',
  /** AUTO_PX400 - two columns layout, where left column is stretched to whatever space remains and right column has fixed 400px width. */
  AUTO_PX400 = 'AUTO_PX400',
  /** PX400 - one column layout with fixed 400px width. */
  PX400 = 'PX400',
}

export type NgfDocumentSeo = {
  __typename?: 'NgfDocumentSeo';
  canonicalUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  noIndex?: Maybe<Scalars['Boolean']>;
  ogImage?: Maybe<Scalars['String']>;
  schemaMarkup?: Maybe<Scalars['NgfDocumentStrJsonLd']>;
  title?: Maybe<Scalars['String']>;
};

export type NgfDocumentSeoDataInput = {
  canonicalUrl?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  noIndex?: InputMaybe<Scalars['Boolean']>;
  ogImage?: InputMaybe<Scalars['String']>;
  schemaMarkup?: InputMaybe<Scalars['NgfDocumentStrJsonLd']>;
  title?: InputMaybe<Scalars['String']>;
};

export type NgfDocumentSetSeoInput = {
  id: Scalars['ID'];
  seo: NgfDocumentSeoDataInput;
};

/** NgfDocumentStWidgetDocumentsDiscoveryV1 widget displays infinity list of documents according to the discovery configuration. */
export type NgfDocumentStWidgetDocumentsDiscoveryV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentStWidgetDocumentsDiscoveryV1';
  data: NgfDocumentStWidgetDocumentsDiscoveryV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentStWidgetDocumentsDiscoveryV1Data = {
  __typename?: 'NgfDocumentStWidgetDocumentsDiscoveryV1Data';
  discovery: NgfUserGeneratedDocumentsDiscovery;
  uiCapabilities?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1UICapabilities>;
};

export type NgfDocumentStWidgetDocumentsDiscoveryV1DataPublishedTimeframe = {
  __typename?: 'NgfDocumentStWidgetDocumentsDiscoveryV1DataPublishedTimeframe';
  enabled: Scalars['Boolean'];
};

export type NgfDocumentStWidgetDocumentsDiscoveryV1DataSearchByAuthor = {
  __typename?: 'NgfDocumentStWidgetDocumentsDiscoveryV1DataSearchByAuthor';
  enabled: Scalars['Boolean'];
};

export type NgfDocumentStWidgetDocumentsDiscoveryV1DataSearchByName = {
  __typename?: 'NgfDocumentStWidgetDocumentsDiscoveryV1DataSearchByName';
  enabled: Scalars['Boolean'];
};

export type NgfDocumentStWidgetDocumentsDiscoveryV1DataSorting = {
  __typename?: 'NgfDocumentStWidgetDocumentsDiscoveryV1DataSorting';
  enabled: Scalars['Boolean'];
};

export type NgfDocumentStWidgetDocumentsDiscoveryV1DataTagFilter = {
  __typename?: 'NgfDocumentStWidgetDocumentsDiscoveryV1DataTagFilter';
  groupSlug: Scalars['String'];
  ui: NgfDocumentStWidgetDocumentsDiscoveryV1DataTagFilterUi;
};

export type NgfDocumentStWidgetDocumentsDiscoveryV1DataTagFilterUi = {
  __typename?: 'NgfDocumentStWidgetDocumentsDiscoveryV1DataTagFilterUi';
  enabled: Scalars['Boolean'];
  type: NgfDocumentStWidgetDocumentsDiscoveryV1DataTagFilterUiType;
};

export enum NgfDocumentStWidgetDocumentsDiscoveryV1DataTagFilterUiType {
  SEARCH_BOX = 'SEARCH_BOX',
  SELECT_BAR = 'SELECT_BAR',
  SELECT_BOX = 'SELECT_BOX',
}

export type NgfDocumentStWidgetDocumentsDiscoveryV1UICapabilities = {
  __typename?: 'NgfDocumentStWidgetDocumentsDiscoveryV1UICapabilities';
  publishedTimeframe?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1DataPublishedTimeframe>;
  searchByAuthor?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1DataSearchByAuthor>;
  searchByName?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1DataSearchByName>;
  sorting?: Maybe<NgfDocumentStWidgetDocumentsDiscoveryV1DataSorting>;
  tagFilters?: Maybe<Array<NgfDocumentStWidgetDocumentsDiscoveryV1DataTagFilter>>;
};

export type NgfDocumentStWidgetHeaderV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentStWidgetHeaderV1';
  data: NgfDocumentStWidgetHeaderV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentStWidgetHeaderV1Data = {
  __typename?: 'NgfDocumentStWidgetHeaderV1Data';
  backgroundCss?: Maybe<Scalars['String']>;
  backgroundImageMask?: Maybe<Scalars['String']>;
  description: Scalars['NgfDocumentWidgetRichTextContentJson'];
  title: Scalars['String'];
};

export type NgfDocumentStWidgetHeaderV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentStWidgetHeaderV2';
  data: NgfDocumentStWidgetHeaderV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentStWidgetHeaderV2Data = {
  __typename?: 'NgfDocumentStWidgetHeaderV2Data';
  backgroundCss?: Maybe<Scalars['String']>;
  backgroundImageMask?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
};

export type NgfDocumentStWidgetHomePageHeaderV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentStWidgetHomePageHeaderV1';
  data: NgfDocumentStWidgetHomePageHeaderV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentStWidgetHomePageHeaderV1Data = {
  __typename?: 'NgfDocumentStWidgetHomePageHeaderV1Data';
  backgroundCss?: Maybe<Scalars['String']>;
  backgroundImageMask?: Maybe<Scalars['String']>;
  description: Scalars['NgfDocumentWidgetRichTextContentJson'];
  title: Scalars['String'];
};

export type NgfDocumentStWidgetHomePageHeaderV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentStWidgetHomePageHeaderV2';
  data: NgfDocumentStWidgetHomePageHeaderV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentStWidgetHomePageHeaderV2Data = {
  __typename?: 'NgfDocumentStWidgetHomePageHeaderV2Data';
  backgroundCss?: Maybe<Scalars['String']>;
  backgroundImageMask?: Maybe<Scalars['String']>;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
};

export type NgfDocumentStWidgetLinksGridManualV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentStWidgetLinksGridManualV1';
  data: NgfDocumentStWidgetLinksGridManualV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentStWidgetLinksGridManualV1Data = {
  __typename?: 'NgfDocumentStWidgetLinksGridManualV1Data';
  description?: Maybe<Scalars['NgfDocumentWidgetRichTextContentJson']>;
  items: Array<NgfDocumentStWidgetLinksGridManualV1DataItem>;
  title: Scalars['String'];
};

export type NgfDocumentStWidgetLinksGridManualV1DataItem = {
  __typename?: 'NgfDocumentStWidgetLinksGridManualV1DataItem';
  backgroundCss?: Maybe<Scalars['String']>;
  backgroundImageMask?: Maybe<Scalars['String']>;
  linkUrl: Scalars['String'];
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type NgfDocumentStWidgetLinksGridManualV2 = NgfDocumentContent & {
  __typename?: 'NgfDocumentStWidgetLinksGridManualV2';
  data: NgfDocumentStWidgetLinksGridManualV2Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentStWidgetLinksGridManualV2Data = {
  __typename?: 'NgfDocumentStWidgetLinksGridManualV2Data';
  items: Array<NgfDocumentStWidgetLinksGridManualV2DataItem>;
  subTitle?: Maybe<Scalars['Map']>;
  title: Scalars['String'];
};

export type NgfDocumentStWidgetLinksGridManualV2DataItem = {
  __typename?: 'NgfDocumentStWidgetLinksGridManualV2DataItem';
  backgroundCss?: Maybe<Scalars['String']>;
  backgroundImageMask?: Maybe<Scalars['String']>;
  linkUrl: Scalars['String'];
  openLinkInNewTab?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  subTitle?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type NgfDocumentTag = {
  __typename?: 'NgfDocumentTag';
  archived?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  groupSlug: Scalars['String'];
  iconUrl?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type NgfDocumentTagGroup = {
  __typename?: 'NgfDocumentTagGroup';
  archived: Scalars['Boolean'];
  defaultSlug?: Maybe<Scalars['String']>;
  inputProhibitedForRegularUser: Scalars['Boolean'];
  multiselect: Scalars['Boolean'];
  name: Scalars['String'];
  noValue?: Maybe<NgfDocumentTagGroupNoValue>;
  required: Scalars['Boolean'];
  slug: Scalars['String'];
  useGroupName: Scalars['Boolean'];
  values?: Maybe<Array<NgfDocumentTag>>;
};

export type NgfDocumentTagGroupNoValue = {
  __typename?: 'NgfDocumentTagGroupNoValue';
  description?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type NgfDocumentTags = {
  data?: Maybe<Array<NgfDocumentTag>>;
};

export type NgfDocumentTier = {
  __typename?: 'NgfDocumentTier';
  color: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type NgfDocumentType = {
  __typename?: 'NgfDocumentType';
  availableWidgets: Array<AvailableWidget>;
  creationIsAllowed: Scalars['Boolean'];
  description: Scalars['String'];
  displayMetadata: DisplayMetadata;
  index: Scalars['Int'];
  name: Scalars['String'];
  pluralName: Scalars['String'];
  slug: Scalars['String'];
  tagGroups?: Maybe<Array<NgfDocumentTagGroup>>;
};

export type NgfDocumentUgWidgetHeaderV1 = NgfDocumentContent & {
  __typename?: 'NgfDocumentUgWidgetHeaderV1';
  data: NgfDocumentUgWidgetHeaderV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type NgfDocumentUgWidgetHeaderV1Data = {
  __typename?: 'NgfDocumentUgWidgetHeaderV1Data';
  backgroundImageMask?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type NgfDocumentWidgetDocumentsDiscoveryInputTagV1 = {
  groupSlug: Scalars['String'];
  slug?: InputMaybe<Scalars['String']>;
};

export type NgfDocumentWidgetDocumentsDiscoveryOverrideV1 = {
  cursor?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  publishedTimeframe?: InputMaybe<NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe>;
  sortBy?: InputMaybe<NgfUserGeneratedDocumentsDiscoverySortingOption>;
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
};

export enum NgfPlatform {
  DESKTOP_OW = 'DESKTOP_OW',
  WEB = 'WEB',
}

export type NgfStructDocument = {
  createdAt: Scalars['NgfDateTime'];
  data: NgfStructDocumentData;
  id: Scalars['ID'];
  permissions?: Maybe<NgfDocumentPermissions>;
  seo?: Maybe<NgfDocumentSeo>;
  slug: Scalars['String'];
  status: NgfStructDocumentStatus;
  updatedAt: Scalars['NgfDateTime'];
  version: Scalars['Int'];
};

export type NgfStructDocumentData = {
  childrenIds: Array<Scalars['String']>;
};

export enum NgfStructDocumentStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum NgfStructDocumentWidgetsError {
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type NgfStructDocumentWidgetsPayload = {
  __typename?: 'NgfStructDocumentWidgetsPayload';
  data?: Maybe<Array<WidgetMeta>>;
  error?: Maybe<NgfStructDocumentWidgetsError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum NgfStructDocumentsListError {
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type NgfStructDocumentsListPayload = {
  __typename?: 'NgfStructDocumentsListPayload';
  documents?: Maybe<Array<NgfStructDocument>>;
  error?: Maybe<NgfStructDocumentsListError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type NgfTemplateDocument = {
  data: NgfTemplateDocumentData;
  type: Scalars['String'];
  typeData: NgfDocumentType;
};

export type NgfTemplateDocumentData = {
  childrenIds: Array<Scalars['String']>;
};

export type NgfUserGeneratedDocument = {
  author: NgfDocumentAuthor;
  createdAt: Scalars['NgfDateTime'];
  data: NgfUserGeneratedDocumentData;
  favorites?: Maybe<NgfDocumentFavorites>;
  featured?: Maybe<NgfDocumentFeatured>;
  firstPublishedAt?: Maybe<Scalars['NgfDateTime']>;
  id: Scalars['ID'];
  permissions?: Maybe<NgfDocumentPermissions>;
  seo?: Maybe<NgfDocumentSeo>;
  status: NgfUserGeneratedDocumentStatus;
  tags?: Maybe<NgfDocumentTags>;
  type: Scalars['String'];
  typeData: NgfDocumentType;
  updatedAt: Scalars['NgfDateTime'];
  version: Scalars['Int'];
};

export type NgfUserGeneratedDocumentData = {
  backgroundImage?: Maybe<Scalars['String']>;
  childrenIds: Array<Scalars['String']>;
  name: Scalars['String'];
  season: NgfDocumentSeason;
};

export enum NgfUserGeneratedDocumentStatus {
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum NgfUserGeneratedDocumentTagGroupsError {
  INTERNAL = 'INTERNAL',
}

export type NgfUserGeneratedDocumentTagGroupsInput = {
  type: Scalars['String'];
};

export type NgfUserGeneratedDocumentTagGroupsPayload = {
  __typename?: 'NgfUserGeneratedDocumentTagGroupsPayload';
  data?: Maybe<Array<NgfDocumentTagGroup>>;
  error?: Maybe<NgfUserGeneratedDocumentTagGroupsError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum NgfUserGeneratedDocumentTypesError {
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type NgfUserGeneratedDocumentTypesPayload = {
  __typename?: 'NgfUserGeneratedDocumentTypesPayload';
  data?: Maybe<Array<NgfDocumentType>>;
  error?: Maybe<NgfUserGeneratedDocumentTypesError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type NgfUserGeneratedDocumentsDiscovery = {
  __typename?: 'NgfUserGeneratedDocumentsDiscovery';
  /** If specified, filters by author id. */
  authorId?: Maybe<Scalars['ID']>;
  /**
   * If specified, filters out document before the specified cursor (forward cursor direction).
   * Backward cursor pagination is not supported as we currently don't have use cases for it.
   */
  cursor?: Maybe<Scalars['String']>;
  /** If specified, filters by document status. Specified as list because we have a case when we need to filter by both DRAFT and PUBLISHED statuses. */
  documentStatus?: Maybe<Array<NgfUserGeneratedDocumentStatus>>;
  /** If specified, filters by document featured status. */
  featuredStatuses?: Maybe<Array<NgfDocumentFeaturedStatus>>;
  /** If specified, filters by document ids. */
  ids?: Maybe<Array<Scalars['ID']>>;
  /** If specified, filters documents that were added to favorites by the current user. */
  isFavorite?: Maybe<Scalars['Boolean']>;
  items: NgfUserGeneratedDocumentsListPayload;
  /** Limits the number of items in response. */
  limit: Scalars['Int'];
  /** If specified, filters by name of the document. */
  name?: Maybe<Scalars['String']>;
  /** If specified, filters by document the timeframe when document been published. */
  publishedTimeframe?: Maybe<NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe>;
  /** If specified, filters by document season or "current-season" keyword. If "current-season" is passed, the current season will be used. */
  seasonSlug?: Maybe<Scalars['String']>;
  /**
   * If specified, filters by document slugs - human readable version of document id.
   * Slug can be defined only for featured documents, like document ids, they must be unique on game level.
   */
  slugs?: Maybe<Array<Scalars['String']>>;
  /** Defines the way output list is sorted. */
  sortBy?: Maybe<NgfUserGeneratedDocumentsDiscoverySortingOption>;
  /** If specified, filters by document tags. */
  tags?: Maybe<Array<NgfUserGeneratedDocumentsDiscoveryTag>>;
  /** If specified, filters by document tier. */
  tierSlug?: Maybe<Scalars['String']>;
  /** If specified, filters by document type. */
  type?: Maybe<Scalars['String']>;
};

export enum NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe {
  DAY = 'DAY',
  MONTH = 'MONTH',
  WEEK = 'WEEK',
}

export enum NgfUserGeneratedDocumentsDiscoverySortingOption {
  NAME = 'NAME',
  NEW = 'NEW',
  TOP = 'TOP',
  TRENDING = 'TRENDING',
}

export type NgfUserGeneratedDocumentsDiscoveryTag = {
  __typename?: 'NgfUserGeneratedDocumentsDiscoveryTag';
  groupSlug: Scalars['String'];
  slug?: Maybe<Scalars['String']>;
};

export enum NgfUserGeneratedDocumentsListError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type NgfUserGeneratedDocumentsListPaginationInfo = {
  __typename?: 'NgfUserGeneratedDocumentsListPaginationInfo';
  cursor?: Maybe<Scalars['String']>;
  hasMoreItems?: Maybe<Scalars['Boolean']>;
};

export type NgfUserGeneratedDocumentsListPayload = {
  __typename?: 'NgfUserGeneratedDocumentsListPayload';
  documents?: Maybe<Array<NgfUserGeneratedDocument>>;
  error?: Maybe<NgfUserGeneratedDocumentsListError>;
  errorMessage?: Maybe<Scalars['String']>;
  pageInfo?: Maybe<NgfUserGeneratedDocumentsListPaginationInfo>;
};

export enum NgfUserLevel {
  AD_FREE = 'AD_FREE',
  CARRY = 'CARRY',
  CHURN = 'CHURN',
  COLLECTOR_EDITION = 'COLLECTOR_EDITION',
  GUEST = 'GUEST',
  REGULAR = 'REGULAR',
  SUPPORTER = 'SUPPORTER',
  TRIAL = 'TRIAL',
}

export enum NgfWidgetMetaType {
  SECTION = 'SECTION',
  UNKNOWN = 'UNKNOWN',
  WIDGET = 'WIDGET',
}

export type OneOfCustomString = {
  values?: InputMaybe<Array<Scalars['CustomString']>>;
};

export type OneOfOptionalString = {
  values?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type OneOfString = {
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type OptionalFlag = {
  value?: InputMaybe<Scalars['Boolean']>;
};

export type Page = {
  all?: InputMaybe<Scalars['Boolean']>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type Pagination = {
  __typename?: 'Pagination';
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type PaginationInfo = {
  __typename?: 'PaginationInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  page: Scalars['Int'];
  perPage: Scalars['Int'];
  totalItems: Scalars['Int'];
  totalPages: Scalars['Int'];
};

export enum PlanExtraFeature {
  EGG_SKIN = 'EGG_SKIN',
  SKIN_CODE = 'SKIN_CODE',
}

export enum PlanFrameOption {
  CURRENT_SEASON = 'CURRENT_SEASON',
}

/** Options for showing plan to the clients */
export type PlanViewOptions = {
  __typename?: 'PlanViewOptions';
  extraFeatures?: Maybe<Array<PlanExtraFeature>>;
  frame?: Maybe<PlanFrameOption>;
};

export type Poe2Amulet = {
  __typename?: 'Poe2Amulet';
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  baseItemType?: Maybe<Poe2BaseItem>;
  baseItemTypeSlug?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['Int']>;
  flavourText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isUnique?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  maxDescriptions?: Maybe<Array<Scalars['Int']>>;
  maxSubstitutions?: Maybe<Array<Scalars['Float']>>;
  minDescriptions?: Maybe<Array<Scalars['Int']>>;
  minSubstitutions?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  prefixStatSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
};

export type Poe2AmuletsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2AmuletsResult = {
  __typename?: 'Poe2AmuletsResult';
  data?: Maybe<Array<Poe2Amulet>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2Armour = {
  __typename?: 'Poe2Armour';
  armourStats?: Maybe<Array<Poe2ArmourStat>>;
  backedStatDescriptions?: Maybe<Array<Poe2BackedStatDescription>>;
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  baseItemType?: Maybe<Poe2BaseItem>;
  baseItemTypeSlug?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['Int']>;
  flavourText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isUnique?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  maxDescriptions?: Maybe<Array<Scalars['Int']>>;
  maxSubstitutions?: Maybe<Array<Scalars['Float']>>;
  minDescriptions?: Maybe<Array<Scalars['Int']>>;
  minSubstitutions?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  prefixStatSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
};

export type Poe2ArmourStat = {
  __typename?: 'Poe2ArmourStat';
  slug: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type Poe2ArmoursFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2ArmoursResult = {
  __typename?: 'Poe2ArmoursResult';
  data?: Maybe<Array<Poe2Armour>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2AtlasSubtree = {
  __typename?: 'Poe2AtlasSubtree';
  background?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  nodeSlugs?: Maybe<Array<Scalars['String']>>;
  rootNodeSlug?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  x?: Maybe<Scalars['Float']>;
  y?: Maybe<Scalars['Float']>;
};

export type Poe2AtlasSubtreesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2AtlasSubtreesResult = {
  __typename?: 'Poe2AtlasSubtreesResult';
  data?: Maybe<Array<Poe2AtlasSubtree>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2AtlasTreeRestriction = {
  __typename?: 'Poe2AtlasTreeRestriction';
  id: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Poe2AtlasTreeRestrictionsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2AtlasTreeRestrictionsResult = {
  __typename?: 'Poe2AtlasTreeRestrictionsResult';
  data?: Maybe<Array<Poe2AtlasTreeRestriction>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2AutogeneratedMainType = {
  __typename?: 'Poe2AutogeneratedMainType';
  amulets?: Maybe<Array<Maybe<Poe2Amulet>>>;
  armours?: Maybe<Array<Maybe<Poe2Armour>>>;
  atlasSubtrees?: Maybe<Array<Maybe<Poe2AtlasSubtree>>>;
  atlasTreeRestrictions?: Maybe<Array<Maybe<Poe2AtlasTreeRestriction>>>;
  baseItems?: Maybe<Array<Maybe<Poe2BaseItem>>>;
  characterStartStatess?: Maybe<Array<Maybe<Poe2CharacterStartStatess>>>;
  characters?: Maybe<Array<Maybe<Poe2Character>>>;
  description?: Maybe<Array<Maybe<Poe2Description>>>;
  dictionary?: Maybe<Array<Maybe<Poe2Dictionary>>>;
  equipmentGems?: Maybe<Array<Maybe<Poe2EquipmentGem>>>;
  equipmentRestrictions?: Maybe<Array<Maybe<Poe2EquipmentRestriction>>>;
  flasks?: Maybe<Array<Maybe<Poe2Flask>>>;
  focuses?: Maybe<Array<Maybe<Poe2Focus>>>;
  gemTags?: Maybe<Array<Maybe<Poe2GemTag>>>;
  gems?: Maybe<Array<Maybe<Poe2Gem>>>;
  itemClasses?: Maybe<Array<Maybe<Poe2ItemClass>>>;
  passiveSkillOverrides?: Maybe<Array<Maybe<Poe2PassiveSkillOverride>>>;
  passiveSkillTreeRestrictions?: Maybe<Array<Maybe<Poe2PassiveSkillTreeRestriction>>>;
  passiveSkills?: Maybe<Array<Maybe<Poe2PassiveSkill>>>;
  passiveSkillsGraph?: Maybe<Array<Maybe<Poe2PassiveSkillsGraph>>>;
  prefixes?: Maybe<Array<Maybe<Poe2Prefix>>>;
  quivers?: Maybe<Array<Maybe<Poe2Quiver>>>;
  rings?: Maybe<Array<Maybe<Poe2Ring>>>;
  shields?: Maybe<Array<Maybe<Poe2Shield>>>;
  skillTypes?: Maybe<Array<Maybe<Poe2SkillType>>>;
  suffixes?: Maybe<Array<Maybe<Poe2Suffix>>>;
  tiledIcons?: Maybe<Array<Maybe<Poe2TiledIcon>>>;
  weapons?: Maybe<Array<Maybe<Poe2Weapon>>>;
};

export type Poe2BackedMinMaxRequire = {
  __typename?: 'Poe2BackedMinMaxRequire';
  description?: Maybe<Poe2Dictionary>;
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Poe2BackedStatDescription = {
  __typename?: 'Poe2BackedStatDescription';
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Poe2BakedMinMaxStatsDescription = {
  __typename?: 'Poe2BakedMinMaxStatsDescription';
  description?: Maybe<Poe2Dictionary>;
  slug: Scalars['String'];
  value?: Maybe<Scalars['String']>;
};

export type Poe2BaseItem = {
  __typename?: 'Poe2BaseItem';
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  domain?: Maybe<Scalars['Int']>;
  dropLevel?: Maybe<Scalars['Int']>;
  grantedSkillSlugs?: Maybe<Array<Scalars['String']>>;
  height?: Maybe<Scalars['Int']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  itemClass?: Maybe<Poe2ItemClass>;
  itemClassSlug?: Maybe<Scalars['String']>;
  itemRequiredStats?: Maybe<Array<Poe2ItemRequiredStat>>;
  itemStatSlugs?: Maybe<Array<Scalars['String']>>;
  maxDescriptions?: Maybe<Array<Scalars['Int']>>;
  maxSubstitutions?: Maybe<Array<Scalars['Float']>>;
  minDescriptions?: Maybe<Array<Scalars['Int']>>;
  minSubstitutions?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  slug: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type Poe2BaseItemsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2BaseItemsResult = {
  __typename?: 'Poe2BaseItemsResult';
  data?: Maybe<Array<Poe2BaseItem>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2BaseStat = {
  __typename?: 'Poe2BaseStat';
  slug: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type Poe2Character = {
  __typename?: 'Poe2Character';
  baseStats?: Maybe<Array<Poe2BaseStat>>;
  desc?: Maybe<Scalars['String']>;
  genderSlug?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  startItemSlugs?: Maybe<Array<Scalars['String']>>;
  startSkillGemSlug?: Maybe<Scalars['String']>;
  startingNodeSlug?: Maybe<Scalars['String']>;
  traitDescription?: Maybe<Scalars['String']>;
};

export type Poe2CharacterStartStatess = {
  __typename?: 'Poe2CharacterStartStatess';
  characterSlug?: Maybe<Scalars['String']>;
  desc?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  passiveSkillSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
};

export type Poe2CharacterStartStatessFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2CharacterStartStatessResult = {
  __typename?: 'Poe2CharacterStartStatessResult';
  data?: Maybe<Array<Poe2CharacterStartStatess>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2CharactersFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2CharactersResult = {
  __typename?: 'Poe2CharactersResult';
  data?: Maybe<Array<Poe2Character>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2Description = {
  __typename?: 'Poe2Description';
  descriptions?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Poe2DescriptionFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2DescriptionResult = {
  __typename?: 'Poe2DescriptionResult';
  data?: Maybe<Array<Poe2Description>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2Dictionary = {
  __typename?: 'Poe2Dictionary';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Poe2DictionaryFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2DictionaryResult = {
  __typename?: 'Poe2DictionaryResult';
  data?: Maybe<Array<Poe2Dictionary>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2DocumentCmWidgetGemsRequirementsV1 = {
  __typename?: 'Poe2DocumentCmWidgetGemsRequirementsV1';
  dex?: Maybe<Scalars['Int']>;
  int?: Maybe<Scalars['Int']>;
  str?: Maybe<Scalars['Int']>;
};

export type Poe2DocumentCmWidgetGemsSlotV1 = {
  __typename?: 'Poe2DocumentCmWidgetGemsSlotV1';
  activeSkill: Poe2DocumentCmWidgetSkillGemsSlotActiveV1;
  grantedByWeaponSet?: Maybe<Scalars['String']>;
  subSkills?: Maybe<Array<Poe2DocumentCmWidgetSkillGemsSlotSubV1>>;
};

export type Poe2DocumentCmWidgetSkillGemsSlotActiveV1 = {
  __typename?: 'Poe2DocumentCmWidgetSkillGemsSlotActiveV1';
  gemIconURL: Scalars['String'];
  gemSlug: Scalars['String'];
  iconURL: Scalars['String'];
  name: Scalars['String'];
};

export enum Poe2DocumentCmWidgetSkillGemsSlotSubType {
  ACTIVE = 'ACTIVE',
  SUPPORT = 'SUPPORT',
}

export type Poe2DocumentCmWidgetSkillGemsSlotSubV1 = {
  __typename?: 'Poe2DocumentCmWidgetSkillGemsSlotSubV1';
  gemSlug: Scalars['String'];
  gemType: Poe2DocumentCmWidgetSkillGemsSlotSubType;
  iconURL: Scalars['String'];
};

export type Poe2DocumentCmWidgetV1 = NgfDocumentContent & {
  __typename?: 'Poe2DocumentCmWidgetV1';
  data: Poe2DocumentCmWidgetV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type Poe2DocumentCmWidgetV1Data = {
  __typename?: 'Poe2DocumentCmWidgetV1Data';
  border: Scalars['String'];
  content: Scalars['NgfDocumentWidgetRichTextContentJson'];
  title: Scalars['String'];
};

export type Poe2DocumentContent =
  | NgfDocumentCmSectionAuto1V1
  | NgfDocumentCmSectionAuto3V1
  | NgfDocumentCmSectionAuto400V1
  | NgfDocumentCmSectionVideoAdV1
  | NgfDocumentCmWidgetCardGridV1
  | NgfDocumentCmWidgetCardGridV2
  | NgfDocumentCmWidgetContentVariantsV1
  | NgfDocumentCmWidgetCrossPageTabBarV1
  | NgfDocumentCmWidgetDiscoveryPreviewCompactV1
  | NgfDocumentCmWidgetDiscoveryPreviewCompactV2
  | NgfDocumentCmWidgetDiscoveryPreviewV1
  | NgfDocumentCmWidgetDiscoveryPreviewV2
  | NgfDocumentCmWidgetDocumentsFeedV1
  | NgfDocumentCmWidgetDocumentsFeedV2
  | NgfDocumentCmWidgetErrorV1
  | NgfDocumentCmWidgetGameDataCardGridV1
  | NgfDocumentCmWidgetGameDataCardGridV2
  | NgfDocumentCmWidgetPromoBannerHorizontalV1
  | NgfDocumentCmWidgetPromoBannerNetboardV1
  | NgfDocumentCmWidgetRichTextSimplifiedV1
  | NgfDocumentCmWidgetRichTextSimplifiedV2
  | NgfDocumentCmWidgetRichTextV1
  | NgfDocumentCmWidgetRichTextV2
  | NgfDocumentCmWidgetTableOfContentV1
  | NgfDocumentCmWidgetUGTierListV1
  | NgfDocumentCmWidgetUGTierListV2
  | NgfDocumentCmWidgetVideoV1
  | NgfDocumentCmWidgetVideoV2
  | NgfDocumentStWidgetDocumentsDiscoveryV1
  | NgfDocumentStWidgetHeaderV1
  | NgfDocumentStWidgetHeaderV2
  | NgfDocumentStWidgetHomePageHeaderV1
  | NgfDocumentStWidgetHomePageHeaderV2
  | NgfDocumentStWidgetLinksGridManualV1
  | NgfDocumentStWidgetLinksGridManualV2
  | NgfDocumentUgWidgetHeaderV1
  | Poe2DocumentCmWidgetV1
  | Poe2DocumentUgWidgetAtlasTreeV1
  | Poe2DocumentUgWidgetEquipmentV1
  | Poe2DocumentUgWidgetPassiveTreeV1
  | Poe2DocumentUgWidgetSkillGemsV1;

export type Poe2DocumentSeason = NgfDocumentSeason & {
  __typename?: 'Poe2DocumentSeason';
  slug: Scalars['String'];
};

export type Poe2DocumentTags = NgfDocumentTags & {
  __typename?: 'Poe2DocumentTags';
  data?: Maybe<Array<NgfDocumentTag>>;
};

export type Poe2DocumentUgWidgetAtlasTreeDataV1 = {
  __typename?: 'Poe2DocumentUgWidgetAtlasTreeDataV1';
  description?: Maybe<NgfDocumentRichTextContent>;
  subTitle?: Maybe<NgfDocumentRichTextContent>;
  title: Scalars['String'];
};

export type Poe2DocumentUgWidgetAtlasTreeV1 = NgfDocumentContent & {
  __typename?: 'Poe2DocumentUgWidgetAtlasTreeV1';
  data: Poe2DocumentUgWidgetAtlasTreeDataV1;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type Poe2DocumentUgWidgetEquipmentAmuletV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentAmuletV1';
  commonItem?: Maybe<Poe2DocumentUgWidgetEquipmentCommonV1>;
};

export type Poe2DocumentUgWidgetEquipmentArmourV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentArmourV1';
  commonItem?: Maybe<Poe2DocumentUgWidgetEquipmentCommonV1>;
};

export type Poe2DocumentUgWidgetEquipmentCharmV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentCharmV1';
  commonItem?: Maybe<Poe2DocumentUgWidgetEquipmentCommonV1>;
};

export type Poe2DocumentUgWidgetEquipmentCommonV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentCommonV1';
  iconURL?: Maybe<Scalars['String']>;
  isUnique?: Maybe<Scalars['Boolean']>;
  itemClassSlug?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  prefixSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
  suffixSlugs?: Maybe<Array<Scalars['String']>>;
};

export type Poe2DocumentUgWidgetEquipmentDataV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentDataV1';
  description?: Maybe<NgfDocumentRichTextContent>;
  subTitle?: Maybe<NgfDocumentRichTextContent>;
  title: Scalars['String'];
};

export type Poe2DocumentUgWidgetEquipmentFlaskV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentFlaskV1';
  commonItem?: Maybe<Poe2DocumentUgWidgetEquipmentCommonV1>;
};

export type Poe2DocumentUgWidgetEquipmentRingV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentRingV1';
  commonItem?: Maybe<Poe2DocumentUgWidgetEquipmentCommonV1>;
};

export type Poe2DocumentUgWidgetEquipmentV1 = NgfDocumentContent & {
  __typename?: 'Poe2DocumentUgWidgetEquipmentV1';
  data: Poe2DocumentUgWidgetEquipmentDataV1;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type Poe2DocumentUgWidgetEquipmentWeaponMainV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentWeaponMainV1';
  set1?: Maybe<Poe2DocumentUgWidgetEquipmentWeaponSetMainV1>;
  set2?: Maybe<Poe2DocumentUgWidgetEquipmentWeaponSetMainV1>;
};

export type Poe2DocumentUgWidgetEquipmentWeaponOffV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentWeaponOffV1';
  set1?: Maybe<Poe2DocumentUgWidgetEquipmentWeaponSetOffV1>;
  set2?: Maybe<Poe2DocumentUgWidgetEquipmentWeaponSetOffV1>;
};

export type Poe2DocumentUgWidgetEquipmentWeaponProvidedSkill = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentWeaponProvidedSkill';
  gemIconURL: Scalars['String'];
  gemSlug: Scalars['String'];
  iconURL: Scalars['String'];
  name: Scalars['String'];
};

export type Poe2DocumentUgWidgetEquipmentWeaponSetMainV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentWeaponSetMainV1';
  blocksOffHand?: Maybe<Scalars['Boolean']>;
  commonItem?: Maybe<Poe2DocumentUgWidgetEquipmentCommonV1>;
  providedSkill?: Maybe<Poe2DocumentUgWidgetEquipmentWeaponProvidedSkill>;
};

export type Poe2DocumentUgWidgetEquipmentWeaponSetOffV1 = {
  __typename?: 'Poe2DocumentUgWidgetEquipmentWeaponSetOffV1';
  commonItem?: Maybe<Poe2DocumentUgWidgetEquipmentCommonV1>;
  providedSkill?: Maybe<Poe2DocumentUgWidgetEquipmentWeaponProvidedSkill>;
};

export type Poe2DocumentUgWidgetPassiveTreeDataV1 = {
  __typename?: 'Poe2DocumentUgWidgetPassiveTreeDataV1';
  description?: Maybe<NgfDocumentRichTextContent>;
  subTitle?: Maybe<NgfDocumentRichTextContent>;
  title: Scalars['String'];
};

export type Poe2DocumentUgWidgetPassiveTreeV1 = NgfDocumentContent & {
  __typename?: 'Poe2DocumentUgWidgetPassiveTreeV1';
  data: Poe2DocumentUgWidgetPassiveTreeDataV1;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type Poe2DocumentUgWidgetSkillGemsDataV1 = {
  __typename?: 'Poe2DocumentUgWidgetSkillGemsDataV1';
  description?: Maybe<NgfDocumentRichTextContent>;
  subTitle?: Maybe<NgfDocumentRichTextContent>;
  title: Scalars['String'];
};

export type Poe2DocumentUgWidgetSkillGemsV1 = NgfDocumentContent & {
  __typename?: 'Poe2DocumentUgWidgetSkillGemsV1';
  data: Poe2DocumentUgWidgetSkillGemsDataV1;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type Poe2DocumentsMutation = {
  __typename?: 'Poe2DocumentsMutation';
  /** Create operation creates a new document with the provided data and content. */
  structDocumentCreate: Poe2StructDocumentCreatePayload;
  /** SEO operation affects only document.seo field. */
  structDocumentSetSeo: Poe2StructDocumentSetSeoPayload;
  /** Status operation affects only document.status field. */
  structDocumentSetStatus: Poe2StructDocumentSetStatusPayload;
  /** Update operation affects only document.data and document.content fields. */
  structDocumentUpdate: Poe2StructDocumentUpdatePayload;
  /** Create operation creates a new document with the provided data and content. */
  userGeneratedDocumentCreate: Poe2UserGeneratedDocumentCreatePayload;
  /** Status operation affects only document.favorites field. */
  userGeneratedDocumentSetFavorite: Poe2UserGeneratedDocumentSetFavoritePayload;
  /** Status operation affects only document.featured field. */
  userGeneratedDocumentSetFeatured: Poe2UserGeneratedDocumentSetFeaturedPayload;
  /** SEO operation affects only document.seo field. */
  userGeneratedDocumentSetSeo: Poe2UserGeneratedDocumentSetSeoPayload;
  /** Status operation affects only document.status field. */
  userGeneratedDocumentSetStatus: Poe2UserGeneratedDocumentSetStatusPayload;
  /** Update operation affects only document.data and document.content fields. */
  userGeneratedDocumentUpdate: Poe2UserGeneratedDocumentUpdatePayload;
};

export type Poe2DocumentsMutationstructDocumentCreateArgs = {
  input: Poe2StructDocumentCreateInput;
};

export type Poe2DocumentsMutationstructDocumentSetSeoArgs = {
  input: NgfDocumentSetSeoInput;
};

export type Poe2DocumentsMutationstructDocumentSetStatusArgs = {
  input: Poe2StructDocumentSetStatusInput;
};

export type Poe2DocumentsMutationstructDocumentUpdateArgs = {
  input: Poe2StructDocumentUpdateInput;
};

export type Poe2DocumentsMutationuserGeneratedDocumentCreateArgs = {
  input: Poe2UserGeneratedDocumentCreateInput;
};

export type Poe2DocumentsMutationuserGeneratedDocumentSetFavoriteArgs = {
  input: Poe2UserGeneratedDocumentSetFavoriteInput;
};

export type Poe2DocumentsMutationuserGeneratedDocumentSetFeaturedArgs = {
  input: Poe2UserGeneratedDocumentSetFeaturedInput;
};

export type Poe2DocumentsMutationuserGeneratedDocumentSetSeoArgs = {
  input: NgfDocumentSetSeoInput;
};

export type Poe2DocumentsMutationuserGeneratedDocumentSetStatusArgs = {
  input: Poe2UserGeneratedDocumentSetStatusInput;
};

export type Poe2DocumentsMutationuserGeneratedDocumentUpdateArgs = {
  input: Poe2UserGeneratedDocumentUpdateInput;
};

export type Poe2DocumentsQuery = {
  __typename?: 'Poe2DocumentsQuery';
  previewDocumentContent: Poe2PreviewDocumentContentPayload;
  structDocumentBySlug: Poe2StructDocumentPayload;
  structDocumentWidgets: NgfStructDocumentWidgetsPayload;
  structDocuments: NgfStructDocumentsListPayload;
  userGeneratedDocumentById: Poe2UserGeneratedDocumentPayload;
  userGeneratedDocumentBySlug: Poe2UserGeneratedDocumentPayload;
  userGeneratedDocumentTagGroups: NgfUserGeneratedDocumentTagGroupsPayload;
  userGeneratedDocumentTemplate: Poe2TemplateDocumentPayload;
  userGeneratedDocumentTypes: NgfUserGeneratedDocumentTypesPayload;
  userGeneratedDocuments: NgfUserGeneratedDocumentsListPayload;
};

export type Poe2DocumentsQuerypreviewDocumentContentArgs = {
  input: Poe2PreviewDocumentContentInput;
};

export type Poe2DocumentsQuerystructDocumentBySlugArgs = {
  input: Poe2StructDocumentInputBySlug;
};

export type Poe2DocumentsQuerystructDocumentsArgs = {
  input: Poe2StructDocumentsInput;
};

export type Poe2DocumentsQueryuserGeneratedDocumentByIdArgs = {
  input: Poe2UserGeneratedDocumentInputById;
};

export type Poe2DocumentsQueryuserGeneratedDocumentBySlugArgs = {
  input: Poe2UserGeneratedDocumentInputBySlug;
};

export type Poe2DocumentsQueryuserGeneratedDocumentTagGroupsArgs = {
  input?: InputMaybe<NgfUserGeneratedDocumentTagGroupsInput>;
};

export type Poe2DocumentsQueryuserGeneratedDocumentTemplateArgs = {
  input: Poe2TemplateDocumentInput;
};

export type Poe2DocumentsQueryuserGeneratedDocumentsArgs = {
  input: Poe2UserGeneratedDocumentsListInput;
  page: Poe2UserGeneratedDocumentsListPage;
};

export type Poe2EquipmentGem = {
  __typename?: 'Poe2EquipmentGem';
  baseItemClass1Slug?: Maybe<Scalars['String']>;
  baseItemClass2Slug?: Maybe<Scalars['String']>;
  gem?: Maybe<Poe2Gem>;
  gemSlug?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Poe2EquipmentGemsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2EquipmentGemsResult = {
  __typename?: 'Poe2EquipmentGemsResult';
  data?: Maybe<Array<Poe2EquipmentGem>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2EquipmentRestriction = {
  __typename?: 'Poe2EquipmentRestriction';
  baseItemClass?: Maybe<Scalars['String']>;
  extraOccupancies?: Maybe<Array<Scalars['String']>>;
  id: Scalars['String'];
  name: Scalars['String'];
  properSlots?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
};

export type Poe2EquipmentRestrictionsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2EquipmentRestrictionsResult = {
  __typename?: 'Poe2EquipmentRestrictionsResult';
  data?: Maybe<Array<Poe2EquipmentRestriction>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2Flask = {
  __typename?: 'Poe2Flask';
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  baseItemType?: Maybe<Poe2BaseItem>;
  baseItemTypeSlug?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['Int']>;
  flaskStats?: Maybe<Array<Poe2FlaskStat>>;
  flavourText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isUnique?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Poe2FlaskStat = {
  __typename?: 'Poe2FlaskStat';
  slug: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type Poe2FlasksFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2FlasksResult = {
  __typename?: 'Poe2FlasksResult';
  data?: Maybe<Array<Poe2Flask>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2Focus = {
  __typename?: 'Poe2Focus';
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  baseItemType?: Maybe<Poe2BaseItem>;
  baseItemTypeSlug?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['Int']>;
  flavourText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isUnique?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Poe2FocusesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2FocusesResult = {
  __typename?: 'Poe2FocusesResult';
  data?: Maybe<Array<Poe2Focus>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2Gem = {
  __typename?: 'Poe2Gem';
  backedMinMaxRequires?: Maybe<Array<Poe2BackedMinMaxRequire>>;
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  bakedMinMaxStatsDescriptions?: Maybe<Array<Poe2BakedMinMaxStatsDescription>>;
  baseItem?: Maybe<Poe2BaseItem>;
  baseItemSlug?: Maybe<Scalars['String']>;
  descriptions?: Maybe<Array<Scalars['Int']>>;
  gemStatSlugs?: Maybe<Array<Scalars['String']>>;
  gemStats?: Maybe<Array<Poe2GemStat>>;
  gemsTagSlugs?: Maybe<Array<Scalars['String']>>;
  gemsTags?: Maybe<Array<Poe2GemTag>>;
  hoverImage?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isSupport?: Maybe<Scalars['Boolean']>;
  isWeaponGranted?: Maybe<Scalars['Boolean']>;
  mainDescription?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  qualityBakedDescriptions?: Maybe<Array<Scalars['String']>>;
  qualityDescriptions?: Maybe<Array<Scalars['Int']>>;
  qualityStatSlugs?: Maybe<Array<Scalars['String']>>;
  qualitySubstitutions?: Maybe<Array<Scalars['Float']>>;
  skillTypeSlugs?: Maybe<Array<Scalars['String']>>;
  skillTypes?: Maybe<Array<Poe2SkillType>>;
  slug: Scalars['String'];
  statsPerLevel?: Maybe<Array<Poe2StatsPerLevel>>;
  substitutions?: Maybe<Array<Scalars['Float']>>;
  weaponRestrictionSlugs?: Maybe<Array<Scalars['String']>>;
  weaponRestrictions?: Maybe<Array<Poe2ItemClass>>;
};

export type Poe2GemStat = {
  __typename?: 'Poe2GemStat';
  slug: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type Poe2GemTag = {
  __typename?: 'Poe2GemTag';
  backedName?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Poe2GemTagsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2GemTagsResult = {
  __typename?: 'Poe2GemTagsResult';
  data?: Maybe<Array<Poe2GemTag>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2GemsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2GemsResult = {
  __typename?: 'Poe2GemsResult';
  data?: Maybe<Array<Poe2Gem>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2Group = {
  __typename?: 'Poe2Group';
  centerX?: Maybe<Scalars['Float']>;
  centerY?: Maybe<Scalars['Float']>;
  groupAssociationKey?: Maybe<Scalars['Int']>;
  groupBackgroundOverride?: Maybe<Scalars['Int']>;
  isJewelPositionReference?: Maybe<Scalars['Boolean']>;
  nodes?: Maybe<Array<Poe2Node>>;
  slug: Scalars['String'];
};

export type Poe2ItemClass = {
  __typename?: 'Poe2ItemClass';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Poe2ItemClassesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2ItemClassesResult = {
  __typename?: 'Poe2ItemClassesResult';
  data?: Maybe<Array<Poe2ItemClass>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2ItemRequiredStat = {
  __typename?: 'Poe2ItemRequiredStat';
  slug: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type Poe2Mutation = {
  __typename?: 'Poe2Mutation';
  documents?: Maybe<Poe2DocumentsMutation>;
  id: Scalars['ID'];
};

export type Poe2Node = {
  __typename?: 'Poe2Node';
  edgeSlugs?: Maybe<Array<Scalars['String']>>;
  edges2?: Maybe<Array<Scalars['Int']>>;
  orbit?: Maybe<Scalars['Int']>;
  orbitIndex?: Maybe<Scalars['Int']>;
  passiveSlug?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type Poe2PassiveSkill = {
  __typename?: 'Poe2PassiveSkill';
  ascendancy?: Maybe<Scalars['Int']>;
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  character?: Maybe<Array<Scalars['Int']>>;
  descriptions?: Maybe<Array<Scalars['Int']>>;
  flavourText?: Maybe<Scalars['String']>;
  grantedSkillSlug?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  jewelSocket?: Maybe<Scalars['Boolean']>;
  justIcon?: Maybe<Scalars['Boolean']>;
  keystone?: Maybe<Scalars['Boolean']>;
  masteryBackgroundImage?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  notable?: Maybe<Scalars['Boolean']>;
  skillPointsGranted?: Maybe<Scalars['Int']>;
  slug: Scalars['String'];
  statSlugs?: Maybe<Array<Scalars['String']>>;
  substitutions?: Maybe<Array<Scalars['Float']>>;
  tiledIconRect?: Maybe<Poe2TiledIconRect>;
};

export type Poe2PassiveSkillOverride = {
  __typename?: 'Poe2PassiveSkillOverride';
  classSlug?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  originalSlug?: Maybe<Scalars['String']>;
  overrideSlug?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
};

export type Poe2PassiveSkillOverridesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2PassiveSkillOverridesResult = {
  __typename?: 'Poe2PassiveSkillOverridesResult';
  data?: Maybe<Array<Poe2PassiveSkillOverride>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2PassiveSkillTreeRestriction = {
  __typename?: 'Poe2PassiveSkillTreeRestriction';
  id: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Poe2PassiveSkillTreeRestrictionsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2PassiveSkillTreeRestrictionsResult = {
  __typename?: 'Poe2PassiveSkillTreeRestrictionsResult';
  data?: Maybe<Array<Poe2PassiveSkillTreeRestriction>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2PassiveSkillsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2PassiveSkillsGraph = {
  __typename?: 'Poe2PassiveSkillsGraph';
  groups?: Maybe<Array<Poe2Group>>;
  id: Scalars['String'];
  name: Scalars['String'];
  orbits?: Maybe<Array<Scalars['Int']>>;
  radii?: Maybe<Array<Scalars['Int']>>;
  rootEdgeSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
  type?: Maybe<Scalars['Int']>;
  version?: Maybe<Scalars['Int']>;
};

export type Poe2PassiveSkillsGraphFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2PassiveSkillsGraphResult = {
  __typename?: 'Poe2PassiveSkillsGraphResult';
  data?: Maybe<Array<Poe2PassiveSkillsGraph>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2PassiveSkillsResult = {
  __typename?: 'Poe2PassiveSkillsResult';
  data?: Maybe<Array<Poe2PassiveSkill>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2Prefix = {
  __typename?: 'Poe2Prefix';
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  domain?: Maybe<Scalars['Int']>;
  flavourText?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  maxDescriptions?: Maybe<Array<Scalars['Int']>>;
  maxSubstitutions?: Maybe<Array<Scalars['Float']>>;
  minDescriptions?: Maybe<Array<Scalars['Int']>>;
  minSubstitutions?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  prefixStatSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
};

export type Poe2PrefixesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2PrefixesResult = {
  __typename?: 'Poe2PrefixesResult';
  data?: Maybe<Array<Poe2Prefix>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export enum Poe2PreviewDocumentContentError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type Poe2PreviewDocumentContentInput = {
  content: Scalars['Poe2DocumentContentInputBase64Json'];
};

export type Poe2PreviewDocumentContentPayload = {
  __typename?: 'Poe2PreviewDocumentContentPayload';
  data?: Maybe<Poe2DocumentContent>;
  error?: Maybe<Poe2PreviewDocumentContentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type Poe2Query = {
  __typename?: 'Poe2Query';
  documents?: Maybe<Poe2DocumentsQuery>;
  id: Scalars['ID'];
  staticData?: Maybe<Poe2StaticDataQuery>;
};

export type Poe2Quiver = {
  __typename?: 'Poe2Quiver';
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  baseItemType?: Maybe<Poe2BaseItem>;
  baseItemTypeSlug?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['Int']>;
  flavourText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isUnique?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  maxDescriptions?: Maybe<Array<Scalars['Int']>>;
  maxSubstitutions?: Maybe<Array<Scalars['Float']>>;
  minDescriptions?: Maybe<Array<Scalars['Int']>>;
  minSubstitutions?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  prefixStatSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
};

export type Poe2QuiversFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2QuiversResult = {
  __typename?: 'Poe2QuiversResult';
  data?: Maybe<Array<Poe2Quiver>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2RequiredSkillLevelStat = {
  __typename?: 'Poe2RequiredSkillLevelStat';
  slug: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type Poe2Ring = {
  __typename?: 'Poe2Ring';
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  baseItemType?: Maybe<Poe2BaseItem>;
  baseItemTypeSlug?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['Int']>;
  flavourText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isUnique?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  maxDescriptions?: Maybe<Array<Scalars['Int']>>;
  maxSubstitutions?: Maybe<Array<Scalars['Float']>>;
  minDescriptions?: Maybe<Array<Scalars['Int']>>;
  minSubstitutions?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  prefixStatSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
};

export type Poe2RingsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2RingsResult = {
  __typename?: 'Poe2RingsResult';
  data?: Maybe<Array<Poe2Ring>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2Shield = {
  __typename?: 'Poe2Shield';
  backedStatDescriptions?: Maybe<Array<Poe2BackedStatDescription>>;
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  baseItemType?: Maybe<Poe2BaseItem>;
  baseItemTypeSlug?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['Int']>;
  flavourText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isUnique?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  maxDescriptions?: Maybe<Array<Scalars['Int']>>;
  maxSubstitutions?: Maybe<Array<Scalars['Float']>>;
  minDescriptions?: Maybe<Array<Scalars['Int']>>;
  minSubstitutions?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  prefixStatSlugs?: Maybe<Array<Scalars['String']>>;
  shieldStats?: Maybe<Array<Poe2ShieldStat>>;
  slug: Scalars['String'];
};

export type Poe2ShieldStat = {
  __typename?: 'Poe2ShieldStat';
  slug: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type Poe2ShieldsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2ShieldsResult = {
  __typename?: 'Poe2ShieldsResult';
  data?: Maybe<Array<Poe2Shield>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2SkillLevelStat = {
  __typename?: 'Poe2SkillLevelStat';
  slug: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type Poe2SkillType = {
  __typename?: 'Poe2SkillType';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type Poe2SkillTypesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2SkillTypesResult = {
  __typename?: 'Poe2SkillTypesResult';
  data?: Maybe<Array<Poe2SkillType>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2StaticDataQuery = {
  __typename?: 'Poe2StaticDataQuery';
  poe2Amulets: Poe2AmuletsResult;
  poe2Armours: Poe2ArmoursResult;
  poe2AtlasSubtrees: Poe2AtlasSubtreesResult;
  poe2AtlasTreeRestrictions: Poe2AtlasTreeRestrictionsResult;
  poe2BaseItems: Poe2BaseItemsResult;
  poe2CharacterStartStatess: Poe2CharacterStartStatessResult;
  poe2Characters: Poe2CharactersResult;
  poe2Description: Poe2DescriptionResult;
  poe2Dictionary: Poe2DictionaryResult;
  poe2EquipmentGems: Poe2EquipmentGemsResult;
  poe2EquipmentRestrictions: Poe2EquipmentRestrictionsResult;
  poe2Flasks: Poe2FlasksResult;
  poe2Focuses: Poe2FocusesResult;
  poe2GemTags: Poe2GemTagsResult;
  poe2Gems: Poe2GemsResult;
  poe2ItemClasses: Poe2ItemClassesResult;
  poe2PassiveSkillOverrides: Poe2PassiveSkillOverridesResult;
  poe2PassiveSkillTreeRestrictions: Poe2PassiveSkillTreeRestrictionsResult;
  poe2PassiveSkills: Poe2PassiveSkillsResult;
  poe2PassiveSkillsGraph: Poe2PassiveSkillsGraphResult;
  poe2Prefixes: Poe2PrefixesResult;
  poe2Quivers: Poe2QuiversResult;
  poe2Rings: Poe2RingsResult;
  poe2Shields: Poe2ShieldsResult;
  poe2SkillTypes: Poe2SkillTypesResult;
  poe2Suffixes: Poe2SuffixesResult;
  poe2TiledIcons: Poe2TiledIconsResult;
  poe2Weapons: Poe2WeaponsResult;
};

export type Poe2StaticDataQuerypoe2AmuletsArgs = {
  filter?: InputMaybe<Poe2AmuletsFilter>;
};

export type Poe2StaticDataQuerypoe2ArmoursArgs = {
  filter?: InputMaybe<Poe2ArmoursFilter>;
};

export type Poe2StaticDataQuerypoe2AtlasSubtreesArgs = {
  filter?: InputMaybe<Poe2AtlasSubtreesFilter>;
};

export type Poe2StaticDataQuerypoe2AtlasTreeRestrictionsArgs = {
  filter?: InputMaybe<Poe2AtlasTreeRestrictionsFilter>;
};

export type Poe2StaticDataQuerypoe2BaseItemsArgs = {
  filter?: InputMaybe<Poe2BaseItemsFilter>;
};

export type Poe2StaticDataQuerypoe2CharacterStartStatessArgs = {
  filter?: InputMaybe<Poe2CharacterStartStatessFilter>;
};

export type Poe2StaticDataQuerypoe2CharactersArgs = {
  filter?: InputMaybe<Poe2CharactersFilter>;
};

export type Poe2StaticDataQuerypoe2DescriptionArgs = {
  filter?: InputMaybe<Poe2DescriptionFilter>;
};

export type Poe2StaticDataQuerypoe2DictionaryArgs = {
  filter?: InputMaybe<Poe2DictionaryFilter>;
};

export type Poe2StaticDataQuerypoe2EquipmentGemsArgs = {
  filter?: InputMaybe<Poe2EquipmentGemsFilter>;
};

export type Poe2StaticDataQuerypoe2EquipmentRestrictionsArgs = {
  filter?: InputMaybe<Poe2EquipmentRestrictionsFilter>;
};

export type Poe2StaticDataQuerypoe2FlasksArgs = {
  filter?: InputMaybe<Poe2FlasksFilter>;
};

export type Poe2StaticDataQuerypoe2FocusesArgs = {
  filter?: InputMaybe<Poe2FocusesFilter>;
};

export type Poe2StaticDataQuerypoe2GemTagsArgs = {
  filter?: InputMaybe<Poe2GemTagsFilter>;
};

export type Poe2StaticDataQuerypoe2GemsArgs = {
  filter?: InputMaybe<Poe2GemsFilter>;
};

export type Poe2StaticDataQuerypoe2ItemClassesArgs = {
  filter?: InputMaybe<Poe2ItemClassesFilter>;
};

export type Poe2StaticDataQuerypoe2PassiveSkillOverridesArgs = {
  filter?: InputMaybe<Poe2PassiveSkillOverridesFilter>;
};

export type Poe2StaticDataQuerypoe2PassiveSkillTreeRestrictionsArgs = {
  filter?: InputMaybe<Poe2PassiveSkillTreeRestrictionsFilter>;
};

export type Poe2StaticDataQuerypoe2PassiveSkillsArgs = {
  filter?: InputMaybe<Poe2PassiveSkillsFilter>;
};

export type Poe2StaticDataQuerypoe2PassiveSkillsGraphArgs = {
  filter?: InputMaybe<Poe2PassiveSkillsGraphFilter>;
};

export type Poe2StaticDataQuerypoe2PrefixesArgs = {
  filter?: InputMaybe<Poe2PrefixesFilter>;
};

export type Poe2StaticDataQuerypoe2QuiversArgs = {
  filter?: InputMaybe<Poe2QuiversFilter>;
};

export type Poe2StaticDataQuerypoe2RingsArgs = {
  filter?: InputMaybe<Poe2RingsFilter>;
};

export type Poe2StaticDataQuerypoe2ShieldsArgs = {
  filter?: InputMaybe<Poe2ShieldsFilter>;
};

export type Poe2StaticDataQuerypoe2SkillTypesArgs = {
  filter?: InputMaybe<Poe2SkillTypesFilter>;
};

export type Poe2StaticDataQuerypoe2SuffixesArgs = {
  filter?: InputMaybe<Poe2SuffixesFilter>;
};

export type Poe2StaticDataQuerypoe2TiledIconsArgs = {
  filter?: InputMaybe<Poe2TiledIconsFilter>;
};

export type Poe2StaticDataQuerypoe2WeaponsArgs = {
  filter?: InputMaybe<Poe2WeaponsFilter>;
};

export type Poe2StatsPerLevel = {
  __typename?: 'Poe2StatsPerLevel';
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  descriptions?: Maybe<Array<Scalars['Int']>>;
  level?: Maybe<Scalars['Int']>;
  requiredSkillLevelStats?: Maybe<Array<Poe2RequiredSkillLevelStat>>;
  skillLevelStatSlugs?: Maybe<Array<Scalars['String']>>;
  skillLevelStats?: Maybe<Array<Poe2SkillLevelStat>>;
  substitutions?: Maybe<Array<Scalars['Float']>>;
};

export type Poe2StructDocument = NgfStructDocument & {
  __typename?: 'Poe2StructDocument';
  content: Array<Poe2DocumentContent>;
  createdAt: Scalars['NgfDateTime'];
  data: Poe2StructDocumentData;
  id: Scalars['ID'];
  permissions?: Maybe<NgfDocumentPermissions>;
  seo?: Maybe<NgfDocumentSeo>;
  slug: Scalars['String'];
  status: NgfStructDocumentStatus;
  updatedAt: Scalars['NgfDateTime'];
  version: Scalars['Int'];
};

export enum Poe2StructDocumentCreateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
}

export type Poe2StructDocumentCreateInput = {
  document: Scalars['Poe2StructDocumentInputBase64Json'];
};

export type Poe2StructDocumentCreatePayload = {
  __typename?: 'Poe2StructDocumentCreatePayload';
  data?: Maybe<Poe2StructDocument>;
  error?: Maybe<Poe2StructDocumentCreateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type Poe2StructDocumentData = NgfStructDocumentData & {
  __typename?: 'Poe2StructDocumentData';
  childrenIds: Array<Scalars['String']>;
  name: Scalars['String'];
  season: Poe2DocumentSeason;
  tags?: Maybe<Poe2DocumentTags>;
};

export enum Poe2StructDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type Poe2StructDocumentInputBySlug = {
  /** Slug is unique human readable identifier of a struct document. */
  slug: Scalars['String'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type Poe2StructDocumentPayload = {
  __typename?: 'Poe2StructDocumentPayload';
  data?: Maybe<Poe2StructDocument>;
  error?: Maybe<Poe2StructDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum Poe2StructDocumentSetSeoError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type Poe2StructDocumentSetSeoPayload = {
  __typename?: 'Poe2StructDocumentSetSeoPayload';
  data?: Maybe<Poe2StructDocument>;
  error?: Maybe<Poe2StructDocumentSetSeoError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum Poe2StructDocumentSetStatusError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type Poe2StructDocumentSetStatusInput = {
  id: Scalars['ID'];
  status: NgfStructDocumentStatus;
};

export type Poe2StructDocumentSetStatusPayload = {
  __typename?: 'Poe2StructDocumentSetStatusPayload';
  data?: Maybe<Poe2StructDocument>;
  error?: Maybe<Poe2StructDocumentSetStatusError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum Poe2StructDocumentUpdateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type Poe2StructDocumentUpdateInput = {
  document: Scalars['Poe2StructDocumentInputBase64Json'];
  id: Scalars['ID'];
};

export type Poe2StructDocumentUpdatePayload = {
  __typename?: 'Poe2StructDocumentUpdatePayload';
  data?: Maybe<Poe2StructDocument>;
  error?: Maybe<Poe2StructDocumentUpdateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type Poe2StructDocumentsInput = {
  statuses: Array<NgfStructDocumentStatus>;
};

export type Poe2Suffix = {
  __typename?: 'Poe2Suffix';
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  domain?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  maxDescriptions?: Maybe<Array<Scalars['Int']>>;
  maxSubstitutions?: Maybe<Array<Scalars['Float']>>;
  minDescriptions?: Maybe<Array<Scalars['Int']>>;
  minSubstitutions?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  slug: Scalars['String'];
  suffixStatSlugs?: Maybe<Array<Scalars['String']>>;
};

export type Poe2SuffixesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2SuffixesResult = {
  __typename?: 'Poe2SuffixesResult';
  data?: Maybe<Array<Poe2Suffix>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2TemplateDocument = NgfTemplateDocument & {
  __typename?: 'Poe2TemplateDocument';
  content: Array<Poe2DocumentContent>;
  data: Poe2TemplateDocumentData;
  type: Scalars['String'];
  typeData: NgfDocumentType;
};

export type Poe2TemplateDocumentData = NgfTemplateDocumentData & {
  __typename?: 'Poe2TemplateDocumentData';
  buildVariants?: Maybe<Poe2UserGeneratedDocumentBuildVariants>;
  childrenIds: Array<Scalars['String']>;
  name: Scalars['String'];
  season: Poe2DocumentSeason;
};

export enum Poe2TemplateDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
}

export type Poe2TemplateDocumentInput = {
  type: Scalars['String'];
};

export type Poe2TemplateDocumentPayload = {
  __typename?: 'Poe2TemplateDocumentPayload';
  data?: Maybe<Poe2TemplateDocument>;
  error?: Maybe<Poe2TemplateDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type Poe2TiledIcon = {
  __typename?: 'Poe2TiledIcon';
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
  tiledPassiveSkillDisabledIcons?: Maybe<Scalars['String']>;
  tiledPassiveSkillIcons?: Maybe<Scalars['String']>;
};

export type Poe2TiledIconRect = {
  __typename?: 'Poe2TiledIconRect';
  Height?: Maybe<Scalars['Int']>;
  Width?: Maybe<Scalars['Int']>;
  X?: Maybe<Scalars['Int']>;
  Y?: Maybe<Scalars['Int']>;
};

export type Poe2TiledIconsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2TiledIconsResult = {
  __typename?: 'Poe2TiledIconsResult';
  data?: Maybe<Array<Poe2TiledIcon>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Poe2UserGeneratedDocument = NgfUserGeneratedDocument & {
  __typename?: 'Poe2UserGeneratedDocument';
  author: NgfDocumentAuthor;
  content: Array<Poe2DocumentContent>;
  createdAt: Scalars['NgfDateTime'];
  data: Poe2UserGeneratedDocumentData;
  favorites?: Maybe<NgfDocumentFavorites>;
  featured?: Maybe<NgfDocumentFeatured>;
  firstPublishedAt?: Maybe<Scalars['NgfDateTime']>;
  id: Scalars['ID'];
  permissions?: Maybe<NgfDocumentPermissions>;
  seo?: Maybe<NgfDocumentSeo>;
  status: NgfUserGeneratedDocumentStatus;
  tags?: Maybe<Poe2DocumentTags>;
  type: Scalars['String'];
  typeData: NgfDocumentType;
  updatedAt: Scalars['NgfDateTime'];
  version: Scalars['Int'];
};

export type Poe2UserGeneratedDocumentBuildVariant = {
  __typename?: 'Poe2UserGeneratedDocumentBuildVariant';
  atlasTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantAtlasTree>;
  equipment?: Maybe<Poe2UserGeneratedDocumentBuildVariantEquipment>;
  id: Scalars['ID'];
  passiveTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTree>;
  skillGems?: Maybe<Poe2UserGeneratedDocumentBuildVariantSkillGems>;
};

export type Poe2UserGeneratedDocumentBuildVariantAtlasTree = {
  __typename?: 'Poe2UserGeneratedDocumentBuildVariantAtlasTree';
  bossTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
  breachTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
  deliriumTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
  expeditionTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
  mainTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
  pinnacleBossTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
  ritualTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
};

export type Poe2UserGeneratedDocumentBuildVariantAtlasTreeNodes = {
  __typename?: 'Poe2UserGeneratedDocumentBuildVariantAtlasTreeNodes';
  selectedSlugs?: Maybe<Array<Scalars['String']>>;
};

export type Poe2UserGeneratedDocumentBuildVariantEquipment = {
  __typename?: 'Poe2UserGeneratedDocumentBuildVariantEquipment';
  amulet?: Maybe<Poe2DocumentUgWidgetEquipmentAmuletV1>;
  belt?: Maybe<Poe2DocumentUgWidgetEquipmentArmourV1>;
  body?: Maybe<Poe2DocumentUgWidgetEquipmentArmourV1>;
  boots?: Maybe<Poe2DocumentUgWidgetEquipmentArmourV1>;
  charm1?: Maybe<Poe2DocumentUgWidgetEquipmentCharmV1>;
  charm2?: Maybe<Poe2DocumentUgWidgetEquipmentCharmV1>;
  charm3?: Maybe<Poe2DocumentUgWidgetEquipmentCharmV1>;
  flask1?: Maybe<Poe2DocumentUgWidgetEquipmentFlaskV1>;
  flask2?: Maybe<Poe2DocumentUgWidgetEquipmentFlaskV1>;
  gloves?: Maybe<Poe2DocumentUgWidgetEquipmentArmourV1>;
  helmet?: Maybe<Poe2DocumentUgWidgetEquipmentArmourV1>;
  leftRing?: Maybe<Poe2DocumentUgWidgetEquipmentRingV1>;
  mainHand?: Maybe<Poe2DocumentUgWidgetEquipmentWeaponMainV1>;
  offHand?: Maybe<Poe2DocumentUgWidgetEquipmentWeaponOffV1>;
  rightRing?: Maybe<Poe2DocumentUgWidgetEquipmentRingV1>;
};

export type Poe2UserGeneratedDocumentBuildVariantPassiveTree = {
  __typename?: 'Poe2UserGeneratedDocumentBuildVariantPassiveTree';
  ascendancyTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
  mainTree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
  set1Tree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
  set2Tree?: Maybe<Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes>;
};

export type Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes = {
  __typename?: 'Poe2UserGeneratedDocumentBuildVariantPassiveTreeNodes';
  selectedSlugs?: Maybe<Array<Scalars['String']>>;
};

export type Poe2UserGeneratedDocumentBuildVariantSkillGems = {
  __typename?: 'Poe2UserGeneratedDocumentBuildVariantSkillGems';
  gemRequirements?: Maybe<Poe2DocumentCmWidgetGemsRequirementsV1>;
  gems?: Maybe<Array<Poe2DocumentCmWidgetGemsSlotV1>>;
};

export type Poe2UserGeneratedDocumentBuildVariants = {
  __typename?: 'Poe2UserGeneratedDocumentBuildVariants';
  values?: Maybe<Array<Poe2UserGeneratedDocumentBuildVariant>>;
};

export enum Poe2UserGeneratedDocumentCreateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type Poe2UserGeneratedDocumentCreateInput = {
  document: Scalars['Poe2UserGeneratedDocumentInputBase64Json'];
};

export type Poe2UserGeneratedDocumentCreatePayload = {
  __typename?: 'Poe2UserGeneratedDocumentCreatePayload';
  data?: Maybe<Poe2UserGeneratedDocument>;
  error?: Maybe<Poe2UserGeneratedDocumentCreateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type Poe2UserGeneratedDocumentData = NgfUserGeneratedDocumentData & {
  __typename?: 'Poe2UserGeneratedDocumentData';
  backgroundImage?: Maybe<Scalars['String']>;
  buildVariants?: Maybe<Poe2UserGeneratedDocumentBuildVariants>;
  childrenIds: Array<Scalars['String']>;
  gameSpecificProp1?: Maybe<Scalars['String']>;
  gameSpecificProp2?: Maybe<Scalars['Int']>;
  gameSpecificProp3?: Maybe<Scalars['Boolean']>;
  gameSpecificProp4?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  season: Poe2DocumentSeason;
};

export enum Poe2UserGeneratedDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type Poe2UserGeneratedDocumentInputById = {
  /** Unique identifier of a document. Either id or slug is required to get content document. */
  id: Scalars['ID'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type Poe2UserGeneratedDocumentInputBySlug = {
  /** Unique human readable identifier of a document. Either id or slug is required to get content document. */
  slug: Scalars['String'];
  /** Document type. */
  type: Scalars['String'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type Poe2UserGeneratedDocumentPayload = {
  __typename?: 'Poe2UserGeneratedDocumentPayload';
  data?: Maybe<Poe2UserGeneratedDocument>;
  error?: Maybe<Poe2UserGeneratedDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum Poe2UserGeneratedDocumentSetFavoriteError {
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type Poe2UserGeneratedDocumentSetFavoriteInput = {
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
};

export type Poe2UserGeneratedDocumentSetFavoritePayload = {
  __typename?: 'Poe2UserGeneratedDocumentSetFavoritePayload';
  data?: Maybe<Poe2UserGeneratedDocument>;
  error?: Maybe<Poe2UserGeneratedDocumentSetFavoriteError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum Poe2UserGeneratedDocumentSetFeaturedError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
}

export type Poe2UserGeneratedDocumentSetFeaturedInput = {
  documentSlug: Scalars['String'];
  id: Scalars['ID'];
  status: NgfDocumentFeaturedStatus;
};

export type Poe2UserGeneratedDocumentSetFeaturedPayload = {
  __typename?: 'Poe2UserGeneratedDocumentSetFeaturedPayload';
  data?: Maybe<Poe2UserGeneratedDocument>;
  error?: Maybe<Poe2UserGeneratedDocumentSetFeaturedError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum Poe2UserGeneratedDocumentSetSeoError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type Poe2UserGeneratedDocumentSetSeoPayload = {
  __typename?: 'Poe2UserGeneratedDocumentSetSeoPayload';
  data?: Maybe<Poe2UserGeneratedDocument>;
  error?: Maybe<Poe2UserGeneratedDocumentSetSeoError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum Poe2UserGeneratedDocumentSetStatusError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type Poe2UserGeneratedDocumentSetStatusInput = {
  id: Scalars['ID'];
  status: NgfUserGeneratedDocumentStatus;
};

export type Poe2UserGeneratedDocumentSetStatusPayload = {
  __typename?: 'Poe2UserGeneratedDocumentSetStatusPayload';
  data?: Maybe<Poe2UserGeneratedDocument>;
  error?: Maybe<Poe2UserGeneratedDocumentSetStatusError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum Poe2UserGeneratedDocumentUpdateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type Poe2UserGeneratedDocumentUpdateInput = {
  document: Scalars['Poe2UserGeneratedDocumentInputBase64Json'];
  id: Scalars['ID'];
};

export type Poe2UserGeneratedDocumentUpdatePayload = {
  __typename?: 'Poe2UserGeneratedDocumentUpdatePayload';
  data?: Maybe<Poe2UserGeneratedDocument>;
  error?: Maybe<Poe2UserGeneratedDocumentUpdateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type Poe2UserGeneratedDocumentsListInput = {
  /** If specified, filters by author id. */
  authorId?: InputMaybe<Scalars['ID']>;
  /** If specified, filters by document status. Specified as list because we have a case when we need to filter by both DRAFT and PUBLISHED statuses. */
  documentStatus?: InputMaybe<Array<NgfUserGeneratedDocumentStatus>>;
  /** If specified, filters by document featured status. */
  featuredStatuses?: InputMaybe<Array<NgfDocumentFeaturedStatus>>;
  /** If specified, filters by document ids. */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** If specified, filters documents that were added to favorites by the current user. */
  isFavorite?: InputMaybe<Scalars['Boolean']>;
  /** If specified, filters by name of the document. */
  name?: InputMaybe<Scalars['String']>;
  /** If specified, filters by document the timeframe when document been published. */
  publishedTimeframe?: InputMaybe<NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe>;
  /** If specified, filters by document season or "current-season" keyword. If "current-season" is passed, the current season will be used. */
  seasonSlug?: InputMaybe<Scalars['String']>;
  /**
   * If specified, filters by document slugs - human readable version of document id.
   * Slug can be defined only for featured documents, like document ids, they must be unique on game level.
   */
  slugs?: InputMaybe<Array<Scalars['String']>>;
  /** Defines the way output list is sorted. */
  sortBy?: InputMaybe<NgfUserGeneratedDocumentsDiscoverySortingOption>;
  /** If specified, filters by document tags. */
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
  /**
   * If specified, filters by document type.
   * 'profile-default-tab' is a special const, allows to request docs without specifying particular type.
   */
  type?: InputMaybe<Scalars['String']>;
};

export type Poe2UserGeneratedDocumentsListPage = {
  /**
   * If specified, filters out document before the specified cursor (forward cursor direction).
   * Backward cursor pagination is not supported as we currently don't have use cases for it.
   */
  cursor?: InputMaybe<Scalars['String']>;
  /** Limits the number of items in response. */
  limit: Scalars['Int'];
};

export type Poe2Weapon = {
  __typename?: 'Poe2Weapon';
  backedStatDescriptions?: Maybe<Array<Poe2BackedStatDescription>>;
  bakedDescriptions?: Maybe<Array<Scalars['String']>>;
  baseItemType?: Maybe<Poe2BaseItem>;
  baseItemTypeSlug?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['Int']>;
  flavourText?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isUnique?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  maxDescriptions?: Maybe<Array<Scalars['Int']>>;
  maxSubstitutions?: Maybe<Array<Scalars['Float']>>;
  minDescriptions?: Maybe<Array<Scalars['Int']>>;
  minSubstitutions?: Maybe<Array<Scalars['Float']>>;
  name: Scalars['String'];
  prefixStatSlugs?: Maybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
  weaponStats?: Maybe<Array<Poe2WeaponStat>>;
};

export type Poe2WeaponStat = {
  __typename?: 'Poe2WeaponStat';
  slug: Scalars['String'];
  value?: Maybe<Scalars['Float']>;
};

export type Poe2WeaponsFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type Poe2WeaponsResult = {
  __typename?: 'Poe2WeaponsResult';
  data?: Maybe<Array<Poe2Weapon>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  _stub?: Maybe<Scalars['String']>;
  deadlock: DeadlockQuery;
  destiny?: Maybe<DestinyQuery>;
  diablo4?: Maybe<Diablo4Query>;
  diablo4StaticData?: Maybe<Diablo4StaticDataQuery>;
  exampleGame: ExampleGameQuery;
  lostArk?: Maybe<LostArkQuery>;
  poe2: Poe2Query;
  tft?: Maybe<TftQuery>;
  theBazaar: TheBazaarQuery;
  treasury?: Maybe<TreasuryQuery>;
};

export enum Region {
  ALL = 'ALL',
  BR = 'BR',
  EUNE = 'EUNE',
  EUW = 'EUW',
  JP = 'JP',
  KR = 'KR',
  LAN = 'LAN',
  LAS = 'LAS',
  ME = 'ME',
  NA = 'NA',
  OCE = 'OCE',
  PBE = 'PBE',
  PH = 'PH',
  RU = 'RU',
  SG = 'SG',
  TH = 'TH',
  TR = 'TR',
  TW = 'TW',
  UNKNOWN = 'UNKNOWN',
  VN = 'VN',
}

export type StringsIntersection = {
  values?: InputMaybe<Array<Scalars['String']>>;
};

export type Subscription = {
  __typename?: 'Subscription';
  /** Composition changed */
  tftCompositionChanged?: Maybe<TftCompositionChangedPayload>;
  tftProfileChanged?: Maybe<TftProfileChangedPayload>;
  /** Payment received events */
  treasuryPaymentsPaymentReceived?: Maybe<TreasuryPaymentsPayment>;
  /** Subscription changed events */
  treasuryPaymentsSubscriptionChanged?: Maybe<TreasuryPaymentsSubscriptionChangedPayload>;
};

export type SubscriptiontftCompositionChangedArgs = {
  input: TftCompositionChangedInput;
};

export type SubscriptiontftProfileChangedArgs = {
  input: TftProfileChangedInput;
};

export type SubscriptiontreasuryPaymentsPaymentReceivedArgs = {
  input: TreasuryPaymentsPaymentReceivedInput;
};

export type SubscriptiontreasuryPaymentsSubscriptionChangedArgs = {
  input: TreasuryPaymentsSubscriptionChangedInput;
};

export type SummonerRank = {
  __typename?: 'SummonerRank';
  division: Scalars['String'];
  tier: Scalars['String'];
};

/** !!! DEPRECATED !!! */
export type SummonerRankFilter = {
  tier: Scalars['String'];
};

export type TftBadge = {
  __typename?: 'TftBadge';
  metadata: Array<TftBadgeMetadata>;
  slug: Scalars['String'];
};

export type TftBadgeMetadata = {
  __typename?: 'TftBadgeMetadata';
  key?: Maybe<BadgeVar>;
  value?: Maybe<Scalars['String']>;
};

export type TftBadgesFilter = {
  patches?: InputMaybe<Array<Scalars['String']>>;
  queues?: InputMaybe<Array<TftQueue>>;
};

/**
 * Champion unit.
 * Optionally could have summon units.
 *
 * Squidex is used as a source of truth.
 * https://squidex.mobalytics.gg/app/tft-v1/content/champions-v1
 */
export type TftChampion = {
  __typename?: 'TftChampion';
  slug: Scalars['String'];
  summons?: Maybe<Array<TftSummon>>;
  synergies?: Maybe<Array<Scalars['String']>>;
};

/** Champion on position could have items on it. */
export type TftChampionWithItems = {
  __typename?: 'TftChampionWithItems';
  champion?: Maybe<TftChampion>;
  hasAnomaly?: Maybe<Scalars['Boolean']>;
  headlinerSynergy?: Maybe<Scalars['String']>;
  items?: Maybe<Array<TftItem>>;
  level?: Maybe<Scalars['Int']>;
};

/** Champion with optional items. */
export type TftChampionWithItemsInput = {
  hasAnomaly?: InputMaybe<Scalars['Boolean']>;
  headlinerSynergy?: InputMaybe<Scalars['String']>;
  items?: InputMaybe<Array<Scalars['String']>>;
  level: Scalars['Int'];
  slug: Scalars['String'];
};

/** Chosen synergy for a composition. */
export type TftChosenSynergyInput = {
  slug: Scalars['String'];
};

/**
 * User created composition.
 * Has all required metainformation.
 * TFT-specific information is stored in formation property.
 */
export type TftComposition = {
  __typename?: 'TftComposition';
  createdAt?: Maybe<Scalars['TftDateTime']>;
  description?: Maybe<Scalars['String']>;
  difficulty: Scalars['String'];
  formation?: Maybe<TftFormation>;
  guide?: Maybe<TftCompositionGuide>;
  hasGuide?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  isExported?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  patch?: Maybe<Scalars['String']>;
  playstyle?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['Int']>;
  set?: Maybe<Scalars['TftSet']>;
  slug: Scalars['String'];
  state?: Maybe<TftCompositionState>;
  tier?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['TftDateTime']>;
  user?: Maybe<TftUser>;
  userVote?: Maybe<TftCompositionVote>;
  video?: Maybe<TftCompositionGuideVideo>;
};

export type TftCompositionChangedInput = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  set?: InputMaybe<Array<Scalars['TftSet']>>;
  userId?: InputMaybe<Array<Scalars['ID']>>;
};

export type TftCompositionChangedPayload = {
  __typename?: 'TftCompositionChangedPayload';
  composition?: Maybe<TftComposition>;
  compositionId?: Maybe<Scalars['ID']>;
  event?: Maybe<TftCompositionEvent>;
  myCompositions?: Maybe<TftMyCompositionsInfiniteScroll>;
  publicCompositions?: Maybe<TftPublicCompositionsInfiniteScroll>;
};

export type TftCompositionChangedPayloadmyCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftMyCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftMyCompositionsSort>>;
};

export type TftCompositionChangedPayloadpublicCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftPublicCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftPublicCompositionsSort>>;
};

/** Deprecated */
export enum TftCompositionEvent {
  COMPOSITION_CREATED = 'COMPOSITION_CREATED',
  COMPOSITION_DELETED = 'COMPOSITION_DELETED',
  COMPOSITION_PUBLISHED = 'COMPOSITION_PUBLISHED',
  COMPOSITION_UNPUBLISHED = 'COMPOSITION_UNPUBLISHED',
  COMPOSITION_UPDATED = 'COMPOSITION_UPDATED',
}

/**
 * Formation to create new composition.
 * Must include positions list.
 * May include core champions and chosen synergy.
 */
export type TftCompositionFormationInput = {
  coreChampions?: InputMaybe<Array<Scalars['TftCoordinates']>>;
  positions: Array<TftCompositionPositionInput>;
};

export type TftCompositionGuide = {
  __typename?: 'TftCompositionGuide';
  alternatives?: Maybe<TftCompositionGuideAlternatives>;
  augments?: Maybe<TftCompositionGuideAugments>;
  carousel?: Maybe<TftCompositionGuideCarousel>;
  composition?: Maybe<TftComposition>;
  compositionId: Scalars['ID'];
  early?: Maybe<TftCompositionGuideGamePlan>;
  legend?: Maybe<TftCompositionLegend>;
  mid?: Maybe<TftCompositionGuideGamePlan>;
  tips?: Maybe<Scalars['String']>;
  video?: Maybe<TftCompositionGuideVideo>;
  whenToMake?: Maybe<Scalars['String']>;
};

export type TftCompositionGuideAlternativeChampWithItems = {
  __typename?: 'TftCompositionGuideAlternativeChampWithItems';
  champion?: Maybe<TftChampion>;
  items?: Maybe<Array<TftItem>>;
};

export type TftCompositionGuideAlternativeChampions = {
  __typename?: 'TftCompositionGuideAlternativeChampions';
  alternative?: Maybe<Array<TftChampion>>;
  base?: Maybe<Array<TftChampion>>;
};

export type TftCompositionGuideAlternativeItems = {
  __typename?: 'TftCompositionGuideAlternativeItems';
  champions?: Maybe<Array<TftCompositionGuideAlternativeChampWithItems>>;
};

export type TftCompositionGuideAlternatives = {
  __typename?: 'TftCompositionGuideAlternatives';
  champions?: Maybe<Array<TftCompositionGuideAlternativeChampions>>;
  items?: Maybe<Array<TftChampionWithItems>>;
};

export type TftCompositionGuideAugments = {
  __typename?: 'TftCompositionGuideAugments';
  augments?: Maybe<Array<TftHextechAugment>>;
};

export type TftCompositionGuideCarousel = {
  __typename?: 'TftCompositionGuideCarousel';
  items?: Maybe<Array<TftItem>>;
};

export type TftCompositionGuideGamePlan = {
  __typename?: 'TftCompositionGuideGamePlan';
  advice?: Maybe<Scalars['String']>;
  formation?: Maybe<TftFormation>;
};

export enum TftCompositionGuideGamePlanType {
  EARLY = 'EARLY',
  MID = 'MID',
}

export type TftCompositionGuideVideo = {
  __typename?: 'TftCompositionGuideVideo';
  isPremium: Scalars['Boolean'];
  previewURL?: Maybe<Scalars['String']>;
  videoURL?: Maybe<Scalars['String']>;
};

export type TftCompositionLegend = {
  __typename?: 'TftCompositionLegend';
  slug?: Maybe<Scalars['String']>;
};

export type TftCompositionPayload = {
  __typename?: 'TftCompositionPayload';
  composition?: Maybe<TftComposition>;
  errors?: Maybe<Array<TftError>>;
};

/** Position links either a champion or a summon to coordinates. */
export type TftCompositionPositionInput = {
  champion?: InputMaybe<TftChampionWithItemsInput>;
  coordinates: Scalars['TftCoordinates'];
  summon?: InputMaybe<TftSummonInput>;
};

export enum TftCompositionState {
  TFT_COMPOSITION_STATE_META = 'TFT_COMPOSITION_STATE_META',
  TFT_COMPOSITION_STATE_PRIVATE = 'TFT_COMPOSITION_STATE_PRIVATE',
  TFT_COMPOSITION_STATE_PUBLIC = 'TFT_COMPOSITION_STATE_PUBLIC',
}

export enum TftCompositionVote {
  TFT_COMPOSITION_VOTE_DOWN = 'TFT_COMPOSITION_VOTE_DOWN',
  TFT_COMPOSITION_VOTE_NONE = 'TFT_COMPOSITION_VOTE_NONE',
  TFT_COMPOSITION_VOTE_UP = 'TFT_COMPOSITION_VOTE_UP',
}

export type TftCreateCompositionGuideInput = {
  compositionId: Scalars['ID'];
  tips?: InputMaybe<Scalars['String']>;
  whenToMake: Scalars['String'];
};

export type TftCreateCompositionGuidePayload = {
  __typename?: 'TftCreateCompositionGuidePayload';
  errors?: Maybe<Array<Maybe<TftError>>>;
  guide?: Maybe<TftCompositionGuide>;
  guideId?: Maybe<Scalars['ID']>;
};

/**
 * Input to create new composition.
 * Must include name, set and unit formation.
 */
export type TftCreateCompositionInput = {
  description?: InputMaybe<Scalars['String']>;
  difficulty: Scalars['String'];
  formation: TftCompositionFormationInput;
  name: Scalars['String'];
  /**
   * Patch is optional only for backward compatibility.
   * It is recommended to set patch proprety.
   * Set to the current patch by default.
   */
  patch?: InputMaybe<Scalars['String']>;
  playstyle?: InputMaybe<Scalars['String']>;
  set: Scalars['TftSet'];
  tier?: InputMaybe<Scalars['String']>;
};

export type TftCreateCompositionPayload = {
  __typename?: 'TftCreateCompositionPayload';
  errors?: Maybe<Array<Maybe<TftError>>>;
  record?: Maybe<TftComposition>;
  recordId?: Maybe<Scalars['ID']>;
};

export type TftDeleteCompositionsInput = {
  id?: InputMaybe<Array<Scalars['ID']>>;
};

export type TftDeleteCompositionsPayload = {
  __typename?: 'TftDeleteCompositionsPayload';
  affected?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<TftError>>;
  myCompositions?: Maybe<TftMyCompositionsInfiniteScroll>;
  publicCompositions?: Maybe<TftPublicCompositionsInfiniteScroll>;
};

export type TftDeleteCompositionsPayloadmyCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftMyCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftMyCompositionsSort>>;
};

export type TftDeleteCompositionsPayloadpublicCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftPublicCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftPublicCompositionsSort>>;
};

/** All errors must have a message property. */
export type TftError = {
  message?: Maybe<Scalars['String']>;
};

export type TftExportCompositionsInput = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  isExported: Scalars['Boolean'];
};

export type TftExportCompositionsPayload = {
  __typename?: 'TftExportCompositionsPayload';
  affected?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<TftError>>;
  myCompositions?: Maybe<TftMyCompositionsInfiniteScroll>;
  publicCompositions?: Maybe<TftPublicCompositionsInfiniteScroll>;
};

export type TftExportCompositionsPayloadmyCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftMyCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftMyCompositionsSort>>;
};

export type TftExportCompositionsPayloadpublicCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftPublicCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftPublicCompositionsSort>>;
};

/** TFT-specifit composition information. */
export type TftFormation = {
  __typename?: 'TftFormation';
  /** @deprecated deprecated tft mechanic */
  chosen?: Maybe<TftSynergy>;
  coreChampions?: Maybe<Array<TftPosition>>;
  positions?: Maybe<Array<TftPosition>>;
};

/** Generic error */
export type TftGenericError = TftError & {
  __typename?: 'TftGenericError';
  message?: Maybe<Scalars['String']>;
};

export type TftGetCompositionGuideFilter = {
  compositionId: Scalars['ID'];
};

export type TftGetCompositionGuidePayload = {
  __typename?: 'TftGetCompositionGuidePayload';
  errors?: Maybe<Array<Maybe<TftError>>>;
  guide?: Maybe<TftCompositionGuide>;
};

/**
 * Hextech augment that could be chosen for a composition.
 *
 * Squidex is used as a source of truth.
 * https://squidex-us.mobalytics.gg/app/tft-v1/content/hextech-augments
 */
export type TftHextechAugment = {
  __typename?: 'TftHextechAugment';
  slug: Scalars['String'];
};

export type TftInfiniteScrollPageInfo = {
  __typename?: 'TftInfiniteScrollPageInfo';
  cursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
  totalItems?: Maybe<Scalars['Int']>;
};

/** Invalid parameter */
export type TftInvalidParameterError = TftError & {
  __typename?: 'TftInvalidParameterError';
  message?: Maybe<Scalars['String']>;
  parameter?: Maybe<Scalars['String']>;
};

/**
 * Item that could be placed on champion.
 *
 * Squidex is used as a source of truth.
 * https://squidex.mobalytics.gg/app/tft-v1/content/game-items-v1
 */
export type TftItem = {
  __typename?: 'TftItem';
  slug: Scalars['String'];
};

export type TftLeaderboardFilter = {
  division?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Region>;
  tier?: InputMaybe<Scalars['String']>;
};

export type TftLeaderboardPagination = {
  __typename?: 'TftLeaderboardPagination';
  items?: Maybe<Array<TftLeaderboardStanding>>;
  pageInfo?: Maybe<TftPaginationInfo>;
  updatedAt?: Maybe<Scalars['TftDateTime']>;
};

export type TftLeaderboardStanding = {
  __typename?: 'TftLeaderboardStanding';
  division?: Maybe<Scalars['String']>;
  divisionPosition?: Maybe<Scalars['Int']>;
  globalPosition?: Maybe<Scalars['Int']>;
  lp?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  profile?: Maybe<TftProfile>;
  region: Region;
  regionPosition?: Maybe<Scalars['Int']>;
  summonerId: Scalars['String'];
  tier?: Maybe<Scalars['String']>;
  tierPosition?: Maybe<Scalars['Int']>;
};

export type TftMakeMetaCompositionsInput = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  isMeta: Scalars['Boolean'];
};

export type TftMakeMetaCompositionsPayload = {
  __typename?: 'TftMakeMetaCompositionsPayload';
  affected?: Maybe<Scalars['Int']>;
  compositions?: Maybe<Array<TftComposition>>;
  errors?: Maybe<Array<TftError>>;
  metaCompositions?: Maybe<TftMetaCompositionsInfiniteScroll>;
  myCompositions?: Maybe<TftMyCompositionsInfiniteScroll>;
};

export type TftMakeMetaCompositionsPayloadcompositionsArgs = {
  id?: InputMaybe<Array<Scalars['ID']>>;
};

export type TftMakeMetaCompositionsPayloadmetaCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftMetaCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftMetaCompositionsSort>>;
};

export type TftMakeMetaCompositionsPayloadmyCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftMyCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftMyCompositionsSort>>;
};

export type TftMatch = {
  __typename?: 'TftMatch';
  date?: Maybe<Scalars['TftDateTime']>;
  durationSeconds?: Maybe<Scalars['Int']>;
  /** @deprecated Use `matchId` instead */
  id?: Maybe<Scalars['Int']>;
  matchId?: Maybe<Scalars['ID']>;
  participants?: Maybe<Array<TftMatchParticipant>>;
  patch?: Maybe<Scalars['String']>;
  queue?: Maybe<TftQueue>;
  region?: Maybe<Region>;
  set?: Maybe<Scalars['TftSet']>;
};

/** Filter to get summoner match. */
export type TftMatchFilter = {
  matchId: Scalars['ID'];
  region: Region;
};

export type TftMatchParticipant = {
  __typename?: 'TftMatchParticipant';
  archetypeId?: Maybe<Scalars['Int']>;
  augments?: Maybe<Array<TftMatchRegistryAugment>>;
  damageDealt?: Maybe<Scalars['Int']>;
  placement?: Maybe<Scalars['Int']>;
  profile?: Maybe<TftProfile>;
  puuid: Scalars['Puuid'];
  region?: Maybe<Region>;
  set?: Maybe<Scalars['TftSet']>;
  timeEliminatedSeconds?: Maybe<Scalars['Int']>;
  traits?: Maybe<Array<TftMatchRegistryTrait>>;
  units?: Maybe<Array<TftMatchRegistryUnit>>;
};

export type TftMatchPlacement = {
  __typename?: 'TftMatchPlacement';
  placement?: Maybe<Scalars['Int']>;
  profile?: Maybe<TftProfile>;
  puuid: Scalars['Puuid'];
  region?: Maybe<Region>;
  set?: Maybe<Scalars['TftSet']>;
};

export type TftMatchRegistry = {
  __typename?: 'TftMatchRegistry';
  archetypeId?: Maybe<Scalars['Int']>;
  augments?: Maybe<Array<TftMatchRegistryAugment>>;
  damageDealt?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['TftDateTime']>;
  durationSeconds?: Maybe<Scalars['Int']>;
  /** @deprecated Use `matchId` instead */
  id?: Maybe<Scalars['Int']>;
  lp?: Maybe<TftMatchRegistryLP>;
  matchId?: Maybe<Scalars['ID']>;
  patch?: Maybe<Scalars['String']>;
  placement?: Maybe<Scalars['Int']>;
  placements?: Maybe<Array<TftMatchPlacement>>;
  queue?: Maybe<TftQueue>;
  region?: Maybe<Region>;
  timeEliminatedSeconds?: Maybe<Scalars['Int']>;
  traits?: Maybe<Array<TftMatchRegistryTrait>>;
  units?: Maybe<Array<TftMatchRegistryUnit>>;
};

export type TftMatchRegistryAugment = {
  __typename?: 'TftMatchRegistryAugment';
  slug?: Maybe<Scalars['String']>;
};

export type TftMatchRegistryLP = {
  __typename?: 'TftMatchRegistryLP';
  after?: Maybe<TftMatchRegistryLPRecord>;
  before?: Maybe<TftMatchRegistryLPRecord>;
  lpDiff?: Maybe<Scalars['Int']>;
  /** null - no promo, true - promo up, false - promo down */
  promoted?: Maybe<Scalars['Boolean']>;
};

export type TftMatchRegistryLPRecord = {
  __typename?: 'TftMatchRegistryLPRecord';
  lp: Scalars['Int'];
  rank: SummonerRank;
};

export type TftMatchRegistryTrait = {
  __typename?: 'TftMatchRegistryTrait';
  numUnits?: Maybe<Scalars['Int']>;
  slug?: Maybe<Scalars['String']>;
  style?: Maybe<Scalars['Int']>;
  tierCurrent?: Maybe<Scalars['Int']>;
  tierTotal?: Maybe<Scalars['Int']>;
};

export type TftMatchRegistryUnit = {
  __typename?: 'TftMatchRegistryUnit';
  /** @deprecated deprecated tft mechanic */
  chosen?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Scalars['String']>>;
  slug?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['Int']>;
};

export type TftMatchesInfiniteScroll = {
  __typename?: 'TftMatchesInfiniteScroll';
  items?: Maybe<Array<TftMatchRegistry>>;
  pageInfo?: Maybe<TftInfiniteScrollPageInfo>;
};

export type TftMetaCompositionsFilter = {
  carryChampion?: InputMaybe<Array<Scalars['String']>>;
  champion?: InputMaybe<Array<Scalars['String']>>;
  coreChampion?: InputMaybe<Array<Scalars['String']>>;
  item?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['TftSet']>>;
  synergy?: InputMaybe<Array<Scalars['String']>>;
  tier?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TftMetaCompositionsInfiniteScroll = {
  __typename?: 'TftMetaCompositionsInfiniteScroll';
  errors?: Maybe<Array<TftError>>;
  items?: Maybe<Array<TftComposition>>;
  pageInfo?: Maybe<TftInfiniteScrollPageInfo>;
};

/** Available compositions sorting options. */
export enum TftMetaCompositionsSort {
  /** Sort by createdAt ascending. */
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  /** Sort by createdAt descending. */
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  /** Sort by tier ascending. */
  TIER_ASC = 'TIER_ASC',
  /** Sort by tier descending. */
  TIER_DESC = 'TIER_DESC',
  /** Sort by updatedAt ascending. */
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  /** Sort by updatedAt descending. */
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
}

export type TftMetrics = {
  __typename?: 'TftMetrics';
  /** !!! DEPRECATED !!! */
  augments?: Maybe<Array<TftMetricsAugment>>;
  augmentsV2?: Maybe<Array<TftMetricsAugmentData>>;
  champions?: Maybe<Array<TftMetricsChampionData>>;
  /** This node contains all queries related to metrics (statistics) of compositions */
  compositions: TftMetricsCompositions;
  items?: Maybe<Array<TftMetricsItemData>>;
  traits?: Maybe<Array<TftMetricsTraitData>>;
};

export type TftMetricsaugmentsArgs = {
  filter?: InputMaybe<TftMetricsAugmentsFilter>;
};

export type TftMetricsaugmentsV2Args = {
  filter?: InputMaybe<TftMetricsFilter>;
};

export type TftMetricschampionsArgs = {
  filter?: InputMaybe<TftMetricsFilter>;
};

export type TftMetricsitemsArgs = {
  filter?: InputMaybe<TftMetricsFilter>;
};

export type TftMetricstraitsArgs = {
  filter?: InputMaybe<TftMetricsFilter>;
};

/** !!! DEPRECATED !!! */
export type TftMetricsAugment = {
  __typename?: 'TftMetricsAugment';
  avgPlacement: Scalars['Float'];
  pickRate: Scalars['Float'];
  slug: Scalars['String'];
  tier: AugmentTier;
  winRate: Scalars['Float'];
};

export type TftMetricsAugmentData = {
  __typename?: 'TftMetricsAugmentData';
  avgPlacement: Scalars['Float'];
  matches: Scalars['Int'];
  pickRate: Scalars['Float'];
  slug: Scalars['String'];
  tier: MetricTier;
  winRate: Scalars['Float'];
};

/** !!! DEPRECATED !!! */
export type TftMetricsAugmentsFilter = {
  ranks?: InputMaybe<Array<SummonerRankFilter>>;
  set?: InputMaybe<Scalars['TftSet']>;
};

export type TftMetricsChampionData = {
  __typename?: 'TftMetricsChampionData';
  avgPlacement: Scalars['Float'];
  matches: Scalars['Int'];
  pickRate: Scalars['Float'];
  slug: Scalars['String'];
  tier: MetricTier;
  winRate: Scalars['Float'];
};

export type TftMetricsCompositionData = {
  __typename?: 'TftMetricsCompositionData';
  avgPlace: Scalars['Float'];
  details: TftMetricsCompositionDetailsPayload;
  formation: TftMetricsCompositionFormation;
  id: Scalars['ID'];
  pickRate: Scalars['Float'];
  slug: Scalars['String'];
  tier: MetricTier;
  top2Traits: TftMetricsCompositionTop2Traits;
  top4Rate: Scalars['Float'];
  traits: Array<TftMatchRegistryTrait>;
  winRate: Scalars['Float'];
};

export type TftMetricsCompositionDetails = {
  __typename?: 'TftMetricsCompositionDetails';
  id: Scalars['ID'];
  variationLvl8: Array<TftMetricsCompositionVariation>;
  variationLvl9: Array<TftMetricsCompositionVariation>;
  variationLvl10: Array<TftMetricsCompositionVariation>;
};

export type TftMetricsCompositionDetailsPayload = {
  __typename?: 'TftMetricsCompositionDetailsPayload';
  data?: Maybe<TftMetricsCompositionDetails>;
  errors?: Maybe<Array<TftError>>;
};

export type TftMetricsCompositionFormation = {
  __typename?: 'TftMetricsCompositionFormation';
  positions: Array<TftPosition>;
};

export type TftMetricsCompositionTop2Traits = {
  __typename?: 'TftMetricsCompositionTop2Traits';
  trait1Slug: Scalars['String'];
  trait2Slug: Scalars['String'];
};

export type TftMetricsCompositionVariation = {
  __typename?: 'TftMetricsCompositionVariation';
  avgPlace: Scalars['Float'];
  formation: TftMetricsCompositionFormation;
  traits: Array<TftMatchRegistryTrait>;
};

export type TftMetricsCompositions = {
  __typename?: 'TftMetricsCompositions';
  /** Returns a particular composition with metrics by unique slug */
  get: TftMetricsCompositionsPayload;
  /** Returns a list of available compositions with metrics */
  list: TftMetricsListCompositionsPayload;
  /** Returns a paginated list of available compositions with metrics */
  listPaginated: TftMetricsPaginatedListCompositionsPayload;
};

export type TftMetricsCompositionsgetArgs = {
  filter: TftMetricsGetCompositionsFilter;
};

export type TftMetricsCompositionslistArgs = {
  filter: TftMetricsListCompositionsFilter;
};

export type TftMetricsCompositionslistPaginatedArgs = {
  filter: TftMetricsPaginatedListCompositionsFilter;
  page: CursorPage;
};

export type TftMetricsCompositionsPayload = {
  __typename?: 'TftMetricsCompositionsPayload';
  data?: Maybe<TftMetricsCompositionData>;
  errors?: Maybe<Array<TftError>>;
};

export type TftMetricsFilter = {
  patch?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<TftRegion>;
  set?: InputMaybe<Scalars['TftSet']>;
  tiers?: InputMaybe<Array<TftTier>>;
};

export type TftMetricsGetCompositionsFilter = {
  set: Scalars['TftSet'];
  slug: Scalars['String'];
};

export type TftMetricsItemData = {
  __typename?: 'TftMetricsItemData';
  avgPlacement: Scalars['Float'];
  matches: Scalars['Int'];
  pickRate: Scalars['Float'];
  slug: Scalars['String'];
  tier: MetricTier;
  winRate: Scalars['Float'];
};

export type TftMetricsListCompositionsFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  set: Scalars['TftSet'];
  tiers: Array<TftTier>;
};

export type TftMetricsListCompositionsPayload = {
  __typename?: 'TftMetricsListCompositionsPayload';
  data?: Maybe<Array<TftMetricsCompositionData>>;
  errors?: Maybe<Array<TftError>>;
};

export type TftMetricsPaginatedListCompositions = {
  __typename?: 'TftMetricsPaginatedListCompositions';
  list: Array<TftMetricsCompositionData>;
  paginationInfo: CursorPaginationInfo;
};

export type TftMetricsPaginatedListCompositionsFilter = {
  /** Filter out compositions that don't contain the specified champion */
  champion?: InputMaybe<Scalars['String']>;
  set: Scalars['TftSet'];
  /** Filter out compositions that don't contain the specified synergy */
  synergy?: InputMaybe<Scalars['String']>;
  tiers: Array<TftTier>;
};

export type TftMetricsPaginatedListCompositionsPayload = {
  __typename?: 'TftMetricsPaginatedListCompositionsPayload';
  data?: Maybe<TftMetricsPaginatedListCompositions>;
  errors?: Maybe<Array<TftError>>;
};

export type TftMetricsTraitData = {
  __typename?: 'TftMetricsTraitData';
  avgPlacement: Scalars['Float'];
  matches: Scalars['Int'];
  pickRate: Scalars['Float'];
  slug: Scalars['String'];
  tier: MetricTier;
  winRate: Scalars['Float'];
};

export type TftMutation = {
  __typename?: 'TftMutation';
  /** Create new TFT composition */
  createComposition: TftCreateCompositionPayload;
  /** Delete existing TFT composition */
  deleteCompositions: TftDeleteCompositionsPayload;
  /** Export compositions to overlay */
  exportCompositions: TftExportCompositionsPayload;
  /** Guides */
  guides?: Maybe<TftMutationCompositionGuides>;
  /** Make compositions meta */
  makeMetaCompositions: TftMakeMetaCompositionsPayload;
  /** Publish compositions */
  publishCompositions: TftPublishCompositionsPayload;
  refreshProfiles: TftRefreshProfilesPayload;
  /** Change existing TFT composition */
  updateComposition: TftUpdateCompositionPayload;
  /** Vote */
  voteForComposition: TftVoteForCompositionPayload;
};

export type TftMutationcreateCompositionArgs = {
  input: TftCreateCompositionInput;
};

export type TftMutationdeleteCompositionsArgs = {
  input: TftDeleteCompositionsInput;
};

export type TftMutationexportCompositionsArgs = {
  input: TftExportCompositionsInput;
};

export type TftMutationmakeMetaCompositionsArgs = {
  input: TftMakeMetaCompositionsInput;
};

export type TftMutationpublishCompositionsArgs = {
  input: TftPublishCompositionsInput;
};

export type TftMutationrefreshProfilesArgs = {
  input: TftRefreshProfilesInput;
};

export type TftMutationupdateCompositionArgs = {
  input: TftUpdateCompositionInput;
};

export type TftMutationvoteForCompositionArgs = {
  input: TftVoteForCompositionInput;
};

export type TftMutationCompositionGuides = {
  __typename?: 'TftMutationCompositionGuides';
  /** Create a guide for a composition */
  create: TftCreateCompositionGuidePayload;
  /** Delete guide video */
  deleteVideo: TftUpdateCompositionGuidePayload;
  /** Update guide for a composition */
  update: TftUpdateCompositionGuidePayload;
  /** Update guide alternative champions */
  updateAlternativeChampions: TftUpdateCompositionGuidePayload;
  /** Update guide alternative items */
  updateAlternativeItems: TftUpdateCompositionGuidePayload;
  /** Update guide carousel */
  updateAugments: TftUpdateCompositionGuidePayload;
  /** Update guide carousel */
  updateCarousel: TftUpdateCompositionGuidePayload;
  /** Update guide carousel */
  updateGamePlan: TftUpdateCompositionGuidePayload;
  updateLegend: TftUpdateCompositionGuidePayload;
  /** Update guide video */
  updateVideo: TftUpdateCompositionGuidePayload;
};

export type TftMutationCompositionGuidescreateArgs = {
  input: TftCreateCompositionGuideInput;
};

export type TftMutationCompositionGuidesdeleteVideoArgs = {
  input: TftUpdateCompositionGuideDeleteVideoInput;
};

export type TftMutationCompositionGuidesupdateArgs = {
  input: TftUpdateCompositionGuideInput;
};

export type TftMutationCompositionGuidesupdateAlternativeChampionsArgs = {
  input: TftUpdateCompositionGuideAlternativeChampionsInput;
};

export type TftMutationCompositionGuidesupdateAlternativeItemsArgs = {
  input: TftUpdateCompositionGuideAlternativeItemsInput;
};

export type TftMutationCompositionGuidesupdateAugmentsArgs = {
  input: TftUpdateCompositionGuideAugmentsInput;
};

export type TftMutationCompositionGuidesupdateCarouselArgs = {
  input: TftUpdateCompositionGuideCarouselInput;
};

export type TftMutationCompositionGuidesupdateGamePlanArgs = {
  input: TftUpdateCompositionGuideGamePlanInput;
};

export type TftMutationCompositionGuidesupdateLegendArgs = {
  input: TftUpdateCompositionGuideLegendInput;
};

export type TftMutationCompositionGuidesupdateVideoArgs = {
  input: TftUpdateCompositionGuideUpdateVideoInput;
};

/** Deprecated */
export type TftMyCompositionChangedInput = {
  id?: InputMaybe<Array<Scalars['ID']>>;
};

/** Deprecated */
export type TftMyCompositionChangedPayload = {
  __typename?: 'TftMyCompositionChangedPayload';
  event?: Maybe<TftCompositionEvent>;
  record?: Maybe<TftComposition>;
  recordId?: Maybe<Scalars['ID']>;
};

export type TftMyCompositionsFilter = {
  carryChampion?: InputMaybe<Array<Scalars['String']>>;
  champion?: InputMaybe<Array<Scalars['String']>>;
  coreChampion?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Array<Scalars['ID']>>;
  idNotEqual?: InputMaybe<Array<Scalars['ID']>>;
  isExported?: InputMaybe<Scalars['Boolean']>;
  item?: InputMaybe<Array<Scalars['String']>>;
  patch?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['TftSet']>>;
  synergy?: InputMaybe<Array<Scalars['String']>>;
  tierV2?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type TftMyCompositionsInfiniteScroll = {
  __typename?: 'TftMyCompositionsInfiniteScroll';
  errors?: Maybe<Array<TftError>>;
  items?: Maybe<Array<TftComposition>>;
  pageInfo?: Maybe<TftInfiniteScrollPageInfo>;
};

/** Available compositions sorting options. */
export enum TftMyCompositionsSort {
  /** Sort by createdAt ascending. */
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  /** Sort by createdAt descending. */
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  /** Sort by rating ascending. */
  RATING_ASC = 'RATING_ASC',
  /** Sort by rating descending. */
  RATING_DESC = 'RATING_DESC',
  /** Sort by tier ascending. */
  TIER_ASC = 'TIER_ASC',
  /** Sort by tier descending. */
  TIER_DESC = 'TIER_DESC',
  /** Sort by updatedAt ascending. */
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  /** Sort by updatedAt descending. */
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
}

/** Need premium error returned if user has exhausted non-premium quota. */
export type TftNeedPremiumError = TftError & {
  __typename?: 'TftNeedPremiumError';
  message?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

/** Not found */
export type TftNotFoundError = TftError & {
  __typename?: 'TftNotFoundError';
  message?: Maybe<Scalars['String']>;
};

export type TftPaginationInfo = {
  __typename?: 'TftPaginationInfo';
  hasNextPage?: Maybe<Scalars['Boolean']>;
  hasPreviousPage?: Maybe<Scalars['Boolean']>;
  page?: Maybe<Scalars['Int']>;
  perPage?: Maybe<Scalars['Int']>;
  totalItems?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

/** Position in composition with coordinates and a unit. */
export type TftPosition = {
  __typename?: 'TftPosition';
  champion?: Maybe<TftChampionWithItems>;
  coordinates?: Maybe<Scalars['TftCoordinates']>;
  summon?: Maybe<TftSummon>;
};

/** TFT Profile page data */
export type TftProfile = {
  __typename?: 'TftProfile';
  badges?: Maybe<Array<Maybe<TftBadge>>>;
  info?: Maybe<TftSummoner>;
  leaderboardStanding?: Maybe<TftLeaderboardStanding>;
  performance?: Maybe<Array<TftProfilePerformance>>;
  progressTrackingV3?: Maybe<TftProfileProgressTrackingV3>;
  puuid?: Maybe<Scalars['Puuid']>;
  rank?: Maybe<SummonerRank>;
  region?: Maybe<Region>;
  set?: Maybe<Scalars['TftSet']>;
  stats?: Maybe<TftProfileStats>;
};

/** TFT Profile page data */
export type TftProfilebadgesArgs = {
  filter?: InputMaybe<TftBadgesFilter>;
  limit?: InputMaybe<Scalars['Int']>;
};

/** TFT Profile page data */
export type TftProfileperformanceArgs = {
  filters?: InputMaybe<Array<TftProfilePerformanceFilter>>;
};

/** TFT Profile page data */
export type TftProfileprogressTrackingV3Args = {
  filter?: InputMaybe<TftProfileProgressTrackingFilterV3>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

/** TFT Profile page data */
export type TftProfilestatsArgs = {
  filter?: InputMaybe<TftProfileStatsFilter>;
};

export type TftProfileArchetypeStat = {
  __typename?: 'TftProfileArchetypeStat';
  averagePlace?: Maybe<Scalars['Float']>;
  averageRound?: Maybe<Scalars['Float']>;
  averageTimeSeconds?: Maybe<Scalars['Int']>;
  compositions?: Maybe<Array<TftProfileCompositionStat>>;
  formation?: Maybe<TftProfileFormation>;
  games?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  lpGained?: Maybe<Scalars['Int']>;
  top4Games?: Maybe<Scalars['Int']>;
  top4Percent?: Maybe<Scalars['Float']>;
};

export type TftProfileArchetypes = {
  __typename?: 'TftProfileArchetypes';
  items?: Maybe<Array<TftProfileArchetypeStat>>;
};

export type TftProfileAugmentStat = {
  __typename?: 'TftProfileAugmentStat';
  averagePlace?: Maybe<Scalars['Float']>;
  games?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  top4Games?: Maybe<Scalars['Int']>;
  top4Percent?: Maybe<Scalars['Float']>;
};

export type TftProfileAugments = {
  __typename?: 'TftProfileAugments';
  items?: Maybe<Array<TftProfileAugmentStat>>;
};

export type TftProfileChampionStat = {
  __typename?: 'TftProfileChampionStat';
  averagePlace?: Maybe<Scalars['Float']>;
  games?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  top4Games?: Maybe<Scalars['Int']>;
  top4Percent?: Maybe<Scalars['Float']>;
};

export type TftProfileChampions = {
  __typename?: 'TftProfileChampions';
  items?: Maybe<Array<TftProfileChampionStat>>;
};

export type TftProfileChangedInput = {
  gameName?: InputMaybe<Scalars['String']>;
  region: Region;
  /** DEPRECATED. Use gameName and tagLine instead. */
  summonerName?: InputMaybe<Scalars['String']>;
  tagLine?: InputMaybe<Scalars['String']>;
};

export type TftProfileChangedPayload = {
  __typename?: 'TftProfileChangedPayload';
  event?: Maybe<TftProfileEvent>;
  gameName?: Maybe<Scalars['String']>;
  profile?: Maybe<TftProfilePayload>;
  region: Region;
  /** DEPRECATED. Use gameName and tagLine instead. */
  summonerName?: Maybe<Scalars['String']>;
  tagLine?: Maybe<Scalars['String']>;
};

export type TftProfileChangedPayloadprofileArgs = {
  set: Scalars['TftSet'];
};

export type TftProfileChosenSynergies = {
  __typename?: 'TftProfileChosenSynergies';
  items?: Maybe<Array<TftProfileChosenSynergyStat>>;
};

export type TftProfileChosenSynergyStat = {
  __typename?: 'TftProfileChosenSynergyStat';
  averagePlace?: Maybe<Scalars['Float']>;
  games?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  top4Games?: Maybe<Scalars['Int']>;
  top4Percent?: Maybe<Scalars['Float']>;
};

export type TftProfileCompositionStat = {
  __typename?: 'TftProfileCompositionStat';
  averagePlace?: Maybe<Scalars['Float']>;
  averageRound?: Maybe<Scalars['Float']>;
  averageTimeSeconds?: Maybe<Scalars['Int']>;
  formation?: Maybe<TftProfileFormation>;
  games?: Maybe<Scalars['Int']>;
  lpGained?: Maybe<Scalars['Int']>;
  top4Games?: Maybe<Scalars['Int']>;
  top4Percent?: Maybe<Scalars['Float']>;
};

export enum TftProfileEvent {
  PROFILE_INTERNAL_ERROR = 'PROFILE_INTERNAL_ERROR',
  PROFILE_NOT_FOUND = 'PROFILE_NOT_FOUND',
  PROFILE_REFRESHED = 'PROFILE_REFRESHED',
  PROFILE_RIOT_ERROR = 'PROFILE_RIOT_ERROR',
}

/**
 * Filter to get player profile.
 * Must include region, gameName, tagLine and set.
 */
export type TftProfileFilter = {
  gameName?: InputMaybe<Scalars['String']>;
  region: Region;
  set: Scalars['TftSet'];
  /** DEPRECATED. Use gameName and tagLine instead. */
  summonerName?: InputMaybe<Scalars['String']>;
  tagLine?: InputMaybe<Scalars['String']>;
};

export type TftProfileFormation = {
  __typename?: 'TftProfileFormation';
  synergies?: Maybe<Array<TftProfileSynergy>>;
  units?: Maybe<Array<TftProfileUnit>>;
};

export type TftProfileItemStat = {
  __typename?: 'TftProfileItemStat';
  averagePlace?: Maybe<Scalars['Float']>;
  games?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  top4Games?: Maybe<Scalars['Int']>;
  top4Percent?: Maybe<Scalars['Float']>;
};

export type TftProfileItems = {
  __typename?: 'TftProfileItems';
  items?: Maybe<Array<TftProfileItemStat>>;
};

export type TftProfilePatches = {
  __typename?: 'TftProfilePatches';
  patches?: Maybe<Array<Scalars['String']>>;
};

export type TftProfilePayload = {
  __typename?: 'TftProfilePayload';
  error?: Maybe<TftError>;
  profile?: Maybe<TftProfile>;
};

export type TftProfilePerformance = {
  __typename?: 'TftProfilePerformance';
  error?: Maybe<TftError>;
  performance?: Maybe<TftProfileQueuePerformance>;
};

export type TftProfilePerformanceFilter = {
  patches?: InputMaybe<Array<Scalars['String']>>;
  queue: TftQueue;
};

export type TftProfilePlacementDistribution = {
  __typename?: 'TftProfilePlacementDistribution';
  items?: Maybe<Array<TftProfilePlacementDistributionItem>>;
};

export type TftProfilePlacementDistributionItem = {
  __typename?: 'TftProfilePlacementDistributionItem';
  games?: Maybe<Scalars['Int']>;
  place?: Maybe<Scalars['Int']>;
};

export type TftProfileProgressTracking = {
  __typename?: 'TftProfileProgressTracking';
  error?: Maybe<TftError>;
  progress?: Maybe<TftProfileQueueProgress>;
};

export type TftProfileProgressTrackingFilter = {
  dateFrom?: InputMaybe<Scalars['TftDateTime']>;
  queue: TftQueue;
};

export type TftProfileProgressTrackingFilterV3 = {
  queue: TftQueue;
};

export type TftProfileProgressTrackingV3 = {
  __typename?: 'TftProfileProgressTrackingV3';
  error?: Maybe<TftError>;
  progress?: Maybe<TftProfileQueueProgressV3>;
};

export type TftProfileQueuePerformance = {
  __typename?: 'TftProfileQueuePerformance';
  archetypes?: Maybe<TftProfileArchetypes>;
  augments?: Maybe<TftProfileAugments>;
  averagePlace?: Maybe<Scalars['Float']>;
  averageRound?: Maybe<Scalars['Float']>;
  averageTimeEliminatedSeconds?: Maybe<Scalars['Int']>;
  champions?: Maybe<TftProfileChampions>;
  /** @deprecated deprecated tft mechanic */
  chosens?: Maybe<TftProfileChosenSynergies>;
  damageDealt?: Maybe<Scalars['Int']>;
  games?: Maybe<Scalars['Int']>;
  items?: Maybe<TftProfileItems>;
  losses?: Maybe<Scalars['Int']>;
  lp?: Maybe<Scalars['Int']>;
  puuid?: Maybe<Scalars['Puuid']>;
  queue?: Maybe<TftQueue>;
  rank?: Maybe<SummonerRank>;
  region?: Maybe<Region>;
  set?: Maybe<Scalars['TftSet']>;
  stats?: Maybe<TftProfileStats>;
  synergies?: Maybe<TftProfileSynergies>;
  top1?: Maybe<Scalars['Int']>;
  top1Winrate?: Maybe<Scalars['Float']>;
  top2?: Maybe<Scalars['Int']>;
  top2Winrate?: Maybe<Scalars['Float']>;
  top4?: Maybe<Scalars['Int']>;
  top4Winrate?: Maybe<Scalars['Float']>;
  winrate?: Maybe<Scalars['Float']>;
  wins?: Maybe<Scalars['Int']>;
};

export type TftProfileQueuePerformancearchetypesArgs = {
  compositions?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileQueuePerformanceaugmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileQueuePerformancechampionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileQueuePerformancechosensArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileQueuePerformanceitemsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileQueuePerformancesynergiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileQueueProgress = {
  __typename?: 'TftProfileQueueProgress';
  entries?: Maybe<Array<TftProfileQueueProgressEntry>>;
  maxValue?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  thresholds?: Maybe<Array<TftProfileQueueProgressTreshold>>;
};

export type TftProfileQueueProgressEntry = {
  __typename?: 'TftProfileQueueProgressEntry';
  date?: Maybe<Scalars['TftDateTime']>;
  division?: Maybe<Scalars['String']>;
  lp?: Maybe<Scalars['Int']>;
  tier?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Int']>;
};

export type TftProfileQueueProgressEntryV3 = {
  __typename?: 'TftProfileQueueProgressEntryV3';
  date?: Maybe<Scalars['TftDateTime']>;
  id?: Maybe<Scalars['Int']>;
  lp?: Maybe<TftProfileQueueProgressEntryV3LP>;
  patch?: Maybe<Scalars['String']>;
  placement?: Maybe<Scalars['Int']>;
  queue?: Maybe<TftQueue>;
  traits?: Maybe<Array<TftMatchRegistryTrait>>;
  units?: Maybe<Array<TftMatchRegistryUnit>>;
};

export type TftProfileQueueProgressEntryV3LP = {
  __typename?: 'TftProfileQueueProgressEntryV3LP';
  after?: Maybe<TftProfileQueueProgressEntryV3LPRecord>;
  before?: Maybe<TftProfileQueueProgressEntryV3LPRecord>;
  lpDiff?: Maybe<Scalars['Int']>;
  /** null - no promo, true - promo up, false - promo down */
  promoted?: Maybe<Scalars['Boolean']>;
};

export type TftProfileQueueProgressEntryV3LPRecord = {
  __typename?: 'TftProfileQueueProgressEntryV3LPRecord';
  lp: Scalars['Int'];
  rank: SummonerRank;
  value: Scalars['Int'];
};

export type TftProfileQueueProgressTreshold = {
  __typename?: 'TftProfileQueueProgressTreshold';
  division?: Maybe<Scalars['String']>;
  maxLP?: Maybe<Scalars['Int']>;
  maxValue?: Maybe<Scalars['Int']>;
  minLP?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  tier?: Maybe<Scalars['String']>;
};

export type TftProfileQueueProgressV3 = {
  __typename?: 'TftProfileQueueProgressV3';
  entries?: Maybe<Array<TftProfileQueueProgressEntryV3>>;
  maxValue?: Maybe<Scalars['Int']>;
  minValue?: Maybe<Scalars['Int']>;
  pageInfo: PaginationInfo;
  thresholds?: Maybe<Array<TftProfileQueueProgressTreshold>>;
};

export type TftProfileRecentActivity = {
  __typename?: 'TftProfileRecentActivity';
  items?: Maybe<Array<TftProfileRecentActivityItem>>;
};

export type TftProfileRecentActivityItem = {
  __typename?: 'TftProfileRecentActivityItem';
  durationSeconds?: Maybe<Scalars['Int']>;
  lost?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['Timestamp']>;
  won?: Maybe<Scalars['Int']>;
};

export type TftProfileRecentActivityV2 = {
  __typename?: 'TftProfileRecentActivityV2';
  gamesPlayed?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<TftProfileRecentActivityV2Item>>;
  timePlayedSeconds?: Maybe<Scalars['Int']>;
};

export type TftProfileRecentActivityV2Item = {
  __typename?: 'TftProfileRecentActivityV2Item';
  date?: Maybe<Scalars['TftDateTime']>;
  gamesPlayed?: Maybe<Scalars['Int']>;
  losses?: Maybe<Scalars['Int']>;
  timePlayedSeconds?: Maybe<Scalars['Int']>;
  winrate?: Maybe<Scalars['Float']>;
  wins?: Maybe<Scalars['Int']>;
};

export type TftProfileRecentSummary = {
  __typename?: 'TftProfileRecentSummary';
  games?: Maybe<Scalars['Int']>;
  items?: Maybe<TftProfileItems>;
  placement?: Maybe<Array<Scalars['Int']>>;
  synergies?: Maybe<TftProfileSynergies>;
  top1?: Maybe<Scalars['Int']>;
  top1Winrate?: Maybe<Scalars['Float']>;
  top4?: Maybe<Scalars['Int']>;
  top4Winrate?: Maybe<Scalars['Float']>;
};

export type TftProfileStats = {
  __typename?: 'TftProfileStats';
  archetypes?: Maybe<TftProfileArchetypes>;
  augments?: Maybe<TftProfileAugments>;
  champions?: Maybe<TftProfileChampions>;
  items?: Maybe<TftProfileItems>;
  matches?: Maybe<TftMatchesInfiniteScroll>;
  patches?: Maybe<TftProfilePatches>;
  placementDistribution?: Maybe<TftProfilePlacementDistribution>;
  puuid?: Maybe<Scalars['Puuid']>;
  queue?: Maybe<TftQueue>;
  /**
   * Deprecated
   * @deprecated Use `recentActivityV2`.
   */
  recentActivity?: Maybe<TftProfileRecentActivity>;
  recentActivityV2?: Maybe<TftProfileRecentActivityV2>;
  recentSummary?: Maybe<TftProfileRecentSummary>;
  region?: Maybe<Region>;
  set?: Maybe<Scalars['TftSet']>;
  synergies?: Maybe<TftProfileSynergies>;
};

export type TftProfileStatsarchetypesArgs = {
  compositions?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileStatsaugmentsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileStatschampionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileStatsitemsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileStatsmatchesArgs = {
  after?: InputMaybe<Scalars['String']>;
  first?: InputMaybe<Scalars['Int']>;
};

export type TftProfileStatsrecentActivityV2Args = {
  dateFrom?: InputMaybe<Scalars['TftDateTime']>;
};

export type TftProfileStatsrecentSummaryArgs = {
  items?: InputMaybe<Scalars['Int']>;
  synergies?: InputMaybe<Scalars['Int']>;
};

export type TftProfileStatssynergiesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftProfileStatsFilter = {
  champion?: InputMaybe<Array<Scalars['String']>>;
  dateFrom?: InputMaybe<Scalars['TftDateTime']>;
  patch?: InputMaybe<Array<Scalars['String']>>;
  queue?: InputMaybe<TftQueue>;
  synergy?: InputMaybe<Array<Scalars['String']>>;
};

export type TftProfileSynergies = {
  __typename?: 'TftProfileSynergies';
  items?: Maybe<Array<TftProfileSynergyStat>>;
};

export type TftProfileSynergy = {
  __typename?: 'TftProfileSynergy';
  slug?: Maybe<Scalars['String']>;
  style?: Maybe<TftProfileSynergyStyle>;
};

export type TftProfileSynergyStat = {
  __typename?: 'TftProfileSynergyStat';
  averagePlace?: Maybe<Scalars['Float']>;
  games?: Maybe<Scalars['Int']>;
  gamesBronze?: Maybe<Scalars['Int']>;
  gamesChromatic?: Maybe<Scalars['Int']>;
  gamesGold?: Maybe<Scalars['Int']>;
  gamesOrange?: Maybe<Scalars['Int']>;
  gamesSilver?: Maybe<Scalars['Int']>;
  lpGained?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  top4Games?: Maybe<Scalars['Int']>;
  top4Percent?: Maybe<Scalars['Float']>;
};

export enum TftProfileSynergyStyle {
  BRONZE = 'BRONZE',
  CHROMATIC = 'CHROMATIC',
  GOLD = 'GOLD',
  NO_STYLE = 'NO_STYLE',
  ORANGE = 'ORANGE',
  SILVER = 'SILVER',
}

export type TftProfileUnit = {
  __typename?: 'TftProfileUnit';
  slug?: Maybe<Scalars['String']>;
};

export type TftPublicCompositionsFilter = {
  carryChampion?: InputMaybe<Array<Scalars['String']>>;
  champion?: InputMaybe<Array<Scalars['String']>>;
  coreChampion?: InputMaybe<Array<Scalars['String']>>;
  createdAtFrom?: InputMaybe<Scalars['TftDateTime']>;
  id?: InputMaybe<Array<Scalars['ID']>>;
  idNotEqual?: InputMaybe<Array<Scalars['ID']>>;
  isExported?: InputMaybe<Scalars['Boolean']>;
  item?: InputMaybe<Array<Scalars['String']>>;
  patch?: InputMaybe<Array<Scalars['String']>>;
  set?: InputMaybe<Array<Scalars['TftSet']>>;
  synergy?: InputMaybe<Array<Scalars['String']>>;
  tierV2?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  updatedAtFrom?: InputMaybe<Scalars['TftDateTime']>;
  userId?: InputMaybe<Array<Scalars['ID']>>;
};

export type TftPublicCompositionsInfiniteScroll = {
  __typename?: 'TftPublicCompositionsInfiniteScroll';
  errors?: Maybe<Array<TftError>>;
  items?: Maybe<Array<TftComposition>>;
  pageInfo?: Maybe<TftInfiniteScrollPageInfo>;
};

/** Available compositions sorting options. */
export enum TftPublicCompositionsSort {
  /** Sort by createdAt ascending. */
  CREATED_AT_ASC = 'CREATED_AT_ASC',
  /** Sort by createdAt descending. */
  CREATED_AT_DESC = 'CREATED_AT_DESC',
  /** Sort by rating ascending. */
  RATING_ASC = 'RATING_ASC',
  /** Sort by rating descending. */
  RATING_DESC = 'RATING_DESC',
  /** Sort by tier ascending. */
  TIER_ASC = 'TIER_ASC',
  /** Sort by tier descending. */
  TIER_DESC = 'TIER_DESC',
  /** Sort by updatedAt ascending. */
  UPDATED_AT_ASC = 'UPDATED_AT_ASC',
  /** Sort by updatedAt descending. */
  UPDATED_AT_DESC = 'UPDATED_AT_DESC',
}

export type TftPublishCompositionsInput = {
  id?: InputMaybe<Array<Scalars['ID']>>;
  isPublished: Scalars['Boolean'];
};

export type TftPublishCompositionsPayload = {
  __typename?: 'TftPublishCompositionsPayload';
  affected?: Maybe<Scalars['Int']>;
  errors?: Maybe<Array<TftError>>;
  myCompositions?: Maybe<TftMyCompositionsInfiniteScroll>;
  publicCompositions?: Maybe<TftPublicCompositionsInfiniteScroll>;
};

export type TftPublishCompositionsPayloadmyCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftMyCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftMyCompositionsSort>>;
};

export type TftPublishCompositionsPayloadpublicCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftPublicCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftPublicCompositionsSort>>;
};

export type TftQuery = {
  __typename?: 'TftQuery';
  /** Get composition by id. */
  composition: TftCompositionPayload;
  /** Get composition by slug. */
  compositionBySlug: TftCompositionPayload;
  /** Guides */
  guides?: Maybe<TftQueryCompositionGuides>;
  leaderboard?: Maybe<TftLeaderboardPagination>;
  /** @deprecated Use `matchV2`. */
  match?: Maybe<TftMatch>;
  matchV2?: Maybe<TftMatch>;
  /**
   * Get meta compositions.
   * Payload is structured for infinite scroll.
   */
  metaCompositions?: Maybe<TftMetaCompositionsInfiniteScroll>;
  /** Metrics */
  metrics?: Maybe<TftMetrics>;
  /**
   * Get TFT user-created compositions.
   * Payload is structured for infinite scroll.
   */
  myCompositions?: Maybe<TftMyCompositionsInfiniteScroll>;
  profile?: Maybe<Array<TftProfilePayload>>;
  /**
   * Get public compositions.
   * Payload is structured for infinite scroll.
   */
  publicCompositions?: Maybe<TftPublicCompositionsInfiniteScroll>;
  /** Get recommended compositions. */
  recommendedCompositions?: Maybe<TftRecommendedCompositionsInfiniteScroll>;
  search: TftSearchPayload;
};

export type TftQuerycompositionArgs = {
  id: Scalars['ID'];
};

export type TftQuerycompositionBySlugArgs = {
  slug: Scalars['String'];
};

export type TftQueryleaderboardArgs = {
  filter?: InputMaybe<TftLeaderboardFilter>;
  page?: InputMaybe<Scalars['Int']>;
  perPage?: InputMaybe<Scalars['Int']>;
};

export type TftQuerymatchArgs = {
  id: Scalars['Int'];
  region: Region;
};

export type TftQuerymatchV2Args = {
  filter: TftMatchFilter;
};

export type TftQuerymetaCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftMetaCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftMetaCompositionsSort>>;
};

export type TftQuerymyCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftMyCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftMyCompositionsSort>>;
};

export type TftQueryprofileArgs = {
  filter?: InputMaybe<Array<TftProfileFilter>>;
};

export type TftQuerypublicCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftPublicCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftPublicCompositionsSort>>;
};

export type TftQueryrecommendedCompositionsArgs = {
  filter: TftRecommendedCompositionsFilter;
};

export type TftQuerysearchArgs = {
  input: TftSearchInput;
};

export type TftQueryCompositionGuides = {
  __typename?: 'TftQueryCompositionGuides';
  /** Get guide for a composition */
  get?: Maybe<TftGetCompositionGuidePayload>;
};

export type TftQueryCompositionGuidesgetArgs = {
  filter: TftGetCompositionGuideFilter;
};

/**
 * TFT queue (aka game type)
 * http://static.developer.riotgames.com/docs/lol/queues.json
 */
export enum TftQueue {
  DOUBLE_UP = 'DOUBLE_UP',
  HYPER_ROLL = 'HYPER_ROLL',
  NORMAL = 'NORMAL',
  RANKED = 'RANKED',
  SET_REVIVAL = 'SET_REVIVAL',
  SOUL_BRAWL = 'SOUL_BRAWL',
}

export enum TftRecommendedCompositionGameStage {
  EARLY = 'EARLY',
  LATE = 'LATE',
  MID = 'MID',
}

export type TftRecommendedCompositionsFilter = {
  augment?: InputMaybe<Array<Scalars['String']>>;
  champion?: InputMaybe<Array<Scalars['String']>>;
  /** Defines the game stage for calculating recommendations. Available only for recommendations based on champions. */
  gameStage?: InputMaybe<TftRecommendedCompositionGameStage>;
  item?: InputMaybe<Array<Scalars['String']>>;
  patch?: InputMaybe<Array<Scalars['String']>>;
  set: Scalars['TftSet'];
};

export type TftRecommendedCompositionsInfiniteScroll = {
  __typename?: 'TftRecommendedCompositionsInfiniteScroll';
  errors?: Maybe<Array<TftError>>;
  items?: Maybe<Array<TftComposition>>;
};

export type TftRefreshProfileInput = {
  gameName?: InputMaybe<Scalars['String']>;
  region: Region;
  /** DEPRECATED. Use gameName and tagLine instead. */
  summonerName?: InputMaybe<Scalars['String']>;
  tagLine?: InputMaybe<Scalars['String']>;
};

export type TftRefreshProfilesInput = {
  inputs?: InputMaybe<Array<TftRefreshProfileInput>>;
};

export type TftRefreshProfilesPayload = {
  __typename?: 'TftRefreshProfilesPayload';
  errors?: Maybe<Array<Maybe<TftError>>>;
};

export enum TftRegion {
  ALL = 'ALL',
  BR = 'BR',
  EUNE = 'EUNE',
  EUW = 'EUW',
  JP = 'JP',
  KR = 'KR',
  LAN = 'LAN',
  LAS = 'LAS',
  ME = 'ME',
  NA = 'NA',
  OCE = 'OCE',
  PBE = 'PBE',
  PH = 'PH',
  RU = 'RU',
  SG = 'SG',
  TH = 'TH',
  TR = 'TR',
  TW = 'TW',
  /** @deprecated(reason: "Use `Region` instead") */
  UNKNOWN = 'UNKNOWN',
  VN = 'VN',
}

export type TftSearchInput = {
  region: Region;
  text: Scalars['String'];
};

export type TftSearchPayload = {
  __typename?: 'TftSearchPayload';
  profiles?: Maybe<Array<TftSearchProfile>>;
  region?: Maybe<Region>;
  text?: Maybe<Scalars['String']>;
};

export type TftSearchPayloadprofilesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
};

export type TftSearchProfile = {
  __typename?: 'TftSearchProfile';
  profile?: Maybe<TftProfile>;
  puuid?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
};

export type TftSearchProfileprofileArgs = {
  set?: InputMaybe<Scalars['TftSet']>;
};

export type TftSubscription = {
  __typename?: 'TftSubscription';
  /**
   * Deprecated. Notify when new TFT composition has changed.
   * @deprecated Use `compositionChanged`.
   */
  myCompositionChanged?: Maybe<TftMyCompositionChangedPayload>;
};

export type TftSubscriptionmyCompositionChangedArgs = {
  input: TftMyCompositionChangedInput;
};

/**
 * Summoned unit.
 * Always is linked to champion that summons it.
 * Could not be placed in composition without linked champion.
 *
 * Squidex is used as a source of truth.
 * https://squidex.mobalytics.gg/app/tft-v1/content/champions-summoned-units-v1
 */
export type TftSummon = {
  __typename?: 'TftSummon';
  champion?: Maybe<TftChampion>;
  items?: Maybe<Array<TftItem>>;
  slug: Scalars['String'];
};

/** Summoned unit by another champion in a composition. */
export type TftSummonInput = {
  items?: InputMaybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
};

export type TftSummoner = {
  __typename?: 'TftSummoner';
  accountId?: Maybe<Scalars['String']>;
  gameName?: Maybe<Scalars['String']>;
  isUpdating?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['Int']>;
  mobaUuid?: Maybe<Scalars['String']>;
  profileIcon?: Maybe<Scalars['Int']>;
  puuid?: Maybe<Scalars['Puuid']>;
  region?: Maybe<Region>;
  summonerId?: Maybe<Scalars['String']>;
  /** DEPRECATED. Use gameName and tagLine instead. */
  summonerName?: Maybe<Scalars['String']>;
  tagLine?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['TftDateTime']>;
};

/** Summoner not found */
export type TftSummonerNotFoundError = TftError & {
  __typename?: 'TftSummonerNotFoundError';
  message?: Maybe<Scalars['String']>;
  /** Deprecated: Use riotId instead */
  name?: Maybe<Scalars['String']>;
  region?: Maybe<Region>;
  riotId?: Maybe<Scalars['String']>;
};

/**
 * Synergy for a composition.
 * Must have a type, slug and value.
 * Value is calculated by addition of synergies of unique champions
 * in composition plus chosen synergy modificator.
 *
 * Squidex is used as a source of truth.
 * https://squidex.mobalytics.gg/app/tft-v1/content/synergies-v1
 */
export type TftSynergy = {
  __typename?: 'TftSynergy';
  slug: Scalars['String'];
  type?: Maybe<TftSynergyType>;
  value?: Maybe<Scalars['Int']>;
};

/** Each champion has class and origin. Both of them could create synergy for composition. */
export enum TftSynergyType {
  TFT_CLASS = 'TFT_CLASS',
  TFT_ORIGIN = 'TFT_ORIGIN',
}

export enum TftTier {
  BRONZE = 'BRONZE',
  CHALLENGER = 'CHALLENGER',
  DIAMOND = 'DIAMOND',
  EMERALD = 'EMERALD',
  GOLD = 'GOLD',
  GRANDMASTER = 'GRANDMASTER',
  IRON = 'IRON',
  MASTER = 'MASTER',
  PLATINUM = 'PLATINUM',
  SILVER = 'SILVER',
}

export type TftUpdateCompositionGuideAlternativeChampionInput = {
  alternative: Array<Scalars['String']>;
  base: Array<Scalars['String']>;
};

export type TftUpdateCompositionGuideAlternativeChampionWithItemsInput = {
  items?: InputMaybe<Array<Scalars['String']>>;
  slug: Scalars['String'];
};

export type TftUpdateCompositionGuideAlternativeChampionsInput = {
  alternatives: Array<TftUpdateCompositionGuideAlternativeChampionInput>;
  compositionId: Scalars['ID'];
};

export type TftUpdateCompositionGuideAlternativeItemsInput = {
  alternatives: Array<TftUpdateCompositionGuideAlternativeChampionWithItemsInput>;
  compositionId: Scalars['ID'];
};

export type TftUpdateCompositionGuideAugmentsInput = {
  augments?: InputMaybe<Array<Scalars['String']>>;
  compositionId: Scalars['ID'];
};

export type TftUpdateCompositionGuideCarouselInput = {
  compositionId: Scalars['ID'];
  items?: InputMaybe<Array<Scalars['String']>>;
};

export type TftUpdateCompositionGuideDeleteVideoInput = {
  compositionId: Scalars['ID'];
};

export type TftUpdateCompositionGuideGamePlanInput = {
  advice: Scalars['String'];
  compositionId: Scalars['ID'];
  formation: TftCompositionFormationInput;
  type: TftCompositionGuideGamePlanType;
};

export type TftUpdateCompositionGuideInput = {
  compositionId: Scalars['ID'];
  tips?: InputMaybe<Scalars['String']>;
  whenToMake: Scalars['String'];
};

export type TftUpdateCompositionGuideLegendInput = {
  compositionId: Scalars['ID'];
  slug?: InputMaybe<Scalars['String']>;
};

export type TftUpdateCompositionGuidePayload = {
  __typename?: 'TftUpdateCompositionGuidePayload';
  errors?: Maybe<Array<Maybe<TftError>>>;
  guide?: Maybe<TftCompositionGuide>;
  guideId?: Maybe<Scalars['ID']>;
};

export type TftUpdateCompositionGuideUpdateVideoInput = {
  compositionId: Scalars['ID'];
  isPremium?: InputMaybe<Scalars['Boolean']>;
  previewURL?: InputMaybe<Scalars['String']>;
  videoURL: Scalars['String'];
};

export type TftUpdateCompositionInput = {
  description?: InputMaybe<Scalars['String']>;
  difficulty: Scalars['String'];
  formation: TftCompositionFormationInput;
  id: Scalars['ID'];
  name: Scalars['String'];
  /**
   * Patch is optional only for backward compatibility.
   * It is recommended to set patch proprety.
   * Set to the current patch by default.
   */
  patch?: InputMaybe<Scalars['String']>;
  playstyle?: InputMaybe<Scalars['String']>;
  tier?: InputMaybe<Scalars['String']>;
};

export type TftUpdateCompositionPayload = {
  __typename?: 'TftUpdateCompositionPayload';
  errors?: Maybe<Array<Maybe<TftError>>>;
  myCompositions?: Maybe<TftMyCompositionsInfiniteScroll>;
  publicCompositions?: Maybe<TftPublicCompositionsInfiniteScroll>;
  record?: Maybe<TftComposition>;
  recordId?: Maybe<Scalars['ID']>;
};

export type TftUpdateCompositionPayloadmyCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftMyCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftMyCompositionsSort>>;
};

export type TftUpdateCompositionPayloadpublicCompositionsArgs = {
  after?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<TftPublicCompositionsFilter>;
  first?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<Array<TftPublicCompositionsSort>>;
};

/**
 * User is an author of TFT composition.
 * Could have zero to many compositions.
 */
export type TftUser = {
  __typename?: 'TftUser';
  compositions?: Maybe<Array<TftComposition>>;
  gameName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  profile?: Maybe<TftProfile>;
  region?: Maybe<Region>;
  summoner?: Maybe<TftSummoner>;
  /** DEPRECATED. Use gameName/tagLine instead. */
  summonerName?: Maybe<Scalars['String']>;
  tagLine?: Maybe<Scalars['String']>;
};

/**
 * User is an author of TFT composition.
 * Could have zero to many compositions.
 */
export type TftUserprofileArgs = {
  set: Scalars['TftSet'];
};

export type TftVoteForCompositionInput = {
  id: Scalars['ID'];
  vote: TftCompositionVote;
};

export type TftVoteForCompositionPayload = {
  __typename?: 'TftVoteForCompositionPayload';
  error?: Maybe<TftError>;
};

export type TheBazaarAutogeneratedMainType = {
  __typename?: 'TheBazaarAutogeneratedMainType';
  heroes?: Maybe<Array<Maybe<TheBazaarHero>>>;
  items?: Maybe<Array<Maybe<TheBazaarItem>>>;
  skills?: Maybe<Array<Maybe<TheBazaarSkill>>>;
};

export type TheBazaarDocumentCmWidgetBoardCreatorV1 = NgfDocumentContent & {
  __typename?: 'TheBazaarDocumentCmWidgetBoardCreatorV1';
  data: TheBazaarDocumentCmWidgetBoardCreatorV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type TheBazaarDocumentCmWidgetBoardCreatorV1Data = {
  __typename?: 'TheBazaarDocumentCmWidgetBoardCreatorV1Data';
  cards?: Maybe<Array<Maybe<TheBazaarItemData>>>;
  description: NgfDocumentRichTextContent;
  subTitle: NgfDocumentRichTextContent;
  title: Scalars['String'];
};

export type TheBazaarDocumentCmWidgetV1 = NgfDocumentContent & {
  __typename?: 'TheBazaarDocumentCmWidgetV1';
  data: TheBazaarDocumentCmWidgetV1Data;
  id: Scalars['ID'];
  meta: NgfDocumentContentMeta;
};

export type TheBazaarDocumentCmWidgetV1Data = {
  __typename?: 'TheBazaarDocumentCmWidgetV1Data';
  border: Scalars['String'];
  content: Scalars['NgfDocumentWidgetRichTextContentJson'];
  title: Scalars['String'];
};

export type TheBazaarDocumentContent =
  | NgfDocumentCmSectionAuto1V1
  | NgfDocumentCmSectionAuto3V1
  | NgfDocumentCmSectionAuto400V1
  | NgfDocumentCmSectionVideoAdV1
  | NgfDocumentCmWidgetCardGridV1
  | NgfDocumentCmWidgetCardGridV2
  | NgfDocumentCmWidgetContentVariantsV1
  | NgfDocumentCmWidgetCrossPageTabBarV1
  | NgfDocumentCmWidgetDiscoveryPreviewCompactV1
  | NgfDocumentCmWidgetDiscoveryPreviewCompactV2
  | NgfDocumentCmWidgetDiscoveryPreviewV1
  | NgfDocumentCmWidgetDiscoveryPreviewV2
  | NgfDocumentCmWidgetDocumentsFeedV1
  | NgfDocumentCmWidgetDocumentsFeedV2
  | NgfDocumentCmWidgetErrorV1
  | NgfDocumentCmWidgetGameDataCardGridV1
  | NgfDocumentCmWidgetGameDataCardGridV2
  | NgfDocumentCmWidgetPromoBannerHorizontalV1
  | NgfDocumentCmWidgetPromoBannerNetboardV1
  | NgfDocumentCmWidgetRichTextSimplifiedV1
  | NgfDocumentCmWidgetRichTextSimplifiedV2
  | NgfDocumentCmWidgetRichTextV1
  | NgfDocumentCmWidgetRichTextV2
  | NgfDocumentCmWidgetTableOfContentV1
  | NgfDocumentCmWidgetUGTierListV1
  | NgfDocumentCmWidgetUGTierListV2
  | NgfDocumentCmWidgetVideoV1
  | NgfDocumentCmWidgetVideoV2
  | NgfDocumentStWidgetDocumentsDiscoveryV1
  | NgfDocumentStWidgetHeaderV1
  | NgfDocumentStWidgetHeaderV2
  | NgfDocumentStWidgetHomePageHeaderV1
  | NgfDocumentStWidgetHomePageHeaderV2
  | NgfDocumentStWidgetLinksGridManualV1
  | NgfDocumentStWidgetLinksGridManualV2
  | NgfDocumentUgWidgetHeaderV1
  | TheBazaarDocumentCmWidgetBoardCreatorV1
  | TheBazaarDocumentCmWidgetV1;

export type TheBazaarDocumentSeason = NgfDocumentSeason & {
  __typename?: 'TheBazaarDocumentSeason';
  slug: Scalars['String'];
};

export type TheBazaarDocumentTags = NgfDocumentTags & {
  __typename?: 'TheBazaarDocumentTags';
  data?: Maybe<Array<NgfDocumentTag>>;
};

export type TheBazaarDocumentsMutation = {
  __typename?: 'TheBazaarDocumentsMutation';
  /** Create operation creates a new document with the provided data and content. */
  structDocumentCreate: TheBazaarStructDocumentCreatePayload;
  /** SEO operation affects only document.seo field. */
  structDocumentSetSeo: TheBazaarStructDocumentSetSeoPayload;
  /** Status operation affects only document.status field. */
  structDocumentSetStatus: TheBazaarStructDocumentSetStatusPayload;
  /** Update operation affects only document.data and document.content fields. */
  structDocumentUpdate: TheBazaarStructDocumentUpdatePayload;
  /** Create operation creates a new document with the provided data and content. */
  userGeneratedDocumentCreate: TheBazaarUserGeneratedDocumentCreatePayload;
  /** Status operation affects only document.favorites field. */
  userGeneratedDocumentSetFavorite: TheBazaarUserGeneratedDocumentSetFavoritePayload;
  /** Status operation affects only document.featured field. */
  userGeneratedDocumentSetFeatured: TheBazaarUserGeneratedDocumentSetFeaturedPayload;
  /** SEO operation affects only document.seo field. */
  userGeneratedDocumentSetSeo: TheBazaarUserGeneratedDocumentSetSeoPayload;
  /** Status operation affects only document.status field. */
  userGeneratedDocumentSetStatus: TheBazaarUserGeneratedDocumentSetStatusPayload;
  /** Update operation affects only document.data and document.content fields. */
  userGeneratedDocumentUpdate: TheBazaarUserGeneratedDocumentUpdatePayload;
};

export type TheBazaarDocumentsMutationstructDocumentCreateArgs = {
  input: TheBazaarStructDocumentCreateInput;
};

export type TheBazaarDocumentsMutationstructDocumentSetSeoArgs = {
  input: NgfDocumentSetSeoInput;
};

export type TheBazaarDocumentsMutationstructDocumentSetStatusArgs = {
  input: TheBazaarStructDocumentSetStatusInput;
};

export type TheBazaarDocumentsMutationstructDocumentUpdateArgs = {
  input: TheBazaarStructDocumentUpdateInput;
};

export type TheBazaarDocumentsMutationuserGeneratedDocumentCreateArgs = {
  input: TheBazaarUserGeneratedDocumentCreateInput;
};

export type TheBazaarDocumentsMutationuserGeneratedDocumentSetFavoriteArgs = {
  input: TheBazaarUserGeneratedDocumentSetFavoriteInput;
};

export type TheBazaarDocumentsMutationuserGeneratedDocumentSetFeaturedArgs = {
  input: TheBazaarUserGeneratedDocumentSetFeaturedInput;
};

export type TheBazaarDocumentsMutationuserGeneratedDocumentSetSeoArgs = {
  input: NgfDocumentSetSeoInput;
};

export type TheBazaarDocumentsMutationuserGeneratedDocumentSetStatusArgs = {
  input: TheBazaarUserGeneratedDocumentSetStatusInput;
};

export type TheBazaarDocumentsMutationuserGeneratedDocumentUpdateArgs = {
  input: TheBazaarUserGeneratedDocumentUpdateInput;
};

export type TheBazaarDocumentsQuery = {
  __typename?: 'TheBazaarDocumentsQuery';
  previewDocumentContent: TheBazaarPreviewDocumentContentPayload;
  structDocumentBySlug: TheBazaarStructDocumentPayload;
  structDocumentWidgets: NgfStructDocumentWidgetsPayload;
  structDocuments: NgfStructDocumentsListPayload;
  userGeneratedDocumentById: TheBazaarUserGeneratedDocumentPayload;
  userGeneratedDocumentBySlug: TheBazaarUserGeneratedDocumentPayload;
  userGeneratedDocumentTagGroups: NgfUserGeneratedDocumentTagGroupsPayload;
  userGeneratedDocumentTemplate: TheBazaarTemplateDocumentPayload;
  userGeneratedDocumentTypes: NgfUserGeneratedDocumentTypesPayload;
  userGeneratedDocuments: NgfUserGeneratedDocumentsListPayload;
};

export type TheBazaarDocumentsQuerypreviewDocumentContentArgs = {
  input: TheBazaarPreviewDocumentContentInput;
};

export type TheBazaarDocumentsQuerystructDocumentBySlugArgs = {
  input: TheBazaarStructDocumentInputBySlug;
};

export type TheBazaarDocumentsQuerystructDocumentsArgs = {
  input: TheBazaarStructDocumentsInput;
};

export type TheBazaarDocumentsQueryuserGeneratedDocumentByIdArgs = {
  input: TheBazaarUserGeneratedDocumentInputById;
};

export type TheBazaarDocumentsQueryuserGeneratedDocumentBySlugArgs = {
  input: TheBazaarUserGeneratedDocumentInputBySlug;
};

export type TheBazaarDocumentsQueryuserGeneratedDocumentTagGroupsArgs = {
  input?: InputMaybe<NgfUserGeneratedDocumentTagGroupsInput>;
};

export type TheBazaarDocumentsQueryuserGeneratedDocumentTemplateArgs = {
  input: TheBazaarTemplateDocumentInput;
};

export type TheBazaarDocumentsQueryuserGeneratedDocumentsArgs = {
  input: TheBazaarUserGeneratedDocumentsListInput;
  page: TheBazaarUserGeneratedDocumentsListPage;
};

export type TheBazaarEnchantment = {
  __typename?: 'TheBazaarEnchantment';
  descriptions?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
};

export type TheBazaarHero = {
  __typename?: 'TheBazaarHero';
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  slug: Scalars['String'];
};

export type TheBazaarHeroesFilter = {
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type TheBazaarHeroesResult = {
  __typename?: 'TheBazaarHeroesResult';
  data?: Maybe<Array<TheBazaarHero>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type TheBazaarItem = {
  __typename?: 'TheBazaarItem';
  enchantments?: Maybe<Array<TheBazaarEnchantment>>;
  heroes?: Maybe<Array<TheBazaarHero>>;
  heroesSlugs?: Maybe<Array<Scalars['String']>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  size?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  tierStats?: Maybe<Array<TheBazaarTierStat>>;
};

export type TheBazaarItemData = {
  __typename?: 'TheBazaarItemData';
  icon?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  size: Scalars['String'];
  slug: Scalars['String'];
};

export type TheBazaarItemsFilter = {
  heroesSlugs?: InputMaybe<StringsIntersection>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type TheBazaarItemsResult = {
  __typename?: 'TheBazaarItemsResult';
  data?: Maybe<Array<TheBazaarItem>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type TheBazaarMutation = {
  __typename?: 'TheBazaarMutation';
  documents?: Maybe<TheBazaarDocumentsMutation>;
  id: Scalars['ID'];
};

export enum TheBazaarPreviewDocumentContentError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type TheBazaarPreviewDocumentContentInput = {
  content: Scalars['TheBazaarDocumentContentInputBase64Json'];
};

export type TheBazaarPreviewDocumentContentPayload = {
  __typename?: 'TheBazaarPreviewDocumentContentPayload';
  data?: Maybe<TheBazaarDocumentContent>;
  error?: Maybe<TheBazaarPreviewDocumentContentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type TheBazaarQuery = {
  __typename?: 'TheBazaarQuery';
  documents?: Maybe<TheBazaarDocumentsQuery>;
  id: Scalars['ID'];
  theBazaarStaticData?: Maybe<TheBazaarStaticDataQuery>;
};

export type TheBazaarSkill = {
  __typename?: 'TheBazaarSkill';
  heroes?: Maybe<Array<TheBazaarHero>>;
  heroesSlugs?: Maybe<Array<Scalars['String']>>;
  icon?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name: Scalars['String'];
  size?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  tags?: Maybe<Array<Scalars['String']>>;
  tierStats?: Maybe<Array<TheBazaarTierStat>>;
};

export type TheBazaarSkillsFilter = {
  heroesSlugs?: InputMaybe<StringsIntersection>;
  id?: InputMaybe<OneOfString>;
  name?: InputMaybe<OneOfString>;
  page?: InputMaybe<Page>;
  slug?: InputMaybe<OneOfString>;
};

export type TheBazaarSkillsResult = {
  __typename?: 'TheBazaarSkillsResult';
  data?: Maybe<Array<TheBazaarSkill>>;
  pagination?: Maybe<Pagination>;
  version?: Maybe<Scalars['String']>;
};

export type TheBazaarStaticDataQuery = {
  __typename?: 'TheBazaarStaticDataQuery';
  theBazaarHeroes: TheBazaarHeroesResult;
  theBazaarItems: TheBazaarItemsResult;
  theBazaarSkills: TheBazaarSkillsResult;
};

export type TheBazaarStaticDataQuerytheBazaarHeroesArgs = {
  filter?: InputMaybe<TheBazaarHeroesFilter>;
};

export type TheBazaarStaticDataQuerytheBazaarItemsArgs = {
  filter?: InputMaybe<TheBazaarItemsFilter>;
};

export type TheBazaarStaticDataQuerytheBazaarSkillsArgs = {
  filter?: InputMaybe<TheBazaarSkillsFilter>;
};

export type TheBazaarStructDocument = NgfStructDocument & {
  __typename?: 'TheBazaarStructDocument';
  content: Array<TheBazaarDocumentContent>;
  createdAt: Scalars['NgfDateTime'];
  data: TheBazaarStructDocumentData;
  id: Scalars['ID'];
  permissions?: Maybe<NgfDocumentPermissions>;
  seo?: Maybe<NgfDocumentSeo>;
  slug: Scalars['String'];
  status: NgfStructDocumentStatus;
  updatedAt: Scalars['NgfDateTime'];
  version: Scalars['Int'];
};

export enum TheBazaarStructDocumentCreateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
}

export type TheBazaarStructDocumentCreateInput = {
  document: Scalars['TheBazaarStructDocumentInputBase64Json'];
};

export type TheBazaarStructDocumentCreatePayload = {
  __typename?: 'TheBazaarStructDocumentCreatePayload';
  data?: Maybe<TheBazaarStructDocument>;
  error?: Maybe<TheBazaarStructDocumentCreateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type TheBazaarStructDocumentData = NgfStructDocumentData & {
  __typename?: 'TheBazaarStructDocumentData';
  childrenIds: Array<Scalars['String']>;
  name: Scalars['String'];
  season: TheBazaarDocumentSeason;
  tags?: Maybe<TheBazaarDocumentTags>;
};

export enum TheBazaarStructDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type TheBazaarStructDocumentInputBySlug = {
  /** Slug is unique human readable identifier of a struct document. */
  slug: Scalars['String'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type TheBazaarStructDocumentPayload = {
  __typename?: 'TheBazaarStructDocumentPayload';
  data?: Maybe<TheBazaarStructDocument>;
  error?: Maybe<TheBazaarStructDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum TheBazaarStructDocumentSetSeoError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type TheBazaarStructDocumentSetSeoPayload = {
  __typename?: 'TheBazaarStructDocumentSetSeoPayload';
  data?: Maybe<TheBazaarStructDocument>;
  error?: Maybe<TheBazaarStructDocumentSetSeoError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum TheBazaarStructDocumentSetStatusError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type TheBazaarStructDocumentSetStatusInput = {
  id: Scalars['ID'];
  status: NgfStructDocumentStatus;
};

export type TheBazaarStructDocumentSetStatusPayload = {
  __typename?: 'TheBazaarStructDocumentSetStatusPayload';
  data?: Maybe<TheBazaarStructDocument>;
  error?: Maybe<TheBazaarStructDocumentSetStatusError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum TheBazaarStructDocumentUpdateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type TheBazaarStructDocumentUpdateInput = {
  document: Scalars['TheBazaarStructDocumentInputBase64Json'];
  id: Scalars['ID'];
};

export type TheBazaarStructDocumentUpdatePayload = {
  __typename?: 'TheBazaarStructDocumentUpdatePayload';
  data?: Maybe<TheBazaarStructDocument>;
  error?: Maybe<TheBazaarStructDocumentUpdateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type TheBazaarStructDocumentsInput = {
  statuses: Array<NgfStructDocumentStatus>;
};

export type TheBazaarTemplateDocument = NgfTemplateDocument & {
  __typename?: 'TheBazaarTemplateDocument';
  content: Array<TheBazaarDocumentContent>;
  data: TheBazaarTemplateDocumentData;
  type: Scalars['String'];
  typeData: NgfDocumentType;
};

export type TheBazaarTemplateDocumentData = NgfTemplateDocumentData & {
  __typename?: 'TheBazaarTemplateDocumentData';
  childrenIds: Array<Scalars['String']>;
  name: Scalars['String'];
  season: TheBazaarDocumentSeason;
};

export enum TheBazaarTemplateDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
}

export type TheBazaarTemplateDocumentInput = {
  type: Scalars['String'];
};

export type TheBazaarTemplateDocumentPayload = {
  __typename?: 'TheBazaarTemplateDocumentPayload';
  data?: Maybe<TheBazaarTemplateDocument>;
  error?: Maybe<TheBazaarTemplateDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type TheBazaarTierStat = {
  __typename?: 'TheBazaarTierStat';
  ammo?: Maybe<Scalars['Int']>;
  cooldown?: Maybe<Scalars['Float']>;
  descriptions?: Maybe<Array<Scalars['String']>>;
  tier?: Maybe<Scalars['String']>;
};

export type TheBazaarUserGeneratedDocument = NgfUserGeneratedDocument & {
  __typename?: 'TheBazaarUserGeneratedDocument';
  author: NgfDocumentAuthor;
  content: Array<TheBazaarDocumentContent>;
  createdAt: Scalars['NgfDateTime'];
  data: TheBazaarUserGeneratedDocumentData;
  favorites?: Maybe<NgfDocumentFavorites>;
  featured?: Maybe<NgfDocumentFeatured>;
  firstPublishedAt?: Maybe<Scalars['NgfDateTime']>;
  id: Scalars['ID'];
  permissions?: Maybe<NgfDocumentPermissions>;
  seo?: Maybe<NgfDocumentSeo>;
  status: NgfUserGeneratedDocumentStatus;
  tags?: Maybe<TheBazaarDocumentTags>;
  type: Scalars['String'];
  typeData: NgfDocumentType;
  updatedAt: Scalars['NgfDateTime'];
  version: Scalars['Int'];
};

export enum TheBazaarUserGeneratedDocumentCreateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type TheBazaarUserGeneratedDocumentCreateInput = {
  document: Scalars['TheBazaarUserGeneratedDocumentInputBase64Json'];
};

export type TheBazaarUserGeneratedDocumentCreatePayload = {
  __typename?: 'TheBazaarUserGeneratedDocumentCreatePayload';
  data?: Maybe<TheBazaarUserGeneratedDocument>;
  error?: Maybe<TheBazaarUserGeneratedDocumentCreateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type TheBazaarUserGeneratedDocumentData = NgfUserGeneratedDocumentData & {
  __typename?: 'TheBazaarUserGeneratedDocumentData';
  backgroundImage?: Maybe<Scalars['String']>;
  childrenIds: Array<Scalars['String']>;
  gameSpecificProp1?: Maybe<Scalars['String']>;
  gameSpecificProp2?: Maybe<Scalars['Int']>;
  gameSpecificProp3?: Maybe<Scalars['Boolean']>;
  gameSpecificProp4?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  season: TheBazaarDocumentSeason;
};

export enum TheBazaarUserGeneratedDocumentError {
  INTERNAL = 'INTERNAL',
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export type TheBazaarUserGeneratedDocumentInputById = {
  /** Unique identifier of a document. Either id or slug is required to get content document. */
  id: Scalars['ID'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type TheBazaarUserGeneratedDocumentInputBySlug = {
  /** Unique human readable identifier of a document. Either id or slug is required to get content document. */
  slug: Scalars['String'];
  /** Document type. */
  type: Scalars['String'];
  widgetsOverride?: InputMaybe<Array<NgfCommonWidgetsOverride>>;
};

export type TheBazaarUserGeneratedDocumentPayload = {
  __typename?: 'TheBazaarUserGeneratedDocumentPayload';
  data?: Maybe<TheBazaarUserGeneratedDocument>;
  error?: Maybe<TheBazaarUserGeneratedDocumentError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum TheBazaarUserGeneratedDocumentSetFavoriteError {
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type TheBazaarUserGeneratedDocumentSetFavoriteInput = {
  id: Scalars['ID'];
  isFavorite: Scalars['Boolean'];
};

export type TheBazaarUserGeneratedDocumentSetFavoritePayload = {
  __typename?: 'TheBazaarUserGeneratedDocumentSetFavoritePayload';
  data?: Maybe<TheBazaarUserGeneratedDocument>;
  error?: Maybe<TheBazaarUserGeneratedDocumentSetFavoriteError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum TheBazaarUserGeneratedDocumentSetFeaturedError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
  UNIQUE_VIOLATION = 'UNIQUE_VIOLATION',
}

export type TheBazaarUserGeneratedDocumentSetFeaturedInput = {
  documentSlug: Scalars['String'];
  id: Scalars['ID'];
  status: NgfDocumentFeaturedStatus;
};

export type TheBazaarUserGeneratedDocumentSetFeaturedPayload = {
  __typename?: 'TheBazaarUserGeneratedDocumentSetFeaturedPayload';
  data?: Maybe<TheBazaarUserGeneratedDocument>;
  error?: Maybe<TheBazaarUserGeneratedDocumentSetFeaturedError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum TheBazaarUserGeneratedDocumentSetSeoError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
}

export type TheBazaarUserGeneratedDocumentSetSeoPayload = {
  __typename?: 'TheBazaarUserGeneratedDocumentSetSeoPayload';
  data?: Maybe<TheBazaarUserGeneratedDocument>;
  error?: Maybe<TheBazaarUserGeneratedDocumentSetSeoError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum TheBazaarUserGeneratedDocumentSetStatusError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type TheBazaarUserGeneratedDocumentSetStatusInput = {
  id: Scalars['ID'];
  status: NgfUserGeneratedDocumentStatus;
};

export type TheBazaarUserGeneratedDocumentSetStatusPayload = {
  __typename?: 'TheBazaarUserGeneratedDocumentSetStatusPayload';
  data?: Maybe<TheBazaarUserGeneratedDocument>;
  error?: Maybe<TheBazaarUserGeneratedDocumentSetStatusError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export enum TheBazaarUserGeneratedDocumentUpdateError {
  BAD_REQUEST = 'BAD_REQUEST',
  INTERNAL = 'INTERNAL',
  NOT_AUTHORIZED = 'NOT_AUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export type TheBazaarUserGeneratedDocumentUpdateInput = {
  document: Scalars['TheBazaarUserGeneratedDocumentInputBase64Json'];
  id: Scalars['ID'];
};

export type TheBazaarUserGeneratedDocumentUpdatePayload = {
  __typename?: 'TheBazaarUserGeneratedDocumentUpdatePayload';
  data?: Maybe<TheBazaarUserGeneratedDocument>;
  error?: Maybe<TheBazaarUserGeneratedDocumentUpdateError>;
  errorMessage?: Maybe<Scalars['String']>;
};

export type TheBazaarUserGeneratedDocumentsListInput = {
  /** If specified, filters by author id. */
  authorId?: InputMaybe<Scalars['ID']>;
  /** If specified, filters by document status. Specified as list because we have a case when we need to filter by both DRAFT and PUBLISHED statuses. */
  documentStatus?: InputMaybe<Array<NgfUserGeneratedDocumentStatus>>;
  /** If specified, filters by document featured status. */
  featuredStatuses?: InputMaybe<Array<NgfDocumentFeaturedStatus>>;
  /** If specified, filters by document ids. */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  /** If specified, filters documents that were added to favorites by the current user. */
  isFavorite?: InputMaybe<Scalars['Boolean']>;
  /** If specified, filters by name of the document. */
  name?: InputMaybe<Scalars['String']>;
  /** If specified, filters by document the timeframe when document been published. */
  publishedTimeframe?: InputMaybe<NgfUserGeneratedDocumentsDiscoveryPublishedTimeframe>;
  /** If specified, filters by document season or "current-season" keyword. If "current-season" is passed, the current season will be used. */
  seasonSlug?: InputMaybe<Scalars['String']>;
  /**
   * If specified, filters by document slugs - human readable version of document id.
   * Slug can be defined only for featured documents, like document ids, they must be unique on game level.
   */
  slugs?: InputMaybe<Array<Scalars['String']>>;
  /** Defines the way output list is sorted. */
  sortBy?: InputMaybe<NgfUserGeneratedDocumentsDiscoverySortingOption>;
  /** If specified, filters by document tags. */
  tags?: InputMaybe<Array<NgfDocumentWidgetDocumentsDiscoveryInputTagV1>>;
  /**
   * If specified, filters by document type.
   * 'profile-default-tab' is a special const, allows to request docs without specifying particular type.
   */
  type?: InputMaybe<Scalars['String']>;
};

export type TheBazaarUserGeneratedDocumentsListPage = {
  /**
   * If specified, filters out document before the specified cursor (forward cursor direction).
   * Backward cursor pagination is not supported as we currently don't have use cases for it.
   */
  cursor?: InputMaybe<Scalars['String']>;
  /** Limits the number of items in response. */
  limit: Scalars['Int'];
};

export type TreasuryAccountsAccount = {
  __typename?: 'TreasuryAccountsAccount';
  email: Scalars['String'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  user: User;
  username: Scalars['String'];
};

export type TreasuryAccountsAccountOrError = TreasuryAccountsAccount | TreasuryAccountsUnknownError;

export type TreasuryAccountsError = {
  /** The error message */
  message?: Maybe<Scalars['String']>;
};

export type TreasuryAccountsUnknownError = TreasuryAccountsError & {
  __typename?: 'TreasuryAccountsUnknownError';
  /** The error message */
  message?: Maybe<Scalars['String']>;
};

export type TreasuryFlowActivatedStep = {
  __typename?: 'TreasuryFlowActivatedStep';
  activatedAt?: Maybe<Scalars['TreasuryFlowTime']>;
  id: Scalars['ID'];
};

export type TreasuryFlowActivatedStepOrError = TreasuryFlowActivatedStep | TreasuryFlowGenericError;

export type TreasuryFlowAvailableStep = {
  __typename?: 'TreasuryFlowAvailableStep';
  availableAfter?: Maybe<Scalars['TreasuryFlowTimeDuration']>;
  expiresAt?: Maybe<Scalars['TreasuryFlowTime']>;
  id: Scalars['ID'];
};

export type TreasuryFlowAvailableStepFilter = {
  flowID: Scalars['ID'];
};

export type TreasuryFlowAvailableStepOrError = TreasuryFlowAvailableStep | TreasuryFlowGenericError;

export type TreasuryFlowError = {
  message?: Maybe<Scalars['String']>;
};

export type TreasuryFlowGenericError = TreasuryFlowError & {
  __typename?: 'TreasuryFlowGenericError';
  message?: Maybe<Scalars['String']>;
};

export type TreasuryFlowInfo = {
  info?: Maybe<Array<TreasuryFlowInfoData>>;
};

export type TreasuryFlowInfoData = {
  __typename?: 'TreasuryFlowInfoData';
  couponID: Scalars['String'];
  planID: Scalars['String'];
};

export type TreasuryFlowInfoFilter = {
  flowID: Scalars['ID'];
  game: Scalars['String'];
};

export type TreasuryFlowInfoGeneral = TreasuryFlowInfo & {
  __typename?: 'TreasuryFlowInfoGeneral';
  info?: Maybe<Array<TreasuryFlowInfoData>>;
};

export type TreasuryFlowMutation = {
  __typename?: 'TreasuryFlowMutation';
  activateStep?: Maybe<TreasuryFlowAvailableStepOrError>;
};

export type TreasuryFlowMutationactivateStepArgs = {
  flowID: Scalars['ID'];
  stepID: Scalars['ID'];
};

export type TreasuryFlowPreviousStepFilter = {
  flowID: Scalars['ID'];
};

export type TreasuryFlowQuery = {
  __typename?: 'TreasuryFlowQuery';
  availableStep?: Maybe<TreasuryFlowAvailableStepOrError>;
  /** Get information specific for a particular flow and game. Flow information is user-agnostic */
  flowInfo?: Maybe<TreasuryFlowInfo>;
  previousStep?: Maybe<TreasuryFlowActivatedStepOrError>;
};

export type TreasuryFlowQueryavailableStepArgs = {
  filter?: InputMaybe<TreasuryFlowAvailableStepFilter>;
};

export type TreasuryFlowQueryflowInfoArgs = {
  filter?: InputMaybe<TreasuryFlowInfoFilter>;
};

export type TreasuryFlowQuerypreviousStepArgs = {
  filter?: InputMaybe<TreasuryFlowPreviousStepFilter>;
};

export type TreasuryMutation = {
  __typename?: 'TreasuryMutation';
  flow?: Maybe<TreasuryFlowMutation>;
  id: Scalars['ID'];
  payments?: Maybe<TreasuryPaymentsMutation>;
};

export type TreasuryPaymentCustomerAction =
  | TreasuryPaymentCustomerActionCancelSubscription
  | TreasuryPaymentCustomerActionChangeSubscription
  | TreasuryPaymentCustomerActionCreateSubscription
  | TreasuryPaymentCustomerActionRenewSubscription
  | TreasuryPaymentCustomerActionUseTrial;

export type TreasuryPaymentCustomerActionCancelSubscription = {
  __typename?: 'TreasuryPaymentCustomerActionCancelSubscription';
  isAllowed: Scalars['Boolean'];
};

export type TreasuryPaymentCustomerActionChangeSubscription = {
  __typename?: 'TreasuryPaymentCustomerActionChangeSubscription';
  isAllowed: Scalars['Boolean'];
  product: TreasuryPaymentsProduct;
};

export type TreasuryPaymentCustomerActionCreateSubscription = {
  __typename?: 'TreasuryPaymentCustomerActionCreateSubscription';
  isAllowed: Scalars['Boolean'];
  product: TreasuryPaymentsProduct;
};

export type TreasuryPaymentCustomerActionRenewSubscription = {
  __typename?: 'TreasuryPaymentCustomerActionRenewSubscription';
  isAllowed: Scalars['Boolean'];
  product: TreasuryPaymentsProduct;
};

export type TreasuryPaymentCustomerActionUseTrial = {
  __typename?: 'TreasuryPaymentCustomerActionUseTrial';
  isAllowed: Scalars['Boolean'];
  product: TreasuryPaymentsProduct;
};

/** Apply coupon to a payment input */
export type TreasuryPaymentsApplyCouponInput = {
  /** Coupon name (code) */
  couponName: Scalars['String'];
  /** Payment plan ids to apply the coupon */
  planIds: Array<Scalars['ID']>;
};

/** Apply coupon to a payment payload */
export type TreasuryPaymentsApplyCouponPayload = {
  __typename?: 'TreasuryPaymentsApplyCouponPayload';
  /** Errors that may occur during the mutation */
  errors?: Maybe<Array<TreasuryPaymentsError>>;
  /** Payment plans with the coupon applied */
  plans?: Maybe<Array<TreasuryPaymentsPlan>>;
};

/** The error returned when given parameters are invalid */
export type TreasuryPaymentsBadRequestError = TreasuryPaymentsError & {
  __typename?: 'TreasuryPaymentsBadRequestError';
  /** Details about the error */
  details?: Maybe<Scalars['String']>;
  /** The error message */
  message?: Maybe<Scalars['String']>;
  /** Parameter name */
  parameter?: Maybe<Scalars['String']>;
};

export type TreasuryPaymentsBillingAddress = {
  __typename?: 'TreasuryPaymentsBillingAddress';
  /** The billing city of the customer */
  city: Scalars['String'];
  /** The billing country of the customer */
  country: Scalars['String'];
  /** The billing address line 1 of the customer */
  line1: Scalars['String'];
  /** The billing address line 2 of the customer */
  line2: Scalars['String'];
  /** The billing state of the customer */
  state: Scalars['String'];
  /** The billing zip code of the customer */
  zip: Scalars['String'];
};

/** The billing info information of the customer */
export type TreasuryPaymentsBillingInfo = {
  __typename?: 'TreasuryPaymentsBillingInfo';
  /** Paypal billing info */
  paypal?: Maybe<TreasuryPaymentsPayPal>;
  /** Stripe billing info */
  stripe?: Maybe<TreasuryPaymentsStripe>;
};

export type TreasuryPaymentsBillingInfoOrError = TreasuryPaymentsBillingInfo | TreasuryPaymentsUnknownError;

/** Cancel subscription input */
export type TreasuryPaymentsCancelSubscriptionInput = {
  /** Unique identifier of a subscription to be cancelled */
  subscriptionId: Scalars['ID'];
};

/** Cancel subscription payload */
export type TreasuryPaymentsCancelSubscriptionPayload = {
  __typename?: 'TreasuryPaymentsCancelSubscriptionPayload';
  /** Error */
  error?: Maybe<TreasuryPaymentsError>;
  /** Cancelled subscription ID */
  id?: Maybe<Scalars['ID']>;
  /** Subscription that was cancelled */
  subscription?: Maybe<TreasuryPaymentsSubscription>;
};

export type TreasuryPaymentsCard = {
  __typename?: 'TreasuryPaymentsCard';
  /** The customer's card last 4 digits */
  last4: Scalars['String'];
  /** The customer's card name */
  name: Scalars['String'];
};

/** Change subscription input */
export type TreasuryPaymentsChangeSubscriptionInput = {
  /** Coupon name */
  couponName?: InputMaybe<Scalars['String']>;
  /** Game that this subscription is associated with */
  game: TreasuryPaymentsGame;
  /** New desired plan */
  planId: Scalars['ID'];
};

/** Change subscription plan payload */
export type TreasuryPaymentsChangeSubscriptionPayload = {
  __typename?: 'TreasuryPaymentsChangeSubscriptionPayload';
  /** Error */
  error?: Maybe<TreasuryPaymentsError>;
  /** Payment intent secret for 3D Secure for instant payment */
  paymentIntentSecret?: Maybe<Scalars['String']>;
  /** Setup intent secret for 3D Secure, for using when trial, and no instant payment needed */
  setupIntentSecret?: Maybe<Scalars['String']>;
  /** The updated subscription */
  subscription?: Maybe<TreasuryPaymentsSubscription>;
};

/** Coupon that can be applied to a payment to get a discount by user */
export type TreasuryPaymentsCoupon = {
  __typename?: 'TreasuryPaymentsCoupon';
  /** Coupon discount. Can be a percentage or an amount */
  discount: TreasuryPaymentsCouponDiscount;
  /** Coupon name */
  name: Scalars['String'];
};

/** Discount that can be applied to a payment to get a discount by user */
export type TreasuryPaymentsCouponDiscount =
  | TreasuryPaymentsCouponDiscountAmount
  | TreasuryPaymentsCouponDiscountPercentage;

/** Discount that can be applied to a payment to get a discount by user */
export type TreasuryPaymentsCouponDiscountAmount = {
  __typename?: 'TreasuryPaymentsCouponDiscountAmount';
  /** Amount of discount */
  amount: TreasuryPaymentsMoney;
};

/** Discount that can be applied to a payment to get a discount by user */
export type TreasuryPaymentsCouponDiscountPercentage = {
  __typename?: 'TreasuryPaymentsCouponDiscountPercentage';
  /** Percentage of discount */
  percentage: Scalars['Float'];
};

/** Input to get plans with applied queries */
export type TreasuryPaymentsCouponPlansFilter = {
  /** Coupon name (code) */
  couponName: Scalars['String'];
  /** Filter plans by chosen game */
  game: TreasuryPaymentsGame;
  /** Filter plans by the availability of a trial */
  hasTrial?: InputMaybe<Scalars['Boolean']>;
};

/** Create subscription input */
export type TreasuryPaymentsCreateSubscriptionInput = {
  /** Coupon code */
  couponName?: InputMaybe<Scalars['String']>;
  /** Game that this subscription is associated with */
  game: TreasuryPaymentsGame;
  /** Plan that this subscription is associated with */
  planId: Scalars['ID'];
  /** The payment provider that this subscription is associated with */
  provider: TreasuryPaymentsProvider;
  /** Payment provider token */
  token?: InputMaybe<Scalars['String']>;
};

/** Create subscription payload */
export type TreasuryPaymentsCreateSubscriptionPayload = {
  __typename?: 'TreasuryPaymentsCreateSubscriptionPayload';
  /** Get a customer for authorized user */
  customer: TreasuryPaymentsCustomerOrError;
  /** Error */
  error?: Maybe<TreasuryPaymentsError>;
  /** Subscription id */
  id?: Maybe<Scalars['ID']>;
  /** Payment intent secret for 3D Secure for instant payment */
  paymentIntentSecret?: Maybe<Scalars['String']>;
  /** Setup intent secret for 3D Secure, for using when trial, and no instant payment needed */
  setupIntentSecret?: Maybe<Scalars['String']>;
  /** The created subscription */
  subscription?: Maybe<TreasuryPaymentsSubscription>;
};

/** Customer stores information about a user's subscription status and payment history. */
export type TreasuryPaymentsCustomer = {
  __typename?: 'TreasuryPaymentsCustomer';
  /** Actions that can be performed on the customer. */
  allowedActions?: Maybe<Array<TreasuryPaymentCustomerAction>>;
  /** Billing information associated with the customer. */
  billingInfo?: Maybe<TreasuryPaymentsBillingInfoOrError>;
  /** The ID of the customer. */
  id: Scalars['ID'];
  /**
   * Payment history associated with the customer.
   * Default sort is by createdAt descending.
   */
  paymentHistory: Array<TreasuryPaymentsPaymentOrError>;
  /**
   * Subscriptions associated with the customer.
   * Default sort is by createdAt descending.
   */
  subscriptions: Array<TreasuryPaymentsSubscriptionOrError>;
  /** Indicates if customer has a trial. */
  trial?: Maybe<TreasuryPaymentsCustomerTrialOrError>;
  /** The user associated with the customer. */
  user: User;
};

/** Customer stores information about a user's subscription status and payment history. */
export type TreasuryPaymentsCustomerallowedActionsArgs = {
  filter: TreasuryPaymentsCustomerActionsFilter;
};

/** Customer stores information about a user's subscription status and payment history. */
export type TreasuryPaymentsCustomersubscriptionsArgs = {
  filter?: InputMaybe<TreasuryPaymentsSubscriptionFilter>;
};

export type TreasuryPaymentsCustomerActionsFilter = {
  game: TreasuryPaymentsGame;
};

/** Result of querying for a customer. */
export type TreasuryPaymentsCustomerOrError =
  | TreasuryPaymentsCustomer
  | TreasuryPaymentsUnauthenticatedError
  | TreasuryPaymentsUnknownError;

/** Customer payload */
export type TreasuryPaymentsCustomerPayload = {
  __typename?: 'TreasuryPaymentsCustomerPayload';
  /** Customer info payload */
  customer?: Maybe<TreasuryPaymentsCustomer>;
  /** Error that may occur during the mutation */
  error?: Maybe<TreasuryPaymentsError>;
};

/** A customer trial. */
export type TreasuryPaymentsCustomerTrial = {
  __typename?: 'TreasuryPaymentsCustomerTrial';
  /** Time when the trial will expire/has expired. */
  expirationDate: Scalars['TreasuryPaymentsTime'];
  /** The ID of the trial. */
  id: Scalars['ID'];
  /** Indicates if the trial is active. */
  isActive: Scalars['Boolean'];
  /** Time when the trial was started. */
  startDate: Scalars['TreasuryPaymentsTime'];
  /** Subscription associated with the trial. */
  subscription: TreasuryPaymentsSubscriptionOrError;
};

/** Result of querying for a customer trial. */
export type TreasuryPaymentsCustomerTrialOrError = TreasuryPaymentsCustomerTrial | TreasuryPaymentsUnknownError;

/** Delete billing info input */
export type TreasuryPaymentsDeleteBillingInfoInput = {
  provider: TreasuryPaymentsProvider;
};

/** Delete billing info payload */
export type TreasuryPaymentsDeleteBillingInfoPayload = {
  __typename?: 'TreasuryPaymentsDeleteBillingInfoPayload';
  /** Is customer info deleted */
  deleteCustomerInfo: Scalars['Boolean'];
  /** Error that may occur during the mutation */
  error?: Maybe<TreasuryPaymentsError>;
};

/** The error interface for the TreasuryPayments API */
export type TreasuryPaymentsError = {
  /** The error message */
  message?: Maybe<Scalars['String']>;
};

/** Games which have plans to support payments. */
export enum TreasuryPaymentsGame {
  DEADLOCK = 'DEADLOCK',
  /** Destiny 2 */
  DESTINY_2 = 'DESTINY_2',
  /** Diablo 4 */
  DIABLO_4 = 'DIABLO_4',
  EXAMPLE_GAME = 'EXAMPLE_GAME',
  /** League of Legends */
  LOL = 'LOL',
  /** Legends of Runeterra */
  LOR = 'LOR',
  /** Lost Ark */
  LOST_ARK = 'LOST_ARK',
  /** Marvel Rivals */
  MARVEL_RIVALS = 'MARVEL_RIVALS',
  /** Monster Hunter Wilds */
  MHW = 'MHW',
  POE_2 = 'POE_2',
  /** Teamfight Tactics */
  TFT = 'TFT',
  THE_BAZAAR = 'THE_BAZAAR',
  /** Valorant */
  VALORANT = 'VALORANT',
}

/**
 * Money type. This is a custom scalar type that represents a monetary value
 * https://www.martinfowler.com/eaaCatalog/money.html
 */
export type TreasuryPaymentsMoney = {
  __typename?: 'TreasuryPaymentsMoney';
  /** Smallest currency unit. For example, cents for USD */
  amount: Scalars['Int'];
  /** Currency code. For example, USD for US dollars */
  currency: Scalars['String'];
};

/** Mutations list for Treasury Payments */
export type TreasuryPaymentsMutation = {
  __typename?: 'TreasuryPaymentsMutation';
  /** Cancel subscription */
  cancelSubscription: TreasuryPaymentsCancelSubscriptionPayload;
  /** Change subscription plan */
  changeSubscription: TreasuryPaymentsChangeSubscriptionPayload;
  /** Create subscription */
  createSubscription: TreasuryPaymentsCreateSubscriptionPayload;
  /** Delete billing info */
  deleteBillingInfo?: Maybe<TreasuryPaymentsCustomerPayload>;
  /** Returns Stripe setup intent secret to be used for updating billing info */
  prepareUpdateBillingInfo?: Maybe<TreasuryPaymentsPrepareUpdateBillingInfoPayload>;
  /** Renew subscription */
  renewSubscription: TreasuryPaymentsRenewSubscriptionPayload;
  /** Update billing info */
  updateBillingInfo?: Maybe<TreasuryPaymentsUpdateBillingInfoPayload>;
};

/** Mutations list for Treasury Payments */
export type TreasuryPaymentsMutationcancelSubscriptionArgs = {
  input: TreasuryPaymentsCancelSubscriptionInput;
};

/** Mutations list for Treasury Payments */
export type TreasuryPaymentsMutationchangeSubscriptionArgs = {
  input: TreasuryPaymentsChangeSubscriptionInput;
};

/** Mutations list for Treasury Payments */
export type TreasuryPaymentsMutationcreateSubscriptionArgs = {
  input: TreasuryPaymentsCreateSubscriptionInput;
};

/** Mutations list for Treasury Payments */
export type TreasuryPaymentsMutationdeleteBillingInfoArgs = {
  input: TreasuryPaymentsDeleteBillingInfoInput;
};

/** Mutations list for Treasury Payments */
export type TreasuryPaymentsMutationrenewSubscriptionArgs = {
  input: TreasuryPaymentsRenewSubscriptionInput;
};

/** Mutations list for Treasury Payments */
export type TreasuryPaymentsMutationupdateBillingInfoArgs = {
  input: TreasuryPaymentsUpdateBillingInfoInput;
};

export type TreasuryPaymentsPayPal = {
  __typename?: 'TreasuryPaymentsPayPal';
  /** The customer's PayPal email */
  email: Scalars['String'];
};

/** A payment is a transaction between a customer and a provider. */
export type TreasuryPaymentsPayment = {
  __typename?: 'TreasuryPaymentsPayment';
  /** Amount of payment */
  amount: TreasuryPaymentsMoney;
  /** The date and time when the payment was created. */
  date: Scalars['TreasuryPaymentsTime'];
  /** The unique identifier of the payment. */
  id: Scalars['ID'];
  /** Product that the payment is for. */
  product: TreasuryPaymentsProduct;
};

export type TreasuryPaymentsPaymentError = TreasuryPaymentsError & {
  __typename?: 'TreasuryPaymentsPaymentError';
  /** Payment error code */
  code: TreasuryPaymentsPaymentErrorCode;
  /** The error message */
  message: Scalars['String'];
};

export enum TreasuryPaymentsPaymentErrorCode {
  /** Error code for generic decline (lost or stolen card, insufficient funds) */
  DECLINED_CARD = 'DECLINED_CARD',
  /** Error code for expired card date */
  EXPIRED_CARD = 'EXPIRED_CARD',
  /** Error code for incorrect card number */
  INCORRECT_CARD_NUMBER = 'INCORRECT_CARD_NUMBER',
  /** Error code for incorrect cvc code */
  INCORRECT_CVC = 'INCORRECT_CVC',
  /** Error code for insufficient funds */
  INSUFFICIENT_FUNDS = 'INSUFFICIENT_FUNDS',
  /** Error code for processing error decline */
  PROCESSING_ERROR = 'PROCESSING_ERROR',
}

/** Payment query result. */
export type TreasuryPaymentsPaymentOrError = TreasuryPaymentsPayment | TreasuryPaymentsUnknownError;

/** Input for subscription on payment received events */
export type TreasuryPaymentsPaymentReceivedInput = {
  /** Filter by subscription id */
  subscriptionId?: InputMaybe<Array<Scalars['ID']>>;
};

/** A plan that a customer can subscribe to */
export type TreasuryPaymentsPlan = {
  __typename?: 'TreasuryPaymentsPlan';
  /** The coupon applied to the plan */
  appliedCoupon?: Maybe<TreasuryPaymentsCoupon>;
  /** The proration that was applied to plan's price */
  appliedProration?: Maybe<TreasuryPaymentsMoney>;
  /** The base price of the plan */
  basePrice: TreasuryPaymentsMoney;
  /** The final price of the plan (considering the coupon) */
  finalPrice: TreasuryPaymentsMoney;
  /** The ID of the plan */
  id: Scalars['ID'];
  /** The interval of the plan billing */
  interval: TreasuryPaymentsPlanInterval;
  /** The number of intervals between each billing */
  intervalCount: Scalars['Int'];
  /** Indicates if the plan is the best choice for the customer */
  isBestChoice: Scalars['Boolean'];
  /** Human readable name of the plan */
  name: Scalars['String'];
  /** The price of the plan per month */
  pricePerMonth: TreasuryPaymentsMoney;
  /** Product of the plan */
  product: TreasuryPaymentsProduct;
  /** Human readable profit of the plan */
  profit: Scalars['String'];
  /** Indicates if the plan has a trial */
  trial?: Maybe<TreasuryPaymentsTrialOrError>;
  /** Options for showing plan to the clients */
  viewOptions?: Maybe<PlanViewOptions>;
};

/** The interval of the plan billing */
export enum TreasuryPaymentsPlanInterval {
  /** The plan is billed on daily basis */
  DAILY = 'DAILY',
  /** The plan is billed on monthly basis */
  MONTHLY = 'MONTHLY',
  /** The plan is billed on yearly basis */
  YEARLY = 'YEARLY',
}

/** The result of a plans query */
export type TreasuryPaymentsPlanOrError =
  | TreasuryPaymentsBadRequestError
  | TreasuryPaymentsPlan
  | TreasuryPaymentsUnknownError;

/** Filter to be used when querying for plans */
export type TreasuryPaymentsPlansFilter = {
  /** Filter plans by chosen game */
  game: TreasuryPaymentsGame;
  /** Filter plans by the availability of a trial */
  hasTrial?: InputMaybe<Scalars['Boolean']>;
};

export type TreasuryPaymentsPrepareUpdateBillingInfoError =
  | TreasuryPaymentsUnauthenticatedError
  | TreasuryPaymentsUnknownError;

export type TreasuryPaymentsPrepareUpdateBillingInfoPayload = {
  __typename?: 'TreasuryPaymentsPrepareUpdateBillingInfoPayload';
  /** Customer info payload */
  customer?: Maybe<TreasuryPaymentsCustomer>;
  /** Error that may occur during the mutation */
  error?: Maybe<TreasuryPaymentsPrepareUpdateBillingInfoError>;
  /** Setup intent secret for updating billing info using Stripe elements */
  setupIntentSecret?: Maybe<Scalars['String']>;
};

export enum TreasuryPaymentsProduct {
  AD_FREE = 'AD_FREE',
  PLUS = 'PLUS',
}

/** Provides a list of payment providers that can be used for payments. */
export enum TreasuryPaymentsProvider {
  /** Braintree is a payment provider that allows for paypal payments. */
  BRAINTREE = 'BRAINTREE',
  /** Stripe is a payment provider that allows for credit card payments. */
  STRIPE = 'STRIPE',
}

/** Queries list for Treasury Payments */
export type TreasuryPaymentsQuery = {
  __typename?: 'TreasuryPaymentsQuery';
  /** Get a customer for authorized user */
  customer?: Maybe<TreasuryPaymentsCustomerOrError>;
  /** List of plans with applied coupon */
  plansWithCoupon?: Maybe<TreasuryPaymentsApplyCouponPayload>;
  /** List of of trials available */
  trials: Array<TreasuryPaymentsTrialOrError>;
};

/** Queries list for Treasury Payments */
export type TreasuryPaymentsQueryplansWithCouponArgs = {
  filter: TreasuryPaymentsCouponPlansFilter;
};

/** Queries list for Treasury Payments */
export type TreasuryPaymentsQuerytrialsArgs = {
  filter?: InputMaybe<TreasuryPaymentsTrialsFilter>;
};

/** Renew subscription input */
export type TreasuryPaymentsRenewSubscriptionInput = {
  /** Coupon name */
  couponName?: InputMaybe<Scalars['String']>;
  /** Game that this subscription is associated with */
  game: TreasuryPaymentsGame;
  /** New desired plan */
  planId: Scalars['ID'];
};

/** Renew subscription payload */
export type TreasuryPaymentsRenewSubscriptionPayload = {
  __typename?: 'TreasuryPaymentsRenewSubscriptionPayload';
  /** Error */
  error?: Maybe<TreasuryPaymentsError>;
  /** Payment intent secret for 3D Secure for instant payment */
  paymentIntentSecret?: Maybe<Scalars['String']>;
  /** Setup intent secret for 3D Secure, for using when trial, and no instant payment needed */
  setupIntentSecret?: Maybe<Scalars['String']>;
  /** The updated subscription */
  subscription?: Maybe<TreasuryPaymentsSubscription>;
};

export type TreasuryPaymentsStripe = {
  __typename?: 'TreasuryPaymentsStripe';
  billing: TreasuryPaymentsBillingAddress;
  card: TreasuryPaymentsCard;
};

/** Subscription is a recurring payment */
export type TreasuryPaymentsSubscription = {
  __typename?: 'TreasuryPaymentsSubscription';
  /** Time when the subscription was created */
  createdAt: Scalars['TreasuryPaymentsTime'];
  /** The customer who owns this subscription */
  customer: TreasuryPaymentsCustomerOrError;
  /** Unique identifier */
  id: Scalars['ID'];
  /** Next payment date */
  nextPaymentDate?: Maybe<Scalars['TreasuryPaymentsTime']>;
  /**
   * Payment history for this subscription
   * Default sort is by createdAt descending.
   */
  paymentHistory: Array<TreasuryPaymentsPaymentOrError>;
  /** Plan that this subscription is associated with */
  plan: TreasuryPaymentsPlan;
  /** The payment provider that this subscription is associated with */
  provider: TreasuryPaymentsProvider;
  /** State of the subscription */
  state: TreasuryPaymentsSubscriptionState;
  /** Trial associated with this subscription */
  trial?: Maybe<TreasuryPaymentsCustomerTrialOrError>;
};

/** Event that occurred on a subscription */
export enum TreasuryPaymentsSubscriptionChangedEvent {
  SUBSCRIPTION_ACTIVATED = 'SUBSCRIPTION_ACTIVATED',
  SUBSCRIPTION_CANCELED = 'SUBSCRIPTION_CANCELED',
  SUBSCRIPTION_CREATED = 'SUBSCRIPTION_CREATED',
  SUBSCRIPTION_DELETED = 'SUBSCRIPTION_DELETED',
  SUBSCRIPTION_PAYMENT_RECEIVED = 'SUBSCRIPTION_PAYMENT_RECEIVED',
  SUBSCRIPTION_WENT_PAST_DUE = 'SUBSCRIPTION_WENT_PAST_DUE',
}

/** Input for subscription on subscription changed events */
export type TreasuryPaymentsSubscriptionChangedInput = {
  /** Filter by subscription id */
  subscriptionId?: InputMaybe<Array<Scalars['ID']>>;
};

/** Payload for subscription changed events */
export type TreasuryPaymentsSubscriptionChangedPayload = {
  __typename?: 'TreasuryPaymentsSubscriptionChangedPayload';
  /** The customer associated with the subscription */
  customer: TreasuryPaymentsCustomerOrError;
  /** The event that occurred */
  event: TreasuryPaymentsSubscriptionChangedEvent;
  /** The subscription that was changed */
  subscription: TreasuryPaymentsSubscription;
};

/** Subscription filter */
export type TreasuryPaymentsSubscriptionFilter = {
  state?: InputMaybe<Array<TreasuryPaymentsSubscriptionState>>;
};

/** Subscription query result */
export type TreasuryPaymentsSubscriptionOrError = TreasuryPaymentsSubscription | TreasuryPaymentsUnknownError;

/** Subscription state */
export enum TreasuryPaymentsSubscriptionState {
  /** Subscription is active */
  ACTIVE = 'ACTIVE',
  /** Subscription is canceled */
  CANCELED = 'CANCELED',
  /** Subscription is deleted */
  DELETED = 'DELETED',
  /** Subscription is past due on the last payment */
  PAST_DUE = 'PAST_DUE',
  /** Subscription is waiting for the first payment */
  PENDING = 'PENDING',
  /** Subscription is in trial period */
  TRIAL = 'TRIAL',
}

/** A trial is a period of time during which a plan is available for free */
export type TreasuryPaymentsTrial = {
  __typename?: 'TreasuryPaymentsTrial';
  /** Duration of the trial */
  duration: Scalars['TreasuryPaymentsDuration'];
  /** The unique identifier of the trial */
  id: Scalars['ID'];
  /** Is trial available to use */
  isAvailable: Scalars['Boolean'];
  /** The plan that the trial is for */
  plan: TreasuryPaymentsPlanOrError;
};

/** The result of a trials query */
export type TreasuryPaymentsTrialOrError = TreasuryPaymentsTrial | TreasuryPaymentsUnknownError;

/** Filter for trials query. */
export type TreasuryPaymentsTrialsFilter = {
  /** Filter by availability of the trial */
  isAvailable?: InputMaybe<Scalars['Boolean']>;
};

/** The error returned when a user is not authenticated */
export type TreasuryPaymentsUnauthenticatedError = TreasuryPaymentsError & {
  __typename?: 'TreasuryPaymentsUnauthenticatedError';
  /** The error message */
  message?: Maybe<Scalars['String']>;
};

/** The error returned when a required argument is missing */
export type TreasuryPaymentsUnknownError = TreasuryPaymentsError & {
  __typename?: 'TreasuryPaymentsUnknownError';
  /** The error message */
  message?: Maybe<Scalars['String']>;
};

/** Update billing info input */
export type TreasuryPaymentsUpdateBillingInfoInput = {
  provider: TreasuryPaymentsProvider;
  token: Scalars['String'];
};

export type TreasuryPaymentsUpdateBillingInfoPayload = {
  __typename?: 'TreasuryPaymentsUpdateBillingInfoPayload';
  /** Customer info payload */
  customer?: Maybe<TreasuryPaymentsCustomer>;
  /** Error that may occur during the mutation */
  error?: Maybe<TreasuryPaymentsError>;
  /** Setup intent secret for 3D Secure, for using when trial, and no instant payment needed */
  setupIntentSecret?: Maybe<Scalars['String']>;
};

export type TreasuryPlusError = {
  /** The error message */
  message?: Maybe<Scalars['String']>;
};

export type TreasuryPlusInfo = {
  __typename?: 'TreasuryPlusInfo';
  hasPlus: Scalars['Boolean'];
};

export type TreasuryPlusInfoOrError = TreasuryPlusInfo | TreasuryPlusUnknownError;

export type TreasuryPlusUnknownError = TreasuryPlusError & {
  __typename?: 'TreasuryPlusUnknownError';
  /** The error message */
  message?: Maybe<Scalars['String']>;
};

export type TreasuryQuery = {
  __typename?: 'TreasuryQuery';
  flow?: Maybe<TreasuryFlowQuery>;
  id: Scalars['ID'];
  payments?: Maybe<TreasuryPaymentsQuery>;
};

export type Twitch = {
  __typename?: 'Twitch';
  live?: Maybe<Scalars['Boolean']>;
  login?: Maybe<Scalars['String']>;
};

export type User = {
  __typename?: 'User';
  account: TreasuryAccountsAccountOrError;
  /** Customer stores information about a user's subscription status and payment history. */
  customer: TreasuryPaymentsCustomerOrError;
  id: Scalars['ID'];
  plus: TreasuryPlusInfoOrError;
};

export type WidgetMeta = {
  __typename?: 'WidgetMeta';
  description: Scalars['String'];
  displayName: Scalars['String'];
  fullDescription?: Maybe<Scalars['String']>;
  type: NgfWidgetMetaType;
  typeName: Scalars['String'];
};
